import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import {
    Container,
    Grid,
    Typography,
    CircularProgress,
    makeStyles,
    useTheme,
    Divider,
    IconButton,
    Hidden,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Tabs,
    Tab,
    Box,
    Tooltip,
    Button,
} from "@material-ui/core";
import SpeedIcon from "@material-ui/icons/Speed";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routesApi, indicatorsApi } from "../../../services/api/axios";
import { AllCulturalRoutes } from "../../../data/Routes/AllCulturalRoutes";
import parse from "html-react-parser";
import RouteDetailsMap from "./RouteDetailsMap";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import ImgLinear from "../../../assets/img/img_linear.png";
import ImgCircular from "../../../assets/img/img_circular.png";
import ImgThematic from "../../../assets/img/img_thematic.png";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ImageGallery from "react-image-gallery";
import { AllHtmlEntities } from "html-entities";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import RouteDetailsIndicators from "./RouteDetailsIndicators";
import RouteIndicatorsBenchmark from "./RouteIndicatorsBenchmark";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: "white",
        paddingTop: "24px",
        paddingBottom: "24px",
        marginBottom: "24px",
        boxShadow: "0px 2px 4px var(--black-opacity6)",
        position: "relative",
    },
    containerWithBanner: {
        top: "-150px",
        marginTop: "0px",
    },
    wrapper: {
        overflowY: "auto",
    },
    country: {
        fontSize: "20px",
        textTransform: "uppercase",
        verticalAlign: "middle",
        display: "inline-block",
        marginLeft: "8px",
    },
    destinationName: {
        fontSize: "30px",
    },
    region: {
        fontSize: "14px",
    },
    divider: {
        marginTop: "12px",
        marginBottom: "12px",
    },
    dataCards: {
        paddingTop: "12px",
    },
    description: {
        textTransform: "initial",
        "& ul": {
            paddingInlineStart: "40px",
        },
    },
    mapContainer: {
        height: "350px",
    },
    graphHolder: {
        minHeight: "300px",
    },
    linkIcon: {
        textAlign: "center",
    },
    seasonsAccordion: {
        boxShadow: "none",
    },
    seasonsAccordionSummary: {
        paddingLeft: "0px",
        "& div": {
            marginTop: "4px",
            marginBottom: "4px",
        },
        "& .MuiButtonBase-root": {
            padding: "8px",
            transition: "all 0.2s ease",
            border: "1px solid #ffffff",
            "&:hover": {
                //boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                border: "1px solid #BBBBBB",
            },
        },
    },
    infoDialogContentWrapper: {
        padding: "0px 36px 24px 36px",
    },
    infoDialogItemTitle: {
        fontWeight: "500",
    },
    infoDialogItemBody: {},
    infoDialogTitle: {
        textAlign: "center",
    },
    actionButtonsWrapper: {
        display: "inline-block",
        float: "right",
        width: "100%",
    },
    editButton: {
        transition: "all 0.3s ease",
        float: "right",
        "&:hover": {
            backgroundColor: "var(--gallery-grey)",
            color: "rgba(0, 0, 0, 0.75)",
        },
    },
    deleteButton: {
        transition: "all 0.3s ease",
        color: "#e57373",
        float: "right",
        "&:hover": {
            backgroundColor: "#ffdfdf",
            color: "#ef5350",
        },
    },
    carouselImage: {
        width: "100%",
        maxHeight: "400px",
        minHeight: "300px",
        objectFit: "cover",
    },
    carousel: {},
    linksWrapper: {
        paddingTop: "24px",
    },
    backToMapButtonWrapper: {
        height: "70px",
        textAlign: "left",
    },
    backToMapButtonWrapperWithBanner: {
        top: "-300px",
        color: "rgb(206, 206, 206)",
        position: "relative",
        "& span": {
            color: "rgb(206, 206, 206) !important",
        },
        "& button:hover span": {
            color: "rgb(247, 247, 247) !important",
        },
    },
    backToMapButton: {
        backgroundColor: "transparent",
        marginTop: "11px",
        marginBottom: "11px",
        fontSize: "16px",
        paddingRight: "0px",
        paddingLeft: "0px",
        "&:hover svg": {
            marginRight: "8px",
        },
        "& p": {
            transition: "all 0.4s ease",
        },
        "&:hover span": {
            color: "rgba(0, 0, 0, 0.7)",
        },
        borderRadius: "10%",
    },
    backToMapButtonIcon: {
        marginRight: "4px",
        transition: "all 0.4s ease",
    },
    bannerWrapper: {
        width: "100%",
        maxHeight: "400px",
        minHeight: "300px",
        backgroundSize: "cover",
    },
    routePartStep: {
        "& svg": {
            color: "#006fba",
        },
        "& .MuiStepLabel-label": {
            fontWeight: "normal",
            color: "rgba(0, 0, 0, 0.9)",
        },
    },
    indicators: {
        paddingTop: "50px",
        flexGrow: 1,
        width: "100%",
    },
}));

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function DestinationDetails({ id, showMapView, allRoutes }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    let history = useHistory();
    const myRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
    const [route, setRoute] = useState(null);
    const [routeIndicators, setRouteIndicators] = useState([]);
    const [indicatorsLatest, setIndicatorsLatest] = useState([]);
    const [indicatorsAssAll, setIndicatorsAssAll] = useState([]);
    const [valueTab, setValueTab] = useState(0);
    const [openTooltip, setOpenTooltip] = useState(false);

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    const handleChangeIndex = index => {
        setValueTab(index);
    };

    const executeScroll = () => {
        setOpenTooltip(false);
        myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    const handleCloseTooltip = () => {
        setOpenTooltip(false);
    };

    const handleOpenTooltip = () => {
        setOpenTooltip(true);
    };

    useEffect(() => {
        const fetchRouteData = async () => {
            setIsLoading(true);
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                const indicators = await indicatorsApi.get("/indicators/latest");
                let indicatorsAll = [];
                const indAssAll = await indicatorsApi.get("/indicatorsass");
                let indicatorsAssAll = [];
                Promise.all(indicators.data.indicators.map(ind => indicatorsAll.push(ind))).then(
                    () => {
                        setIndicatorsLatest(indicatorsAll);
                    }
                );
                Promise.all(indAssAll.data.map(ind => indicatorsAssAll.push(ind))).then(() => {
                    setIndicatorsAssAll(indicatorsAssAll);
                });
                const result = await routesApi.get(`/routes/${id}`);
                await Promise.all(
                    result.data.images.map(async (image, index) => {
                        const res = await routesApi.get("/routes/download/" + image.image, {
                            responseType: "blob",
                        });
                        const file = new File([res.data], image.caption, {
                            type: res.data.type,
                        });
                        result.data.images[index] = {
                            file,
                            caption: image.caption,
                            preview: URL.createObjectURL(res.data),
                            id: image.image,
                        };
                    })
                ).then(async () => {
                    let parts = [];
                    let partsIndicators = [];
                    await Promise.all(
                        result.data.parts.map(async element => {
                            const resultPart = await routesApi.get(`/parts/${element}`);
                            parts.push(resultPart.data);
                            const resultPartIndicatorsHeader = await indicatorsApi.get(
                                `/indicatorsass/header/${resultPart.data.id}`
                            );
                            await Promise.all(
                                resultPartIndicatorsHeader.data.map(async header => {
                                    const indAss = await indicatorsApi.get(
                                        `/indicatorsass/${header.id}`
                                    );
                                    partsIndicators.push(indAss.data);
                                })
                            );
                        })
                    ).then(async () => {
                        result.data.description = AllHtmlEntities.decode(result.data.description);
                        result.data.parts = parts;
                        setRoute(result.data);
                        setRouteIndicators(partsIndicators);
                        setIsLoading(false);
                    });
                });
            } else {
                setRoute(AllCulturalRoutes.filter(route => route.id == id)[0]);
                setIsLoading(false);
            }
        };
        fetchRouteData();
    }, [id]);
    const handleEdit = () => {
        history.push(`/dashboard/edit-cultural-route/${id}`);
    };
    const handleDelete = async () => {
        const allImagesIds = route.images.map(image => image.id);
        allImagesIds.forEach(async id => {
            const response = await routesApi.delete("/routes/delete/" + id);
        });
        const routeDeleted = await routesApi.delete("/routes/" + id);
        history.push(`/dashboard/`);
    };

    var actionButtons = "";
    if (localStorage.getItem("token")) {
        actionButtons = (
            <div className={classes.actionButtonsWrapper}>
                <Tooltip title={t("forms.viewCulturalRoute.delete")}>
                    <IconButton className={classes.deleteButton} onClick={handleDelete}>
                        <DeleteRoundedIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("forms.viewCulturalRoute.edit")}>
                    <IconButton className={classes.editButton} onClick={handleEdit}>
                        <EditRoundedIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }
    var containerWithBannerClass = "";
    var buttonWithBannerClass = "";
    var content = <CircularProgress />;
    if (!isLoading) {
        if (process.env.REACT_APP_IS_DEV == 1) {
            console.log(route);
        }
        var pathTypeImage = "";
        if (route.type === "linear") {
            pathTypeImage = ImgLinear;
        } else if (route.type === "circular") {
            pathTypeImage = ImgCircular;
        } else if (route.type === "thematic") {
            pathTypeImage = ImgThematic;
        }
        var bannerImage = "";
        var imageCarousel = "";
        if (route.images.length > 0 && process.env.REACT_APP_IS_DATA_LIVE == 1) {
            var images = [];
            route.images.map(image => {
                var imageItem = {
                    original: image.preview,
                    thumbnail: image.preview,
                    originalTitle: image.caption,
                };
                images.push(imageItem);
            });
            containerWithBannerClass = classes.containerWithBanner;
            buttonWithBannerClass = classes.backToMapButtonWrapperWithBanner;
            bannerImage = (
                <div
                    className={classes.bannerWrapper}
                    style={{
                        background: `linear-gradient(to bottom, rgba(0,0,0,0.55) 10%, rgba(0,0,0,0)), url(${route.images[0].preview}) no-repeat 100% 50%`,
                        backgroundSize: "cover",
                    }}
                ></div>
            );
            imageCarousel = <ImageGallery showPlayButton={false} items={images} />;
        }
        content = (
            <Grid container alignItems="stretch" spacing={3}>
                <Grid item xs={12} md={7}>
                    <Grid container>
                        {/*actionButtons*/}
                        <Grid item xs={12}>
                            <img
                                src={pathTypeImage}
                                height="28px"
                                style={{ verticalAlign: "middle", display: "inline-block" }}
                            />
                            <Typography className={classes.country} color="textSecondary">
                                {route.type}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h3"
                                color="textPrimary"
                                className={classes.destinationName}
                            >
                                {route.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.region} color="textSecondary">
                                {route.geoLocation.coordinates}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography align="justify" className={classes.description}>
                                {parse(route.description)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12}>
                            {imageCarousel}
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} md={5}>
                        <Grid container>
                            <Grid item xs={12} className={classes.mapContainer}>
                                <RouteDetailsMap route={route} />
                            </Grid>
                            <Grid item xs={12} className={classes.linksWrapper}>
                                <Typography>
                                    <b>{t("routes.detailsPage.routeParts")}:</b>
                                </Typography>
                                <Stepper orientation="vertical">
                                    {route.parts.map((part, index) => (
                                        <Step key={index} className={classes.routePartStep}>
                                            <StepLabel>{part.title}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <Grid item>
                                    <Tooltip
                                        open={openTooltip}
                                        onClose={handleCloseTooltip}
                                        onOpen={handleOpenTooltip}
                                        title={t("routes.detailsPage.indicatorsView")}
                                        placement="right"
                                    >
                                        <Button
                                            size="medium"
                                            variant="outlined"
                                            color="primary"
                                            className="back-button"
                                            onClick={executeScroll}
                                        >
                                            <SpeedIcon className="back-button-icon" />
                                            &nbsp;{t("routes.detailsPage.indicatorsButton")}
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.linksWrapper}>
                                <Typography>
                                    <b>{t("routes.detailsPage.links")}:</b>
                                </Typography>
                                <List dense={true}>
                                    {route.links.map(link => (
                                        <ListItem key={link.link}>
                                            <a href={"//" + link.link} target="_blank">
                                                <IconButton>
                                                    <LinkRoundedIcon
                                                        style={{ marginRight: "8px" }}
                                                    />
                                                    <Typography>{link.caption}</Typography>
                                                </IconButton>
                                            </a>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            <Grid item xs={12} className={classes.linksWrapper}>
                                <Typography>
                                    <b>{t("routes.detailsPage.contacts")}:</b>
                                </Typography>
                                <List dense={true}>
                                    {route.contacts.map(contact => (
                                        <ListItem key={contact.contact}>
                                            <ListItemIcon>
                                                <AccountCircleIcon style={{ size: "35px" }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={contact.contact}
                                                secondary={contact.type}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={classes.indicators} ref={myRef}>
                        <Tabs
                            value={valueTab}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Indicators Values" />
                            <Tab label="Benchmarking" />
                        </Tabs>
                        <SwipeableViews
                            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                            index={valueTab}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={valueTab} index={0}>
                                <RouteDetailsIndicators
                                    routeIndicators={routeIndicators}
                                    indicatorsLatest={indicatorsLatest}
                                />
                            </TabPanel>
                            <TabPanel value={valueTab} index={1}>
                                <RouteIndicatorsBenchmark
                                    indicatorsAssAll={indicatorsAssAll}
                                    indicatorsLatest={indicatorsLatest}
                                    allRoutes={allRoutes}
                                />
                            </TabPanel>
                        </SwipeableViews>
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <Grid item xs={12} className={classes.mapContainer}>
                        <RouteDetailsMap route={route} />
                    </Grid>
                </Hidden>
            </Grid>
        );
    }
    return (
        <div className={classes.wrapper}>
            {bannerImage}
            <Container maxWidth="lg" className={buttonWithBannerClass}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        className={classes.backToMapButtonWrapper}
                        alignItems="center"
                    >
                        <IconButton className={classes.backToMapButton} onClick={showMapView}>
                            <ArrowBackIcon className={classes.backToMapButtonIcon} />
                            <Typography>{t("destinations.detailsPage.backToMap")}</Typography>
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" className={[classes.container, containerWithBannerClass]}>
                {content}
            </Container>
        </div>
    );
}

export default DestinationDetails;
