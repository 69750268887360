import { useState } from "react";
import {
    Grid,
    Typography,
    Button,
    Divider,
    TextField,
    Box,
    Tooltip,
    InputAdornment,
    Snackbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { indicatorsApi } from "../../services/api/axios";
import { whoAmI, getToken } from "../User/Auth";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    questionnaireInfo: {
        fontWeight: "450",
    },
    submitButton: {
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "5px",
        paddingBottom: "5px",
        marginTop: "15px",
    },
    resetButton: {
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "5px",
        paddingBottom: "5px",
        marginTop: "15px",
        marginRight: "5px",
    },
    submitButtonWrapper: {
        marginTop: "auto",
        marginBottom: "auto",
    },
}));

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const IndAssInput = ({ indicator, indAssId, stateChanger }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [value, setValue] = useState(indicator.value);
    const [severity, setSeverity] = useState();
    const [message, setMessage] = useState();
    const [open, setOpen] = useState(false);
    const [filled, setFilled] = useState(indicator.value);

    const handleChange = event => {
        setValue(event.target.value);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const handleClear = () => {
        setValue("");
    };

    const handleSubmit = async value => {
        if (value > 0 || value === "") {
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                let currentUserIdToken = "";
                let currentUser = "";
                if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                    currentUser = whoAmI();
                    if (currentUser) {
                        const idToken = await getToken();
                        if (idToken) {
                            currentUserIdToken = idToken;
                        }
                    }
                } else {
                    currentUser = JSON.parse(localStorage.getItem("user"));
                }
                let data = {
                    lastModified: Date.now().toString(),
                    lastModifiedBy: currentUser.email,
                    value: value,
                };
                indicatorsApi
                    .put(
                        `/indicatorsass/${indAssId}/${indicator.indicatorReference}`,
                        JSON.stringify(data),
                        {
                            headers: { Authorization: "Bearer " + currentUserIdToken },
                        }
                    )
                    .then(response => {
                        if (response.status === 200) {
                            setSeverity("success");
                            setMessage(t("messageRouteeval.success"));
                        } else {
                            setSeverity("error");
                            setMessage(t("messageRouteeval.error"));
                        }
                        setOpen(true);
                    });
            }
        } else {
            setSeverity("error");
            setMessage("Invalid value!");
            setOpen(true);
        }
    };

    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={10} lg={7} xl={6}>
                    <Typography variant="body2" color="textSecondary" className="form-input-label">
                        {`${indicator.indicatorReference}. `}
                        <span className={classes.questionnaireInfo}>
                            {indicator.coreIndicators}
                        </span>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={1} xl={1}>
                    <Tooltip
                        title={
                            <Typography fontSize={16}>{indicator.methodOfCalculation}</Typography>
                        }
                        arrow
                    >
                        <HelpOutlineIcon />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={2} xl={2}>
                    <TextField
                        type={"number"}
                        id="outlined-basic"
                        variant="outlined"
                        value={value}
                        onChange={handleChange}
                        InputProps={
                            indicator.type === "PERCENTAGE"
                                ? {
                                      endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                      ),
                                  }
                                : {
                                      endAdornment: (
                                          <InputAdornment position="end"></InputAdornment>
                                      ),
                                  }
                        }
                    />
                </Grid>
                <Grid item xs={12} className={classes.submitButtonWrapper}>
                    <Box alignItems="center" align="right">
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            className={classes.resetButton}
                            onClick={() => {
                                handleSubmit("");
                                handleClear();
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.submitButton}
                            disabled={!value}
                            onClick={() => {
                                handleSubmit(value);
                                filled === ""
                                    ? stateChanger(state => [...state, indicator.group])
                                    : stateChanger(state => [...state]);
                                setFilled(value);
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Divider className="input-divider" />
        </>
    );
};

export default IndAssInput;
