import Main from "./components/Main/Main";
import LocationState from "./context/Destinations/Location/LocationState";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import muiTheme from "./assets/mui/muiTheme";
import { config } from "./components/User/firebaseConfig";
import firebase from "firebase";
import "./assets/css/Styles.css";
import React, { useState, useEffect, useContext } from "react";
import AuthContext from "./context/auth/AuthContext";
import { whoAmI, isLoggedIn, checkRole, getToken } from "./components/User/Auth";
import { usersApi } from "./services/api/axios.js";
import ApplicationsContext from "./context/UserManagement/ApplicationsContext";

!firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);

    const [applications, setApplications] = useState(0);

    const readSession = () => {
        const currentUser = whoAmI();
        if (currentUser) {
            setIsLoggedIn(true);
            setUser(currentUser);
        }
    };

    useEffect(() => {
        const getNumOfRequests = async () => {
            if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                var currentUserIdToken = "";
                const currentUser = user;
                if (currentUser) {
                    const idToken = await getToken();
                    if (idToken) {
                        currentUserIdToken = idToken;
                        const result = await usersApi.get(`/applications`, {
                            headers: { Authorization: "Bearer " + currentUserIdToken },
                        });
                        if (result.data) {
                            var num = result.data.length;
                            setApplications(num);
                        }
                    }
                }
            }
        };
        if (user != null && role !== null) {
            if (role == "admin") {
                getNumOfRequests();
            }
        }
    }, [user, role]);

    useEffect(() => {
        readSession();
        if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
            var unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    setIsLoggedIn(true);
                    setUser(user);
                }
            });
            const cleanup = () => unsubscribe();
            return cleanup;
        }
    }, []);
    useEffect(() => {
        const checkRole = async () => {
            if (user) {
                if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                    var currentUserIdToken = "";
                    const currentUser = whoAmI();
                    if (currentUser) {
                        const idToken = await getToken();
                        if (idToken) {
                            currentUserIdToken = idToken;
                        }
                    }
                    const result = await usersApi.get(`/${user.email}`, {
                        headers: { Authorization: "Bearer " + currentUserIdToken },
                    });
                    if (result.data) {
                        setRole(result.data.role);
                    }
                } else {
                    setRole("admin");
                }
            }
        };
        checkRole();
    }, [user]);

    return (
        <div className="app-container">
            <AuthContext.Provider
                value={{ isLoggedIn, setIsLoggedIn, user, setUser, role, setRole }}
            >
                <ApplicationsContext.Provider value={{ applications, setApplications }}>
                    <ThemeProvider theme={muiTheme}>
                        <LocationState>
                            <BrowserRouter>
                                <Main />
                            </BrowserRouter>
                        </LocationState>
                    </ThemeProvider>
                </ApplicationsContext.Provider>
            </AuthContext.Provider>
        </div>
    );
}

export default App;
