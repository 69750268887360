import ImgLinear from "../../assets/img/img_linear.png";
import ImgCircular from "../../assets/img/img_circular.png";
import ImgThematic from "../../assets/img/img_thematic.png";

import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function InputCardSelect({ initialValue, routeType, isReadOnly }) {
    const { t } = useTranslation();

    const [selectedCard, setSelectedCard] = useState(initialValue);

    useEffect(() => {
        routeType(initialValue);
    }, [initialValue]);

    const routeTypeSelectOptions = [
        {
            id: "linear",
            value: "forms.inputs.routeType.linear",
            img: ImgLinear,
        },
        {
            id: "circular",
            value: "forms.inputs.routeType.circular",
            img: ImgCircular,
        },
        {
            id: "thematic",
            value: "forms.inputs.routeType.thematic",
            img: ImgThematic,
        },
    ];

    const handleCardSelect = clickedCard => {
        if (!isReadOnly) {
            clickedCard === selectedCard ? setSelectedCard(null) : setSelectedCard(clickedCard);
            clickedCard === selectedCard ? routeType(null) : routeType(clickedCard);
        }
    };

    return (
        <Grid container>
            {routeTypeSelectOptions.map(item => {
                return (
                    <Grid
                        item
                        key={`input-card-select-${item.id}`}
                        className={` 
                        ${
                            !isReadOnly ? "input-clickable" : "input-cursor-deafult"
                        }                        
                        ${
                            item.id === routeTypeSelectOptions.slice(-1)[0]["id"]
                                ? ""
                                : "input-card-select-container"
                        }                        
                        ${
                            isReadOnly && item.id !== initialValue
                                ? "input-card-select-container-hidden"
                                : null
                        }
                        `}
                    >
                        <Box
                            boxShadow={0}
                            className={
                                selectedCard === item.id && !isReadOnly
                                    ? "input-card-select-selected"
                                    : "input-card-select"
                            }
                            onClick={() => handleCardSelect(item.id)}
                        >
                            <Grid container alignItems="center" spacing={4}>
                                <Grid item>
                                    <img src={item.img} alt={item.id} width="80px" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" color="textSecondary">
                                        {t(item.value)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default InputCardSelect;
