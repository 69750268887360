import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LoginButton() {
    const { t } = useTranslation();

    return (
        <Link to="/login" className="button-login-link">
            <Button
                name="login"
                color="primary"
                variant="contained"
                disableElevation
                className="button-login"
            >
                {t("actions.signin")}
            </Button>
        </Link>
    );
}
export default LoginButton;
