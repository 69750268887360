import { DestinationsDataNuts } from "../../data/Nuts/DestinationsDataNuts";
import { DestinationsNutsLabelsEn } from "../../data/Nuts/DestinationsNutsLabelsEn";
import { AllCulturalRoutes } from "../../data/Routes/AllCulturalRoutes";

import { destinationsApi, routesApi } from "../../services/api/axios";

import React, { useContext, useEffect, useState } from "react";
import LocationContext from "../../context/Destinations/Location/LocationContext";
import { GET_LOCATION } from "../../context/types";
import {
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    InputBase,
    ListSubheader,
    ListItemText,
} from "@material-ui/core";
import { Search as IconSearch } from "@material-ui/icons";
import { UnfoldMore as IconSort } from "@material-ui/icons";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

const Accordion = withStyles({
    root: {
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummarySectionGroup = withStyles({
    root: {
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionSummarySectionName = withStyles({
    root: {
        minHeight: 48,
        "&$expanded": {
            minHeight: 48,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
    root: {
        display: "block",
    },
}))(MuiAccordionDetails);

function ListData({
    sortedDestinationsData,
    routesData,
    showDestinationDetailsView,
    showRouteDetailsView,
}) {
    const { t } = useTranslation();
    const [expandedDestinations, setExpandedDestinations] = useState("panelDestinations");
    const [expandedRoutes, setExpandedRoutes] = useState("panelRoutes");
    const [expanded, setExpanded] = useState();

    const handleChangeDestinations = panel => (event, newExpanded) => {
        setExpandedDestinations(newExpanded ? panel : false);
    };

    const handleChangeRoutes = panel => (event, newExpanded) => {
        setExpandedRoutes(newExpanded ? panel : false);
    };

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const { state, dispatch } = useContext(LocationContext);

    return (
        <div className="destination-route-list">
            <Accordion
                square
                expanded={expandedDestinations === "panelDestinations"}
                onChange={handleChangeDestinations("panelDestinations")}
                className="destination-list"
            >
                <AccordionSummarySectionGroup className="destination-list-section-container">
                    <Typography variant="body2" className="destination-route-list-section-group">
                        {t("list.destinations")}
                    </Typography>
                </AccordionSummarySectionGroup>
                <AccordionDetails>
                    {sortedDestinationsData.map((data, index) => (
                        <li key={`destination-section-${data.sectionName}`}>
                            <ul>
                                <Accordion
                                    square
                                    expanded={expanded === `panel-destinations-${index}`}
                                    onChange={handleChange(`panel-destinations-${index}`)}
                                >
                                    <AccordionSummarySectionName
                                        aria-controls={`panel-content-${index}`}
                                        id={`panel-header-${index}`}
                                    >
                                        <ListSubheader disableSticky>
                                            <Typography className="destination-route-list-section-name">
                                                {data.sectionName === "Undefined"
                                                    ? t("list.undefined")
                                                    : data.sectionName}
                                            </Typography>
                                        </ListSubheader>
                                    </AccordionSummarySectionName>
                                    <AccordionDetails>
                                        {data.destinations.map(destination => (
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    showDestinationDetailsView(destination)
                                                }
                                                key={`destination-${destination.name}`}
                                            >
                                                <ListItemText>
                                                    <Typography
                                                        variant="body2"
                                                        className="destination-route-list-name"
                                                    >
                                                        {destination.name}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            </ul>
                        </li>
                    ))}
                </AccordionDetails>
            </Accordion>

            <Accordion
                square
                expanded={expandedRoutes === "panelRoutes"}
                onChange={handleChangeRoutes("panelRoutes")}
                className="route-list"
            >
                <AccordionSummarySectionGroup className="route-list-section-container">
                    <Typography variant="body2" className="destination-route-list-section-group">
                        {t("list.culturalRoutes")}
                    </Typography>
                </AccordionSummarySectionGroup>
                <AccordionDetails>
                    {routesData.map((data, index) => (
                        <li key={`route-section-${data.sectionName}`}>
                            <ul>
                                <Accordion
                                    square
                                    expanded={expanded === `panel-routes-${index}`}
                                    onChange={handleChange(`panel-routes-${index}`)}
                                >
                                    <AccordionSummarySectionName
                                        aria-controls={`panel-content-${index}`}
                                        id={`panel-header-${index}`}
                                    >
                                        <ListSubheader disableSticky>
                                            <Typography className="destination-route-list-section-name">
                                                {data.sectionName === "Undefined" ||
                                                data.sectionName === "Null"
                                                    ? t("list.undefined")
                                                    : data.sectionName}
                                            </Typography>
                                        </ListSubheader>
                                    </AccordionSummarySectionName>
                                    <AccordionDetails>
                                        {data.routes.map(route => (
                                            <ListItem
                                                button
                                                onClick={() => showRouteDetailsView(route)}
                                                key={`route-${route.name}`}
                                            >
                                                <ListItemText>
                                                    <Typography
                                                        variant="body2"
                                                        className="destination-route-list-name"
                                                    >
                                                        {route.name}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            </ul>
                        </li>
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

function DestinationsSearch({ showDestinationDetailsView, showRouteDetailsView, allRoutes }) {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [destinationsData, setDestinationsData] = useState({
        data: [],
        sortedCountry: [],
        sortedAlphabet: [],
    });
    const [routesData, setRoutesData] = useState({ sortedTypes: [], sortedAlphabet: [] });
    const [sortTypeCountry, setSortTypeCountry] = useState(true);

    const [search, setSearch] = useState("");

    const handleSort = () => setSortTypeCountry(!sortTypeCountry);

    useEffect(() => {
        const fetchDestinationsData = async () => {
            setIsLoading(true);

            let data = DestinationsDataNuts;
            let dataLabels = DestinationsNutsLabelsEn;
            let dataRoutes = [];
            let dataRoutesSource = [];
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                const resultLabels = await destinationsApi.get("/labels");
                dataLabels = resultLabels.data;
                const result = await destinationsApi.get("/destinations");
                data = result.data;
                const routes = await routesApi.get("/routes");
                dataRoutesSource = routes.data;
            } else {
                dataRoutesSource = AllCulturalRoutes;
            }
            let newDataRoutes = [];
            dataRoutesSource
                .reduce((p, c) => (c.visible !== false && p.push(c), p), [])
                .map(route => {
                    newDataRoutes.push({
                        name: route.title,
                        _id: route.id,
                        type: route.type,
                        lat: parseFloat(route.geoLocation.latitude),
                        lng: parseFloat(route.geoLocation.longitude),
                    });
                });
            allRoutes(dataRoutesSource);
            dataRoutes = newDataRoutes.filter(route =>
                route.name.toUpperCase().includes(search.toUpperCase())
            );
            //start routes sorting
            let sortedRoutesAlphabet = Object.values(
                dataRoutes.reduce((r, e) => {
                    let firstLetter = e.name[0];

                    if (!r[firstLetter]) {
                        r[firstLetter] = {
                            sectionName: firstLetter,
                            routes: [
                                {
                                    name: e.name,
                                    _id: e._id,
                                    lat: e.lat,
                                    lng: e.lng,
                                },
                            ],
                        };
                    } else {
                        r[firstLetter].routes.push({
                            name: e.name,
                            _id: e._id,
                            lat: e.lat,
                            lng: e.lng,
                        });
                    }

                    return r;
                }, {})
            );
            dataRoutes = dataRoutes.reduce((r, a) => {
                r[a.type] = r[a.type] || [];
                r[a.type].push(a);
                return r;
            }, Object.create(null));
            let finalTypesRoutes = [];
            Object.entries(dataRoutes).map(([key, value]) => {
                finalTypesRoutes.push({
                    sectionName:
                        (typeof key[0] !== "undefined" ? key[0].toUpperCase() : "Undefined") +
                        key.slice(1),
                    routes: value,
                });
            });
            //end routes sorting
            setRoutesData({
                sortedTypes: finalTypesRoutes.sort((a, b) =>
                    a.sectionName.localeCompare(b.sectionName)
                ),
                sortedAlphabet: sortedRoutesAlphabet,
            });
            let destinations = data.filter(destination =>
                destination.nuts.mitomedName.toUpperCase().includes(search.toUpperCase())
            );
            destinations.sort((a, b) =>
                a.nuts.mitomedName.localeCompare(b.nuts.mitomedName, "es", { sensitivity: "base" })
            );

            let sortedDataCountry = [];
            let countries = [];
            destinations.forEach(element => {
                countries.push(element.nuts.country);
            });
            let uniqueCountries = countries.filter((item, index, array) => {
                return array.indexOf(item) === index;
            });
            uniqueCountries.sort((a, b) => a.localeCompare(b, "es", { sensitivity: "base" }));
            uniqueCountries.forEach((country, index) => {
                if (country === "undefined") {
                    sortedDataCountry.push({
                        sectionName: "Undefined",
                        destinations: [],
                    });
                } else {
                    sortedDataCountry.push({
                        sectionName: dataLabels[0][country],
                        destinations: [],
                    });
                }
                let destinationName = [];
                destinations.forEach(element => {
                    if (country === element.nuts.country) {
                        destinationName.push({
                            name: element.nuts.mitomedName,
                            _id: element._id,
                            lat: element.latitude,
                            lng: element.longitude,
                        });
                    }
                });
                sortedDataCountry[index].destinations = destinationName;
            });
            //zakomentiral ker meče napako. Potrebno je odkomentirati in popraviti
            sortedDataCountry.sort((a, b) =>
                a.sectionName.localeCompare(b.sectionName, "es", { sensitivity: "base" })
            );

            let sortedDataAlphabet = Object.values(
                destinations.reduce((r, e) => {
                    let firstLetter = e.nuts.mitomedName[0];

                    if (!r[firstLetter]) {
                        r[firstLetter] = {
                            sectionName: firstLetter,
                            destinations: [
                                {
                                    name: e.nuts.mitomedName,
                                    _id: e._id,
                                    lat: e.latitude,
                                    lng: e.longitude,
                                },
                            ],
                        };
                    } else {
                        r[firstLetter].destinations.push({
                            name: e.nuts.mitomedName,
                            _id: e._id,
                            lat: e.latitude,
                            lng: e.longitude,
                        });
                    }

                    return r;
                }, {})
            );

            setDestinationsData({
                data: data,
                sortedCountry: sortedDataCountry,
                sortedAlphabet: sortedDataAlphabet,
            });

            setIsLoading(false);
        };

        fetchDestinationsData();
    }, [search]);

    return (
        <Box className="destination-search-list">
            <List>
                <ListItem className="destination-search-bar">
                    <InputBase
                        className="destination-search-input"
                        placeholder={t("list.search")}
                        onChange={e => setSearch(e.target.value)}
                    />
                    <IconButton type="submit" disabled className="destination-search-icon">
                        <IconSearch />
                    </IconButton>
                    <IconButton
                        className="destination-sort-icon"
                        onClick={handleSort}
                        disableRipple
                    >
                        <IconSort />
                    </IconButton>
                </ListItem>
                {/*<Fragment>
                        {isLoading ? (
                            <div>Loading ...</div>
                        ) : (*/}
                <ListData
                    showDestinationDetailsView={showDestinationDetailsView}
                    showRouteDetailsView={showRouteDetailsView}
                    sortedDestinationsData={
                        sortTypeCountry
                            ? destinationsData.sortedCountry
                            : destinationsData.sortedAlphabet
                    }
                    routesData={
                        sortTypeCountry ? routesData.sortedTypes : routesData.sortedAlphabet
                    }
                />
                {/*})}
                    </Fragment>*/}
            </List>
        </Box>
    );
}

export default DestinationsSearch;
