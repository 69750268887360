import React, { useState } from "react";
import { MapContainer, TileLayer, CircleMarker, useMapEvents } from "react-leaflet";

const URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

const mapCenter = [45.90333, 15.59111];

function LocationMarker({ initialGeoLocation, routeGeoLocation, isReadOnly }) {
    const [routeLocation, setRouteLocation] = useState(initialGeoLocation);

    useMapEvents({
        click(e) {
            if (!isReadOnly) {
                setRouteLocation(e.latlng);
                routeGeoLocation({ latitude: e.latlng.lat, longitude: e.latlng.lng });
            }
        },
    });

    return routeLocation === null ? null : (
        <CircleMarker
            center={routeLocation}
            radius={7}
            fillColor="#006fba"
            fillOpacity="1"
            stroke={false}
            className={`${isReadOnly ? "input-route-location-marker-not-clickable" : null}`}
        />
    );
}

function InputRouteLocation({ initialValue, routeGeoLocation, isReadOnly }) {
    const initialGeoLocation =
        initialValue.latitude && initialValue.longitude
            ? [initialValue.latitude, initialValue.longitude]
            : mapCenter;

    return (
        <MapContainer
            key={`map-${isReadOnly}`}
            center={initialGeoLocation}
            minZoom={5}
            zoom={5}
            maxZoom={14}
            scrollWheelZoom={!isReadOnly}
            dragging={!isReadOnly}
            className={`${
                !isReadOnly ? "input-route-location-map-clickable" : "input-route-location-map"
            }`}
        >
            <TileLayer attribution={attribution} url={URL} />
            <LocationMarker
                initialGeoLocation={initialGeoLocation}
                routeGeoLocation={routeGeoLocation}
                isReadOnly={isReadOnly}
            />
        </MapContainer>
    );
}

export default InputRouteLocation;
