export const AnnualOccupancyUrbanisationLineGraphData = {
    dateTime:"2021-02-06T11:36:51.392+01:00",
    label:"Nights spent at tourist accommodation establishments by degree of urbanisation and by NUTS 2 regions (from 2012 onwards)",
    href:"http://ec.europa.eu/eurostat/wdds/rest/data/v2.1/json/en/tour_occ_nin2d?c_resid=TOTAL&unit=NR&nace_r2=I551-I553",
    source:"Eurostat",
    updated:"2020-12-01T23:00:00Z",
    extension:{
       datasetId:"tour_occ_nin2d",
       lang:"EN",
       description:null,
       subTitle:null
    },
    value:[
       {
          name:2012,
          "DEG1":775374,
          "DEG2":861446,
          "DEG3":2463471,
          TOTAL:4100291
       },
       {
          name:2013,
          "DEG1":787357,
          "DEG2":912301,
          "DEG3":2341582,
          TOTAL:4041240
       },
       {
          name:2014,
          "DEG1":827446,
          "DEG2":982158,
          "DEG3":2510016,
          TOTAL:4319620
       },
       {
          name:2015,
          "DEG1":828666,
          "DEG2":1072293,
          "DEG3":2790471,
          TOTAL:4691430
       },
       {
          name:2016,
          "DEG1":892659,
          "DEG2":1065277,
          "DEG3":3136929,
          TOTAL:5094865
       },
       {
          name:2017,
          "DEG1":1096785,
          "DEG2":1032000,
          "DEG3":3194360,
          TOTAL:5323145
       },
       {
          name:2018,
          "DEG1":1092318,
          "DEG2":1226870,
          "DEG3":3210959,
          TOTAL:5530146
       },
       {
          name:2019,
          "DEG1":1112868,
          "DEG2":1206159,
          "DEG3":3263982,
          TOTAL:5583008
       }
    ],
    graphDimensions:[
       {
          label:"Cities",
          key:"DEG1"
       },
       {
          label:"Towns and suburbs",
          key:"DEG2"
       },
       {
          label:"Rural areas",
          key:"DEG3"
       },
       {
          label:"Total",
          key:"TOTAL"
       }
    ],
    dimensions:{
       c_resid:{
          label:"c_resid",
          values:{
             TOTAL:"Total"
          }
       },
       unit:{
          label:"unit",
          values:{
             NR:"Number"
          }
       },
       "nace_r2":{
          label:"nace_r2",
          values:{
             "I551-I553":"Hotels; holiday and other short-stay accommodation; camping grounds, recreational vehicle parks and trailer parks"
          }
       },
       deg_urb:{
          label:"deg_urb",
          values:{
             "DEG1":"Cities",
             "DEG2":"Towns and suburbs",
             "DEG3":"Rural areas",
             TOTAL:"Total"
          }
       },
       geo:{
          label:"geo",
          values:{
             "ES13":"Cantabria"
          }
       },
       time:{
          label:"time",
          values:{
             "2012":"2012",
             "2013":"2013",
             "2014":"2014",
             "2015":"2015",
             "2016":"2016",
             "2017":"2017",
             "2018":"2018",
             "2019":"2019"
          }
       }
    },
    nuts:{
       "nuts2":"ES13",
       label:"Cantabria"
    }
 }