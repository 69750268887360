import { useTranslation } from "react-i18next";
import { Grid, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import AlertCancel from "../Dialogs/AlertCancel";
import { routesApi } from "../../services/api/axios";
import { AllHtmlEntities } from "html-entities";
import { whoAmI, getToken } from "../User/Auth";

import {
    Route,
    RouteImage,
    RouteLink,
    RouteContact,
    RouteLocation,
    RoutePart,
    RoutePoint,
    Point,
} from "./model/Route";

function FormEditCulturalRoute({
    titles,
    initialRoute,
    routeId,
    routeTitle,
    routeColor,
    routeVisible,
    routeType,
    routeDescription,
    routeImages,
    routeLinks,
    routeContacts,
    routeGeoLocation,
    routePartTitle,
    allImages,
    routePoints,
    validationMessage,
}) {
    const { t } = useTranslation();

    let history = useHistory();

    const handleSave = async () => {
        if (routeTitle) {
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                let currentUserIdToken = "";
                let currentUserEmail = "";
                if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                    const currentUser = whoAmI();
                    currentUserEmail = currentUser.email;
                    if (currentUser) {
                        const idToken = await getToken();
                        if (idToken) {
                            currentUserIdToken = idToken;
                        }
                    }
                }
                const formData = new FormData();
                let oldImagesIds = [];
                let oldCaptions = [];
                let captions = [];
                const allImagesIds = allImages.map(image => image.id);
                let currImagesIds = routeImages.map(image => image.id);
                const missingImages = allImagesIds.filter(
                    item => currImagesIds.indexOf(item) == -1
                );
                routeImages.forEach((file, index) => {
                    if (!file.id) {
                        formData.append("File[" + index + "]", file.file);
                        captions.push(file.caption);
                    } else {
                        oldImagesIds.push(file.id);
                        oldCaptions.push(file.caption);
                    }
                });
                missingImages.forEach(async id => {
                    const response = await routesApi.delete("/routes/delete/" + id, {
                        headers: { Authorization: "Bearer " + currentUserIdToken },
                    });
                });
                const response = await routesApi.post("/routes/upload", formData, {
                    headers: { Authorization: "Bearer " + currentUserIdToken },
                });
                if (response.data) {
                    const images = [];
                    oldImagesIds.forEach((id, index) => {
                        images.push(new RouteImage(id, oldCaptions[index]));
                    });
                    response.data.forEach((id, index) => {
                        images.push(new RouteImage(id, captions[index]));
                    });
                    const links = [];
                    routeLinks.forEach(link => {
                        if (link.caption !== "" || link.link !== "")
                            links.push(new RouteLink(link.link, link.caption));
                    });
                    const points = [];
                    if (routePoints !== null) {
                        routePoints.forEach(point => {
                            if (point.destinationId !== null) {
                                points.push(
                                    new RoutePoint(
                                        point.destinationId,
                                        point.name,
                                        point.latitude,
                                        point.longitude,
                                        point.position
                                    )
                                );
                            }
                        });
                    }
                    const contacts = [];
                    routeContacts.forEach(contact => {
                        if (contact.type !== "" || contact.contact !== "")
                            contacts.push(new RouteContact(contact.type, contact.contact));
                    });
                    const parts = [];
                    const allPartsIds = initialRoute.parts.map(part => part.id);
                    let currPartsIds = routePartTitle.map(part => part.id);
                    const missingParts = allPartsIds.filter(
                        item => currPartsIds.indexOf(item) == -1
                    );
                    missingParts.map(async id => {
                        const partsDeleted = await routesApi.delete("/parts/" + id, {
                            headers: { Authorization: "Bearer " + currentUserIdToken },
                        });
                        console.log(partsDeleted);
                        console.log(id + " deleted");
                    });
                    await Promise.all(
                        routePartTitle.map(async part => {
                            if (part.title !== "") {
                                const undef = "undefined";
                                const newPart = new RoutePart(
                                    part.title,
                                    undef,
                                    new RouteLocation(undef, undef, undef),
                                    [new RouteImage(undef, undef)],
                                    [new RouteLink(undef, undef)],
                                    [
                                        new Point(
                                            undef,
                                            undef,
                                            new RouteLocation(undef, undef, undef),
                                            [new RouteImage(undef, undef)],
                                            [new RouteLink(undef, undef)]
                                        ),
                                    ]
                                );
                                if (typeof part.id === "undefined") {
                                    const partId = await routesApi.post(
                                        "/parts",
                                        JSON.stringify(newPart),
                                        {
                                            headers: {
                                                Authorization: "Bearer " + currentUserIdToken,
                                            },
                                        }
                                    );
                                    parts.push(partId.data.id);
                                } else {
                                    const updatePart = await routesApi.put(
                                        "/parts/" + part.id,
                                        JSON.stringify(newPart),
                                        {
                                            headers: {
                                                Authorization: "Bearer " + currentUserIdToken,
                                            },
                                        }
                                    );
                                    console.log(updatePart);
                                    parts.push(part.id);
                                }
                            }
                        })
                    ).then(async () => {
                        const route = new Route(
                            routeTitle,
                            routeColor,
                            routeVisible,
                            routeType,
                            AllHtmlEntities.encode(routeDescription),
                            images,
                            links,
                            contacts,
                            new RouteLocation(
                                routeGeoLocation.latitude + ", " + routeGeoLocation.longitude,
                                routeGeoLocation.latitude.toString(),
                                routeGeoLocation.longitude.toString()
                            ),
                            parts,
                            points,
                            currentUserEmail
                        );
                        const updateRoute = await routesApi.put(
                            "/routes/" + routeId,
                            JSON.stringify(route),
                            { headers: { Authorization: "Bearer " + currentUserIdToken } }
                        );
                    });
                }
            }
            validationMessage(null);
            history.push(`/dashboard/cultural-route/${routeId}`);
        } else validationMessage("forms.editCulturalRoute.requiredField");
    };

    const [modalOpen, setModalOpen] = useState(false);

    let existChanges =
        (routeTitle && routeTitle !== initialRoute.title) ||
        (routeColor && routeColor !== initialRoute.routeColor) ||
        (routeDescription && routeDescription !== initialRoute.description) ||
        (routeVisible !== null && routeVisible !== initialRoute.visible) ||
        routeType !== initialRoute.type ||
        routeImages !== initialRoute.images ||
        routeLinks !== initialRoute.links ||
        routeContacts !== initialRoute.contacts ||
        (routeGeoLocation.latitude !== initialRoute.geoLocation.latitude &&
            routeGeoLocation.longitude !== initialRoute.geoLocation.longitude) ||
        routePartTitle !== initialRoute.parts;

    const handleCancel = () => {
        if (existChanges) setModalOpen(true);
        else {
            history.push(`/dashboard/cultural-route/${routeId}`);
            validationMessage(null);
        }
    };

    const handleConfirmAction = () => {
        history.push(`/dashboard/cultural-route/${routeId}`);
        validationMessage(null);
    };

    return (
        <>
            <Grid container alignItems="flex-end">
                <Grid item xs={12} sm={5}>
                    {titles && (
                        <Typography
                            variant="h5"
                            color="textSecondary"
                            className="title"
                            component="h2"
                        >
                            {t("forms.editCulturalRoute.pageTitle")}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={7} className="save-button-container">
                    <Grid container spacing={1} className="save-cancel-button">
                        <Grid item>
                            <Button
                                size="medium"
                                variant="contained"
                                color="primary"
                                className="save-button"
                                onClick={handleSave}
                            >
                                {t("forms.editCulturalRoute.save")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                size="medium"
                                variant="outlined"
                                color="primary"
                                className="cancel-button"
                                onClick={handleCancel}
                            >
                                {t("forms.editCulturalRoute.cancel")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <AlertCancel
                confirmAction={handleConfirmAction}
                modalOpen={modalOpen}
                modalClose={modalClose => setModalOpen(modalClose)}
            />
        </>
    );
}

export default FormEditCulturalRoute;
