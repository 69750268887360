import React from "react";

import {
    Box,
    Grid,
    Card,
    CardContent,
    Divider,
    IconButton,
    makeStyles,
    Typography,
    Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AnimatedShowMore from "react-animated-show-more";
import { AllHtmlEntities } from "html-entities";
import parse from "html-react-parser";

import ImgLinear from "../../assets/img/img_linear.png";
import ImgCircular from "../../assets/img/img_circular.png";
import ImgThematic from "../../assets/img/img_thematic.png";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";

const useStyles = makeStyles(theme => ({
    infoCard: {
        maxWidth: "400px",
        float: "right",
        boxShadow: "0px 0px 15px #787878",
        borderRadius: "15px",
    },
    description: {
        fontSize: 14,
        textTransform: "initial",
        "& ul": {
            paddingInlineStart: "40px",
        },
    },
    destinationInfo: {
        backgroundColor: "#ffffff",
        paddingBottom: "10px",
        //backgroundColor: '#006fba',
        //color: '#ffffff'
    },
    routeType: {
        paddingLeft: "5px",
    },
    routeTypeImage: {
        width: "100%",
    },
    routeTypeSubtitle: {
        marginTop: "5px",
    },
    hoverTitle: {
        fontSize: "15px !important",
        textTransform: "uppercase !important",
    },
    hoverCard: {
        float: "right",
        boxShadow: "0px 0px 15px #787878",
        borderRadius: "15px",
    },
    hoverCardTitle: {
        fontSize: "1.25rem",
    },
    hoverContent: {
        paddingTop: "16px !important",
        paddingBottom: "16px !important",
        paddingLeft: "24px !important",
        paddingRight: "24px !important",
    },
    fullInfo: {
        backgroundColor: "#ffffff",
        paddingBottom: "0px !important",
    },
    linkDivider: {
        marginTop: "15px",
    },
    routeLinksContainer: {
        paddingBottom: "5px !important",
    },
    homeButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

function RouteCard(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    if (process.env.REACT_ID_DEV_APP) {
        console.log(props.route);
    }

    const description = AllHtmlEntities.decode(props.route.description);

    let routeType = "";
    let routeLinks = [];
    let linkDivider = "";

    if (props.route.type === "thematic") {
        routeType = (
            <Box justifyContent="center">
                <Grid container>
                    <Grid item xs={1}>
                        <img src={ImgThematic} alt="Thematic" className={classes.routeTypeImage} />
                    </Grid>
                    <Grid item xs={11} className={classes.routeType}>
                        <Typography className={classes.hoverTitle} color="textSecondary">
                            Thematic
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    } else if (props.route.type === "circular") {
        routeType = (
            <Box justifyContent="center">
                <Grid container>
                    <Grid item xs={1}>
                        <img src={ImgCircular} alt="Circular" className={classes.routeTypeImage} />
                    </Grid>
                    <Grid item xs={11} className={classes.routeType}>
                        <Typography className={classes.hoverTitle} color="textSecondary">
                            Circular
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    } else if (props.route.type === "linear") {
        routeType = (
            <Box justifyContent="center">
                <Grid container>
                    <Grid item xs={1}>
                        <img src={ImgLinear} alt="Linear" className={classes.routeTypeImage} />
                    </Grid>
                    <Grid item xs={11} className={classes.routeType}>
                        <Typography className={classes.hoverTitle} color="textSecondary">
                            Linear
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    if (props.route.links.length > 0) {
        linkDivider = <Divider className={classes.linkDivider} />;
        routeLinks.push(
            <Typography
                variant="h5"
                style={{
                    fontSize: "15px",
                    fontWeight: "500",
                }}
            >
                Links
            </Typography>
        );

        for (let i = 0; i < props.route.links.length; i++) {
            routeLinks.push(
                <a href={props.route.links[i].link} target="_blank" rel="noreferrer">
                    <IconButton
                        style={{
                            backgroundColor: "transparent",
                            padding: "4px",
                            paddingLeft: "0px",
                        }}
                        disableRipple={true}
                        disableFocusRipple={true}
                    >
                        <Typography style={{ fontSize: "13px" }}>
                            {props.route.links[i].caption}
                        </Typography>
                    </IconButton>
                </a>
            );
        }
    }

    return (
        <Box m={2}>
            <Card className={classes.infoCard}>
                <Box p={1}>
                    <CardContent>
                        <CardContent className={classes.destinationInfo}>
                            <Box>
                                <Typography variant="h5" component="h2">
                                    <Tooltip
                                        title={t("destinations.card.findOutMore")}
                                        onClick={() => props.showRouteDetailsView(props.route)}
                                        style={{ cursor: "pointer" }}
                                        placement="right"
                                    >
                                        <span>{props.route.title}</span>
                                    </Tooltip>
                                </Typography>
                            </Box>
                            <Box className={classes.routeTypeSubtitle}>{routeType}</Box>
                        </CardContent>

                        <Divider />

                        <CardContent className={classes.fullInfo}>
                            <AnimatedShowMore
                                height={100}
                                toggle={({ isOpen }) =>
                                    isOpen ? (
                                        <Typography className={classes.description}>
                                            {t("destinations.card.showLess")}
                                        </Typography>
                                    ) : (
                                        <Typography className={classes.description}>
                                            {t("destinations.card.showMore")}
                                        </Typography>
                                    )
                                }
                                speed={350}
                            >
                                <Typography className={classes.description} align="justify">
                                    {parse(description)}
                                </Typography>
                            </AnimatedShowMore>
                        </CardContent>

                        {linkDivider}

                        <CardContent className={classes.routeLinksContainer}>
                            {routeLinks}
                            <Grid container>
                                <Grid item xs={12} className={classes.homeButton}>
                                    <IconButton
                                        style={{
                                            backgroundColor: "transparent",
                                            padding: "4px",
                                        }}
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        onClick={() => props.showRouteDetailsView(props.route)}
                                    >
                                        <HelpOutlineRoundedIcon style={{ paddingRight: "5px" }} />
                                        <Typography style={{ fontSize: "14px" }}>
                                            {t("destinations.card.findOutMore")}
                                        </Typography>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardContent>
                </Box>
            </Card>
        </Box>
    );
}

export function RouteHoverCard(props) {
    const classes = useStyles();

    let routeType = "";
    let xs;

    if (props.route.title.length > 10) {
        xs = 1;
    } else {
        xs = 4;
    }

    if (props.route.type === "thematic") {
        routeType = (
            <Box justifyContent="center" alignItems="center">
                <Grid container style={{ paddingRight: "16px" }}>
                    <Grid item xs={xs} style={{ marginTop: "4px", marginBottom: "auto" }}>
                        <img src={ImgThematic} alt="Thematic" className={classes.routeTypeImage} />
                    </Grid>
                    <Grid
                        item
                        xs={12 - xs}
                        className={classes.routeType}
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                        <Typography className={classes.hoverTitle} color="textSecondary">
                            Thematic
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    } else if (props.route.type === "circular") {
        routeType = (
            <Box justifyContent="center" alignItems="center">
                <Grid container style={{ paddingRight: "16px" }}>
                    <Grid item xs={xs} style={{ marginTop: "4px", marginBottom: "auto" }}>
                        <img src={ImgCircular} alt="Circular" className={classes.routeTypeImage} />
                    </Grid>
                    <Grid
                        item
                        xs={12 - xs}
                        className={classes.routeType}
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                        <Typography className={classes.hoverTitle} color="textSecondary">
                            Circular
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    } else if (props.route.type === "linear") {
        routeType = (
            <Box justifyContent="center" alignItems="center">
                <Grid container style={{ paddingRight: "16px" }}>
                    <Grid item xs={xs} style={{ marginTop: "4px", marginBottom: "auto" }}>
                        <img src={ImgLinear} alt="Linear" className={classes.routeTypeImage} />
                    </Grid>
                    <Grid
                        item
                        xs={12 - xs}
                        className={classes.routeType}
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                        <Typography className={classes.hoverTitle} color="textSecondary">
                            Linear
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <Card className={classes.hoverCard}>
            <CardContent className={classes.hoverContent}>
                <Box className={classes.routeTypeSubtitle}>{routeType}</Box>
                <Box>
                    <Typography variant="h5" component="h2" className={classes.hoverCardTitle}>
                        <span>{props.route.title}</span>
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}

export default RouteCard;
