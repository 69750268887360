import React, { useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function InputLinks({ initialValue, routeLinks, isReadOnly }) {
    const { t } = useTranslation();

    const initialLinks = initialValue.length > 0 ? initialValue : [{ caption: "", link: "" }];

    const [links, setLinks] = useState(initialLinks);

    const handleInputChange = (index, event) => {
        const values = [...links];

        if (event.target.name === "caption") {
            values[index].caption = event.target.value;
        } else {
            values[index].link = event.target.value;
        }

        setLinks(values);
        routeLinks(values);
    };

    const handleAddInputLink = () => {
        const values = [...links];
        values.push({ caption: "", link: "" });
        setLinks(values);
    };

    const handleRemoveInputLink = index => {
        const values = [...links];
        values.splice(index, 1);
        setLinks(values);
        routeLinks(values);
    };

    return (
        <>
            {links.map((link, index) => {
                return (
                    <Grid
                        container
                        key={`input-link-${index}`}
                        className="input-link-container"
                        alignItems="center"
                    >
                        <Grid item className="input-link-inputs-container">
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        variant="outlined"
                                        label={t("forms.inputs.links.caption")}
                                        name="caption"
                                        value={link.caption}
                                        onChange={event => handleInputChange(index, event)}
                                        className="input-link-text-field"
                                        fullWidth
                                        disabled={isReadOnly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <TextField
                                        variant="outlined"
                                        label={t("forms.inputs.links.link")}
                                        name="link"
                                        value={link.link}
                                        onChange={event => handleInputChange(index, event)}
                                        className="input-link-text-field"
                                        fullWidth
                                        disabled={isReadOnly}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {isReadOnly ? null : (
                            <Grid item className="input-link-remove-btn-container">
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleRemoveInputLink(index)}
                                    className="input-link-remove-btn"
                                >
                                    {t("forms.inputs.links.remove")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                );
            })}
            {isReadOnly ? null : (
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className="input-link-add-more-btn"
                    onClick={() => handleAddInputLink()}
                >
                    {t("forms.inputs.links.addMore")}
                </Button>
            )}
        </>
    );
}

export default InputLinks;
