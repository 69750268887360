import IconArrowBack from "@material-ui/icons/ArrowBack";

import AlertDelete from "../Dialogs/AlertDelete";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { routesApi, usersApi } from "../../services/api/axios";
import { whoAmI, getToken } from "../User/Auth";

function FormViewCulturalRoute({ titles, routeId, allImages, allParts, author }) {
    const { t } = useTranslation();
    const [isAuthorOrAdmin, setIsAuthorOrAdmin] = useState(false);

    useEffect(async () => {
        const currentUser = whoAmI();
        if (currentUser) {
            let email = currentUser.email;
            if (email === author) {
                setIsAuthorOrAdmin(true);
            }
            else {
                const idToken = await getToken();
                if (idToken) {
                    const user = await usersApi.get(`/${email}`, {
                        headers: {
                            Authorization: "Bearer " + idToken,
                        },
                    });
                    if (user.data.role === "admin") {
                        setIsAuthorOrAdmin(true);
                    }
                }
            }
        }

    }, [])

    let history = useHistory();
    const handleEdit = () => {
        history.push(`/dashboard/edit-cultural-route/${routeId}`);
    };

    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleDelete = async () => {
        if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
            let currentUserIdToken = "";
            if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                const currentUser = whoAmI();
                if (currentUser) {
                    const idToken = await getToken();
                    if (idToken) {
                        currentUserIdToken = idToken;
                    }
                }
            }
            const allImagesIds = allImages.map(image => image.id);
            allImagesIds.forEach(async id => {
                const response = await routesApi.delete("/routes/delete/" + id, {
                    headers: { Authorization: "Bearer " + currentUserIdToken },
                });
                console.log(response);
                console.log(id + " deleted");
            });
            allParts.map(async part => {
                const partsDeleted = await routesApi.delete("/parts/" + part.id, {
                    headers: { Authorization: "Bearer " + currentUserIdToken },
                });
                console.log(partsDeleted);
                console.log(part.id + " deleted");
            });
            const routeDeleted = await routesApi.delete("/routes/" + routeId, {
                headers: { Authorization: "Bearer " + currentUserIdToken },
            });
            console.log(routeDeleted);
        }
        history.push(`/dashboard/`);
    };

    return (
        <>
            <Grid container alignItems="flex-end">
                <Grid item xs={12} sm={4}>
                    {titles && (
                        <Typography
                            variant="h5"
                            color="textSecondary"
                            className="title"
                            component="h2"
                        >
                            {t("forms.viewCulturalRoute.pageTitle")}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={8} className="edit-delete-button-container">
                    <Grid container spacing={1} className="edit-delete-button">
                        <Grid item>
                            <Link to="/dashboard">
                                <Button
                                    size="medium"
                                    variant="outlined"
                                    color="primary"
                                    className="back-button"
                                >
                                    <IconArrowBack className="back-button-icon" />
                                    {t("forms.viewCulturalRoute.backToDashboard")}
                                </Button>
                            </Link>
                        </Grid>
                        {isAuthorOrAdmin ? (
                            <>
                                <Grid item>
                                    <Button
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        className="edit-button"
                                        onClick={handleEdit}
                                    >
                                        {t("forms.viewCulturalRoute.edit")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        className="delete-button"
                                        onClick={handleModalOpen}
                                    >
                                        {t("forms.viewCulturalRoute.delete")}
                                    </Button>
                                </Grid>
                            </>
                        ) : ('')}

                    </Grid>
                </Grid>
            </Grid>
            <AlertDelete
                confirmAction={handleDelete}
                modalOpen={modalOpen}
                modalClose={modalClose => setModalOpen(modalClose)}
                textTitle={t("forms.viewCulturalRoute.modalDeleteConfirmation.title")}
                textDescription={t("forms.viewCulturalRoute.modalDeleteConfirmation.description")}
                textDelete={t("forms.viewCulturalRoute.modalDeleteConfirmation.delete")}
                textCancel={t("forms.viewCulturalRoute.modalDeleteConfirmation.cancel")}
            />
        </>
    );
}

export default FormViewCulturalRoute;
