import React, { useState } from "react";
import { MenuItem, Menu, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { withRouter } from "react-router-dom";

function LanguageSwitcher({ location }) {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const languages = i18n.options.supportedLngs.slice(0, -1);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = event => {
        i18n.changeLanguage(event.currentTarget.dataset.value);
        setAnchorEl(null);
    };

    // NOTE: findDOMNode error because of material-ui menu (https://stackoverflow.com/questions/61115871/finddomnode-error-on-react-material-ui-select-menu)
    return (
        <>
            <Button
                variant="contained"
                onClick={handleClick}
                className="language-switcher-button"
                disableElevation
                disableRipple
            >
                <ReactCountryFlag
                    countryCode={currentLanguage.split("-")[1]}
                    svg
                    className="language-switcher-item-flag"
                />
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                PaperProps={{
                    style: {
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        boxShadow: "0px 5px 5px 2px rgba(0, 0, 0, 0.1)",
                    },
                }}
                className="language-switcher-dropdown-container"
                disableAutoFocusItem
            >
                {languages.map(
                    language =>
                        language !== currentLanguage && (
                            <MenuItem
                                data-value={language}
                                value={language}
                                key={language}
                                className={
                                    location.pathname === "/" || location.pathname === "/login"
                                        ? "language-switcher-item-floating"
                                        : "language-switcher-item"
                                }
                                onClick={handleLanguageChange}
                            >
                                <ReactCountryFlag
                                    countryCode={language.split("-")[1]}
                                    svg
                                    className="language-switcher-item-flag"
                                />
                            </MenuItem>
                        )
                )}
            </Menu>
        </>
    );
}

export default withRouter(LanguageSwitcher);
