import React, { useContext, useEffect, useState } from "react";
import { AllCulturalRoutes } from "../../data/Routes/AllCulturalRoutes";
import {
    Container,
    Grid,
    Typography,
    Button,
    IconButton,
    makeStyles,
    Select,
    MenuItem,
    FormControl,
    Tooltip
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/auth/AuthContext";
import { completedFirstTime, completedFirstTimeForever, completedFirstTimeForNow } from "../User/Auth";
import BestmedLogo from "../../assets/img/logo_interreg.svg";
import TouchApp from "../../assets/img/touch_app.svg";
import Settings from "../../assets/img/settings.svg";
import Equalizer from "../../assets/img/equalizer.svg";
import Public from "../../assets/img/public.svg";
import Explore from "../../assets/img/explore.svg";
import SupervisedUser from "../../assets/img/supervised_user.svg";
import LogoUM from "../../assets/img/logo-um_en.svg";
import { useHistory, Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    darkBackground: {
        backgroundColor: "#EFEFEF",
        paddingLeft: "135px",
        paddingRight: "135px",
    },
    lightBackground: {
        backgroundColor: "#FFFFFF",
        paddingLeft: "135px",
        paddingRight: "135px",
    },
    blueBackground: {
        backgroundColor: "#10458C",
        paddingLeft: "135px",
        paddingRight: "135px",
    },
    bestmedLogo: {
        height: "53px",
        width: "161px",
        position: "relative",
        left: "-15px",
        marginBottom: "69px"
    },
    sectionHeading: {
        fontFamily: "'Ubuntu', sans-serif",
        color: "#10458C",
        fontSize: "29px",
        fontWeight: "700"
    },
    sectionSubHeading: {
        fontFamily: "'Ubuntu', sans-serif",
        color: "#10458C",
        fontSize: "20px",
        fontWeight: "400"
    },
    normalText: {
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "300",
        fontSize: "15px",
        lineHeight: "20px"
    },
    enterButton: {
        width: "100%",
        height: "58px",
        boxShadow: "0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
        textTransform: "none",
        fontSize: "15px",
        fontWeight: "700"
    },
    featureCard: {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "20px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001F",
        borderRadius: "4px",
        minHeight: "260px"

    },
    featureCardIcon: {
        marginTop: "27px",
        width: "63px",
        height: "50px"
    },
    stakeholdersIcon: {
        width: "50px",
        height: "50px",
        display: "inline-block"
    },
    stakeholdersText: {
        display: "inline-block"
    },
    link: {
        marginLeft: "4px",
        marginRight: "4px",
        color: "inherit",
        textDecoration: "underline"
    }
}));

function WelcomePage() {
    const { t } = useTranslation();
    const classes = useStyles();
    let history = useHistory();
    let Auth = useContext(AuthContext);

    return (
        <div style={{ backgroundColor: "#EAEAEA" }}>
            <Grid container alignItems="flex-end" style={{ maxWidth: "1200px", marginLeft: "auto", marginRight: "auto" }}>
                <Grid item xs={12}>
                    <div
                        className={classes.lightBackground}
                        style={{
                            paddingTop: "55px",
                            paddingBottom: "35px"
                        }}
                    >
                        <img src={BestmedLogo} className={classes.bestmedLogo} />
                        <Grid item xs={12}>
                            <Typography className={classes.sectionHeading}>
                                Sustainability indicators for cultural routes & tourism destinations
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.sectionSubHeading}>
                                A better coordination and touristic planning for a more sustainable tourism
                            </Typography>
                        </Grid>
                    </div>
                    <div
                        className={classes.lightBackground}
                        style={{
                            paddingBottom: "50px",
                            paddingTop: "15px",
                            backgroundColor: "#10458C",
                            color: "white"
                            
                        }}
                    >
                        <Grid item xs={12} style={{
                            marginTop: "40px",
                        }}>
                            <Typography className={classes.normalText} style={{fontSize: "17px"}}>
                                <Bold>Interreg Mediterranean BEST MED</Bold> project aims to <Bold>enhance Mediterranean Governance</Bold>, being the main challenges to tackle seasonality and the lack of effective cooperation among main tourism actors, including citizen participation on the policies design.
                                <br />
                                More information is available at:
                                <a
                                    title="About the project"
                                    href="https://best-med.interreg-med.eu/what-we-do/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classes.link}
                                >
                                    <Bold>about the project.</Bold>
                                </a>
                            </Typography>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.darkBackground}
                        style={{
                            paddingTop: "60px",
                            paddingBottom: "63px",
                        }}>
                        <Grid item xs={12}>
                            <Typography className={classes.sectionHeading}>
                                BEST MED Open platform
                            </Typography>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Typography className={classes.normalText}>
                                    This platform allows the exchange of experiences and information among the stakeholders, contributing a method by which the competitiveness of tourist destinations can be achieved for designing policies, benchmarking and monitoring the results of the strategies and policies.
                                    <br />
                                    You are invited to
                                    <span
                                        onClick={() => { if (!Auth.isLoggedIn) { history.push("/apply-for-account") } else { history.push("/dashboard") } }}
                                        className={classes.link}
                                        style={{ cursor: "pointer" }}
                                    >
                                        apply for the account
                                    </span>
                                    to use all platform features.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} lg={2} style={{ textAlign: "center", minWidth: "240px" }}>
                                <Button className={classes.enterButton}
                                    style={{
                                        backgroundColor: "#82B422",
                                        color: "#FFFFFF",
                                        marginBottom: "24px"
                                    }}
                                    onClick={() => {
                                        completedFirstTimeForNow();
                                        history.push("/");
                                    }}
                                >
                                    Enter platform
                                </Button>
                                <Button className={[classes.enterButton, classes.normalText]}
                                    style={{
                                        backgroundColor: "#FFFFFF",
                                        color: "#1B1B1B",
                                        fontWeight: "300",
                                        fontSize: "15px"
                                    }}
                                    onClick={() => {
                                        completedFirstTimeForever();
                                        history.push("/");
                                    }}
                                >
                                    Enter platform and never show this again
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={8} justifyContent="space-between">
                            <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                                <Typography className={classes.sectionSubHeading} style={{ fontWeight: "700" }}>
                                    Platform features
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FeatureCard
                                    title="Interactive Tool"
                                    text="Best Med open platform is an interactive tool for your data input in order to measure compliance with the Med Sustainable Path & Cultural Route model."
                                    icon={TouchApp}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FeatureCard style={{ marginLeft: "auto", marginRight: "auto" }}
                                    title="Destination management"
                                    text="The results of gathering the indicators underline the importance of involving all tourism stakeholders in destination management."
                                    icon={Settings}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FeatureCard style={{ float: "right" }}
                                    title="Tourism analysis "
                                    text="Indicators help tourism stakeholders analyse tourism's current impact on local economies, environments, and societies."
                                    icon={Equalizer}
                                />
                            </Grid>

                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        className={classes.lightBackground}
                        style={{
                            paddingTop: "60px",
                            paddingBottom: "60px"
                        }}
                    >
                        <Grid container spacing={4} justifyContent="space-between">
                            <Grid item xs={12}>
                                <Typography className={classes.sectionSubHeading} style={{ fontWeight: "700" }}>
                                    Better decision making and planning in tourism
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.normalText} style={{ maxWidth: "635px" }}>
                                    The platform's content allows better decision making and planning as well as improved policy environment and processes in tourism.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <hr style={{ border: "none", backgroundColor: "#DEDEDE", height: "1px", width: "100%" }} />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography className={classes.normalText}>
                                    The platform can be used by the following stakeholders:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Grid container>
                                    <Grid item xs style={{ maxWidth: "70px" }}>
                                        <img src={SupervisedUser} className={classes.stakeholdersIcon} />

                                    </Grid>
                                    <Grid item xs style={{ maxWidth: "200px" }}>
                                        <Typography className={[classes.normalText, classes.stakeholdersText]}>
                                            <Bold>Managers</Bold> and <Bold>coordinators</Bold> of the Cultural Routes of the Council of Europe and other noncertified cultural routes.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Grid container>
                                    <Grid item xs style={{ maxWidth: "70px" }}>
                                        <img src={Explore} className={classes.stakeholdersIcon} />

                                    </Grid>
                                    <Grid item xs style={{ maxWidth: "200px" }}>
                                        <Typography className={[classes.normalText, classes.stakeholdersText]}>
                                            National, regional and local <Bold>public authorities</Bold> related to <Bold>culture and tourism</Bold>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Grid container>
                                    <Grid item xs style={{ maxWidth: "70px" }}>
                                        <img src={Public} className={classes.stakeholdersIcon} />

                                    </Grid>
                                    <Grid item xs style={{ maxWidth: "200px" }}>
                                        <Typography className={[classes.normalText, classes.stakeholdersText]}>
                                            Other <Bold>interest groups</Bold>, including <Bold>NGOs</Bold>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        className={classes.darkBackground}
                        style={{
                            paddingTop: "53px",
                            paddingBottom: "60px"
                        }}
                    >
                        <Grid item xs={12}>
                            <Typography className={classes.sectionHeading} style={{  }}>
                                MITOMED+
                            </Typography>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={7} md={8} lg={8}>
                                <Typography className={classes.normalText} style={{  }}>
                                    Mitomed+ is a Interreg MED funded project that supports involved regions in developing
                                    a responsible and sustainable maritime and coastal (M&C) tourism.
                                    <br />
                                    <br />
                                    The output of the projects was a system of indicators and an online common open platform for the observation and monitoring of social and environmental impacts of M&C tourism, which is also implemented into the Best Med Open platform.
                                    <br />
                                    <br />
                                    Mitomed+ is an open platform with indicators related to maritime and coastal tourism.
                                    <br/>
                                    <br/>
                                    For detailed information about the Mitomed+ platform, contact the<a href="mailto:bestmed@um.si" className={classes.link} style={{marginRight: "0px"}}><u>administrator</u></a>.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5} md={4} lg={4} style={{ textAlign: "center" }}>
                                <a
                                    title="Mitomed+"
                                    href="https://mitomed-plus.interreg-med.eu/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Button className={classes.enterButton}
                                        style={{
                                            backgroundColor: "#82B422",
                                            color: "#FFFFFF",
                                            marginBottom: "24px",
                                            maxWidth: "247px",
                                            float: "right",
                                            fontSize: "18px",
                                            fontWeight: "700",
                                            fontFamily: "Ubuntu"
                                        }}
                                    >
                                        Visit Mitomed+
                                    </Button>
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                    
                </Grid>
                <Grid item xs={12} className={classes.darkBackground}>
                <hr style={{ border: "none", backgroundColor: "#DEDEDE", height: "1px", width: "100%" }} />
                    <div
                        
                        style={{
                            paddingTop: "53px",
                            paddingBottom: "60px"
                        }}
                    >
                        <Grid container spacing={2}>

                            <Grid item xs={4}
                                style={{
                                    textAlign: "left"
                                }}
                            >
                                <Typography className={classes.normalText}>
                                    About
                                    <a
                                        title="About Best Med project"
                                        href="https://best-med.interreg-med.eu/what-we-do/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className={classes.link}
                                    >
                                        BEST MED project
                                    </a>
                                    <br />
                                    <br />
                                    For instructions on using the platform download the manual.
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: "center" }}>
                                <a
                                    title="University of Maribor"
                                    href="https://www.um.si/en/Pages/default.aspx"
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classes.link}>
                                    <img src={LogoUM} style={{
                                        width: "98px",
                                        height: "57px"
                                    }} />
                                </a>
                            </Grid>
                            <Grid item xs={4}
                                style={{
                                    textAlign: "right"
                                }}
                            >
                                <Typography className={classes.normalText}>
                                    Project co-financed by the European Regional Development Fund.
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

function FeatureCard({ icon, title, text }) {
    const classes = useStyles();

    return (
        <div className={classes.featureCard}>
            <img src={icon} className={classes.featureCardIcon} />
            <Typography
                style={{
                    marginTop: "13px",
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    fontSize: "15px"
                }}
            >
                {title}
            </Typography>
            <Typography
                style={{
                    marginTop: "17px",
                }}
                className={classes.normalText}
            >
                {text}
            </Typography>
        </div>
    );
}

function Bold({ children }) {
    return (
        <span style={{ fontWeight: "700" }}>
            {children}
        </span>
    );
}

export default WelcomePage;
