export const DestinationsDataNuts = [
    {
        _id: 47,
        nuts: {
            mitomedName: "Alcudia",
            country: "ES",
            nutS1: "ES6",
            nutS2: "ES61",
        },
        description:
            "Alcúdia is a relative small region located on the north coast of Mallorca, in the Balearic Islands. It is a hot touristic destination mainly attracted by the variety of different type of beach along its 50,5 Km of coastal length during summer. Important heritage can be found as the old wall enclosure or the ancient Roman settlement of Pollentia. Also, relevant ecological areas are found, as humis protected area, s'Albufereta, key for migratory birds, or two public mountains (Puig de Sant Martí i sa Talaia de la Victòria).",
        highSeason: "April, may, june, july, august, september.",
        lowSeason: "January, february, march, october, november, december.",
        latitude: 39.853301024427,
        longitude: 3.1226706504821777,
        population: 23822,
        totalArea: 59.88,
        coastLength: 50.5,
        website: "http://www.alcudia.net",
    },
    {
        _id: 11,
        nuts: {
            mitomedName: "Almuñecar",
            country: "ES",
            nutS1: "ES6",
            nutS2: "ES61",
        },
        description:
            "Almuñécar is a municipality located in the western end of the Costa Tropical region, in the province of Granada, and it´s located between the sea and mountains. Almuñécar is around 27.400 habitants and together with another municipality (Motril), represents more than 70% of the Granada´s coast population. The municipality is located 90 Kms from the province capital of Granada. ",
        highSeason: "July, August and September",
        lowSeason: "December, January and February",
        latitude: 36.73037812487145,
        longitude: -3.687154788887423,
        population: 27397,
        totalArea: 83.4,
        coastLength: 21.6,
        website: "http://www.almunecar.es/",
    },
    {
        _id: 26,
        nuts: {
            mitomedName: "Balearic Islands",
            country: "ES",
            nutS1: "ES5",
            nutS2: "ES53",
        },
        description:
            "Tourist destination located in the Mediterranean Sea, off the eastern coast of the Iberian Peninsula. It is composed of different islands and islets, the four main islands are Mallorca, Menorca, Ibiza and Formentera.\r\nEminently tourist community that receives more than 16 million tourists a year, offers art and culture, nature, gastronomy, sports and active tourism, sun and beach.\r\n",
        highSeason: "\r\nMay, June, July, August, September, October",
        lowSeason: "\r\nJanuary, February, March, April, November, December, ",
        latitude: 39.569599826812976,
        longitude: 2.6510252792269036,
        population: 1128908,
        totalArea: 4984.78,
        coastLength: 1726.72,
        website: "http://www.illesbalears.travel",
    },
    {
        _id: 19,
        nuts: {
            mitomedName: "City of Labin",
            country: "HR",
            nutS1: "HR0",
            nutS2: "HR03",
        },
        description:
            "The City of Labin is situated on the eastern coast of the Istrian Peninsula, covering 71.85 km2 of the land. According to the Census (2011), the city has 11,642 inhabitants. The population is deployed in 17 settlements: Bartići, Breg, Duga Luka, Gondolići, Gora Glušići, Kapelica, Kranjci, Labin, Marceljani, Presika, Rabac, Ripenda Kosi, Ripenda Kras, Ripenda Verbanci, Rogočani, Salakovci and Vinež.",
        highSeason: "",
        lowSeason: "",
        latitude: 45.086477,
        longitude: 14.124263,
        population: 11642,
        totalArea: 71.85,
        coastLength: 20.2,
        website: "http://www.labin.hr",
    },
    {
        _id: 21,
        nuts: {
            mitomedName: "City of Novigrad",
            country: "HR",
            nutS1: "HR0",
            nutS2: "HR03",
        },
        description:
            "The City of Novigrad-Cittanova is situated on the northwestern coast of the Istrian Peninsula, 25 km from the border with Slovenia. It is 15 kilometers away from the nearby town centers - Poreč, Umag and Buje. The city area extends over 27 km2, from Dajle in the north to the mouth of the Mirna River in the south. The City area officially covers a total of five settlements which are: Antenal, Bužinija, Dajla, Mareda and Novigrad. Today, tourism is the most popular activity in Novigrad.",
        highSeason: "01.05. - 30.09.",
        lowSeason: "01.10. - 30.04.",
        latitude: 45.315,
        longitude: 13.55806,
        population: 4345,
        totalArea: 27,
        coastLength: 14.86,
        website: "http://www.novigrad.hr/",
    },
    {
        _id: 20,
        nuts: {
            mitomedName: "City of Poreč",
            country: "HR",
            nutS1: "HR0",
            nutS2: "HR03",
        },
        description:
            "The city area extends over 119 square km. Because of its remarkable geographical position, natural and cultural beauties, today is one of the strongest tourist centers in Croatia. The main economic branch is tourism, which is positively correlated with trade, construction and agriculture.",
        highSeason: "",
        lowSeason: "",
        latitude: 45.22751,
        longitude: 13.594568,
        population: 16696,
        totalArea: 119,
        coastLength: 29.02,
        website: "http://www.porec.hr/",
    },
    {
        _id: 32,
        nuts: {
            mitomedName: "Costa Degli Etruschi",
            country: "IT",
            nutS1: "ITI",
            nutS2: "ITI1",
        },
        description:
            "The Territory of Etruscan Coast is composed by 15 beautiful municipalities located in the Tuscany region. Bibbona, Cecina, San Vincenzo and Rosignano Marittimo are located in the middle of a wide countryside, interrupted by suggestive views of the coast, they represent a destination that really permits to live a holiday in a deep contact with the nature.\r\nCastagneto Carducci is famous for its medieval castle located on the hilltop. Campiglia Marittima, Suvereto and Sassetta are located in the hinterland and they are part the Val di Cornia area, famous for its natural and cultural heritage. \r\nPiombino is situated on a headland facing the Tuscan Archipelago and it has a wealth of history, protected natural areas and beaches. Casale Marittimo, Castellina Marittima, Guardistallo, Montescudaio, Riparbella and Santa Luce are beautiful villages located in the hinterland of Tuscany and surrounded by olives. They are few kms from the coast and they have rich cultural and natural heritage. ",
        highSeason:
            "The Etruscan Coast is characterized for its natural and historical heritage. The high season is during the summer months because in the area are located some famous beaches and blue sea that making the Etruscan Coast one of the loveliest and fascinating area to explore. \r\nThere are equipped and non-beaches. During the holiday is possible to discover the Etruscan sites and villages richest of history. ",
        lowSeason:
            "The low season is during the winter period in which visitors can explore the hinterland villages, explore the protected natural areas and walk along the coast. At the same time in this period is possible to take walks along the beautiful coast and discover the local cuisine. ",
        latitude: 43.23114622792497,
        longitude: 10.525520067635115,
        population: 138.545,
        totalArea: 964.37,
        coastLength: 0,
        website: "http://www.comune.san-vincenzo.li.it ",
    },
    {
        _id: 38,
        nuts: {
            mitomedName: "Elba and Tuscan Islands",
            country: "IT",
            nutS1: "ITI",
            nutS2: "ITI1",
        },
        description:
            "Elba and Giglio are islands of the Tuscan Archipelago and they are part of the National Park of Tuscan Archipelago, the largest marine park in Europe. The islands are famous for their clear and blue sea and beautiful beaches. Thanks to their amazing landscape, both land and marine, the islands are a unique destination in Tuscany. They are the ideal place for outdoor sports activities, such as diving and snorkeling, hiking, mountain biking, golf. Elba is the biggest island of the Archipelago. It is famous for its blue sea, but also for its historical and cultural heritage with its Napoleonic residences, Etruscan and Romans archeological sites. Giglio is a beautiful island covered by Mediterranean vegetation. It is famous stunned beaches, colors, unforgettable sunsets and small villages.",
        highSeason:
            "Elba and Tuscan Islands are characterized for its natural and cultural heritage. The high season is during the summer months (june, july, august and september). This area is famous for its beautiful beaches and blue sea. The beaches are surrounded by a luxuriant nature and this beaches are the perfect place for relaxing and swimming. There are equipped and free beaches. At the same time Elba and Giglio islands offer the possibility to practice outdoor sports activities, taste traditional dishes and discover the historical and cultural heritage. ",
        lowSeason:
            "The low season is during the winter months. The winter and spring seasons are the perfect moment to discover and to stay in touch with the nature of islands. Capraia, Elba and Giglio are the perfect place for outdoor sports activities, such as diving and snorkeling, hiking, mountain biking, golf. ",
        latitude: 42.758898802833954,
        longitude: 10.240839628172749,
        population: 33854,
        totalArea: 288.36,
        coastLength: 0,
        website: "http://www.visitelba.info",
    },
    {
        _id: 18,
        nuts: {
            mitomedName: "Larnaca District",
            country: "CY",
            nutS1: "CY0",
            nutS2: "CY00",
        },
        description:
            "Beautifully compact, easily accessible and truly authentic, Larnaka is Cyprus' oldest soul; the longest continually inhabited region of the island, with a history that dates back 10,000 years, and the most centrally positioned, offering easy access to other regions.\r\n\r\nAnd whilst it is rich in the ancient culture of hundreds of years of contrasting civilizations and architecture, Larnaka is also a thriving and modern European city that offers the best of all worlds. A characteristic feature of the region is that tourists will find that there is no distinction between resort and town; locals and visitors alike can enjoy the same daily experience of a charming and diverse Mediterranean city. From stretches of varied coastline, a mix of traditional and cosmopolitan establishments and fascinating monuments, Larnaka seamlessly blends its two 'faces'.",
        highSeason: "",
        lowSeason: "",
        latitude: 34.908111,
        longitude: 33.629845,
        population: 143192,
        totalArea: 1076.22,
        coastLength: 94.95,
        website: "http://larnakaregion.com/",
    },
    {
        _id: 36,
        nuts: {
            mitomedName: "Leghorn",
            country: "IT",
            nutS1: "ITI",
            nutS2: "ITI1",
        },
        description:
            "The area of Livorno is composed by the municipality of Leghorn, Collesalvetti and Capraia islands. In this area, you can find different villages, landscape and beautiful sea. Leghorn is a city with a rich cultural and historical heritage. It is a port city and it is considered one of the most important in Italy. Leghorn is a city with strong traditions and traditional quarters, such as La Venezia. It is a Venezia- style quarter with canals crossing the all district, small bridges and boats that reminds one of the city in Northern Italy. Another suggestive place is the Terrazza Mascagni, a great chessboard-style terrace. Collesalvetti is a municipality located in the hinterland of Tuscany. It is composed by different villages with historical Etruscan-Roman monuments. Capraia is one island of the Tuscan Archipelago and it is part of the National Park of Tuscan Archipelago, the largest marine park in Europe. It is famous for its blue sea and for its hiking paths to admire the wild nature.",
        highSeason:
            "Leghorn area is characterized for its historical, natural and cultural heritage. The high season is during the summer months (june, july, august and september). This area is famous for its beautiful beaches and blue sea. The beaches are surrounded by a luxuriant nature and this beaches are the perfect place for relaxing and swimming. There are equipped and free beaches. At the same time this area is the ideal place to discover the historical and cultural heritage, practice outdoor sports activities and taste traditional dishes. ",
        lowSeason:
            "The low season is during the winter months. The winter season is the perfect moment to discover villages and the city of Leghorn and to stay in touch with the nature of Capraia island. ",
        latitude: 43.54506070891108,
        longitude: 10.294763265811495,
        population: 175469,
        totalArea: 231.79,
        coastLength: 0,
        website: "http://www.comune.livorno.it",
    },
    {
        _id: 23,
        nuts: {
            mitomedName: "Limassol District",
            country: "CY",
            nutS1: "CY0",
            nutS2: "CY00",
        },
        description:
            "Limassol is the second largest city of Cyprus located on the southern coast. In addition to the recognized popularity as a tourism destination, Limassol is also an administrative and business centre, a port, a resort, the centre of the wine industry and home to many archeological sites. ",
        highSeason: "",
        lowSeason: "",
        latitude: 34.725862141448005,
        longitude: 32.9863916395061,
        population: 239400,
        totalArea: 1393.3,
        coastLength: 487.65,
        website: "http://www.limassoltourism.com/en/",
    },
    {
        _id: 25,
        nuts: {
            mitomedName: "Lloret de Mar",
            country: "ES",
            nutS1: "ES5",
            nutS2: "ES51",
        },
        description:
            "Lloret de Mar, a town on the Costa Brava in Spain's Catalonia region, is known for its Mediterranean beaches. The medieval hilltop Castle of Sant Joan, to the east, offers views over the area, while the central Iglesia de Sant Romà church provides examples of both Catalan Gothic and modernist architecture. On a cliff overlooking the sea, the Santa Clotilde Gardens were built in the style of the Italian Renaissance.",
        highSeason: ".",
        lowSeason: ".",
        latitude: 41.69893759534301,
        longitude: 2.840512890427502,
        population: 37042,
        totalArea: 48.7,
        coastLength: 7,
        website: "http://lloretdemar.org/",
    },
    {
        _id: 48,
        nuts: {
            mitomedName: "Malgrat de Mar",
            country: "ES",
            nutS1: "ES5",
            nutS2: "ES51",
        },
        description:
            "Malgrat de Mar is a town with diferent ways of life. At the beginning its people were fishermen and farmers. At late 19th century people also worked in the mines.\r\n\r\nThe municipality began as a tourist destination in the sixties. So, Malgrat de Mar has a long tradition in the tourist sector, complemented by good commercial and catering services which have continued improving to arrive at where we are now, being certified by the Catalan Government as a Destination for Family Tourism and the Biosphere Certification for our two Tourism Offices.\r\n\r\nMalgrat de Mar is characterized by having good weather almost all the year round. Moret han 4,5 km. Of beaches wherer you will find life guard and security servces, underpasses for peaople with any disabilities, adapted sanitary services and showers. Two of our four beaches are certified with the Blue Flag (certification by the Foundation for Environm",
        highSeason: "15/06 – 31/08",
        lowSeason: "15/4 – 14/06     01/09 – 15/10",
        latitude: 41.64583987500816,
        longitude: 2.741354513273108,
        population: 18865,
        totalArea: 8.65,
        coastLength: 4.53,
        website: "http://www.ajmalgrat.cat/",
    },
    {
        _id: 41,
        nuts: {
            mitomedName: "Mali Lošinj",
            country: "HR",
            nutS1: "HR0",
            nutS2: "HR03",
        },
        description:
            "A mild climate, rich biodiversity, a pleasant environment and 250 cultural and entertainment events – these are just some of the reasons why Lošinj has become famous as the island of vitality in the international market.\r\nHealth tourism is an important tradition here and it has been on offer for more than 125 years. The combined fragrance of thick pine forests and medicinal herbs create unique aromatherapy in the open.\r\n \r\nHigh quality seas and supreme air quality with over 200 days of sunshine and 1018 plant species, of which 939 belong to indigenous flora, make Lošinj the ideal aromatherapy centre and a modern destination of vitality, health tourism and environmental awareness. \r\n",
        highSeason: "",
        lowSeason: "",
        latitude: 44.53322938658567,
        longitude: 14.4654111604971,
        population: 8056,
        totalArea: 225,
        coastLength: 33,
        website: "https://www.visitlosinj.hr/",
    },
    {
        _id: 50,
        nuts: {
            mitomedName: "Mallorca",
            country: "ES",
            nutS1: "ES6",
            nutS2: "ES61",
        },
        description: "xx",
        highSeason: "xx",
        lowSeason: "xx",
        latitude: 39.56052749962623,
        longitude: 3.2135007530450803,
        population: 11,
        totalArea: 11,
        coastLength: 11,
        website: "http://www.test.es",
    },
    {
        _id: 31,
        nuts: {
            mitomedName: "Maremma Nord",
            country: "IT",
            nutS1: "ITI",
            nutS2: "ITI1",
        },
        description:
            "The territory of North Maremma is composed by 8 beautiful municipalities located in Tuscany Region. Castiglione della Pescaia is an ancient fishing village and it lies among the blue sea and the green of the hills. Follonica is a tourist village situated along the golf beach opposite to Elba island and is surrounded by a luxuriant pinewood. Scarlino is a very lovely medieval village and in the municipality you can visit beautiful beaches wrapped in the nature. Massa Marittima is a small and splendid art city, one of the most beautiful medieval towns of Tuscany. Montieri, Gavorrano and Monterotondo Marittimo are in the hinterland of Tuscany and they have a beautiful natural and cultural heritage. In this municipalities, you can discover the national park with its trekking routes. Roccastrada is an ancient city with a beautiful skyline on the surrounded area.",
        highSeason:
            "The North Maremma area is characterized for its natural and cultural heritage. The high season is during the summer months. This area is famous for its beautiful beaches and blue sea. The beaches are surrounded by a luxuriant nature and this beaches are the perfect place for relaxing and swimming. There are equipped and free beaches. At the same time the area of Maremma Nord offers the possibility to visit the national park, do excursions, discover medieval village and taste traditional dishes. \r\n",
        lowSeason:
            "The low season is during the winter months. The winter and spring sesons are the perfect period to discover the hinterland of this part of Tuscany rich of medieval and historical memories. The North Maremma is the perfect area to stay in touch with the nature and visit the national park with its trekking routes. At the same time in this period is possible to take walks along the beautiful coast and discover the local cuisine. \r\n",
        latitude: 42.86706849609442,
        longitude: 10.774772138859053,
        population: 60.632,
        totalArea: 1296.29,
        coastLength: 0,
        website: "http://www.comune.follonica.gr.it",
    },
    {
        _id: 24,
        nuts: {
            mitomedName: "Paphos District",
            country: "CY",
            nutS1: "CY0",
            nutS2: "CY00",
        },
        description:
            "Beautiful countryside, a cosmopolitan resort, historic sites as well as sporting activities, the Pafos region has something for everyone at any time of year. Pafos, with its pleasant harbour and medieval fort, combines a cosmopolitan holiday resort, spectacular countryside and historical sites. The region offers the possibility of enjoying both sea and mountains, as well as getting a taste of the island's culture with its many archaeological sites.\r\nIn Pafos region you can bask in sunshine all the year round and feel the warmth of a welcoming people with a reputation for hospitality. Splash about in the sparkling warm waters of the Mediterranean in the summer; marvel at the splendours of nature as the fields are carpeted in wild flowers in the spring; explore ancient sites in perfect temperatures in the autumn; and in the mild winter keep fit with a round or two of golf or an invigorating game of tennis.",
        highSeason: "",
        lowSeason: "",
        latitude: 34.82870477801427,
        longitude: 32.455182475778095,
        population: 91300,
        totalArea: 1389.8,
        coastLength: 675.8,
        website: "http://www.visitpafos.org.cy/regional_tourism_board.aspx",
    },
    {
        _id: 46,
        nuts: {
            mitomedName: "Ponza Island",
            country: "IT",
            nutS1: "ITI",
            nutS2: "ITI4",
        },
        description:
            "Ponza is an island located in the western coast of central Italy and it is in Lazio Region. \r\nThe island is reachable every day with ferries departing from various ports, such as Terracina, Formia, Napoli Anzio and San Felice. The main nearest airports are Rome and Naples. Ponza has an historical, natural and cultural heritage. Fishing villages, island atmosphere, boats, the and adventure are the main characteristics of the island. ",
        highSeason:
            "Ponza is characterized for its historical, natural and cultural heritage. The high season is during the summer months (mainly on June, July, August and September). The island is famous for its beautiful beaches and blue sea. The beaches are surrounded by a luxuriant nature and this beaches are the perfect place for relaxing and swimming. At the same time this area is the ideal place to discover the historical and cultural heritage, practice outdoor sports activities and taste traditional dishes.",
        lowSeason:
            "The low season is during the winter months. The winter season is the perfect moment to discover villages, traditions, food and to stay in touch with the nature of Ponza Island. ",
        latitude: 40.90094323688595,
        longitude: 12.952902262878668,
        population: 3.348,
        totalArea: 0,
        coastLength: 0,
        website: "http://www.comune.ponza.lt.it",
    },
    {
        _id: 37,
        nuts: {
            mitomedName: "Riviera Apuana",
            country: "IT",
            nutS1: "ITI",
            nutS2: "ITI1",
        },
        description:
            'The area of Riviera Apuana is composed by the municipality of Carrara, Massa and Montignoso and it is located in the north of Tuscany. This area is famous for its blue sea, landscape, cultural heritages and with marble caves. The coast is noted for its beautiful beaches some of this equipped but at the same time you can find free beaches. \r\nCarrara and Massa are famous thanks to the spectacular white marble caves that are unique in the world. Tourists can do an extraordinary experience in the caves and at the same time enjoy the blue sea. The strong tourist vocation of the Municipality of Montignoso has its point of excellence in the seaside resort of Cinquale, in an area where "Green beach model" will be implemented. Clear sea and equipped beaches are unforgettable scenery for many tourists, both italian and foreign, who choose this stretch of Tuscan coast for a holiday in a spirit of relax, fun and quality of services.',
        highSeason:
            "In the Riviera Apuana area the high season is during the summer months (june-september). This area is famous for its beautiful beaches that offer quality services. Thanks to the natural heritage and the short distance from the sea, the Apuan Alps with the withe marble caves are the paradise for nature lovers and excursionist. ",
        lowSeason:
            "The low season is during the winter months. The winter and spring months are the perfect moment to discover the traditional villages, nature, withe marble caves and taste traditional dishes. The Riviera Apuana area is a paradise for nature lovers and excursionists, in summer and winter, thanks to the rich touristic offers. ",
        latitude: 44.00100074257688,
        longitude: 10.10654048904388,
        population: 141885,
        totalArea: 181.59,
        coastLength: 0,
        website: "http://www.comune.massa.ms.it",
    },
    {
        _id: 17,
        nuts: {
            mitomedName: "Sant Antoni de Calonge",
            country: "ES",
            nutS1: "ES5",
            nutS2: "ES51",
        },
        description:
            "Sant Antoni de Calonge is a town which forms part of the city of Calonge, located 40km from the main provincial town of Girona.",
        highSeason: "",
        lowSeason: "",
        latitude: 41.846389,
        longitude: 3.098889,
        population: 10709,
        totalArea: 33.6,
        coastLength: 0,
        website: "http://www.calonge-santantoni.cat/",
    },
    {
        _id: 40,
        nuts: {
            mitomedName: "Sant Llorenç des Cardassar",
            country: "ES",
            nutS1: "ES5",
            nutS2: "ES53",
        },
        description:
            "Sant Llorenç des Cardassar is a municipality formed by five population centers, Cala Millor, Son Carriò, Sa Coma, s'Illot and Sant Llorenç. It is located in the county of Llevant and borders the municipalities of Manacor, Artà, Son Servera and Petra.",
        highSeason: "From 01.05 to 31.10",
        lowSeason: "From 01.11 to 30.04",
        latitude: 39.6098593290119,
        longitude: 3.2840787489914627,
        population: 8.405,
        totalArea: 82,
        coastLength: 7,
        website: "https://www.santllorenc.cat",
    },
    {
        _id: 45,
        nuts: {
            mitomedName: "Santa Marinella",
            country: "IT",
            nutS1: "ITI",
            nutS2: "ITI4",
        },
        description:
            "Santa Marinella is a municipality located on the Lazio Region coast (Italy). It is famous for its beaches, but also for its cultural and natural heritage. The Small Tourist Port of Santa Marinella is placed in a suggestive setting with an ancient sense. The port is placed in the heart of the old town of Santa Marinella, within walking distance from the city center, among shops of any kind especialised in artisan and typical food products, everything fitting into a very pleasant historical and architectonical context.",
        highSeason:
            "The high season of Santa Marinella is concentrated during the summer period (from June to September). Every year tourists choose this stretch of Lazio coast for a holiday in a spirit of relax and quality of services. The area offer a wide tourist offer thanks to the cultural, natural and historical heritage. Santa Marinella is the ideal place to discover the historical and cultural heritage, taste traditional dishes and discover surrounded beautiful villages. ",
        lowSeason:
            "The low season is during the winter months. The winter season is the perfect moment to discover villages, traditions and food. \r\n",
        latitude: 42.03076422957017,
        longitude: 11.837668312911127,
        population: 18935,
        totalArea: 48.91,
        coastLength: 0,
        website: "https://www.comune.santamarinella.rm.it/",
    },
    {
        _id: 16,
        nuts: {
            mitomedName: "Torredembarra",
            country: "ES",
            nutS1: "ES5",
            nutS2: "ES51",
        },
        description:
            "Torredembarra is located in the southern part of the Catalan coast, 20 km from the Provincial town of Tarragona and 80 km from Barcelona. It counts with 4 beaches.",
        highSeason: "",
        lowSeason: "",
        latitude: 41.15,
        longitude: 1.4,
        population: 15.46,
        totalArea: 8.7,
        coastLength: 8.7,
        website: "http://turismetorredembarra.cat/",
    },
    {
        _id: 39,
        nuts: {
            mitomedName: "Valencia",
            country: "ES",
            nutS1: "ES5",
            nutS2: "ES52",
        },
        description:
            "Valencia is an old Mediterranean city at the cutting edge, visited every year by more than 2.5 million of tourists from all over the world",
        highSeason: "",
        lowSeason: "",
        latitude: 39.47214891757164,
        longitude: -0.3723240484251003,
        population: 800.666,
        totalArea: 134.5,
        coastLength: 19.5,
        website: "http:\\\\www.visitvalencia.com",
    },
    {
        _id: 52,
        nuts: {
            mitomedName: "Vallès Oriental",
            country: "ES",
            nutS1: "ES5",
            nutS2: "ES51",
        },
        description:
            "The Vallès Oriental is an area composed by 39 municipalities in the region of Barcelona",
        highSeason:
            "The summer is mainly the high season in the whole area, but the municipalities around the Montseny Natural Parc have also high season in autumn. ",
        lowSeason: "Winter and spring are the low season of the area.",
        latitude: 41.60594040141365,
        longitude: 2.286241455003619,
        population: 403259,
        totalArea: 735,
        coastLength: 55,
        website: "http://www.vallesoriental.cat/",
    },
    {
        _id: 12,
        nuts: {
            mitomedName: "Vera",
            country: "ES",
            nutS1: "ES6",
            nutS2: "ES61",
        },
        description:
            "Vera is in the oriental coast of Almería. It is around 15.200 inhabitants and located at 92 kilometers from the provincial capital. \r\nNear the coast is also the Laguna de Puerto Rey, mouth of the river Antas, recently declared a nature reserve.\r\n",
        highSeason: "July, August and September",
        lowSeason: "December, January and February",
        latitude: 37.2191000315964,
        longitude: -1.8060084447364197,
        population: 15168,
        totalArea: 58,
        coastLength: 4.9,
        website: "http://www.vera.es/",
    },
    {
        _id: 22,
        nuts: {
            mitomedName: "Versilia",
            country: "IT",
            nutS1: "ITI",
            nutS2: "ITI1",
        },
        description:
            "Versilia area is composed by 7 beautiful municipalities located in Tuscany Region. Pietrasanta is located 3 km from the sea and it is called the ‘The little Athens’ thanks to its artistic and cultural heritage. Its coastal village, called Marina di Pietrasanta, allows peaceful stay. Forte dei Marmi is an exclusive tourist destination along the coast. The municipalities of Seravezza and Stazzema are located few kilometers from the sea in slope of the beautiful Apuan Alps. Camaiore is a Romans city and visitors can choose if stay along the coast or inland. The municipality of Massarosa is located inland. It offers a different landscape from the other coastal municipalities and its famous lake of Massaciuccoli where the composer Giacomo Puccini lived and worked. \r\nViareggio is characterized by its long sandy beach, natural heritage and it is surrounded by the majestic scenery of the Apuan Alps. ",
        highSeason:
            "In Versilia area the high season is during the summer period (june-september). Every year many tourists, both Italian and foreign, choose this stretch of Tuscan coast for a holiday in a spirit of relax and quality of services. The area offer a wide tourist offer thanks to the cultural, natural and historical heritage.",
        lowSeason:
            "The low season is during the winter months. The winter and spring months are the perfect moment to discover the traditional villages, nature and taste traditional dishes.\r\n",
        latitude: 43.9926044,
        longitude: 10.22565485,
        population: 164723,
        totalArea: 438.18,
        coastLength: 20.5,
        website: "http://www.unionedicomunialtaversilia.lu.it   ",
    },
    {
        _id: 13,
        nuts: {
            mitomedName: "Vélez Málaga",
            country: "ES",
            nutS1: "ES6",
            nutS2: "ES61",
        },
        description:
            "Vélez Málaga is located in the Oriental Costa del Sol, 39 kms from de capital of the province. It´s the most important city in the region of Axarquía, the third municipality of the province in terms of population (78.890 in 2016). The main productive sector of the municipality is tourism, followed by agriculture, which stands out for the production of subtropical crops.",
        highSeason: "July, August and September",
        lowSeason: "December, January and February",
        latitude: 36.73920390631224,
        longitude: -4.0929574272600036,
        population: 78890,
        totalArea: 157.9,
        coastLength: 23.2,
        website: "http://www.velezmalaga.es/ ",
    },
    {
        _id: 51,
        nuts: {
            mitomedName: "vorio aigaio",
            country: "EL",
            nutS1: "EL4",
            nutS2: "EL41",
        },
        description:
            "one of the therteen greek region with 10 inhabited islands divided in 5 districts",
        highSeason: "june-july-august-september",
        lowSeason: "october-november-december-juanary-february-march-april-may",
        latitude: 39.11834115416519,
        longitude: 26.14402770996094,
        population: 199.231,
        totalArea: 199.231,
        coastLength: 1343,
        website: "https://www.pvaigaiou.gov.gr/",
    },
    {
        _id: 53,
        nuts: {
            mitomedName: "Sevilla",
            country: "undefined",
            nutS1: "undefined",
            nutS2: "undefined",
        },
        description:
            "Sevilla is the capital city of Andalusia, in the south of Spain, and has a population of nearly 700.000. It's the third tourist destination in Spain, with over 3.000.000 visitors in 2019.",
        highSeason: "Januray to June - September to December",
        lowSeason: "July and August",
        latitude: 36.60891366719368,
        longitude: -5.734863281250002,
        population: 700,
        totalArea: 140,
        coastLength: 0,
        website: "https://www.visitasevilla.es/turismo-de-sevilla",
    },
    {
        _id: 55,
        nuts: {
            mitomedName: "Dijon",
            country: "undefined",
            nutS1: "undefined",
            nutS2: "undefined",
        },

        description: "xxxxxxxxxxxxxxxxxxx",
        highSeason: "xxxxxxxxxxxxx",
        lowSeason: "xxxxxxxxxxxxx",
        latitude: 47.32868912962343,
        longitude: 5.025902117421439,
        population: 0,
        totalArea: 0,
        coastLength: 0,
        website: "http://www.xxxxxxxxxx.es",
    },
];
