import IconConfirm from "../../assets/img/icon_alert_confirm.svg";
import IconClose from "@material-ui/icons/Close";

import { Grid, Typography, Button, Modal, Backdrop, Fade } from "@material-ui/core";

function AlertConfirm({
    confirmAction,
    modalOpen,
    modalClose,
    textTitle,
    textDescription,
    textConfirm,
    textCancel
}) {
    const handleModalClose = () => {
        modalClose(false);
    };

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className="modal-confirmation"
        >
            <Fade in={modalOpen}>
                <div className="modal-confirmation-container">
                    <div className="modal-confirmation-icon-close-container">
                        <IconClose
                            className="modal-confirmation-icon-close"
                            onClick={handleModalClose}
                        />
                    </div>
                    <div className="modal-confirmation-icon-container">
                        <img src={IconConfirm} alt="Discard changes" width={55} />
                    </div>
                    <div className="modal-confirmation-text-container">
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            className="modal-confirmation-title"
                        >
                            {textTitle}
                        </Typography>

                        <Typography
                            variant="body2"
                            color="textSecondary"
                            className="modal-confirmation-description"
                        >
                            {textDescription}
                        </Typography>
                    </div>
                    <Grid
                        container
                        justify="center"
                        direction="row"
                        className="modal-confirmation-actions-container"
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="modal-confirmation-actions-confirm-container"
                        >
                            <Button
                                size="small"
                                variant="contained"
                                className=""
                                onClick={confirmAction}
                                style={{backgroundColor: "#4caf50", color: "white"}}
                            >
                                {textConfirm}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="modal-confirmation-actions-cancel-container"
                        >
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                className="cancel-button"
                                onClick={handleModalClose}
                            >
                                {textCancel}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </Modal>
    );
}

export default AlertConfirm;
