export const ActiveEnterprisesLineGraphData = {
    dateTime:"2021-02-05T19:55:21.979+01:00",
    label:"Business demography by size class (from 2004 onwards, NACE Rev. 2)",
    href:"http://ec.europa.eu/eurostat/wdds/rest/data/v2.1/json/en/bd_9bd_sz_cl_r2?precision=1&indic_sb=V11910&indic_sb=V16910&nace_r2=R91",
    source:"Eurostat",
    updated:"2021-01-18T23:00:00Z",
    extension:{
       datasetId:"bd_9bd_sz_cl_r2",
       lang:"EN",
       description:null,
       subTitle:null
    },
    value:[
       {
          "0":1203,
          name:2010,
          "1-4":794,
          "5-9":212,
          "GE10":228,
          TOTAL:2437
       },
       {
          "0":1206,
          name:2011,
          "1-4":866,
          "5-9":216,
          "GE10":239,
          TOTAL:2527
       },
       {
          "0":1322,
          name:2012,
          "1-4":1061,
          "5-9":233,
          "GE10":223,
          TOTAL:2839
       },
       {
          "0":1433,
          name:2013,
          "1-4":1105,
          "5-9":246,
          "GE10":206,
          TOTAL:2990
       },
       {
          "0":1602,
          name:2014,
          "1-4":1145,
          "5-9":258,
          "GE10":243,
          TOTAL:3248
       },
       {
          "0":1674,
          name:2015,
          "1-4":1208,
          "5-9":244,
          "GE10":246,
          TOTAL:3372
       },
       {
          "0":1765,
          name:2016,
          "1-4":1049,
          "5-9":238,
          "GE10":251,
          TOTAL:3303
       },
       {
          "0":1826,
          name:2017,
          "1-4":881,
          "5-9":209,
          "GE10":228,
          TOTAL:3144
       },
       {
          "0":3203,
          name:2018,
          "1-4":1227,
          "5-9":212,
          "GE10":223,
          TOTAL:4865
       }
    ],
    graphDimensions:[
       {
          label:"Zero",
          key:"0"
       },
       {
          label:"From 1 to 4 employees",
          key:"1-4"
       },
       {
          label:"From 5 to 9 employees",
          key:"5-9"
       },
       {
          label:"10 employees or more",
          key:"GE10"
       },
       {
          label:"Total",
          key:"TOTAL"
       }
    ],
    dimensions:{
       indic_sb:{
          label:"indic_sb",
          values:{
             "V11910":"Population of active enterprises in t - number"
          }
       },
       "nace_r2":{
          label:"nace_r2",
          values:{
             "R91":"Libraries, archives, museums and other cultural activities"
          }
       },
       sizeclas:{
          label:"sizeclas",
          values:{
             "0":"Zero",
             "1-4":"From 1 to 4 employees",
             "5-9":"From 5 to 9 employees",
             "GE10":"10 employees or more",
             TOTAL:"Total"
          }
       },
       geo:{
          label:"geo",
          values:{
             ES:"Spain"
          }
       },
       time:{
          label:"time",
          values:{
             "2004":"2004",
             "2005":"2005",
             "2006":"2006",
             "2007":"2007",
             "2008":"2008",
             "2009":"2009",
             "2010":"2010",
             "2011":"2011",
             "2012":"2012",
             "2013":"2013",
             "2014":"2014",
             "2015":"2015",
             "2016":"2016",
             "2017":"2017",
             "2018":"2018"
          }
       }
    },
    nuts:{
       "nuts2":"ES",
       label:"Spain"
    }
 }
