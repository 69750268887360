import IconWww from "../../assets/img/icon_www.svg";
import Area from "../../assets/img/Area.png";
import Population from "../../assets/img/Population.png";
import Coast from "../../assets/img/Coast.png";
import Season from "../../assets/img/Season.png";
import { Link } from "react-router-dom";

import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    makeStyles,
    Typography,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import AnimatedShowMore from "react-animated-show-more";
import LanguageIcon from "@material-ui/icons/Language";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import DestinationCardGraphHolder from "../Graphs/DestinationCardGraphHolder";
import React from "react";
import DestinationCardAccordion from "./DestinationCardAccordion";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";

const useStyles = makeStyles(theme => ({
    infoCard: {
        maxWidth: "400px",
        float: "right",
        boxShadow: "0px 0px 15px #787878",
        borderRadius: "15px",
    },
    infoLabel: {
        fontSize: "0rem !important",
    },
    titleCountry: {
        fontSize: 15,
        textTransform: "uppercase",
        //color: '#ffffff'
    },
    titleData: {
        fontSize: "0.6rem",
        textTransform: "uppercase",
    },
    stats: {
        fontSize: 15,
        fontWeight: "fontWeightBold",
    },
    highSeason: {
        fontSize: "13px !important",
        fontWeight: "bold !important",
    },
    informationCard: {
        position: "absolute",
        top: "4px",
        right: "8px",
        fontSize: "0.85rem",
    },
    informationCardWrapper: {
        display: "inline-block",
        position: "relative",
        textAlign: "right",
        color: "white",
    },
    description: {
        fontSize: 14,
    },
    destinationInfo: {
        backgroundColor: "#ffffff",
        //backgroundColor: '#006fba',
        //color: '#ffffff'
    },
    fullInfo: {
        backgroundColor: "#ffffff",
        paddingBottom: "0px !important",
    },
    flag: {
        marginRight: "10px",
    },
    links: {
        marginTop: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    linkIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    hoverContent: {
        paddingTop: "16px !important",
        paddingBottom: "16px !important",
        paddingLeft: "24px !important",
        paddingRight: "24px !important",
    },
    hoverLocation: {
        marginBottom: "0px !important",
        fontWeight: "fontWeightBold !important",
        fontSize: "20px",
    },
    hoverTitle: {
        fontSize: "15px !important",
        textTransform: "uppercase !important",
    },
    homeButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    homeLink: {
        textDecoration: "none",
    },
    graphWrapper: {
        height: "150px",
    },
    destinationDetailsLink: {
        color: "black",
    },
}));

function DestinationCard({ selectedDestination, showDestinationDetailsView, dataLabels }) {
    const { t } = useTranslation();
    const classes = useStyles();

    const idDestination = String(selectedDestination._id);

    const populationClean = String(selectedDestination.population).replace(".", "");

    const population = Number(populationClean).toLocaleString("sl-SI");
    const totalArea = Number(selectedDestination.totalArea).toLocaleString("sl-SI");
    const coastLength = Number(selectedDestination.coastLength).toLocaleString("sl-SI");

    let populationCard = "",
        totalAreaCard = "",
        coastLengthCard = "",
        highSeasonCard = "";

    if (populationClean > 0) {
        populationCard = (
            <Grid item md={6} lg={6}>
                <div className={classes.informationCardWrapper}>
                    <img src={Population} alt="" width="100%" />
                    <div className={classes.informationCard}>
                        <Typography
                            className={classes.stats}
                            variant="h6"
                            paragraph={false}
                            style={{ marginBottom: "0px" }}
                        >
                            {population}
                        </Typography>
                        <Typography className={classes.infoLabel}>
                            <span className={classes.titleData}>
                                {t("destinations.card.population")}
                            </span>
                        </Typography>
                    </div>
                </div>
            </Grid>
        );
    }

    if (totalArea.length > 0 && !totalArea.startsWith("0")) {
        totalAreaCard = (
            <Grid item md={6} lg={6}>
                <div className={classes.informationCardWrapper}>
                    <img src={Area} alt="" width="100%" />
                    <div className={classes.informationCard}>
                        <Typography
                            className={classes.stats}
                            variant="h6"
                            paragraph={false}
                            style={{ marginBottom: "0px" }}
                        >
                            {totalArea + " km²"}
                        </Typography>
                        <Typography className={classes.infoLabel}>
                            <span className={classes.titleData}>
                                {t("destinations.card.totalArea")}
                            </span>
                        </Typography>
                    </div>
                </div>
            </Grid>
        );
    }

    if (coastLength.length > 0 && !coastLength.startsWith("0")) {
        coastLengthCard = (
            <Grid item md={6} lg={6}>
                <div className={classes.informationCardWrapper}>
                    <img src={Coast} alt="" width="100%" />
                    <div className={classes.informationCard}>
                        <Typography
                            className={classes.stats}
                            variant="h6"
                            paragraph={false}
                            style={{ marginBottom: "0px" }}
                        >
                            {coastLength + " km"}
                        </Typography>
                        <Typography className={classes.infoLabel}>
                            <span className={classes.titleData}>
                                {t("destinations.card.coastLength")}
                            </span>
                        </Typography>
                    </div>
                </div>
            </Grid>
        );
    }

    if (
        selectedDestination.highSeason !== "" &&
        selectedDestination.highSeason.length < 35 &&
        selectedDestination.highSeason !== "0" &&
        selectedDestination.highSeason !== "-1"
    ) {
        highSeasonCard = (
            <Grid item md={6} lg={6}>
                <div className={classes.informationCardWrapper}>
                    <img src={Season} alt="" width="100%" />
                    <div className={classes.informationCard}>
                        <Typography className={classes.highSeason}>
                            {selectedDestination.highSeason}
                        </Typography>
                        <Typography className={classes.infoLabel}>
                            <span className={classes.titleData}>
                                High season
                                {/*t("destinations.card.highSeason")*/}
                            </span>
                        </Typography>
                    </div>
                </div>
            </Grid>
        );
    }

    return (
        <Box m={2}>
            <Card className={classes.infoCard}>
                <Box p={1}>
                    <CardContent>
                        <CardContent
                            className={classes.destinationInfo}
                            style={
                                (populationCard !== "" &&
                                    totalAreaCard !== "" &&
                                    coastLengthCard !== "" &&
                                    highSeasonCard !== "") ||
                                (selectedDestination.description !== "0" &&
                                    selectedDestination.description !== "-1" &&
                                    selectedDestination.description !== "00" &&
                                    selectedDestination.description !== "xx" &&
                                    selectedDestination.description.length > 0)
                                    ? {}
                                    : { paddingBottom: "0px" }
                            }
                        >
                            {selectedDestination.nuts.country !== "undefined" && (
                                <Box>
                                    <Typography
                                        className={classes.hoverTitle}
                                        color="textSecondary"
                                    >
                                        <Flag
                                            selectedDestination={selectedDestination}
                                            dataLabels={dataLabels}
                                        />
                                        {dataLabels[0][selectedDestination.nuts.country]}
                                    </Typography>
                                </Box>
                            )}

                            <Typography
                                variant="h5"
                                component="h2"
                                onClick={() => showDestinationDetailsView(selectedDestination)}
                            >
                                <Tooltip
                                    title={t("destinations.card.findOutMore")}
                                    placement="right"
                                    style={{ cursor: "pointer" }}
                                >
                                    <span>{selectedDestination.nuts.mitomedName}</span>
                                </Tooltip>
                            </Typography>

                            {selectedDestination.nuts.country !== "undefined" && (
                                <Typography style={{ fontSize: "13px" }} color="textSecondary">
                                    {dataLabels[0][selectedDestination.nuts.nutS2]}
                                </Typography>
                            )}
                        </CardContent>

                        {(populationCard !== "" &&
                            totalAreaCard !== "" &&
                            coastLengthCard !== "" &&
                            highSeasonCard !== "") ||
                        (selectedDestination.description !== "0" &&
                            selectedDestination.description !== "-1" &&
                            selectedDestination.description !== "00" &&
                            selectedDestination.description !== "xx" &&
                            selectedDestination.description.length > 0) ? (
                            <Divider />
                        ) : null}

                        <CardContent className={classes.fullInfo}>
                            {selectedDestination.description !== "0" &&
                            selectedDestination.description !== "-1" &&
                            selectedDestination.description !== "00" &&
                            selectedDestination.description !== "xx" &&
                            selectedDestination.description.length > 0 ? (
                                <>
                                    <AnimatedShowMore
                                        height={100}
                                        toggle={({ isOpen }) =>
                                            isOpen ? (
                                                <Typography className={classes.description}>
                                                    {t("destinations.card.showLess")}
                                                </Typography>
                                            ) : (
                                                <Typography className={classes.description}>
                                                    {t("destinations.card.showMore")}
                                                </Typography>
                                            )
                                        }
                                        speed={350}
                                    >
                                        <Typography align="justify" className={classes.description}>
                                            {selectedDestination.description}
                                        </Typography>
                                    </AnimatedShowMore>

                                    <br />
                                    <Divider />
                                </>
                            ) : null}

                            {populationCard !== "" ||
                            totalAreaCard !== "" ||
                            coastLengthCard !== "" ||
                            highSeasonCard !== "" ? (
                                <Box style={{ marginTop: "16px", marginBottom: "16px" }}>
                                    <Grid container spacing={1}>
                                        {populationCard}
                                        {totalAreaCard}
                                        {coastLengthCard}
                                        {highSeasonCard}
                                    </Grid>
                                </Box>
                            ) : null}

                            <br />
                            <Divider />

                            <DestinationCardAccordion>
                                <Grid container spacing={1}>
                                    <Grid xs={12}>
                                        {selectedDestination.nuts.country !== "undefined" && (
                                            <DestinationCardGraphHolder
                                                selectedDestination={selectedDestination}
                                                graphHeight={150}
                                            />
                                        )}
                                    </Grid>
                                    <Grid xs={12}>
                                        <Grid className={classes.links} container spacing={1}>
                                            <Grid item md={2} className={classes.linkIcon}>
                                                <a
                                                    title={t(
                                                        "destinations.card.mitomedShowDestination"
                                                    )}
                                                    href={`https://mitomedplus.andalucia.org/mitomedplus/publicShowDestinationData.html?id=${idDestination}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={IconWww}
                                                        width="35px"
                                                        alt="Destination"
                                                    />
                                                </a>
                                            </Grid>
                                            <Grid item md={2} className={classes.linkIcon}>
                                                <a
                                                    title={t(
                                                        "destinations.card.mitomedShowIndicators"
                                                    )}
                                                    href={`https://mitomedplus.andalucia.org/mitomedplus/publicListDestinationIndicators.html?id=${idDestination}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={IconWww}
                                                        width="35px"
                                                        alt="Indicator"
                                                    />
                                                </a>
                                            </Grid>
                                            <Grid item md={2} className={classes.linkIcon}>
                                                <a
                                                    title={t(
                                                        "destinations.card.mitomedDestinationDashboard"
                                                    )}
                                                    href={`https://mitomedplus.andalucia.org/mitomedplus/dashboard.html?groupId=0&id=${idDestination}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={IconWww}
                                                        width="35px"
                                                        alt="Dashboard"
                                                    />
                                                </a>
                                            </Grid>
                                            <Grid item md={2} className={classes.linkIcon}>
                                                <a
                                                    title={t(
                                                        "destinations.card.mitomedEconomicIndocators"
                                                    )}
                                                    href={`https://mitomedplus.andalucia.org/mitomedplus/dashboard.html?groupId=1&id=${idDestination}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={IconWww}
                                                        width="35px"
                                                        alt="Economic"
                                                    />
                                                </a>
                                            </Grid>
                                            <Grid item md={2} className={classes.linkIcon}>
                                                <a
                                                    title={t(
                                                        "destinations.card.mitomedEnvironmentalIndocators"
                                                    )}
                                                    href={`https://mitomedplus.andalucia.org/mitomedplus/dashboard.html?groupId=3&id=${idDestination}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={IconWww}
                                                        width="35px"
                                                        alt="Environmental"
                                                    />
                                                </a>
                                            </Grid>
                                            <Grid item xs={2} className={classes.linkIcon}>
                                                <a
                                                    title={t(
                                                        "destinations.card.mitomedSocialIndocators"
                                                    )}
                                                    href={`https://mitomedplus.andalucia.org/mitomedplus/dashboard.html?groupId=2&id=${idDestination}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src={IconWww} width="35px" alt="Social" />
                                                </a>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DestinationCardAccordion>

                            <Box>
                                <Grid className={classes.links} container spacing={0}>
                                    <Grid item xs={12} className={classes.homeButton}>
                                        <a
                                            title={selectedDestination.website}
                                            href={selectedDestination.website}
                                            className={classes.homeLink}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <IconButton
                                                style={{
                                                    backgroundColor: "transparent",
                                                    padding: "4px",
                                                }}
                                                disableRipple={true}
                                                disableFocusRipple={true}
                                            >
                                                <LanguageIcon style={{ paddingRight: "5px" }} />
                                                <Typography style={{ fontSize: "14px" }}>
                                                    {t("destinations.card.visitOfficialWebsite")}
                                                </Typography>
                                            </IconButton>
                                        </a>
                                    </Grid>
                                    <Grid item xs={12} className={classes.homeButton}>
                                        <IconButton
                                            style={{
                                                backgroundColor: "transparent",
                                                padding: "4px",
                                            }}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            onClick={() =>
                                                showDestinationDetailsView(selectedDestination)
                                            }
                                        >
                                            <HelpOutlineRoundedIcon
                                                style={{ paddingRight: "5px" }}
                                            />
                                            <Typography style={{ fontSize: "14px" }}>
                                                {t("destinations.card.findOutMore")}
                                            </Typography>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </CardContent>
                </Box>
            </Card>
        </Box>
    );
}

export function Flag({ selectedDestination, dataLabels }) {
    const classes = useStyles();

    let country = String(dataLabels[0][selectedDestination.nuts.country]);
    const countries = require("i18n-iso-countries");
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    const countryCode = countries.getAlpha2Code(country.trim(), "en");

    return (
        <ReactCountryFlag
            countryCode={countryCode}
            className={classes.flag}
            style={{ width: "19px", height: "13px", paddingBottom: "2px", marginBottom: "auto" }}
            svg
        />
    );
}

export function HoverCard({ selectedDestination, dataLabels }) {
    const classes = useStyles();

    return (
        <Card className={classes.infoCard}>
            <CardContent className={classes.hoverContent}>
                {selectedDestination.nuts.country !== "undefined" && (
                    <Typography className={classes.hoverTitle} color="textSecondary">
                        <Flag selectedDestination={selectedDestination} dataLabels={dataLabels} />
                        {dataLabels[0][selectedDestination.nuts.country]}
                    </Typography>
                )}
                <Typography className={classes.hoverLocation}>
                    {selectedDestination.nuts.mitomedName}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default DestinationCard;
