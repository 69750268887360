import axios, { AxiosResponse } from "axios";
import { RootObject } from "../../types/types";

const baseUrl: string =
  "https://ar-tour.com/ArtourAPI/GetRoute.ashx?apikey=cemeteriesAPIKEY15052015&guideid=923";

export const getBritofData = async (): Promise<AxiosResponse<RootObject>> => {
  try {
    const getData: AxiosResponse<RootObject> = await axios.get(baseUrl);
    return getData;
  } catch (error) {
    throw new Error("");
  }
};
