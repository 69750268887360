import React, { useState } from "react";
import clsx from "clsx";
import { Drawer, /*List, ListItem, ListItemIcon,*/ makeStyles } from "@material-ui/core";
/*import { ChevronLeft as IconChevronLeft, ChevronRight as IconChevronRight } from '@material-ui/icons';*/

const drawerWidth = 300;
const drawerCloseWidth = 15;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        background: "#006FBA",
    },
    drawerOpen: {
        width: drawerWidth,
        background: "#006FBA",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        background: "#006FBA",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: drawerCloseWidth,
    },
    hide: {
        display: "none",
    },
    drawerHandler: {
        position: "fixed",
        bottom: 0,
        width: "fit-content",
        marginBottom: "15px",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    drawerHandlerIcon: {
        color: "#ffffff",
    },
}));

function DrawerMenu() {
    const classes = useStyles();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleDrawer = () => setDrawerOpen(!drawerOpen);

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: drawerOpen,
                    [classes.drawerClose]: !drawerOpen,
                }),
            }}
        >
            {/*<List>  
                <ListItem button disableRipple disableTouchRipple key='open' className={classes.drawerHandler} onClick={handleDrawer} >
                    <ListItemIcon>
                        <IconChevronRight className={ clsx(classes.drawerHandlerIcon, { [classes.hide]: drawerOpen }) } />
                        <IconChevronLeft className={ clsx(classes.drawerHandlerIcon, { [classes.hide]: !drawerOpen }) } />
                    </ListItemIcon>
                </ListItem>
            </List>*/}
        </Drawer>
    );
}

export default DrawerMenu;
