import LogoInterreg from "../../assets/img/logo_interreg_acknowledgement.svg";

import {
    Button,
    TextField,
    Grid,
    Container,
    IconButton,
    Collapse,
    makeStyles,
    Typography,
    TextArea
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import firebase from "firebase";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { config } from "../../components/User/firebaseConfig";
import { signIn, isLoggedIn } from "../User/Auth";
import AuthContext from "../../context/auth/AuthContext";
import { usersApi } from "../../services/api/axios";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

const useStyles = makeStyles(theme => ({
    closeAlertButton: {
        backgroundColor: "#fdecea",
        "&:hover": {
            backgroundColor: "#ffe1de",
        },
    },
    closeSuccessAlertButton: {
        backgroundColor: "#edf7ed",
        "&:hover": {
            backgroundColor: "#5fb76226",
        },
    },
    forgotPasswordText: {
        fontSize: '12px',
        textAlign: 'right'
    },
    resetPasswordInfo: {
        color: '#3e3e3e'
    },
    captchaField: {
        marginTop: '7px',
        "& input": {
            padding: '17px 14px',
        }
    }
}));

function ApplyForAccount() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const Auth = useContext(AuthContext);
    var auth = firebase.auth();

    const [email, setEmail] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [institution, setInstitution] = useState("");
    const [culturalRoute, setCulturalRoute] = useState("");
    const [captcha, setCaptcha] = useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [emptyError, setEmptyError] = React.useState(false);
    const [captchaError, setCaptchaError] = React.useState(false);
    const [alreadyExistsError, setAlreadyExistsError] = React.useState(false);

    const onEmailChange = event => {
        setEmail(event.target.value);
        setEmptyError(false);
        setErrorAlertOpen(false);
        setSuccessAlertOpen(false);
        setAlreadyExistsError(false);
    };

    const onFirstnameChange = event => {
        setFirstname(event.target.value);
    }

    const onLastnameChange = event => {
        setLastname(event.target.value);
    }

    const onInstitutionChange = event => {
        setInstitution(event.target.value);
    }

    const onCulturalRouteChange = event => {
        setCulturalRoute(event.target.value);
    }

    const onCaptchaChange = event => {
        setCaptchaError(false);
        setCaptcha(event.target.value);
    }

    useEffect(() => {
        loadCaptchaEnginge(5);
    }, [])

    const handleSubmit = async event => {
        event.preventDefault();
        if (email && firstname && lastname) {
            if (validateCaptcha(captcha)) {
                if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                    var user = {
                        firstname: firstname,
                        lastname: lastname,
                        institution: institution,
                        culturalRoute: culturalRoute,
                        email: email
                    }
                    await usersApi.post(`/applications`, user)
                        .then((result) =>{
                            if (result.data) {
                                setSuccessAlertOpen(true);
                                setFirstname("");
                                setLastname("");
                                setEmail("");
                                setInstitution("");
                                setCulturalRoute("");
                                setCaptcha("");
                                setTimeout(()=>history.push("/"), 3000);
                            }
                        })
                        .catch((error) => {
                            setErrorAlertOpen(true);
                            if(error.response.status === 409)
                                setAlreadyExistsError(true);
                        })
                    loadCaptchaEnginge(5);
                }
            }
            else {
                setCaptchaError(true);
            }
        }
        else {
            setEmptyError(true);
        }

    };

    if (Auth.isLoggedIn) {
        return <Redirect to="/login" />;
    }

    const errorAlert = (
        <Collapse in={errorAlertOpen}>
            <Alert
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        className={classes.closeAlertButton}
                        onClick={() => {
                            setErrorAlertOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                severity="error"
                style={{ border: "solid 1px rgb(255, 224, 220)", marginBottom: '10px' }}
            >
                {alreadyExistsError ? t("applyForAccount.alreadyExistsError") : t("applyForAccount.error")}
            </Alert>
        </Collapse>
    );
    const successAlert = (
        <Collapse in={successAlertOpen}>
            <Alert
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        className={classes.closeSuccessAlertButton}
                        onClick={() => {
                            setSuccessAlertOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                severity="success"
                style={{ border: "solid 1px #6dbd7154", marginBottom: '10px' }}
            >
                {t("applyForAccount.success")}
            </Alert>
        </Collapse>
    );
    return (
        <Grid container  justify="center" alignItems="stretch">
            <Grid item xs={false} sm={false} md={4} className="login-image" style={{height: 'auto'}}></Grid>
            <Grid item xs={12} sm={12} md={8} style={{paddingTop: '80px', paddingBottom: '50px'}}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                >
                    <Container component="main" maxWidth="xs">
                        <a
                            href="https://best-med.interreg-med.eu/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={LogoInterreg}
                                alt="Interreg Mediterranean BEST MED"
                                className="login-logotype-container"
                            />
                        </a>
                        {errorAlert}
                        {successAlert}
                        <Typography className={classes.resetPasswordInfo}>
                            {t("applyForAccount.applyForAccountText")}
                        </Typography>
                        <form noValidate onSubmit={handleSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="firstname"
                                label={t("applyForAccount.firstname")}
                                name="firstname"
                                value={firstname}
                                onChange={onFirstnameChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="lastname"
                                label={t("applyForAccount.lastname")}
                                name="lastname"
                                value={lastname}
                                onChange={onLastnameChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="institution"
                                label={t("applyForAccount.institution")}
                                name="institution"
                                value={institution}
                                onChange={onInstitutionChange}
                            />
                            <TextField
                                multiline
                                rows={2}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="culturalRoute"
                                label={t("applyForAccount.culturalRoute")}
                                name="culturalRoute"
                                value={culturalRoute}
                                onChange={onCulturalRouteChange}
                            />
                            <TextField
                                error={emptyError}
                                helperText={emptyError ? "email cant be empty" : ""}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                label={t("forgotPassword.emailAddress")}
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={onEmailChange}
                            />
                            <Grid container alignItems="center" style={{ marginTop: '25px' }}>
                                <Grid item xs={12} md={4}>
                                    <LoadCanvasTemplate />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        error={captchaError}
                                        className={classes.captchaField}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="captcha"
                                        label={"Enter captcha value"}
                                        name="captcha"
                                        value={captcha}
                                        onChange={onCaptchaChange}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className="login-submit-button"
                            >
                                {t("applyForAccount.applyForAccount")}
                            </Button>
                        </form>
                    </Container>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ApplyForAccount;
