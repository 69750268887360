export class Route {
    constructor(
        title,
        routeColor,
        visible,
        type,
        description,
        images,
        links,
        contacts,
        geoLocation,
        parts,
        routePoints,
        author
    ) {
        this.title = title;
        this.routeColor = routeColor;
        this.visible = visible;
        this.type = type;
        this.description = description;
        this.images = images;
        this.links = links;
        this.contacts = contacts;
        this.geoLocation = geoLocation;
        this.parts = parts;
        this.routePoints = routePoints;
        this.author = author;
    }
}

export class RouteImage {
    constructor(image, caption) {
        this.image = image;
        this.caption = caption;
    }
}

export class RouteLink {
    constructor(link, caption) {
        this.link = link;
        this.caption = caption;
    }
}

export class RouteContact {
    constructor(type, contact) {
        this.type = type;
        this.contact = contact;
    }
}

export class RouteLocation {
    constructor(coordinates, latitude, longitude) {
        this.coordinates = coordinates;
        this.latitude = latitude;
        this.longitude = longitude;
    }
}

export class RoutePart {
    constructor(title, description, geoLocation, images, links, points) {
        this.title = title;
        this.description = description;
        this.geoLocation = geoLocation;
        this.images = images;
        this.links = links;
        this.points = points;
    }
}

export class RoutePoint {
    constructor(destinationId, name, latitude, longitude, position) {
        this.destinationId = destinationId;
        this.name = name;
        this.latitude = latitude;
        this.longitude = longitude;
        this.position = position;
    }
}

export class Point {
    constructor(title, description, geoLocation, images, links) {
        this.title = title;
        this.description = description;
        this.geoLocation = geoLocation;
        this.images = images;
        this.links = links;
    }
}
