import React, { useReducer } from "react";

import LocationContext from "./LocationContext";
import LocationReducer from "./LocationReducer";

const LocationState = props => {
    const initialState = {
        location: [],
    };

    const [state, dispatch] = useReducer(LocationReducer, initialState);

    return (
        <LocationContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {props.children}
        </LocationContext.Provider>
    );
};

export default LocationState;
