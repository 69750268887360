import * as Excel from "exceljs";
import { saveAs } from "file-saver";

export const exportXlsxStructure = async (json, routeName, assessmentFor) => {
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet(`${routeName}_${assessmentFor}`);
    worksheet.columns = [
        { header: 'ID', key: 'ID', width: 10 },
        { header: 'Title', key: 'Title', width: 50 },
        { header: 'Question type', key: 'Question type', width: 15 },
        { header: 'required', key: 'required', width: 10 }
    ];
    json.forEach((data) => {
        worksheet.addRow(data);
    });
    worksheet.getRow(1).eachCell((cell) => {
        cell.font = {
            bold: true
        }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, `${routeName}_${assessmentFor}_Structure` + fileExtension);
}

export const exportXlsxContent = async (json, routeName, assessmentFor, graphData, base64Image, dimensions) => {
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet(`${routeName}_${assessmentFor}`);
    worksheet.columns = [
        { header: 'ID', key: 'ID', width: 10 },
        { header: 'answer', key: 'answer', width: 25 }
    ];
    json.forEach((data) => {
        worksheet.addRow(data);
    });


    let graphDataHeaderRow = worksheet.getRow(1);
    graphDataHeaderRow.getCell(4).value = "section";
    graphDataHeaderRow.getCell(5).value = "yes";
    graphDataHeaderRow.getCell(6).value = "no";
    graphDataHeaderRow.getCell(7).value = "unanswered";

    for (let i = 0; i < graphData.length; i++) {
        let row = worksheet.getRow(i + 2);
        let nameCell = row.getCell(4);
        let yesCell = row.getCell(5);
        let noCell = row.getCell(6);
        let unansweredCell = row.getCell(7);
        //nameCell.font = { bold: true };

        nameCell.value = graphData[i].name;
        yesCell.value = graphData[i].yes;
        noCell.value = graphData[i].no;
        unansweredCell.value = graphData[i].unanswered;
    }

    worksheet.getRow(1).eachCell((cell) => {
        cell.font = {
            bold: true
        }
    });

    const excelImage = workbook.addImage({
        base64: base64Image,
        extension: 'png',
    });
    var width = Math.round(dimensions.width / 64);
    var height = Math.round(dimensions.height / 20);
    var excelColumns = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    var endingCell = excelColumns[width + 2] + (height + 8);
    worksheet.mergeCells('D8', endingCell);
    worksheet.addImage(excelImage, 'D8:' + endingCell);


    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, `${routeName}_${assessmentFor}_Content` + fileExtension);
}