
import React, { useContext, useEffect, useState, Fragment, useRef } from "react";
import {
    MapContainer, TileLayer, CircleMarker, Popup, useMap, Tooltip, Polyline,
    Polygon,
} from "react-leaflet";
import * as helpers from "@turf/helpers";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import {
    makeStyles,
    Typography,
    Card,
    CardContent
} from "@material-ui/core";

const URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: "transparent",
        border: "0px",
        boxShadow: "0 0",
    },
    infoCard: {
        width: "220px",
        float: "right",
        boxShadow: "0px 0px 15px #78787847",
        borderRadius: "15px",
    },
    infoLabel: {
        fontSize: "0rem !important",
    },
    hoverContent: {
        padding: "14px 18px 14px 18px !important"
    },
    hoverTitle: {
        fontWeight: "500",
        fontSize: "14px",
        textTransform: "capitalize !important",
        whiteSpace: "normal !important"
    },
    hoverLatLng: {
        fontSize: "13px"
    }
}));

function RouteDetailsMap({ route }) {
    const classes = useStyles();
    return (
        <MapContainer
            center={[route.geoLocation.latitude, route.geoLocation.longitude]}
            minZoom={4}
            zoom={5}
            maxZoom={14}
            scrollWheelZoom={true}
            style={{ height: '100%', maxHeight: '450px' }}
        >
            <TileLayer attribution={attribution} url={URL} />
            <CircleMarker
                key={`marker-${route.id}`}
                center={[route.geoLocation.latitude, route.geoLocation.longitude]}
                radius={7}
                fillColor="#fbbe00"
                fillOpacity="1"
                stroke={false}
            >
                <Tooltip className={classes.tooltip} opacity={1}>
                    <Card className={classes.infoCard}>
                        <CardContent className={classes.hoverContent}>
                            <Typography className={classes.hoverTitle}>
                                {route.title}
                            </Typography>
                            <Typography className={classes.hoverLatLng}>
                                lat • {route.geoLocation.latitude}
                            </Typography>
                            <Typography className={classes.hoverLatLng}>
                                lng • {route.geoLocation.longitude}
                            </Typography>
                        </CardContent>
                    </Card>
                </Tooltip>
            </CircleMarker>
            <RouteVisualise
                routeType={route.type}
                routePoints={route.routePoints}
                route={route}
                routeIndex={0}
            />
            {route.routePoints ? (<VisualizePoints routePoints={route.routePoints}/>):("")}
        </MapContainer>
    );
}
function VisualizePoints({ routePoints }) {
    const classes = useStyles();
    return (
            routePoints.map((point) => (
                < CircleMarker
                    center={[point.latitude, point.longitude]}
                    radius={7}
                    fillColor="#006fba"
                    fillOpacity="1"
                    stroke={false}
                >
                    <Tooltip className={classes.tooltip} opacity={1}>
                        <Card className={classes.infoCard}>
                            <CardContent className={classes.hoverContent}>
                                <Typography className={classes.hoverTitle}>
                                    {point.name}
                                </Typography>
                                <Typography className={classes.hoverLatLng}>
                                    lat • {point.latitude}
                                </Typography>
                                <Typography className={classes.hoverLatLng}>
                                    lng • {point.longitude}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Tooltip>
                </CircleMarker >
            ))

    );
}
function RouteVisualise({ routeType, routePoints, route }) {
    const map = useMap();
    const line_polyline = useRef(null);
    const area_polyline = useRef(null);
    const [active, setActive] = useState(false);
    const color = route.routeColor;
    var line;
    const defaultLineColor = "#046FB8";
    var polyLinePositions = [];
    if (routePoints) {
        routePoints.forEach(point => {
            polyLinePositions.push([point.latitude, point.longitude]);
        });
    }
    if (polyLinePositions.length > 1) {
        var test = polyLinePositions.map(lngLat => [lngLat[0], lngLat[1]]);
        line = helpers.lineString(test);
    }
    if (routeType === "circular") {
        const line = polyLinePositions;
        if (polyLinePositions.length > 1) {
            line.push(polyLinePositions[0]);
        }
        return polyLinePositions.length > 1 ? (
            <Polyline
                ref={line_polyline}
                pathOptions={{
                    color: color || defaultLineColor,
                    opacity: active ? 0.9 : 0.6,
                    weight: active ? 8 : 6,
                }}
                className="polyline-animate"
                positions={line}
                zIndexOffset={9}
                eventHandlers={{
                    mouseover: e => {
                        e.target.setStyle({ opacity: 0.8 });
                    },
                    mouseout: e => {
                        e.target.setStyle({ opacity: 0.6 });
                    },
                }}
            ></Polyline>
        ) : (
            ""
        );
    } else if (routeType === "linear") {
        return polyLinePositions.length > 1 ? (
            <Polyline
                ref={line_polyline}
                pathOptions={{
                    color: color || defaultLineColor,
                    opacity: active ? 0.9 : 0.6,
                    weight: active ? 8 : 6,
                }}
                zIndexOffset={9}
                className="polyline-animate"
                positions={polyLinePositions}
                eventHandlers={{
                    mouseover: e => {
                        e.target.setStyle({ opacity: 0.8 });
                    },
                    mouseout: e => {
                        e.target.setStyle({ opacity: 0.6 });
                    },
                }}
            />
        ) : (
            ""
        );
    } else {
        return polyLinePositions.length > 1 ? (
            <Polygon
                ref={area_polyline}
                pathOptions={{
                    color: color || defaultLineColor,
                    fillOpacity: active ? 0.5 : 0.3,
                    opacity: active ? 0.5 : 0.3,
                    weight: 30,
                    stroke: false,
                    lineCap: "square",
                    smoothFactor: 0.2,
                }}
                positions={polyLinePositions}
                zIndexOffset={9}
                eventHandlers={{
                    mouseover: e => {
                        e.target.setStyle({ fillOpacity: 0.4 });
                        console.log("hover");
                    },
                    mouseout: e => {
                        e.target.setStyle({ fillOpacity: 0.3 });
                    },
                }}
            />
        ) : (
            ""
        );
    }
}

export default RouteDetailsMap;
