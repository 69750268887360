import LanguageSwitcher from "../Header/LanguageSwitcher";
import UserMenu from "../Header/UserMenu";
import LoginButton from "../Header/LoginButton";
import AppMenu from "../Header/AppMenu";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Grid, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { isLoggedIn } from "../User/Auth";
import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../context/auth/AuthContext";
import { AppMenuItems } from "./AppMenuItems";
import { useTranslation } from "react-i18next";

function Header({ location }) {
    const Auth = useContext(AuthContext);
    const { t } = useTranslation();
    return (
        <AppBar
            position="relative"
            className={
                location.pathname === "/" ||
                    location.pathname === "/login" ||
                    location.pathname === "/change-password" ||
                    location.pathname === "/forgot-password" ||
                    location.pathname === "/apply-for-account" ||
                    location.pathname === "/welcome-page" ||
                    location.pathname === "/routes"
                    ? "appbar-container-transparent"
                    : "appbar-container"
            }
        >
            <Toolbar>
                <Grid justify="space-between" className="header-container" container>
                    <Grid item>
                        {location.pathname !== "/login" &&
                            location.pathname !== "/change-password" &&
                            location.pathname !== "/forgot-password" &&
                            location.pathname !== "/welcome-page" &&
                            location.pathname !== "/" &&
                            location.pathname !== "/apply-for-account" &&
                            location.pathname !== "/routes" && <AppMenu />}
                        {(location.pathname === "/apply-for-account" || location.pathname === "/login" || location.pathname === "/forgot-password" || location.pathname === "/change-password") ? (
                            <Grid container alignItems="flex-end" className="app-menu">
                                <Grid item className="app-menu-item-container" key={AppMenuItems[0].id}>
                                    <Link to={AppMenuItems[0].link}>
                                        <Button
                                            disableRipple
                                            disableFocusRipple
                                            className={
                                                AppMenuItems[0].link === location.pathname
                                                    ? "app-menu-item-active"
                                                    : "app-menu-item"
                                            }
                                        >
                                            {t(AppMenuItems[0].title)}
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        ) : ('')}
                    </Grid>
                    <Grid item style={{ display: "flex" }}>
                        <Grid item className="item-container-language-switcher">
                            <LanguageSwitcher />
                        </Grid>
                        {location.pathname !== "/login" &&
                            location.pathname !== "/change-password" &&
                            (Auth.isLoggedIn ? (
                                <Grid item className="item-container-user-menu">
                                    <UserMenu />
                                </Grid>
                            ) : (
                                <Grid item className="item-container-login-button">
                                    <LoginButton />
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default withRouter(Header);
