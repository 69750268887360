import { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { Container, Grid, Typography, CircularProgress, Button, Divider } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import IconArrowBack from "@material-ui/icons/ArrowBack";
import { indicatorsApi } from "../../services/api/axios";
import { whoAmI, getToken } from "../User/Auth";
import indAssFake from "./IndicatorsAssessment";
import IndAssInput from "./IndicatorsAssessmentInput";

const useStyles = makeStyles(theme => ({
    questionnaireBackground: {
        padding: "40px",
        paddingRight: "10px",
        marginTop: "24px",
        marginBottom: "10px",
        backgroundColor: "#ffffff",
        border: "1px solid rgba(224, 224, 224, 1)",
    },
    questionnaireInfo: {
        fontWeight: "450",
    },
    container: {
        marginLeft: "45px",
        marginRight: "30px",
        paddingBottom: "48px",
        paddingTop: "48px",
    },
    title: {
        fontWeight: "500 !important",
        fontSize: "1.625em !important",
        textTransform: "uppercase",
    },
    subtitle: {
        fontSize: "1.05em",
        fontWeight: "500 !important",
    },
    subtitleContainer: {
        marginTop: "auto",
    },
    routeName: {
        fontWeight: "500 !important",
        fontSize: "0.95em !important",
        textTransform: "uppercase",
    },
    check: {
        fontSize: "0.95rem !important",
        paddingLeft: "50px",
        paddingRight: "50px",
    },
    category: {
        fontSize: "0.95rem !important",
        fontWeight: "500 !important",
        paddingLeft: "0px",
        color: "#006fba",
    },
}));

const Accordion = withStyles({
    root: {
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummarySectionName = withStyles({
    root: {
        minHeight: 48,
        "&$expanded": {
            minHeight: 48,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
    root: {
        display: "block",
    },
}))(MuiAccordionDetails);

function IndicatorsAssessment({ indAss, routeName, routeId, groupedInd }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState("panel-indicators-Management");
    const [counter, setCounter] = useState([]);

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    let dateOptions = { weekday: "long", day: "numeric", month: "long", year: "numeric" };

    let lastModified = new Date(parseInt(indAss.lastModified)).toLocaleDateString(
        "en-US",
        dateOptions
    );
    let started = new Date(parseInt(indAss.started)).toLocaleDateString("en-US", dateOptions);

    let lastModifiedBy = indAss.lastModifiedBy || "";
    let startedBy = indAss.leadAuditor || "";
    let indicators = groupedInd || [[0, []]];

    return (
        <div className={classes.container}>
            <Container maxWidth="xl">
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={12} sm={9}>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                    component="h2"
                                    className={classes.routeName}
                                >
                                    {routeName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                    component="h2"
                                    className={classes.title}
                                >
                                    {indAss.assessmentFor}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                    component="h2"
                                    className={classes.title}
                                >
                                    {indAss.indicatorsYear}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container alignItems="flex-end">
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    className={classes.buttons}
                                    justify="flex-end"
                                >
                                    <Grid item>
                                        <Link to={"/dashboard/cultural-route/" + routeId}>
                                            <Button
                                                size="medium"
                                                variant="outlined"
                                                color="primary"
                                                className="back-button"
                                            >
                                                <IconArrowBack className="back-button-icon" />
                                                Back to dashboard
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-end">
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid
                                item
                                xs={3}
                                sm={4}
                                md={5}
                                lg={6}
                                xl={7}
                                className={classes.subtitleContainer}
                            >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h5"
                                            color="textSecondary"
                                            className={classes.subtitle}
                                        >
                                            {indAss.title}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9} sm={8} md={7} lg={6} xl={5}>
                                <Grid container>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item xs={2} sm={3} md={4} lg={5} xl={6}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                align="right"
                                            >
                                                Started:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10} sm={9} md={8} lg={7} xl={6}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                align="left"
                                            >
                                                <span className={classes.questionnaireInfo}>
                                                    {startedBy}
                                                </span>{" "}
                                                ({started})
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item xs={2} sm={3} md={4} lg={5} xl={6}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                align="right"
                                            >
                                                Last modified:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10} sm={9} md={8} lg={7} xl={6}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                align="left"
                                            >
                                                <span className={classes.questionnaireInfo}>
                                                    {lastModifiedBy}
                                                </span>{" "}
                                                ({lastModified})
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    className={classes.questionnaireBackground}
                    alignItems="center"
                    justify="center"
                >
                    <div className="form-cultural-route-container">
                        <Container maxWidth="lg">
                            {indicators.map(([indicatorsGroup, indicators]) => (
                                <Accordion
                                    square
                                    expanded={expanded === `panel-indicators-${indicatorsGroup}`}
                                    onChange={handleChange(`panel-indicators-${indicatorsGroup}`)}
                                    key={`indicator-${indicatorsGroup}`}
                                >
                                    <AccordionSummarySectionName
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel-content-${indicatorsGroup}`}
                                        id={`panel-header-${indicatorsGroup}`}
                                    >
                                        <Grid
                                            justify="space-between" // Add it here :)
                                            container
                                            spacing={24}
                                        >
                                            <Grid item>
                                                <Typography
                                                    variant="body2"
                                                    color="primary"
                                                    component="h2"
                                                    className={classes.routeName}
                                                >
                                                    {indicatorsGroup}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    className={classes.category}
                                                    style={{
                                                        color:
                                                            indicators.reduce(
                                                                (c, i) =>
                                                                    c + (i.value.length ? 1 : 0),
                                                                0
                                                            ) === indicators.length
                                                                ? "#60b277"
                                                                : "#000000",
                                                    }}
                                                >
                                                    {indicators.reduce(
                                                        (c, i) => c + (i.value.length ? 1 : 0),
                                                        0
                                                    ) +
                                                        (isNaN(
                                                            counter.reduce(
                                                                (acc, curr) => (
                                                                    (acc[curr] =
                                                                        (acc[curr] || 0) + 1),
                                                                    acc
                                                                ),
                                                                {}
                                                            )[indicatorsGroup]
                                                        )
                                                            ? 0
                                                            : counter.reduce(
                                                                  (acc, curr) => (
                                                                      (acc[curr] =
                                                                          (acc[curr] || 0) + 1),
                                                                      acc
                                                                  ),
                                                                  {}
                                                              )[indicatorsGroup])}
                                                    /{indicators.length}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummarySectionName>
                                    <Divider className="input-divider" />
                                    <AccordionDetails>
                                        {indicators.map(indicator => (
                                            <IndAssInput
                                                indicator={indicator}
                                                indAssId={indAss.id}
                                                stateChanger={setCounter}
                                            />
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Container>
                    </div>
                </Grid>
            </Container>
        </div>
    );
}

function IndicatorsAssessmentSource() {
    const [isLoading, setIsLoading] = useState(false);
    const [routeName, setRouteName] = useState();
    const [routeId, setRouteId] = useState();
    const [indicatorsAss, setIndicatorsAss] = useState(indAssFake);
    const [indicatorsByGroup, setIndicatorsByGroup] = useState(indAssFake.indicators);
    let { assId } = useParams();
    let location = useLocation();

    useEffect(() => {
        setIsLoading(true);
        (async function fetchData() {
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                let currentUserIdToken = "";
                if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                    const currentUser = whoAmI();
                    if (currentUser) {
                        const idToken = await getToken();
                        if (idToken) {
                            currentUserIdToken = idToken;
                        }
                    }
                }
                indicatorsApi
                    .get(`/indicatorsass/${assId}`, {
                        headers: { Authorization: "Bearer " + currentUserIdToken },
                    })
                    .then(response => {
                        setIndicatorsAss(response.data);
                        const groupBy = key => array =>
                            array.reduce((objectsByKeyValue, obj) => {
                                const value = obj[key];
                                objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(
                                    obj
                                );
                                return objectsByKeyValue;
                            }, {});
                        const groupByGroup = groupBy("group");
                        setIndicatorsByGroup(
                            Object.entries(groupByGroup(response.data.indicators))
                        );
                        setIsLoading(false);
                    });
            } else {
                setIndicatorsAss(indAssFake);
                setIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (location.state !== undefined) {
            setRouteName(location.state.detail);
            setRouteId(location.state.routeId);
        }
    }, [indicatorsAss, location]);

    return (
        <>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <IndicatorsAssessment
                    indAss={indicatorsAss}
                    routeName={routeName}
                    routeId={routeId}
                    groupedInd={indicatorsByGroup}
                />
            )}
        </>
    );
}

export default IndicatorsAssessmentSource;
