import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../../assets/css/indicatorsTable.model.scss";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const materialTheme = createTheme({
    overrides: {
        MuiToolbar: {
            root: {
                backgroundColor: "#006fba !important",
            },
        },
        MuiButton: {
            label: {
                color: "#006fba !important",
            },
        },
        MuiPickersYear: {
            root: {
                color: "#006fba !important",
            },
        },
    },
});

const RouteDetailsIndicators = ({ routeIndicators, indicatorsLatest }) => {
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [year, setYear] = useState(new Date());

    const handleChange = newValue => {
        setYear(newValue);
    };

    const columnsStart = [
        { field: "indicatorReference", headerName: "ID", width: 90 },
        {
            field: "coreIndicators",
            headerName: "Indicator name",
            minWidth: 350,
            renderCell: params => <div>{params.value}</div>,
        },
        {
            field: "group",
            headerName: "Group",
            minWidth: 120,
        },
        {
            field: "period",
            headerName: "Period",
            minWidth: 120,
        },
    ];

    useEffect(() => {
        const newIndAss = routeIndicators.filter(indAss =>
            indAss.indicatorsYear.includes(`${Object.is(year, null) ? "" : year.getFullYear()}`)
        );
        setColumns(columnsStart);
        setRows([]);
        indicatorsLatest.map((ind, index) => {
            newIndAss.map(indAss => {
                ind[`${indAss.assessmentFor}-${indAss.indicatorsYear}`] =
                    indAss.indicators[index].value;
                ind["period"] = `${Object.is(year, null) ? "∞" : year.getFullYear()}`;
            });
            setRows(rows => [...rows, ind]);
        });
        newIndAss.map(indAss => {
            setColumns(columns => [
                ...columns,
                {
                    field: `${indAss.assessmentFor}-${indAss.indicatorsYear}`,
                    headerName: Object.is(year, null)
                        ? `${indAss.indicatorsYear}-${indAss.assessmentFor}-Value`
                        : `${indAss.assessmentFor}-Value`,
                    width: indAss.assessmentFor.length + 170,
                    renderCell: params => <>{params.value.length ? params.value : "N/A"}</>,
                },
            ]);
        });
    }, [year]);

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={materialTheme}>
                    <KeyboardDatePicker
                        views={["year"]}
                        value={year}
                        onChange={handleChange}
                        inputVariant="outlined"
                        label="Select Year"
                        InputAdornmentProps={{ position: "start" }}
                    />
                </ThemeProvider>
            </MuiPickersUtilsProvider>
            <div
                className="indicatorsTabel"
                style={{ height: 500, width: "100%", paddingTop: "16px" }}
            >
                <DataGrid rows={rows} getRowId={row => row.indicatorReference} columns={columns} />
            </div>
        </>
    );
};

export default RouteDetailsIndicators;
