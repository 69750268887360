import DrawerMenu from "../Menu/DrawerMenu";
import Destinations from "../Destinations/Destinations";
import Login from "../User/Login";
import ChangePassword from "../User/ChangePassword";
import Dashboard from "../Dashboard/Dashboard";
import FormCulturalRoute from "../Forms/FormCulturalRoute";
import LeafletMap from "../Test/components/LeafletMap";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import ProtectedRoute from "./ProtectedRoute";
import RoleProtectedRoute from "./RoleProtectedRoute";
import Routes from "../Routes/Routes";
import SelfAssessmentSource from "../SelfAssessment/SelfAssessment";
import SelfAssessmentViewSource from "../SelfAssessment/SelfAssessmentView";
import DestinationDetails from "../Destinations/DestinationDetails/DestinationDetails";
import RouteDetails from "../Routes/RouteDetails/RouteDetails";
import ForgotPassword from "../User/ForgotPassword";
import ApplyForAccount from "../User/ApplyForAccount";
import IndicatorsAssessmentSource from "../Indicators/IndicatorsAssessmentSource";
import IndicatorsAssessmentViewSource from "../Indicators/IndicatorsAssessmentViewSource";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import WelcomePage from "../WelcomePage/WelcomePage";

function Main({ location }) {
    return (
        <>
            {location.pathname === "/login" ||
            location.pathname === "/change-password" ||
            location.pathname === "/apply-for-account" ||
            location.pathname === "/welcome-page" ||
            location.pathname === "/forgot-password" ? (
                ""
            ) : (
                <DrawerMenu />
            )}

            {location.pathname !== "/welcome-page" ? <Header /> : ""}

            <div className="main-container">
                <Switch>
                    <Route exact path="/" component={Destinations} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/apply-for-account" component={ApplyForAccount} />
                    <RoleProtectedRoute exact path="/user-management" role="admin">
                        <AdminDashboard />
                    </RoleProtectedRoute>
                    <ProtectedRoute exact path="/change-password">
                        <ChangePassword />
                    </ProtectedRoute>
                    <Route exact path="/routes" component={Routes} />
                    <Route exact path="/destination/:id" component={DestinationDetails} />
                    <Route exact path="/route/:routeId" component={RouteDetails} />
                    <ProtectedRoute exact path="/test">
                        <LeafletMap />
                    </ProtectedRoute>
                    <Route exact path="/welcome-page">
                        <WelcomePage />
                    </Route>
                    <ProtectedRoute exact path="/questionnaire/:partId">
                        <SelfAssessmentSource />
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/questionnaire/answers/:partId">
                        <SelfAssessmentViewSource />
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/indicators/:assId">
                        <IndicatorsAssessmentSource />
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/indicators/answers/:assId">
                        <IndicatorsAssessmentViewSource />
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/dashboard">
                        <Dashboard />
                    </ProtectedRoute>
                    <ProtectedRoute exact path="/dashboard/add-cultural-route">
                        <FormCulturalRoute formType={"add"} />
                    </ProtectedRoute>
                    <ProtectedRoute path="/dashboard/cultural-route/:routeId">
                        <FormCulturalRoute formType={"view"} />
                    </ProtectedRoute>
                    <ProtectedRoute path="/dashboard/edit-cultural-route/:routeId">
                        <FormCulturalRoute formType={"edit"} />
                    </ProtectedRoute>
                    <Redirect to="/" />
                </Switch>
            </div>

            {location.pathname === "/" ||
            location.pathname === "/login" ||
            location.pathname === "/change-password" ||
            location.pathname === "/forgot-password" ||
            location.pathname === "/welcome-page" ||
            location.pathname === "/apply-for-account" ||
            location.pathname === "/routes" ? (
                ""
            ) : (
                <Footer />
            )}
        </>
    );
}

export default withRouter(Main);
