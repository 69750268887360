export const AllCulturalRoutes = [
    {
        id: "safds323423sdffasdfsa3",
        title: "European cemeteries Route",
        visible: true,
        type: "thematic",
        description:
            "<p>The <strong>European Cemeteries Route</strong> offers the visitors the possibility to literally walk through the local history, to learn about important personalities who have worked and left their mark in cities. Traveling through this route actually enables visitors to discover the local, national and European Cultural Heritage at rest in cemeteries. It helps to raise European citizens' awareness of the importance of Europe's significant cemeteries in their multicultural dimension.</p>",
        images: [
            { image: "6026921bfc5d94f51b1ceab3", caption: "feri_logo" },
            { image: "6026921bfc5d94f51b1ceab3", caption: "fri_logo" },
        ],
        links: [{ link: "Link", caption: "Testni" }],
        contacts: [{ type: "email", contact: "test.test@gmail.com" }],
        geoLocation: {
            coordinates: "46.24597119249251, 14.615127640673295",
            latitude: "46.24597119249251",
            longitude: "14.615127640673295",
        },
        parts: [
            {
                title: "Pobrežje",
                id: "6026921cfc5d94f51b1ceab6",
            },
        ],
    },
    {
        id: "6026921cfc5d94f51b1ceab5",
        title: "Wine, Gastronomy & Heritage tour of Slovenia",
        visible: true,
        type: "circular",
        description:
            "<p>The <strong>Iter Vitis Route of the Cultural Routes of the Council of Europe</strong> proposes a very special Wine, gastronomy and heritage tour in Slovenia. This unique program is designed for travelers who would liike to combine their love for discovering new wines with their interest in traditional gastronomy and local traditions.</p>",
        images: [{ image: "undefined", caption: "undefined" }],
        links: [
            {
                link: "https://itervitis.eu/wine-gastronomy-heritage-tour-of-slovenia/",
                caption: "Official Website",
            },
        ],
        contacts: [
            { type: "telephone", contact: "+386 (0)7 393 1 520" },
            { type: "email", contact: "test.test@gmail.com" },
        ],
        geoLocation: {
            coordinates: "46.55472, 15.64667",
            latitude: "46.55472",
            longitude: "15.64667",
        },
        parts: [
            {
                id: "6026921cfc5d94f51b1ceab3",
                title: "Bela Krajina",
            },
            {
                id: "6026921cfc5d94f51b1ceab4",
                title: "Škocjan Caves",
            },
            {
                id: "6026921cfc5d94f51b1ceab5",
                title: "Eastern Slovenia",
            },
            {
                id: "6026921cfc5d94f51b1ceab7",
                title: "Slovenian Coast",
            },
        ],
    },
];
