import React, { useEffect, useState } from "react";

import {
    Container,
    Grid,
    Typography,
    Button,
    CircularProgress,
    makeStyles,
    Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Area from "../../../assets/img/Area.png";
import Population from "../../../assets/img/Population.png";
import Coast from "../../../assets/img/Coast.png";
import Season from "../../../assets/img/Season.png";

const useStyles = makeStyles(theme => ({
    infoCard: {
        maxWidth: "400px",
        float: "right",
        boxShadow: "0px 0px 15px #787878",
        borderRadius: "15px",
    },
    infoLabel: {
        fontSize: "0rem !important",
    },
    titleCountry: {
        fontSize: 15,
        textTransform: "uppercase",
        //color: '#ffffff'
    },
    titleData: {
        fontSize: "0.6rem",
        textTransform: "uppercase",
    },
    stats: {
        fontSize: 15,
        fontWeight: "fontWeightBold",
    },
    highSeason: {
        fontSize: "13px !important",
        fontWeight: "bold !important",
    },
    informationCard: {
        position: "absolute",
        top: "4px",
        right: "8px",
        fontSize: "0.85rem",
    },
    informationCardWrapper: {
        display: "inline-block",
        position: "relative",
        textAlign: "right",
        color: "white",
        maxWidth: "220px",
    },
}));

function DestinationDataCards({ selectedDestination }) {
    const { t } = useTranslation();
    const classes = useStyles();

    const populationClean = String(selectedDestination.population).replace(".", "");

    const population = Number(populationClean).toLocaleString("sl-SI");
    const totalArea = Number(selectedDestination.totalArea).toLocaleString("sl-SI");
    const coastLength = Number(selectedDestination.coastLength).toLocaleString("sl-SI");

    let populationCard = "",
        totalAreaCard = "",
        coastLengthCard = "",
        highSeasonCard = "";

    if (populationClean > 0) {
        populationCard = (
            <Grid item xs={6} sm={4} md={4} lg={3}>
                <div className={classes.informationCardWrapper}>
                    <img src={Population} alt="" width="100%" />
                    <div className={classes.informationCard}>
                        <Typography
                            className={classes.stats}
                            variant="h6"
                            paragraph={false}
                            style={{ marginBottom: "0px" }}
                        >
                            {population}
                        </Typography>
                        <Typography className={classes.infoLabel}>
                            <span className={classes.titleData}>
                                {t("destinations.card.population")}
                            </span>
                        </Typography>
                    </div>
                </div>
            </Grid>
        );
    }

    if (totalArea.length > 0 && !totalArea.startsWith("0")) {
        totalAreaCard = (
            <Grid item xs={6} sm={4} md={4} lg={3}>
                <div className={classes.informationCardWrapper}>
                    <img src={Area} alt="" width="100%" />
                    <div className={classes.informationCard}>
                        <Typography
                            className={classes.stats}
                            variant="h6"
                            paragraph={false}
                            style={{ marginBottom: "0px" }}
                        >
                            {totalArea + " km²"}
                        </Typography>
                        <Typography className={classes.infoLabel}>
                            <span className={classes.titleData}>
                                {t("destinations.card.totalArea")}
                            </span>
                        </Typography>
                    </div>
                </div>
            </Grid>
        );
    }

    if (coastLength.length > 0 && !coastLength.startsWith("0")) {
        coastLengthCard = (
            <Grid item xs={6} sm={4} md={4} lg={3}>
                <div className={classes.informationCardWrapper}>
                    <img src={Coast} alt="" width="100%" />
                    <div className={classes.informationCard}>
                        <Typography
                            className={classes.stats}
                            variant="h6"
                            paragraph={false}
                            style={{ marginBottom: "0px" }}
                        >
                            {coastLength + " km"}
                        </Typography>
                        <Typography className={classes.infoLabel}>
                            <span className={classes.titleData}>
                                {t("destinations.card.coastLength")}
                            </span>
                        </Typography>
                    </div>
                </div>
            </Grid>
        );
    }

    if (
        selectedDestination.highSeason !== "" &&
        selectedDestination.highSeason !== "-1" &&
        selectedDestination.highSeason !== "0" &&
        selectedDestination.highSeason.length < 35
    ) {
        highSeasonCard = (
            <Grid item xs={6} sm={4} md={4} lg={3}>
                <div className={classes.informationCardWrapper}>
                    <img src={Season} alt="" width="100%" />
                    <div className={classes.informationCard}>
                        <Typography className={classes.highSeason}>
                            {selectedDestination.highSeason}
                        </Typography>
                        <Typography className={classes.infoLabel}>
                            <span className={classes.titleData}>
                                High season
                                {/*t("destinations.card.highSeason")*/}
                            </span>
                        </Typography>
                    </div>
                </div>
            </Grid>
        );
    }

    return (
        <Box>
            <Grid container spacing={1}>
                {populationCard}
                {totalAreaCard}
                {coastLengthCard}
                {highSeasonCard}
            </Grid>
        </Box>
    );
}

export default DestinationDataCards;
