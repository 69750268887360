import React from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    makeStyles,
    Typography,
    IconButton,
    Button,
} from "@material-ui/core";

import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
    BarChart,
    Bar,
} from "recharts";

function beautifyNumbers(n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return Number((n / 1e3).toFixed(0)).toLocaleString("sl-SI") + "K";
    if (n >= 1e6 && n < 1e9) return Number((n / 1e6).toFixed(0)).toLocaleString("sl-SI") + "M";
    if (n >= 1e9 && n < 1e12) return Number((n / 1e9).toFixed(1)).toLocaleString("sl-SI") + "B";
    if (n >= 1e12) return Number((n / 1e12).toFixed(1)).toLocaleString("sl-SI") + "T";
}
const useStyles = makeStyles(theme => ({
    axisValues: {
        fontSize: "0.8rem",
        fontFamily: "Roboto",
        color: "#dfdfdf",
    },
    tooltipCard: {
        padding: "10px",
    },
}));

function DestinationCardLineGraph({ data, selectedDestination }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const format = v => beautifyNumbers(v);
    const graphData = data.value;
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload[0] !== undefined) {
            var sortedPayload = payload.sort((a, b) =>
                Number(a.value) > Number(b.value) ? -1 : 1
            );
            return (
                <Card className={classes.tooltipCard}>
                    <Typography variant="body2" component="p">
                        <b>{data.nuts.label}</b> •{" "}
                        <b>{label}</b>
                    </Typography>
                    {sortedPayload.map(payload => (
                        <Typography variant="body2" component="p">
                            <b style={{ color: payload.stroke }}>{`${Number(
                                payload.value
                            ).toLocaleString("sl-SI")}`}</b>
                        </Typography>
                    ))}
                </Card>
            );
        }
        return null;
    };
    const colors = ["#FBBE00", "#89BD23", "#10458C", "#1B5E20", "#006fba", "#0F798B"];
    var colorIndex = 0;
    const lines = data.graphDimensions.map(line => (
        <Line
            type="monotone"
            name={line.label}
            key={line.key}
            dataKey={line.key}
            animationEasing="ease-in-out"
            stroke={colors[colorIndex]}
            strokeWidth={2}
            dot={{ stroke: colors[colorIndex++], strokeWidth: 2, r: 2 }}
            activeDot={{ r: 4 }}
        />
    ));
    const legendLabelFormatter = (value, entry) => {
        return (
            <Typography variant="body2" component="span" style={{ fontSize: "12px" }}>
                {value}
            </Typography>
        );
    };
    var legend = "";
    if (data.graphDimensions.length > 1) {
        legend = <Legend formatter={legendLabelFormatter} align="center" />;
    }

    return (
        <ResponsiveContainer>
            <LineChart
                height={300}
                connectNulls={false}
                data={graphData}
                margin={{
                    top: 10,
                    right: 7,
                    left: 0,
                    bottom: 0,
                }}
            >
                <XAxis
                    dataKey="name"
                    tickLine={false}
                    tick={{
                        fontSize: "0.75rem",
                        fontFamily: "Roboto",
                        fill: "#a3a3a3",
                    }}
                    axisLine={false}
                />

                <YAxis
                    tickFormatter={format}
                    tickLine={false}
                    tick={{
                        fontSize: "0.75rem",
                        fontFamily: "Roboto",
                        fill: "#a3a3a3",
                    }}
                    width={40}
                    axisLine={false}
                    domain={["dataMin - 100", "dataMax + 100"]}
                />
                <CartesianGrid strokeDasharray="1 8" vertical={false} stroke="#C5C5C5" />
                <Tooltip content={CustomTooltip} filterNull={true} />
                {lines}
                {legend}
            </LineChart>
        </ResponsiveContainer>
    );
}

export default DestinationCardLineGraph;
