import React, { useEffect, useState } from "react";
import {
    Button,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Snackbar,
} from "@material-ui/core";
import { FiberManualRecord as IconCircle } from "@material-ui/icons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MuiAlert from "@material-ui/lab/Alert";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { indicatorsApi, usersApi } from "../../services/api/axios";
import { whoAmI, getToken } from "../User/Auth";

import AlertDelete from "../Dialogs/AlertDelete";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function formatDate(date, currentLanguage) {
    let dateOptions = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(currentLanguage, dateOptions);
}

const materialTheme = createTheme({
    overrides: {
        MuiToolbar: {
            root: {
                backgroundColor: "#006fba !important",
            },
        },
        MuiButton: {
            label: {
                color: "#006fba !important",
            },
        },
        MuiPickersYear: {
            root: {
                color: "#006fba !important",
            },
        },
    },
});

function InputRoutePartIndicators({ routeParts, routeName, routeAuthor }) {
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language;

    const { routeId } = useParams();

    const [routeSelfAssessmentData, setRouteSelfAssessmentData] = useState([]);
    const [accordionContent, setAccordionContent] = React.useState("");
    const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
    const [isRouteAuthor, setIsRouteAuthor] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    let history = useHistory();

    const handleEdit = data => {
        history.push({
            pathname: `/indicators/${data.idIndicatorsAss}`,
            state: { detail: routeName, routeId: routeId },
        });
    };

    const handleResults = data => {
        if (data.status !== "Not started") {
            history.push({
                pathname: `/indicators/answers/${data.idIndicatorsAss}`,
                state: { detail: routeName, routeId: routeId },
            });
        }
    };

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState();
    const [message, setMessage] = useState();

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const [deleteData, setDeleteData] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = data => {
        setModalOpen(true);
        setDeleteData(data);
    };

    const deleteIndicators = async () => {
        let currentUserIdToken = "";
        let currentUser = "";
        if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
            currentUser = whoAmI();
            if (currentUser) {
                const idToken = await getToken();
                if (idToken) {
                    currentUserIdToken = idToken;
                }
            }
        } else {
            currentUser = JSON.parse(localStorage.getItem("user"));
        }
        indicatorsApi
            .delete(`/indicatorsass/${deleteData.idIndicatorsAss}`, {
                headers: { Authorization: "Bearer " + currentUserIdToken },
            })
            .then(response => {
                if (response.status === 200) {
                    setSeverity("success");
                    setMessage(t("messageIndicators.successDelete"));
                    setModalOpen(false);
                    setDeleteData(null);
                    if (
                        routeSelfAssessmentData.filter(evaluation => evaluation.id == deleteData.id)
                            .length === 1
                    ) {
                        let newHeaders = routeSelfAssessmentData.filter(
                            evaluation => evaluation.idIndicatorsAss !== deleteData.idIndicatorsAss
                        );
                        newHeaders.push({
                            id: deleteData.id,
                            name: deleteData.name,
                            status: "Not started",
                            started: "/",
                            lastModified: "/",
                        });
                        newHeaders.sort((a, b) => a.name.localeCompare(b.name));
                        setRouteSelfAssessmentData(newHeaders);
                    } else {
                        setRouteSelfAssessmentData(
                            routeSelfAssessmentData.filter(
                                evaluation =>
                                    evaluation.idIndicatorsAss !== deleteData.idIndicatorsAss
                            )
                        );
                    }
                } else {
                    setSeverity("error");
                    setMessage(t("messageIndicators.errorDelete"));
                }
                setOpen(true);
            });
    };
    useEffect(() => {
        let phAccordion = [],
            routePartId = [];
        for (let i = 0; i < routeSelfAssessmentData.length; i++) {
            let tableRows = [];
            for (let j = 0; j < routeSelfAssessmentData.length; j++) {
                if (routeSelfAssessmentData[i].id === routeSelfAssessmentData[j].id) {
                    if (routeSelfAssessmentData[j].status !== "Not started") {
                        if (isAdmin) {
                            routeSelfAssessmentData[j].isAuthor = true;
                        }
                        tableRows.push(
                            <TableRow>
                                <TableCell align="left">
                                    {routeSelfAssessmentData[j].indicatorsYear
                                        ? routeSelfAssessmentData[j].indicatorsYear
                                        : ""}
                                </TableCell>
                                <TableCell align="left">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        className={`self-assessment-status-${routeSelfAssessmentData[
                                            j
                                        ].status.replace(/ /g, "-")}`}
                                        disabled
                                    >
                                        <IconCircle className="self-assessment-status-indicator" />
                                        <span className="self-assessment-status-text">
                                            {routeSelfAssessmentData[j].status}
                                        </span>
                                    </Button>
                                </TableCell>
                                <TableCell align="left">
                                    {routeSelfAssessmentData[j].started instanceof Date
                                        ? formatDate(
                                              routeSelfAssessmentData[j].started,
                                              currentLanguage
                                          )
                                        : ""}
                                </TableCell>
                                <TableCell align="left">
                                    {routeSelfAssessmentData[j].started instanceof Date
                                        ? formatDate(
                                              routeSelfAssessmentData[j].lastModified,
                                              currentLanguage
                                          )
                                        : ""}
                                </TableCell>
                                <TableCell align="left">
                                    {routeSelfAssessmentData[j].isAuthor ? (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={() => handleEdit(routeSelfAssessmentData[j])}
                                        >
                                            <EditIcon fontSize="small" />
                                            <span style={{ marginLeft: "3px" }}>Continue</span>
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </TableCell>
                                <TableCell align="left">
                                    {routeSelfAssessmentData[j].status === "Not started" ? (
                                        <></>
                                    ) : routeSelfAssessmentData[j].isAuthor ? (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={() =>
                                                handleResults(routeSelfAssessmentData[j])
                                            }
                                        >
                                            <VisibilityIcon fontSize="small" />
                                            <span style={{ marginLeft: "3px" }}>Results</span>
                                        </Button>
                                    ) : isRouteAuthor ? (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={() =>
                                                handleResults(routeSelfAssessmentData[j])
                                            }
                                        >
                                            <VisibilityIcon fontSize="small" />
                                            <span style={{ marginLeft: "3px" }}>Results</span>
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </TableCell>
                                <TableCell align="left">
                                    {routeSelfAssessmentData[j].status === "Not started" ? (
                                        <></>
                                    ) : routeSelfAssessmentData[j].isAuthor ? (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            className="input-route-self-assessment-delete"
                                            onClick={() =>
                                                handleModalOpen(routeSelfAssessmentData[j])
                                            }
                                        >
                                            <HighlightOffIcon fontSize="small" />
                                            <span style={{ marginLeft: "3px" }}>Delete</span>
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    }
                }
            }

            if (!routePartId.includes(routeSelfAssessmentData[i].id)) {
                routePartId.push(routeSelfAssessmentData[i].id);
                phAccordion.push(
                    <Accordion key={routeSelfAssessmentData[i].id}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={routeSelfAssessmentData[i].id}
                            id={routeSelfAssessmentData[i].id}
                        >
                            <Typography style={{ fontSize: "14px", fontWeight: "425" }}>
                                {routeSelfAssessmentData[i].name}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ paddingTop: "0px", paddingBottom: "24px" }}>
                            <Grid container justify="center" spacing={1}>
                                <Grid container style={{ marginBottom: "24px" }}>
                                    <Grid item xs={12}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Year</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Started</TableCell>
                                                    <TableCell>Last modified</TableCell>
                                                    <TableCell>
                                                        <YearPicker
                                                            routePart={routeSelfAssessmentData[i]}
                                                            routeName={routeName}
                                                            routeId={routeId}
                                                        />
                                                    </TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>{tableRows}</TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            }
        }
        setAccordionContent(phAccordion);
    }, [routeSelfAssessmentData, isOpenDatePicker]);

    useEffect(async () => {
        let routePartIndicatorsAss = [];
        await Promise.all(
            routeParts.map(async routePart => {
                if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                    let currentUserIdToken = "";
                    let currentUser = "";
                    if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                        currentUser = whoAmI();
                        if (currentUser) {
                            let email = currentUser.email;
                            if (email === routeAuthor) {
                                setIsRouteAuthor(true);
                            } else if (!routeAuthor) {
                                setIsRouteAuthor(true);
                            }
                            const idToken = await getToken();
                            if (idToken) {
                                currentUserIdToken = idToken;
                                const user = await usersApi.get(`/${email}`, {
                                    headers: {
                                        Authorization: "Bearer " + idToken,
                                    },
                                });
                                if (user.data.role === "admin") {
                                    setIsAdmin(true);
                                }
                            }
                        }
                    }
                    await indicatorsApi
                        .get(`/indicatorsass/header/${routePart.id}`, {
                            headers: { Authorization: "Bearer " + currentUserIdToken },
                        })
                        .then(res => {
                            if (res.status === 200 && res.data.length) {
                                res.data.map(evaluation => {
                                    routePartIndicatorsAss.push({
                                        id: routePart.id,
                                        name: routePart.title,
                                        status: evaluation.status,
                                        started: new Date(parseInt(evaluation.started)),
                                        lastModified: new Date(parseInt(evaluation.lastModified)),
                                        idIndicatorsAss: evaluation.id,
                                        indicatorsYear: evaluation.indicatorsYear,
                                        isAuthor:
                                            currentUser.email === evaluation.leadAuditor
                                                ? true
                                                : false,
                                    });
                                });
                            } else {
                                routePartIndicatorsAss.push({
                                    id: routePart.id,
                                    name: routePart.title,
                                    status: "Not started",
                                    started: "/",
                                    lastModified: "/",
                                });
                            }
                            routePartIndicatorsAss.sort((a, b) => a.name.localeCompare(b.name));
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }
            })
        ).then(() => {
            setRouteSelfAssessmentData(routePartIndicatorsAss);
        });
    }, [routeParts]);

    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <div className="table-container">{accordionContent}</div>
            <AlertDelete
                confirmAction={deleteIndicators}
                modalOpen={modalOpen}
                modalClose={modalClose => setModalOpen(modalClose)}
                textTitle={t("forms.viewCulturalRoute.deleteEvaluation.title")}
                textDescription={t("forms.viewCulturalRoute.deleteEvaluation.description")}
                textDelete={t("forms.viewCulturalRoute.deleteEvaluation.delete")}
                textCancel={t("forms.viewCulturalRoute.deleteEvaluation.cancel")}
            />
        </>
    );
}

function YearPicker({ routePart, routeName, routeId }) {
    const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
    const [selectedDate, handleDateChange] = useState(new Date());

    let history = useHistory();

    const handleCreate = async (data, date) => {
        if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
            let currentUserIdToken = "";
            let currentUser = "";
            if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                currentUser = whoAmI();
                if (currentUser) {
                    const idToken = await getToken();
                    if (idToken) {
                        currentUserIdToken = idToken;
                    }
                }
            } else {
                currentUser = JSON.parse(localStorage.getItem("user"));
            }
            const result = await indicatorsApi.post(
                "/indicatorsass/create",
                {
                    id: `${data.id}`, //just for fun
                    LeadAuditor: currentUser.email, //get current user
                    Started: `${Date.now()}`,
                    LastModified: `${Date.now()}`,
                    LastModifiedBy: currentUser.email, //get current user
                    Status: "STARTED",
                    AssessmentSubject: `${data.id}`,
                    AssessmentFor: `${data.name}`,
                    IndicatorsYear: `${date.getFullYear()}`,
                },
                { headers: { Authorization: "Bearer " + currentUserIdToken } }
            );
            history.push({
                pathname: `/indicators/${result.data.id}`,
                state: { detail: routeName, routeId: routeId },
            });
        }
    };

    return (
        <>
            <Button
                color="primary"
                size="small"
                className="self-assessment-start-short"
                onClick={() => {
                    setIsOpenDatePicker(true);
                }}
            >
                <AddCircleIcon fontSize="small" />
                <span>Set Indicators Values</span>
            </Button>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={materialTheme}>
                    <DatePicker
                        onAccept={date => handleCreate(routePart, date)}
                        open={isOpenDatePicker}
                        onOpen={() => setIsOpenDatePicker(true)}
                        onClose={() => setIsOpenDatePicker(false)}
                        views={["year"]}
                        value={selectedDate}
                        onChange={handleDateChange}
                        TextFieldComponent={() => null}
                    />
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </>
    );
}

export default InputRoutePartIndicators;
