import React, { useEffect, useState } from "react";
import { getBritofData } from "../services/api/API";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  Tooltip
} from "react-leaflet";

import { makeStyles } from "@material-ui/core";
//import "./map.scss";
import { RootObject } from "../types/types";
import L, { latLngBounds } from "leaflet";
import marker_icon from "../icon/marker_icon.png";

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginLeft: "15px"
  }
}));

const LeafletMap: React.FC = () => {
  const classes = useStyles();

  const position: any = [46.309, 16.323];
  const zoom: number = 20;

  const [guides, setGuides] = useState<any>();

  const fetchGuides = (): void => {
    getBritofData()
      .then(({ data: guides }: RootObject | any) => setGuides(guides))
      .catch((err: Error) => console.log(err));
  };

  useEffect(() => {
    fetchGuides();
  }, []);

  let icon = L.icon({
    iconUrl: marker_icon,
    iconSize: [10, 10]
  });
  const latLng: any = [46.31002, 16.32628];
  const bounds = latLngBounds(latLng);
  guides?.Points.forEach((data: any) => {
    const boundsExtend: any = [data.Geo.Latitude, data.Geo.Latitude];
    bounds.extend(boundsExtend);
  });

  return (
    <div className={classes.wrapper}>
      <MapContainer
        center={position}
        zoom={zoom}
        scrollWheelZoom={false}
        bounds={bounds}
      >
        <TileLayer
          attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {guides?.Points.map((item: any, index: any) => (
          <Marker
            icon={icon}
            key={index}
            position={[item.Geo.Latitude, item.Geo.Longitude]}
            title={item.Title}
          >
            <Tooltip permanent>{index + 1}</Tooltip>
            <Popup>
              <strong>
                {item.Title} {index + 1}
              </strong>
              <br />
              <p>{item.MainContent}</p>
              <br />
              <p>{item.Geo.Coordinates}</p>
            </Popup>
            {index < 14 ? (
              <Polyline
                positions={[
                  [
                    guides.Points[index].Geo.Latitude,
                    guides.Points[index].Geo.Longitude
                  ],
                  [
                    guides.Points[index + 1].Geo.Latitude,
                    guides.Points[index + 1].Geo.Longitude
                  ]
                ]}
                color={"red"}
              />
            ) : (
              <Polyline
                positions={[
                  [
                    guides.Points[index].Geo.Latitude,
                    guides.Points[index].Geo.Longitude
                  ],
                  [
                    guides.Points[index].Geo.Latitude,
                    guides.Points[index].Geo.Longitude
                  ]
                ]}
                color={"red"}
              />
            )}
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default LeafletMap;
