import { UserMenuItems, UserDevMenuItems } from "./UserMenuItems";

import IconAvatar from "../../assets/img/icon_avatar.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import React, { useState, useContext } from "react";
import { Avatar, Grid, Typography, Button, Menu, Divider, Badge } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { logOut, whoAmI, isLoggedIn } from "../User/Auth";
import AuthContext from "../../context/auth/AuthContext";
import ApplicationsContext from "../../context/UserManagement/ApplicationsContext";

function UserMenu({ location }) {
    const { t } = useTranslation();
    const Auth = useContext(AuthContext);
    const Applications = useContext(ApplicationsContext);


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let history = useHistory();
    const handleItemClick = link => {
        setAnchorEl(null);
        history.push(link);
    };

    const handleSignOut = () => {
        logOut().then(() => {
            Auth.setIsLoggedIn(false);
            Auth.setUser(null);
        });
    };

    var email = Auth.user ? Auth.user.email : "";

    return (
        <>
            <Grid container alignItems="center" className="input-clickable" onClick={handleClick}>
                <Grid item>
                    <Avatar src={IconAvatar} className="user-menu-avatar"></Avatar>
                </Grid>
                <Grid item className="user-menu-user-container">
                    <Grid container alignItems="center">
                        <Typography variant="body1" className="user-name">
                            {t("header.userMenu.placeholderUserName")}
                        </Typography>
                        <ArrowDropDownIcon className="arrow-icon" />
                    </Grid>
                    <Typography variant="body2" className="user-email">
                        {email}
                    </Typography>
                </Grid>
            </Grid>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                PaperProps={{
                    style: {
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        boxShadow: "0px 5px 5px 2px rgba(0, 0, 0, 0.1)",
                        minWidth: "320px",
                    },
                }}
                className={
                    location.pathname === "/"
                        ? "user-menu-dropdown-container-floating"
                        : "user-menu-dropdown-container"
                }
                disableAutoFocusItem
            >
                <Grid container className="user-menu-item-row-container">
                    {UserMenuItems.map(item => {
                        return (
                            <Grid
                                item
                                xs={4}
                                className={`input-clickable user-menu-item-container`}
                                key={item.id}
                                onClick={() => handleItemClick(item.link)}
                            >
                                {item.icon}
                                <Typography variant="body2" className="user-menu-item-title">
                                    {t(item.title)}
                                </Typography>
                            </Grid>
                        );
                    })}
                    {Auth.role === "admin" ? (
                        <Grid
                            item
                            xs={4}
                            className={`input-clickable user-menu-item-container`}
                            onClick={() => handleItemClick("/user-management")}
                        >
                            <Badge color="primary" badgeContent={Applications.applications} style={{display: 'inherit'}}>
                                <img src={IconAvatar} alt="map" className="user-menu-item-icon" />
                            </Badge>
                            <Typography variant="body2" className="user-menu-item-title">
                                {t("header.userMenu.users")}
                            </Typography>
                        </Grid>
                    ) : ('')}
                </Grid>
                {process.env.REACT_APP_EXCLUDE_DEV_FUNCTIONALITIES == 0 && (
                    <div>
                        <Divider className="user-menu-dropdown-divider" />
                        <Grid container className="user-menu-item-row-container">
                            {UserDevMenuItems.map(item => {
                                return (
                                    <Grid
                                        item
                                        xs={4}
                                        className={`input-clickable user-menu-item-container`}
                                        key={item.id}
                                        onClick={() => handleItemClick(item.link)}
                                    >
                                        {item.icon}
                                        <Typography
                                            variant="body2"
                                            className="user-menu-item-title"
                                        >
                                            {t(item.title)}
                                        </Typography>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                )}
                <Divider className="user-menu-dropdown-divider" />
                <Grid container direction="column" className="user-menu-user-settings-container">
                    <Grid item>
                        <Typography variant="body1" className="user-name">
                            {t("header.userMenu.placeholderUserName")}
                        </Typography>
                        <Typography variant="body2" className="user-email">
                            {email}
                        </Typography>
                    </Grid>
                    {process.env.REACT_APP_IS_LOGIN_REAL == 1 ?
                        (
                            <Grid item className="user-menu-change-password-container">
                                <Link to="/change-password">
                                    <Typography variant="body2" className="change-password">
                                        {t("header.userMenu.changePassword")}
                                    </Typography>
                                </Link>
                            </Grid>
                        ) : ("")
                    }
                    <Grid item className="user-menu-sign-out-container">
                        <Link to="/">
                            <Button
                                size="medium"
                                variant="outlined"
                                color="primary"
                                className="sign-out-button"
                                onClick={handleSignOut}
                            >
                                {t("header.userMenu.signOut")}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Menu>
        </>
    );
}
export default withRouter(UserMenu);
