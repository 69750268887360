import React, { useState } from "react";
import {
    Grid,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

function InputContacts({ initialValue, routeContacts, isReadOnly }) {
    const { t } = useTranslation();

    const initialContacts = initialValue.length > 0 ? initialValue : [{ type: "", contact: "" }];

    const [contacts, setContacts] = useState(initialContacts);

    const handleInputChange = (index, event) => {
        const values = [...contacts];

        if (event.target.name === "type") {
            values[index].type = event.target.value;
        } else {
            values[index].contact = event.target.value;
        }

        setContacts(values);
        routeContacts(values);
    };

    const handleAddInputContact = () => {
        const values = [...contacts];
        values.push({ type: "", contact: "" });
        setContacts(values);
    };

    const handleRemoveInputContact = index => {
        const values = [...contacts];
        values.splice(index, 1);
        setContacts(values);
        routeContacts(values);
    };

    return (
        <>
            {contacts.map((contact, index) => {
                return (
                    <Grid
                        container
                        key={`input-contacts-${index}`}
                        className="input-contacts-container"
                        alignItems="center"
                    >
                        <Grid item className="input-contacts-inputs-container">
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        className="input-contacts-select"
                                    >
                                        <InputLabel>
                                            {t("forms.inputs.contacts.contactType")}
                                        </InputLabel>
                                        <Select
                                            value={contact.type}
                                            onChange={event => handleInputChange(index, event)}
                                            label={t("forms.inputs.contacts.contactType")}
                                            name="type"
                                            MenuProps={{
                                                classes: {
                                                    paper:
                                                        "input-contacts-inputs-dropdown-container",
                                                },
                                            }}
                                            disabled={isReadOnly}
                                        >
                                            <MenuItem value="address">
                                                {t("forms.inputs.contacts.address")}
                                            </MenuItem>
                                            <MenuItem value="email">
                                                {t("forms.inputs.contacts.email")}
                                            </MenuItem>
                                            <MenuItem value="telephone">
                                                {t("forms.inputs.contacts.telephone")}
                                            </MenuItem>
                                            <MenuItem value="person">
                                                {t("forms.inputs.contacts.person")}
                                            </MenuItem>
                                            <MenuItem value="socialMedia">
                                                {t("forms.inputs.contacts.socialMedia")}
                                            </MenuItem>
                                            <MenuItem value="other">
                                                {t("forms.inputs.contacts.other")}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <TextField
                                        variant="outlined"
                                        label={t("forms.inputs.contacts.contact")}
                                        name="contact"
                                        value={contact.contact}
                                        onChange={event => handleInputChange(index, event)}
                                        className="input-contacts-text-field"
                                        fullWidth
                                        disabled={isReadOnly}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {isReadOnly ? null : (
                            <Grid item className="input-link-remove-btn-container">
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleRemoveInputContact(index)}
                                    className="input-contacts-remove-btn"
                                >
                                    {t("forms.inputs.contacts.remove")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                );
            })}

            {isReadOnly ? null : (
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className="input-contacts-add-more-btn"
                    onClick={() => handleAddInputContact()}
                >
                    {t("forms.inputs.contacts.addMore")}
                </Button>
            )}
        </>
    );
}

export default InputContacts;
