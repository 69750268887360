import { GET_LOCATION } from "../../types";
import update from "immutability-helper";

export default (state, action) => {
    switch (action.type) {
        case GET_LOCATION:
            return update(state, { location: { $set: action.data } });

        default:
            return state;
    }
};
