import { AppMenuItems } from "./AppMenuItems";
import { Button, Grid, Badge } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link, withRouter, useLocation } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../context/auth/AuthContext";
import { usersApi } from "../../services/api/axios";
import { whoAmI, getToken } from "../User/Auth";
import ApplicationsContext from "../../context/UserManagement/ApplicationsContext";

function AppMenu({ location }) {
    const { t } = useTranslation();
    const Auth = useContext(AuthContext);
    const Applications = useContext(ApplicationsContext);

    const isCurrentPageAdmin =
        location.pathname !== "/" &&
        location.pathname !== "/login" &&
        location.pathname !== "/routes";

    const currentPage = isCurrentPageAdmin ? "/dashboard" : "/";

    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });

    const { mobileView, drawerOpen } = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 600
                ? setState(prevState => ({ ...prevState, mobileView: true }))
                : setState(prevState => ({ ...prevState, mobileView: false }));
        };
        
        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    const displayDesktop = () => {
        return (
            <Grid container alignItems="flex-end" className="app-menu">
                {AppMenuItems.map(item => {
                    return (
                        <Grid item className="app-menu-item-container" key={item.id}>
                            <Link to={item.link}>
                                <Button
                                    disableRipple
                                    disableFocusRipple
                                    className={
                                        item.link === location.pathname
                                            ? "app-menu-item-active"
                                            : "app-menu-item"
                                    }
                                >
                                    {t(item.title)}
                                </Button>
                            </Link>
                        </Grid>
                    );
                })}
                {Auth.role === "admin" ? (
                    <Grid item className="app-menu-item-container">
                        <Link to={"/user-management"}>
                            <Badge badgeContent={Applications.applications} color="primary" style={{display: "inherit"}}>
                                <Button
                                    disableRipple
                                    disableFocusRipple
                                    className={
                                        "/user-management" === location.pathname
                                            ? "app-menu-item-active"
                                            : "app-menu-item"
                                    }
                                >
                                    {t("header.appMenu.userManagement")}
                                </Button>
                            </Badge>
                        </Link>
                    </Grid>
                ) : ('')}
            </Grid>
        );
    };

    /* TODO: mobile menu */
    const displayMobile = () => {
        return <></>;
    };

    return <>{mobileView ? displayMobile() : displayDesktop()}</>;
}
export default withRouter(AppMenu);
