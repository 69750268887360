import en from "./en-GB.json";
import es from "./es-ES.json";
import sl from "./sl-SI.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    resources: {
        "en-GB": {
            translation: en,
        },
        /*
        "es-ES": {
            translation: es,
        },
        */
        "sl-SI": {
            translation: sl,
        },
    },
    lng: "en-GB",
    supportedLngs: ["en-GB", /*"es-ES",*/ "sl-SI"],
    fallbackLng: "en-GB",
});

export default i18n;
