import IconCancel from "../../assets/img/icon_alert_cancel.svg";
import IconClose from "@material-ui/icons/Close";

import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, Modal, Backdrop, Fade } from "@material-ui/core";

function AlertCancel({ confirmAction, modalOpen, modalClose }) {
    const { t } = useTranslation();

    const handleModalClose = () => {
        modalClose(false);
    };

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className="modal-confirmation"
        >
            <Fade in={modalOpen}>
                <div className="modal-confirmation-container">
                    <div className="modal-confirmation-icon-close-container">
                        <IconClose
                            className="modal-confirmation-icon-close"
                            onClick={handleModalClose}
                        />
                    </div>
                    <div className="modal-confirmation-icon-container">
                        <img src={IconCancel} alt="Discard changes" width={55} />
                    </div>
                    <div className="modal-confirmation-text-container">
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            className="modal-confirmation-title"
                        >
                            {t("forms.viewCulturalRoute.modalCancelConfirmation.title")}
                        </Typography>

                        <Typography
                            variant="body2"
                            color="textSecondary"
                            className="modal-confirmation-description"
                        >
                            {t("forms.viewCulturalRoute.modalCancelConfirmation.description")}
                        </Typography>
                    </div>
                    <Grid
                        container
                        justify="center"
                        direction="row"
                        className="modal-confirmation-actions-container"
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="modal-confirmation-actions-confirm-container"
                        >
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className="discard-changes-button"
                                onClick={confirmAction}
                            >
                                {t(
                                    "forms.viewCulturalRoute.modalCancelConfirmation.discardChanges"
                                )}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className="modal-confirmation-actions-cancel-container"
                        >
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                className="cancel-button"
                                onClick={handleModalClose}
                            >
                                {t("forms.viewCulturalRoute.modalCancelConfirmation.cancel")}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </Modal>
    );
}

export default AlertCancel;
