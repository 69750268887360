import IconImageUpload from "../../assets/img/icon_input_image_upload.png";
import IconError from "../../assets/img/icon_error.svg";
import IconClose from "@material-ui/icons/Close";

import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Grid, Button, TextField, Divider, Typography } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";

function removeFileExtension(filename) {
    var lastDotPosition = filename.lastIndexOf(".");
    if (lastDotPosition === -1) return filename;
    else return filename.substr(0, lastDotPosition);
}

function formatBytes(bytes, decimals = 1) {
    if (bytes === 0) return "0 B";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

function InputImageUpload({ initialValue, routeImages, isReadOnly }) {
    const { t } = useTranslation();

    const initialImages = initialValue.length > 0 ? initialValue : [];

    const [images, setImages] = useState(initialImages);
    const [errors, setErrors] = useState([]);

    const maxSize = 2097152;

    const { isDragActive, getRootProps, getInputProps, isDragReject } = useDropzone({
        onDrop: (acceptedFiles, fileRejections) => {
            setErrors([]);
            handleDropImages(acceptedFiles);
            handleRejectedImages(fileRejections);
        },
        accept: "image/png, image/jpeg",
        minSize: 0,
        maxSize,
        disabled: isReadOnly,
    });

    const handleDropImages = acceptedFiles => {
        const values = [...images];
        acceptedFiles.forEach(file => {
            if (
                !values.find(
                    updloadedImage =>
                        updloadedImage.file.path === file.path &&
                        updloadedImage.file.lastModified === file.lastModified
                )
            ) {
                values.push({
                    file,
                    caption: removeFileExtension(file.name),
                    preview: URL.createObjectURL(file),
                });
            } else {
                setErrors(errors => [
                    ...errors,
                    {
                        errorType: "forms.inputs.gallery.errorMessage.imageDuplicate",
                        fileName: file.path,
                    },
                ]);
            }
        });
        setImages(values);
        routeImages(values);
    };

    const handleRejectedImages = fileRejections => {
        fileRejections.forEach(file => {
            file.errors.forEach(err => {
                if (err.code === "file-too-large") {
                    setErrors(errors => [
                        ...errors,
                        {
                            errorType: "forms.inputs.gallery.errorMessage.imageSize",
                            fileName: file.file.path,
                        },
                    ]);
                }

                if (err.code === "file-invalid-type") {
                    setErrors(errors => [
                        ...errors,
                        {
                            errorType: "forms.inputs.gallery.errorMessage.fileType",
                            fileName: file.file.path,
                        },
                    ]);
                }
            });
        });
    };

    const handleDismissErrorMessage = index => {
        const values = [...errors];
        values.splice(index, 1);
        setErrors(values);
    };

    const handleInputCaptionChange = (index, event) => {
        const values = [...images];
        values[index].caption = event.target.value;
        setImages(values);
        routeImages(values);
    };

    const handleRemoveInputImage = index => {
        const values = [...images];
        values.splice(index, 1);
        setImages(values);
        routeImages(values);
    };

    return (
        <div
            className={`input-image-upload-container ${
                isReadOnly ? "input-image-upload-container-disabled" : null
            }`}
        >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                {...getRootProps({
                    onClick: e => e.target.blur(),
                })}
                className={`input-clickable 
                    ${
                        isDragActive && !isDragReject
                            ? "input-image-upload-dropzone-dropping"
                            : "input-image-upload-dropzone"
                    }
                    ${isReadOnly ? "input-image-upload-dropzone-hidden" : null}
                `}
            >
                <Grid item xs={12}>
                    <input {...getInputProps()} />
                    <img
                        src={IconImageUpload}
                        alt="Upload"
                        className="input-image-upload-dropzone-icon"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                        {!isDragActive && t("forms.inputs.gallery.msgDropOrUpload")}
                        {isDragActive && !isDragReject && t("forms.inputs.gallery.msgDrop")}
                        {isDragReject && t("forms.inputs.gallery.msgFileType")}
                    </Typography>
                </Grid>
            </Grid>

            {errors.length > 0 && (
                <div className="error-messages-container">
                    {errors.map((error, index) => {
                        return (
                            <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                                className="error-message-container"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={1}
                                    className="error-message-icon-error-container"
                                >
                                    <img src={IconError} className="error-message-icon-error" />
                                </Grid>
                                <Grid item xs={12} sm={10} className="error-message-text-container">
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        className="error-message-text"
                                    >
                                        <Trans i18nKey={error.errorType}>{error.fileName}</Trans>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={1}
                                    className="error-message-icon-close-container"
                                >
                                    <IconClose
                                        className="error-message-icon-close"
                                        onClick={() => handleDismissErrorMessage(index)}
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}
                </div>
            )}

            {images.length > 0 && (
                <div className={isReadOnly ? null : "error-images-container"}>
                    {images.map((image, index) => {
                        return (
                            <div
                                key={
                                    image.file
                                        ? `input-image-${image.file.path}-${image.file.lastModified}`
                                        : `input-image-${image.caption}`
                                }
                            >
                                <Grid
                                    container
                                    spacing={1}
                                    className="input-image-upload-image-container"
                                >
                                    <Grid
                                        item
                                        className="input-image-upload-image-preview-container"
                                    >
                                        <img
                                            src={image.preview}
                                            alt={image.file ? image.file.path : null}
                                            className="input-image-upload-image-preview"
                                        />
                                    </Grid>
                                    <Grid item className="input-image-upload-inputs-container">
                                        <Grid
                                            container
                                            direction="column"
                                            justify="flex-start"
                                            className="input-image-upload-text-container"
                                        >
                                            <Grid item>
                                                <TextField
                                                    variant="outlined"
                                                    label={t("forms.inputs.gallery.caption")}
                                                    name="caption"
                                                    value={image.caption}
                                                    onChange={event =>
                                                        handleInputCaptionChange(index, event)
                                                    }
                                                    className="input-image-caption-text-field"
                                                    fullWidth
                                                    disabled={isReadOnly}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className="input-image-size"
                                                >
                                                    {t("forms.inputs.gallery.size")}:{" "}
                                                    {image.file
                                                        ? formatBytes(image.file.size)
                                                        : t("forms.inputs.gallery.unknown")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {isReadOnly ? null : (
                                        <Grid item className="input-image-remove-btn-container">
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                className="input-image-remove-btn"
                                                onClick={() => handleRemoveInputImage(index)}
                                            >
                                                {t("forms.inputs.gallery.remove")}
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                                {index !== images.length - 1 ? (
                                    <Divider className="input-image-divider" />
                                ) : (
                                    ""
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default InputImageUpload;
