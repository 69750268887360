export const EmployedPersonsLineGraphData = {
    dateTime:"2021-02-04T18:18:20.443+01:00",
    label:"Employed persons and employees by sex and full-time/part-time activity and NACE Rev. 2 activity",
    href:"http://ec.europa.eu/eurostat/wdds/rest/data/v2.1/json/en/tour_lfs6r2?precision=1&unit=THS&worktime=TOTAL&nace_r2=TOTAL",
    source:"Eurostat",
    updated:"2020-11-10T23:00:00Z",
    extension:{
       datasetId:"tour_lfs6r2",
       lang:"EN",
       description:null,
       subTitle:null
    },
    value:[
       {
          name:2010,
          F:8301,
          M:10424,
          T:18724
       },
       {
          name:2011,
          F:8269,
          M:10152,
          T:18421
       },
       {
          name:2012,
          F:8024,
          M:9608,
          T:17633
       },
       {
          name:2013,
          F:7823,
          M:9316,
          T:17139
       },
       {
          name:2014,
          F:7902,
          M:9443,
          T:17344
       },
       {
          name:2015,
          F:8106,
          M:9760,
          T:17866
       },
       {
          name:2016,
          F:8341,
          M:10001,
          T:18342
       },
       {
          name:2017,
          F:8558,
          M:10266,
          T:18825
       },
       {
          name:2018,
          F:8796,
          M:10532,
          T:19328
       },
       {
          name:2019,
          F:9034,
          M:10746,
          T:19779
       }
    ],
    graphDimensions:[
       {
          label:"Females",
          key:"F"
       },
       {
          label:"Males",
          key:"M"
       },
       {
          label:"Total",
          key:"T"
       }
    ],
    dimensions:{
       worktime:{
          label:"worktime",
          values:{
             TOTAL:"Total"
          }
       },
       wstatus:{
          label:"wstatus",
          values:{
             EMP:"Employed persons"
          }
       },
       unit:{
          label:"unit",
          values:{
             THS:"Thousand"
          }
       },
       "nace_r2":{
          label:"nace_r2",
          values:{
             TOTAL:"Total - all NACE activities"
          }
       },
       sex:{
          label:"sex",
          values:{
             F:"Females",
             M:"Males",
             T:"Total"
          }
       },
       geo:{
          label:"geo",
          values:{
             ES:"Spain"
          }
       },
       time:{
          label:"time",
          values:{
             "2008":"2008",
             "2009":"2009",
             "2010":"2010",
             "2011":"2011",
             "2012":"2012",
             "2013":"2013",
             "2014":"2014",
             "2015":"2015",
             "2016":"2016",
             "2017":"2017",
             "2018":"2018",
             "2019":"2019"
          }
       }
    },
    nuts:{
       "nuts2":"ES",
       label:"Spain"
    }
 }