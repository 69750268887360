import LogoInterreg from "../../assets/img/logo_interreg_acknowledgement.svg";

import {
    Button,
    TextField,
    Grid,
    Container,
    IconButton,
    Collapse,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import firebase from "firebase";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { config } from "../../components/User/firebaseConfig";
import { signIn, isLoggedIn, isFirstTime, completedFirstTime } from "../User/Auth";
import AuthContext from "../../context/auth/AuthContext";
import { usersApi } from "../../services/api/axios.js";
import { whoAmI, getToken, registerUser } from "../User/Auth";

const useStyles = makeStyles(theme => ({
    closeAlertButton: {
        backgroundColor: "#fdecea",
        "&:hover": {
            backgroundColor: "#ffe1de",
        },
    },
    forgotPasswordText: {
        fontSize: "12px",
        textAlign: "right",
    },
    applyForAccountText: {
        fontSize: "12px",
        textAlign: "left",
    },
}));

function Login() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const Auth = useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);

    const onEmailChange = event => {
        setEmail(event.target.value);
        setErrorAlertOpen(false);
    };
    const onPasswordChange = event => {
        setPassword(event.target.value);
        setErrorAlertOpen(false);
    };

    const checkRole = async email => {
        if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
            var currentUserIdToken = "";
            const currentUser = whoAmI();
            if (currentUser) {
                const idToken = await getToken();
                if (idToken) {
                    currentUserIdToken = idToken;
                }
            }
            const result = await usersApi.get(`/${email}`, {
                headers: { Authorization: "Bearer " + currentUserIdToken },
            });
            if (result.data) {
                Auth.setRole(result.data.role);
            }
        } else {
            Auth.setRole("admin");
        }
    };

    const handleSubmit = async event => {
        event.preventDefault();
        signIn(email, password)
            .then(res => {
                if (res.user) {
                    Auth.setUser(res.user);
                    Auth.setIsLoggedIn(true);
                    checkRole(res.user.email);
                }
            })
            .catch(e => {
                setErrorAlertOpen(true);
            });
    };

    if (Auth.isLoggedIn) {
        history.push("/dashboard");
    }

    const errorAlert = (
        <Collapse in={errorAlertOpen}>
            <Alert
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        className={classes.closeAlertButton}
                        onClick={() => {
                            setErrorAlertOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                severity="error"
                style={{ border: "solid 1px rgb(255, 224, 220)" }}
            >
                {t("login.errorAlert")}
            </Alert>
        </Collapse>
    );
    var forgotPassword = "";
    var applyForAccount = "";
    if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
        forgotPassword = (
            <Link to="/forgot-password" style={{ float: "right" }}>
                <Typography component="span" className={classes.forgotPasswordText} color="primary">
                    {t("forgotPassword.forgotPassword")}
                </Typography>
            </Link>
        );
        applyForAccount = (
            <Link to="/apply-for-account">
                <Typography
                    component="span"
                    className={classes.applyForAccountText}
                    color="primary"
                >
                    {t("applyForAccount.applyForAccount")}
                </Typography>
            </Link>
        );
    }
    return (
        <Grid container className="login-container" justify="center" alignItems="stretch">
            <Grid item xs={false} sm={false} md={4} className="login-image"></Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    className="login-content-container"
                >
                    <Container component="main" maxWidth="xs">
                        <a
                            href="https://best-med.interreg-med.eu/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={LogoInterreg}
                                alt="Interreg Mediterranean BEST MED"
                                className="login-logotype-container"
                            />
                        </a>
                        {errorAlert}
                        <form noValidate onSubmit={handleSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                label={t("login.emailAddress")}
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={onEmailChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="password"
                                label={t("login.password")}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={onPasswordChange}
                            />
                            {applyForAccount}
                            {forgotPassword}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className="login-submit-button"
                            >
                                {t("login.signin")}
                            </Button>
                        </form>
                    </Container>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Login;
