import React from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    makeStyles,
    Typography,
    IconButton,
    Tooltip,
    Button,
    ButtonGroup,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Menu,
    Fab
} from "@material-ui/core";
import BarChartIcon from '@material-ui/icons/BarChart';
import TimelineIcon from '@material-ui/icons/Timeline';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

const useStyles = makeStyles(theme => ({
    muiAccordionDetailsRoot: {
        padding: '0px'
    },
    showGraphFab: {
        color: 'grey',
        boxShadow: 'none',
        position: 'relative',
        top: '-17px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: 'white',
        boxShadow: 'none',
        "&:hover": {
            boxShadow: 'none',

        },
        "&:active": {
            boxShadow: 'none'
        }
    },
    showGraphFabText: {
        textTransform: "lowercase",
        color: 'white',
        fontSize: '13px'
    },
    expanded: {
        transform: " rotate(180deg)"
    },
    expandIcon: {
        transition: 'transform 0.5s ease' 
    }
}));



function DestinationCardAccordion({ children }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [isExpanded, setIsExpanded] = React.useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    }

    return (
        <div>
            <Accordion style={{ boxShadow: 'none' }} expanded={isExpanded} TransitionProps={{timeout: 500}}>
                <div style={{ textAlign: 'center', height: '20px', marginBottom: '2px' }}>
                    <Fab
                        className={classes.showGraphFab}
                        aria-label="showGraph"
                        variant="extended"
                        size="small"
                        onClick={() => toggleExpansion()}
                        style={{boxShadow: 'none'}}>
                        <BarChartIcon />
                        <Typography className={classes.showGraphFabText}>

                        </Typography>
                        <ExpandLessRoundedIcon className={[isExpanded ? classes.expanded : '', classes.expandIcon]}/>
                    </Fab>
                </div>
                <AccordionDetails classes={{ root: classes.muiAccordionDetailsRoot }}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </div>
    );

}

export default DestinationCardAccordion;
