import React, { useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AlertDelete from "../Dialogs/AlertDelete";

function InputRouteParts({ initialValue, routePartTitle, isReadOnly }) {
    const { t } = useTranslation();

    const initialRouteParts = initialValue.length > 0 ? initialValue : [{ title: "" }];

    const [routeParts, setRouteParts] = useState(initialRouteParts);

    const handleInputChange = (index, event) => {
        const values = [...routeParts];
        values[index].title = event.target.value;
        setRouteParts(values);
        routePartTitle(values);
    };

    const handleAddInputRoutePart = () => {
        const values = [...routeParts];
        values.push({ title: "" });
        setRouteParts(values);
    };

    const handleRemoveInputRoutePart = index => {
        const values = [...routeParts];
        values.splice(index, 1);
        setRouteParts(values);
        routePartTitle(values);
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);

    const handleModalOpen = index => {
        setCurrentIndex(index);
        setModalOpen(true);
    };

    const handleConfirmAction = () => {
        handleRemoveInputRoutePart(currentIndex);
        setModalOpen(false);
    };

    return (
        <>
            {routeParts.map((routePart, index) => {
                return (
                    <Grid
                        container
                        key={`input-route-part-${index}`}
                        className="input-route-parts-container"
                        alignItems="center"
                    >
                        <Grid item className="input-route-parts-inputs-container">
                            <Grid container spacing={1}>
                                <TextField
                                    variant="outlined"
                                    label={t("forms.inputs.routeParts.name")}
                                    name="title"
                                    value={routePart.title}
                                    onChange={event => handleInputChange(index, event)}
                                    className="input-route-parts-text-field"
                                    fullWidth
                                    disabled={isReadOnly}
                                />
                            </Grid>
                        </Grid>
                        {isReadOnly ? null : (
                            <Grid item className="input-route-parts-remove-btn-container">
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleModalOpen(index)}
                                    className="input-route-parts-remove-btn"
                                >
                                    {t("forms.inputs.routeParts.remove")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                );
            })}

            {isReadOnly ? null : (
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className="input-route-parts-add-more-btn"
                    onClick={() => handleAddInputRoutePart()}
                >
                    {t("forms.inputs.routeParts.addMore")}
                </Button>
            )}

            <AlertDelete
                confirmAction={handleConfirmAction}
                modalOpen={modalOpen}
                modalClose={modalClose => setModalOpen(modalClose)}
                textTitle={t("forms.editCulturalRoute.modalDeleteRoutePartConfirmation.title")}
                textDescription={t(
                    "forms.editCulturalRoute.modalDeleteRoutePartConfirmation.description"
                )}
                textDelete={t("forms.editCulturalRoute.modalDeleteRoutePartConfirmation.delete")}
                textCancel={t("forms.editCulturalRoute.modalDeleteRoutePartConfirmation.cancel")}
            />
        </>
    );
}

export default InputRouteParts;
