import React, { useEffect, useState } from "react";

import {
    Container,
    Grid,
    Typography,
    Button,
    CircularProgress,
    makeStyles,
    Divider,
    Box,
    IconButton,
    Hidden,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
} from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import { destinationsApi } from "../../../services/api/axios";
import { useTranslation } from "react-i18next";
import { Flag } from "../DestinationCard";
import { DestinationsNutsLabelsEn } from "../../../data/Nuts/DestinationsNutsLabelsEn";
import DestinationDataCards from "./DestinationDetailsDataCards";
import { DestinationsDataNuts } from "../../../data/Nuts/DestinationsDataNuts";
import DestinationDetailsMap from "./DestinationDetailsMap";
import DestinationCardGraphHolder from "../../Graphs/DestinationCardGraphHolder";
import DestinationDetailsGraphHolder from "../../Graphs/DestinationDetailsGraphHolder";
import IconWww from "../../../assets/img/icon_www.svg";
import LanguageIcon from "@material-ui/icons/Language";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: "white",
        paddingTop: "24px",
        paddingBottom: "24px",
        marginBottom: "24px",
        boxShadow: "0px 2px 4px var(--black-opacity6)",
    },
    wrapper: {
        //marginLeft: '16px',
        //paddingTop: '24px',
        overflowY: "auto",
    },
    country: {
        fontSize: "18px",
        textTransform: "uppercase",
    },
    destinationName: {
        fontSize: "30px",
    },
    region: {
        fontSize: "14px",
    },
    divider: {
        marginTop: "12px",
        marginBottom: "12px",
    },
    dataCards: {
        paddingTop: "12px",
    },
    description: {
        textTransform: "initial",
    },
    mapContainer: {
        minHeight: "250px",
    },
    graphHolder: {
        minHeight: "300px",
    },
    linkIcon: {
        textAlign: "center",
    },
    seasonsAccordion: {
        boxShadow: "none",
    },
    seasonsAccordionSummary: {
        paddingLeft: "0px",
        "& div": {
            marginTop: "4px",
            marginBottom: "4px",
        },
        "& .MuiButtonBase-root": {
            padding: "8px",
            transition: "all 0.2s ease",
            border: "1px solid #ffffff",
            "&:hover": {
                //boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                border: "1px solid #BBBBBB",
            },
        },
    },
    infoDialogContentWrapper: {
        padding: "0px 36px 24px 36px",
    },
    infoDialogItemTitle: {
        fontWeight: "500",
    },
    infoDialogItemBody: {},
    infoDialogTitle: {
        textAlign: "center",
    },
    linkIconText: {
        fontSize: "12px",
        paddingLeft: "10px",
        textAlign: "left",
    },
    linkIconText2: {
        fontSize: "12px",
        paddingLeft: "10px",
        textAlign: "center",
    },
    linkIcon: {
        "& img": {
            minWidth: "35px",
        },
    },
    linkIconSmall: {
        "& img": {
            minWidth: "35px",
        },
        textAlign: "center",
    },
    linkIconButton: {
        height: "100%",
    },
    backToMapButtonWrapper: {
        height: "70px",
        textAlign: "left",
    },
    backToMapButton: {
        backgroundColor: "transparent",
        marginTop: "11px",
        marginBottom: "11px",
        fontSize: "16px",
        paddingRight: "0px",
        paddingLeft: "0px",
        "&:hover svg": {
            marginRight: "8px",
            color: "rgba(0, 0, 0, 0.7)",
        },
        "& p": {
            transition: "all 0.4s ease",
        },
        "&:hover p": {
            color: "rgba(0, 0, 0, 0.7)",
        },
        borderRadius: "10%",
    },
    backToMapButtonIcon: {
        marginRight: "4px",
        transition: "all 0.4s ease",
    },
}));

export function DestinationDetailsContent({ id }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [destination, setDestination] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [currentDataset, setCurrentDataset] = useState(null);
    let dataLabels = DestinationsNutsLabelsEn;
    useEffect(() => {
        const fetchDestinationData = async () => {
            setIsLoading(true);
            var data = DestinationsDataNuts.find(el => el._id === Number(id));
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                const resultLabels = await destinationsApi.get("/labels");
                dataLabels = resultLabels.data;
                const result = await destinationsApi.get("/destinations/" + id);
                data = result.data;
            }
            setDestination(data);
            setIsLoading(false);
        };

        fetchDestinationData();
    }, [id]);

    const handleDialogClose = () => {
        setInfoDialogOpen(false);
    };
    const handleDialogOpen = () => {
        setInfoDialogOpen(true);
    };

    var content = (
        <div style={{ textAlign: "center" }}>
            <CircularProgress />
        </div>
    );
    if (!isLoading) {
        if (process.env.REACT_APP_IS_DEV == 1) {
            console.log(currentDataset);
        }
        var highSeason = "";
        var lowSeason = "";
        if (
            destination.highSeason !== "" &&
            destination.highSeason !== "-1" &&
            destination.highSeason !== "00" &&
            destination.highSeason !== "xx" &&
            destination.highSeason !== "0"
        ) {
            highSeason = (
                <Grid item xs={12} className={classes.seasons}>
                    <Accordion className={classes.seasonsAccordion}>
                        <AccordionSummary
                            className={classes.seasonsAccordionSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                {t("destinations.detailsPage.highSeason")}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography align="justify" className={classes.description}>
                                {destination.highSeason}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            );
        }
        if (
            destination.lowSeason !== "" &&
            destination.lowSeason !== "-1" &&
            destination.lowSeason !== "00" &&
            destination.lowSeason !== "xx" &&
            destination.lowSeason !== "0"
        ) {
            lowSeason = (
                <Grid item xs={12} className={classes.seasons}>
                    <Accordion className={classes.seasonsAccordion}>
                        <AccordionSummary
                            className={classes.seasonsAccordionSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                {t("destinations.detailsPage.lowSeason")}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography align="justify" className={classes.description}>
                                {destination.lowSeason}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            );
        }
        var graphInfoDialog = "";
        if (currentDataset !== null) {
            graphInfoDialog = (
                <Dialog
                    onClose={handleDialogClose}
                    aria-labelledby="simple-dialog-title"
                    open={infoDialogOpen}
                >
                    <DialogTitle id="simple-dialog-title" className={classes.infoDialogTitle}>
                        {t("destinations.detailsPage.graphInfo.title")}
                    </DialogTitle>
                    <Grid container className={classes.infoDialogContentWrapper}>
                        <Grid item xs={12}>
                            <Typography className={classes.infoDialogItemTitle}>
                                {t("destinations.detailsPage.graphInfo.geoUnit")}
                            </Typography>
                            <Typography>
                                {currentDataset.nuts.label} ({currentDataset.nuts.nuts2})
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.infoDialogItemTitle}>
                                {t("destinations.detailsPage.graphInfo.datasetName")}
                            </Typography>
                            <Typography>{currentDataset.label}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.infoDialogItemTitle}>
                                {t("destinations.detailsPage.graphInfo.datasetUrl")}
                            </Typography>
                            <Typography>{currentDataset.href}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.infoDialogItemTitle}>
                                {t("destinations.detailsPage.graphInfo.datasetId")}
                            </Typography>
                            <Typography>{currentDataset.extension.datasetId}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.infoDialogItemTitle}>
                                {t("destinations.detailsPage.graphInfo.retrieved")}
                            </Typography>
                            <Typography>
                                {new Date(currentDataset.dateTime).toLocaleString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.infoDialogItemTitle}>
                                {t("destinations.detailsPage.graphInfo.updated")}
                            </Typography>
                            <Typography>
                                {new Date(currentDataset.updated).toLocaleString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.infoDialogItemTitle}>
                                {t("destinations.detailsPage.graphInfo.dimensionsShown")}
                            </Typography>
                            <List dense={true}>
                                {currentDataset.graphDimensions.map(dimension => (
                                    <ListItem key={dimension.key}>
                                        <Typography>• {dimension.label}</Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center", marginTop: "8px" }}>
                            <a
                                href={
                                    "https://ec.europa.eu/eurostat/databrowser/view/" +
                                    currentDataset.extension.datasetId +
                                    "/default/line?lang=en"
                                }
                                className={classes.homeLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <IconButton
                                    style={{ backgroundColor: "transparent" }}
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                >
                                    <LanguageIcon style={{ paddingRight: "5px" }} />
                                    <Typography style={{ fontSize: "14px" }}>
                                        {t("destinations.detailsPage.graphInfo.viewDataBrowser")}
                                    </Typography>
                                </IconButton>
                            </a>
                        </Grid>
                    </Grid>
                </Dialog>
            );
        }
        var idDestination = destination._id;
        content = (
            <Grid container alignItems="stretch" spacing={3}>
                <Grid item xs={12} md={7}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className={classes.country} color="textSecondary">
                                <Flag selectedDestination={destination} dataLabels={dataLabels} />
                                {dataLabels[0][destination.nuts.country]}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h3"
                                color="textPrimary"
                                className={classes.destinationName}
                            >
                                {destination.nuts.mitomedName}
                            </Typography>
                        </Grid>
                        {destination.nuts.country !== undefined && (
                            <Grid item xs={12}>
                                <Typography className={classes.region} color="textSecondary">
                                    {dataLabels[0][destination.nuts.nutS2]}
                                </Typography>
                            </Grid>
                        )}
                        {destination.description !== "0" && destination.description !== "-1" && destination.description !== "00" &&
                                    destination.description !== "xx" ? (
                            <Grid item xs={12}>
                                <Typography align="justify" className={classes.description}>
                                    {destination.description}
                                </Typography>
                            </Grid>
                        ) : null}
                        {highSeason}
                        {lowSeason}
                        <Grid item xs={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} className={classes.dataCards}>
                            <Typography align="justify">
                                <DestinationDataCards selectedDestination={destination} />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} md={5} className={classes.mapContainer}>
                        <DestinationDetailsMap destination={destination} dataLabels={dataLabels} />
                    </Grid>
                    <Grid item xs={12} className={classes.graphHolder}>
                        <DestinationDetailsGraphHolder
                            selectedDestination={destination}
                            graphHeight={350}
                            handleDialogOpen={handleDialogOpen}
                            setCurrentDataset={setCurrentDataset}
                        />
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Grid item xs={12} className={classes.graphHolder}>
                        <DestinationCardGraphHolder
                            selectedDestination={destination}
                            graphHeight={250}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.mapContainer}>
                        <DestinationDetailsMap destination={destination} dataLabels={dataLabels} />
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid item xs={12}>
                        <Grid className={classes.links} container spacing={1} justify="center">
                            <Grid item xs={12}>
                                <Grid container justify="space-evenly">
                                    <Grid item xs={2} className={classes.linkIcon}>
                                        <a
                                            title={t("destinations.card.mitomedShowDestination")}
                                            href={`https://mitomedplus.andalucia.org/mitomedplus/publicShowDestinationData.html?id=${idDestination}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <IconButton
                                                style={{ backgroundColor: "transparent" }}
                                                disableRipple={true}
                                                disableFocusRipple={true}
                                                className={classes.linkIconButton}
                                            >
                                                <img src={IconWww} width="35px" alt="Destination" />
                                                <Typography className={classes.linkIconText}>
                                                    {t("destinations.card.mitomedShowDestination")}
                                                </Typography>
                                            </IconButton>
                                        </a>
                                    </Grid>
                                    <Grid item xs={2} className={classes.linkIcon}>
                                        <a
                                            title={t("destinations.card.mitomedShowIndicators")}
                                            href={`https://mitomedplus.andalucia.org/mitomedplus/publicListDestinationIndicators.html?id=${idDestination}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <IconButton
                                                style={{ backgroundColor: "transparent" }}
                                                disableRipple={true}
                                                disableFocusRipple={true}
                                                className={classes.linkIconButton}
                                            >
                                                <img src={IconWww} width="35px" alt="Destination" />
                                                <Typography className={classes.linkIconText}>
                                                    {t("destinations.card.mitomedShowIndicators")}
                                                </Typography>
                                            </IconButton>
                                        </a>
                                    </Grid>
                                    <Grid item xs={2} className={classes.linkIcon}>
                                        <a
                                            title={t(
                                                "destinations.card.mitomedDestinationDashboard"
                                            )}
                                            href={`https://mitomedplus.andalucia.org/mitomedplus/dashboard.html?groupId=0&id=${idDestination}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <IconButton
                                                style={{ backgroundColor: "transparent" }}
                                                disableRipple={true}
                                                disableFocusRipple={true}
                                                className={classes.linkIconButton}
                                            >
                                                <img src={IconWww} width="35px" alt="Destination" />
                                                <Typography className={classes.linkIconText}>
                                                    {t(
                                                        "destinations.card.mitomedDestinationDashboard"
                                                    )}
                                                </Typography>
                                            </IconButton>
                                        </a>
                                    </Grid>
                                    <Grid item xs={2} className={classes.linkIcon}>
                                        <a
                                            title={t("destinations.card.mitomedEconomicIndocators")}
                                            href={`https://mitomedplus.andalucia.org/mitomedplus/dashboard.html?groupId=1&id=${idDestination}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <IconButton
                                                style={{ backgroundColor: "transparent" }}
                                                disableRipple={true}
                                                disableFocusRipple={true}
                                                className={classes.linkIconButton}
                                            >
                                                <img src={IconWww} width="35px" alt="Destination" />
                                                <Typography className={classes.linkIconText}>
                                                    {t(
                                                        "destinations.card.mitomedEconomicIndocators"
                                                    )}
                                                </Typography>
                                            </IconButton>
                                        </a>
                                    </Grid>
                                    <Grid item xs={2} className={classes.linkIcon}>
                                        <a
                                            title={t(
                                                "destinations.card.mitomedEnvironmentalIndocators"
                                            )}
                                            href={`https://mitomedplus.andalucia.org/mitomedplus/dashboard.html?groupId=3&id=${idDestination}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <IconButton
                                                style={{ backgroundColor: "transparent" }}
                                                disableRipple={true}
                                                disableFocusRipple={true}
                                                className={classes.linkIconButton}
                                            >
                                                <img src={IconWww} width="35px" alt="Destination" />
                                                <Typography className={classes.linkIconText}>
                                                    {t(
                                                        "destinations.card.mitomedEnvironmentalIndocators"
                                                    )}
                                                </Typography>
                                            </IconButton>
                                        </a>
                                    </Grid>
                                    <Grid item xs={2} className={classes.linkIcon}>
                                        <a
                                            title={t("destinations.card.mitomedSocialIndocators")}
                                            href={`https://mitomedplus.andalucia.org/mitomedplus/dashboard.html?groupId=2&id=${idDestination}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <IconButton
                                                style={{ backgroundColor: "transparent" }}
                                                disableRipple={true}
                                                disableFocusRipple={true}
                                                className={classes.linkIconButton}
                                            >
                                                <img src={IconWww} width="35px" alt="Destination" />
                                                <Typography className={classes.linkIconText}>
                                                    {t("destinations.card.mitomedSocialIndocators")}
                                                </Typography>
                                            </IconButton>
                                        </a>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ textAlign: "center", marginTop: "8px" }}
                                    >
                                        <Tooltip title={destination.website}>
                                            <a
                                                href={destination.website}
                                                className={classes.homeLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <IconButton
                                                    style={{ backgroundColor: "transparent" }}
                                                    disableRipple={true}
                                                    disableFocusRipple={true}
                                                    className={classes.linkIconButton}
                                                >
                                                    <LanguageIcon style={{ paddingRight: "5px" }} />
                                                    <Typography style={{ fontSize: "14px" }}>
                                                        {t(
                                                            "destinations.card.visitOfficialWebsite"
                                                        )}
                                                    </Typography>
                                                </IconButton>
                                            </a>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Grid
                        className={classes.links}
                        container
                        spacing={1}
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <Grid container justify="space-evenly">
                                <Grid item xs={2} className={classes.linkIconSmall}>
                                    <a
                                        title={t("destinations.card.mitomedShowDestination")}
                                        href={`https://mitomedplus.andalucia.org/mitomedplus/publicShowDestinationData.html?id=${idDestination}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <IconButton
                                            style={{ backgroundColor: "transparent" }}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                        >
                                            <img src={IconWww} width="35px" alt="Destination" />
                                        </IconButton>
                                    </a>
                                </Grid>
                                <Grid item xs={2} className={classes.linkIconSmall}>
                                    <a
                                        title={t("destinations.card.mitomedShowIndicators")}
                                        href={`https://mitomedplus.andalucia.org/mitomedplus/publicListDestinationIndicators.html?id=${idDestination}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <IconButton
                                            style={{ backgroundColor: "transparent" }}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                        >
                                            <img src={IconWww} width="35px" alt="Destination" />
                                        </IconButton>
                                    </a>
                                </Grid>
                                <Grid item xs={2} className={classes.linkIconSmall}>
                                    <a
                                        title={t("destinations.card.mitomedDestinationDashboard")}
                                        href={`https://mitomedplus.andalucia.org/mitomedplus/dashboard.html?groupId=0&id=${idDestination}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <IconButton
                                            style={{ backgroundColor: "transparent" }}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                        >
                                            <img src={IconWww} width="35px" alt="Destination" />
                                        </IconButton>
                                    </a>
                                </Grid>
                                <Grid item xs={2} className={classes.linkIconSmall}>
                                    <a
                                        title={t("destinations.card.mitomedEconomicIndocators")}
                                        href={`https://mitomedplus.andalucia.org/mitomedplus/dashboard.html?groupId=1&id=${idDestination}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <IconButton
                                            style={{ backgroundColor: "transparent" }}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                        >
                                            <img src={IconWww} width="35px" alt="Destination" />
                                        </IconButton>
                                    </a>
                                </Grid>
                                <Grid item xs={2} className={classes.linkIconSmall}>
                                    <a
                                        title={t(
                                            "destinations.card.mitomedEnvironmentalIndocators"
                                        )}
                                        href={`https://mitomedplus.andalucia.org/mitomedplus/dashboard.html?groupId=3&id=${idDestination}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <IconButton
                                            style={{ backgroundColor: "transparent" }}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                        >
                                            <img src={IconWww} width="35px" alt="Destination" />
                                        </IconButton>
                                    </a>
                                </Grid>
                                <Grid item xs={2} className={classes.linkIconSmall}>
                                    <a
                                        title={t("destinations.card.mitomedSocialIndocators")}
                                        href={`https://mitomedplus.andalucia.org/mitomedplus/dashboard.html?groupId=2&id=${idDestination}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <IconButton
                                            style={{ backgroundColor: "transparent" }}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                        >
                                            <img src={IconWww} width="35px" alt="Destination" />
                                        </IconButton>
                                    </a>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "center", marginTop: "8px" }}
                                >
                                    <Tooltip title={destination.website}>
                                        <a
                                            href={destination.website}
                                            className={classes.homeLink}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <IconButton
                                                style={{ backgroundColor: "transparent" }}
                                                disableRipple={true}
                                                disableFocusRipple={true}
                                            >
                                                <LanguageIcon style={{ paddingRight: "5px" }} />
                                                <Typography style={{ fontSize: "14px" }}>
                                                    {t("destinations.card.visitOfficialWebsite")}
                                                </Typography>
                                            </IconButton>
                                        </a>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
        );
    }
    return (
        <div>
            {graphInfoDialog}
            {content}
        </div>
    );
}

function DestinationDetails({ id, showMapView }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.wrapper}>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        className={classes.backToMapButtonWrapper}
                        alignItems="center"
                    >
                        <IconButton className={classes.backToMapButton} onClick={showMapView}>
                            <ArrowBackIcon className={classes.backToMapButtonIcon} />
                            <Typography>{t("destinations.detailsPage.backToMap")}</Typography>
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" className={classes.container}>
                <DestinationDetailsContent id={id} />
            </Container>
        </div>
    );
}

export default DestinationDetails;
