
import { HoverCard } from "../DestinationCard";

import React, { useContext, useEffect, useState, Fragment } from "react";
import { MapContainer, TileLayer, CircleMarker, Popup, useMap, Tooltip } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { makeStyles } from "@material-ui/core";

const URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: "transparent",
        border: "0px",
        boxShadow: "0 0",
    },
}));

function DestinationDetailsMap({destination, dataLabels}) {
    const classes = useStyles();
    return (
        <MapContainer
            center={[destination.latitude, destination.longitude]}
            minZoom={5}
            zoom={5}
            maxZoom={14}
            scrollWheelZoom={true}
            style={{height: '100%', maxHeight: '450px'}}
        >
            <TileLayer attribution={attribution} url={URL} />
            <CircleMarker
                    key={`marker-${destination._id}`}
                    center={[destination.latitude, destination.longitude]}
                    radius={7}
                    fillColor="#006fba"
                    fillOpacity="1"
                    stroke={false}
                >
                    <Tooltip className={classes.tooltip} opacity={1}>
                        <HoverCard selectedDestination={destination} dataLabels={dataLabels} />
                    </Tooltip>
                </CircleMarker>
        </MapContainer>
    );
}

export default DestinationDetailsMap;
