import Axios from "axios";

export const eurostatApi = Axios.create({
    baseURL: process.env.REACT_APP_MICROSERVICE_EUROSTAT,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

export const destinationsApi = Axios.create({
    baseURL: process.env.REACT_APP_MICROSERVICE_DESTINATIONS,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

export const routesApi = Axios.create({
    baseURL: process.env.REACT_APP_MICROSERVICE_ROUTES,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

export const routeevalApi = Axios.create({
    baseURL: process.env.REACT_APP_MICROSERVICE_ROUTEEVAL,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

export const usersApi = Axios.create({
    baseURL: process.env.REACT_APP_MICROSERVICE_USERS,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

export const indicatorsApi = Axios.create({
    baseURL: process.env.REACT_APP_MICROSERVICE_INDICATORS,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});
