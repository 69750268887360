let saq = {
  "id": "60bf80112a5070b4c263a3b6",
  "createdAt": "2021-06-08T14:34:57Z",
  "questionnaireVersion": "2021_06_02",
  "questionnaireTitle": "Route Self Assessment Audit",
  "leadAuditor": "Janez",
  "started": "8.6.2021",
  "lastModified": "8.6.2021",
  "lastModifiedBy": "Tilen",
  "status": "IN PROGRESS",
  "assessmentFor": "Janezova pot",
  "assessmentSubject": "Potka",
  "defaultShort": false,
  "sections": [
    {
      "title": "1. Sustainable Management",
      "yes": 1,
      "no": 0,
      "unanswered": 30,
      "references": [
        {
          "title": "1.1.1 Management Structure",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 1,
          "allQuestions": 3,
          "finishedQuestions": 1,
          "questions": [
            {
              "questionId": "1.1.1.1.1",
              "required": true,
              "criteria": "1.1.1.1 The route has a legal entity, group or committee responsible for managing the route.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Does the route have a legal entity, group or committee responsible for managing the route?",
              "checkType": "YES_NO",
              "value": "Yes",
              "lastModified": "8.6.2021",
              "lastModifiedBy": "Tilen"
            },
            {
              "questionId": "1.1.1.1.2",
              "required": false,
              "criteria": "1.1.1.1 The route has a legal entity, group or committee responsible for managing the route.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "If found necessary, please provide the explanation about the organs.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.1.1.3",
              "required": false,
              "criteria": "1.1.1.1 The route has a legal entity, group or committee responsible for managing the route.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Concerning the founding documents of the legal entity, please provide the titles or the description of the documents and links to the documents.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "1.1.2 Stakeholders Involvement",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "1.1.2.1.1",
              "required": true,
              "criteria": "1.1.2.1 The management structure involves stakeholders from the public and private sector and civil society, enabling participation in the planning and management of the route.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Does the management structure involve stakeholders from the public, private sector and civil society, enabling participation in the planning and management of the route?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.2.1.2",
              "required": false,
              "criteria": "1.1.2.1 The management structure involves stakeholders from the public and private sector and civil society, enabling participation in the planning and management of the route.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Please provide the number of stakeholder meetings per year?",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "1.1.3 Funding",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 11,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "1.1.3.1.1",
              "required": true,
              "criteria": "1.1.3.1 The management structure is appropriately funded to carry out its duties.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Is the management structure appropriately funded to carry out its duties?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.3.1.2",
              "required": false,
              "criteria": "1.1.3.1 The management structure is appropriately funded to carry out its duties.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Have you had any additional funding sources from the public sector for the past year?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.3.1.2.1",
              "required": false,
              "criteria": "1.1.3.1 The management structure is appropriately funded to carry out its duties.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "If yes, please state the amount of yearly funding from the public sector (in EUR).",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.3.1.3",
              "required": false,
              "criteria": "1.1.3.1 The management structure is appropriately funded to carry out its duties.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Please state the amount of yearly founding from the European Commission for the cultural route (in EUR).",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.3.1.4",
              "required": false,
              "criteria": "1.1.3.1 The management structure is appropriately funded to carry out its duties.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Have you had any additional funding sources from the private sector for the past year?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.3.1.4.1",
              "required": false,
              "criteria": "1.1.3.1 The management structure is appropriately funded to carry out its duties.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "If yes, please state the amount of yearly funding from the private sector (in EUR).",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.3.1.5",
              "required": false,
              "criteria": "1.1.3.1 The management structure is appropriately funded to carry out its duties.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Please provide the number of thematic projects (European projects related to the topic of the Cultural Route).",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.3.1.5.1",
              "required": false,
              "criteria": "1.1.3.1 The management structure is appropriately funded to carry out its duties.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Please state which thematic projects were executed.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.3.1.5.2",
              "required": false,
              "criteria": "1.1.3.1 The management structure is appropriately funded to carry out its duties.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Please provide the amount of funding from the thematic projects (in EUR).",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.3.1.6",
              "required": false,
              "criteria": "1.1.3.1 The management structure is appropriately funded to carry out its duties.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Have you had any additional funding sources from other sources for the past year?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.3.1.6.1",
              "required": false,
              "criteria": "1.1.3.1 The management structure is appropriately funded to carry out its duties.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "If yes, please state the amount of funding from other sources (in EUR).",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "1.1.4 Trained Staff",
          "allQuestionsReq": 3,
          "finishedQuestionsReq": 0,
          "allQuestions": 6,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "1.1.4.1.1",
              "required": true,
              "criteria": "1.1.4.1 The staff working in the management structure is adequately trained in sustainability.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Is the staff working in the management structure adequately trained in sustainability?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.4.1.1.1",
              "required": false,
              "criteria": "1.1.4.1 The staff working in the management structure is adequately trained in sustainability.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "If found necessary, provide the explanation about the training and the competences.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.4.2.1",
              "required": true,
              "criteria": "1.1.4.2 The staff working in the management structure is adequately trained in tourism.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Is the staff working in the management structure adequately trained in tourism?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.4.2.1.1",
              "required": false,
              "criteria": "1.1.4.2 The staff working in the management structure is adequately trained in tourism.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "If found necessary, provide the explanation about the training and the competences.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.4.3.1",
              "required": true,
              "criteria": "1.1.4.3 The staff working in the management structure is adequately trained in heritage.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Is the staff working in the management structure adequately trained in heritage?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.4.3.1.1",
              "required": false,
              "criteria": "1.1.4.3 The staff working in the management structure is adequately trained in heritage.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "If found necessary, provide the explanation about the training and the competences.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "1.1.5 Route's Strategic Plan",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "1.1.5.1.1",
              "required": true,
              "criteria": "1.1.5.1 The route/path has a strategic, multi-stakeholder, up to date plan in place to manage all aspects of the route, including tourism and sustainability, with performance indicators to monitor implementation results.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Does the route/path have a strategic, multi-stakeholder, up to date plan in place to manage all aspects of the route, including tourism and sustainability, with performance indicators to monitor implementation results?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.5.1.1.1",
              "required": false,
              "criteria": "1.1.5.1 The route/path has a strategic, multi-stakeholder, up to date plan in place to manage all aspects of the route, including tourism and sustainability, with performance indicators to monitor implementation results.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "If found necessary, please provide the information about the plan.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "1.1.6 Region’s sustainable tourism strategy",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "1.1.6.1.1",
              "required": true,
              "criteria": "1.1.6.1 The region where the stretch of the route is located has a sustainable tourism strategy and the cultural route/path operation is compatible with it.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Does the region where the stretch of the route is located have a sustainable tourism strategy with which the cultural route/path operation is compatible with it?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.6.1.1.1",
              "required": false,
              "criteria": "1.1.6.1 The region where the stretch of the route is located has a sustainable tourism strategy and the cultural route/path operation is compatible with it.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Please explain the connection between the route’s strategy and sustainability strategy of the region and corresponding processes.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "1.1.7 Visitors Management",
          "allQuestionsReq": 2,
          "finishedQuestionsReq": 0,
          "allQuestions": 8,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "1.1.7.1.1",
              "required": true,
              "criteria": "1.1.7.1 Tools are implemented along the route to count the number of visitors on territories crossed.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Are tools implemented along the route to count the number of visitors on territories crossed?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.7.1.1.1",
              "required": false,
              "criteria": "1.1.7.1 Tools are implemented along the route to count the number of visitors on territories crossed.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Please specify in which territories and with which result(s); which kind of tools and indicators are being or have been used.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.7.1.2",
              "required": false,
              "criteria": "1.1.7.1 Tools are implemented along the route to count the number of visitors on territories crossed.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Is there usage of Big data such as reviewing sites (Trip Advisor, Facebook, Booking.com)?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.7.1.2.1",
              "required": false,
              "criteria": "1.1.7.1 Tools are implemented along the route to count the number of visitors on territories crossed.",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Please provide the description of the usage of such data.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.7.2.1",
              "required": true,
              "criteria": "1.1.7.2 Visitors satisfaction with the quality and sustainability of the route is regularly monitored .",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Is visitors satisfaction with the quality and sustainability of the route regularly monitored?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.7.2.1.1",
              "required": false,
              "criteria": "1.1.7.2 Visitors satisfaction with the quality and sustainability of the route is regularly monitored .",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Please provide the description of the methods for measuring the visitor satisfaction and the results of the monitoring.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.7.2.2",
              "required": false,
              "criteria": "1.1.7.2 Visitors satisfaction with the quality and sustainability of the route is regularly monitored .",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "Is the perception of cleanliness monitored?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.1.7.2.2.1",
              "required": false,
              "criteria": "1.1.7.2 Visitors satisfaction with the quality and sustainability of the route is regularly monitored .",
              "category": "1.1 Cultural Route / Path Planning & Management",
              "check": "If found necessary, please provide additional information.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "1.2.1 Infrastructure Condition",
          "allQuestionsReq": 12,
          "finishedQuestionsReq": 0,
          "allQuestions": 19,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "1.2.1.1.1",
              "required": true,
              "criteria": "1.2.1.1 The infrastructure along the route is well maintained and provides all the services visitors might need.",
              "category": "1.2 Quality of Infrastructure",
              "check": "Is the infrastructure along the route well maintained and provides all the services visitors might need?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.2.1",
              "required": true,
              "criteria": "1.2.1.2 Physical paths/treks",
              "category": "1.2 Quality of Infrastructure",
              "check": "Are physical paths/treks available for visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.2.1.1",
              "required": false,
              "criteria": "1.2.1.2 Physical paths/treks",
              "category": "1.2 Quality of Infrastructure",
              "check": "If yes, please provide the percentage of trail and margins degraded.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.3.1",
              "required": true,
              "criteria": "1.2.1.3 Viewpoints",
              "category": "1.2 Quality of Infrastructure",
              "check": "Are viewpoints available for visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.4.1",
              "required": true,
              "criteria": "1.2.1.4 Rest areas",
              "category": "1.2 Quality of Infrastructure",
              "check": "Are rest areas available for visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.4.1.1",
              "required": false,
              "criteria": "1.2.1.4 Rest areas",
              "category": "1.2 Quality of Infrastructure",
              "check": "If yes, please provide the number of new infrastructure facilities as rest areas built in the last five years.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.5.1",
              "required": true,
              "criteria": "1.2.1.5 Litter bins",
              "category": "1.2 Quality of Infrastructure",
              "check": "Are litter bins available for visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.6.1",
              "required": true,
              "criteria": "1.2.1.6 Water fountains",
              "category": "1.2 Quality of Infrastructure",
              "check": "Are water fountains available for visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.7.1",
              "required": true,
              "criteria": "1.2.1.7 Toilets",
              "category": "1.2 Quality of Infrastructure",
              "check": "Are toilets available for visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.7.1.1",
              "required": false,
              "criteria": "1.2.1.7 Toilets",
              "category": "1.2 Quality of Infrastructure",
              "check": "If yes, please provide the number of new toilet facilities available to the visitors built in the last five years.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.8.1",
              "required": true,
              "criteria": "1.2.1.8 Information Boards",
              "category": "1.2 Quality of Infrastructure",
              "check": "Are information boards available for visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.9.1",
              "required": true,
              "criteria": "1.2.1.9 Sheds",
              "category": "1.2 Quality of Infrastructure",
              "check": "Are sheds or other type of shelter available for visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.9.1.1",
              "required": false,
              "criteria": "1.2.1.9 Sheds",
              "category": "1.2 Quality of Infrastructure",
              "check": "If yes, please provide the percentage of rest facilities considered in degraded condition.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.10.1",
              "required": true,
              "criteria": "1.2.1.10 Bicycle Racks",
              "category": "1.2 Quality of Infrastructure",
              "check": "Are bicycle racks available for visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.10.1.1",
              "required": false,
              "criteria": "1.2.1.10 Bicycle Racks",
              "category": "1.2 Quality of Infrastructure",
              "check": "If yes, please provide the number of bicycle racks or related bicycle infrastructure built for the visitors in the last five years.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.11.1",
              "required": true,
              "criteria": "1.2.1.11 Vehicle Parking Bays",
              "category": "1.2 Quality of Infrastructure",
              "check": "Are vehicle parking bays available for visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.11.1.1",
              "required": false,
              "criteria": "1.2.1.11 Vehicle Parking Bays",
              "category": "1.2 Quality of Infrastructure",
              "check": "If yes, please provide the number of new infrastructures built for vehicle parking for the visitors in the last five years.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.12.1",
              "required": true,
              "criteria": "1.2.1.12 Signposting system indicating the sites/paths/buildings that are part of the cultural route.",
              "category": "1.2 Quality of Infrastructure",
              "check": "Is signposting system indicating the sites/paths/buildings that are part of the cultural route established?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.2.1.12.1.1",
              "required": false,
              "criteria": "1.2.1.12 Signposting system indicating the sites/paths/buildings that are part of the cultural route.",
              "category": "1.2 Quality of Infrastructure",
              "check": "Please provide the description of the signposting system and state which sites does it indicate.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "1.3.1 Safety Information",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "1.3.1.1.1",
              "required": true,
              "criteria": "1.3.1.1 A safe use of the route is guaranteed by safety information: the provision of permanent, all-weather, environment-friendly, clear signposting and other markings associated to a trail are available wherever necessary.",
              "category": "1.3 Health & Safety",
              "check": "Is the safe use of the route guaranteed by safety information: the provision of permanent, all-weather, environment-friendly, clear signposting and other markings associated to a trail are available wherever necessary?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.3.1.1.1.1",
              "required": false,
              "criteria": "1.3.1.1 A safe use of the route is guaranteed by safety information: the provision of permanent, all-weather, environment-friendly, clear signposting and other markings associated to a trail are available wherever necessary.",
              "category": "1.3 Health & Safety",
              "check": "Please provide additional information about the safety information for the visitors.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "1.3.2 Emergency Protocols",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "1.3.2.1.1",
              "required": true,
              "criteria": "1.3.2.1 Emergency protocols are established to respond to natural or man-made disasters as well as health issues from visitors.",
              "category": "1.3 Health & Safety",
              "check": "Are emergency protocols established to respond to natural or man-made disasters as well as health issues from visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.3.2.1.1.1",
              "required": false,
              "criteria": "1.3.2.1 Emergency protocols are established to respond to natural or man-made disasters as well as health issues from visitors.",
              "category": "1.3 Health & Safety",
              "check": "Please provide the description of the emergency protocols.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "1.4.1 Visitor Information",
          "allQuestionsReq": 3,
          "finishedQuestionsReq": 0,
          "allQuestions": 10,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "1.4.1.1.1",
              "required": true,
              "criteria": "1.4.1.1 There are Information Centres along the route to provide guidance and information to visitors.",
              "category": "1.4 Information & Promotion",
              "check": "Are there Information Centres along the route to provide guidance and information to visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.1.1.1.1",
              "required": false,
              "criteria": "1.4.1.1 There are Information Centres along the route to provide guidance and information to visitors.",
              "category": "1.4 Information & Promotion",
              "check": "Please provide the description of the Information Centres for visitors.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.1.1.2",
              "required": false,
              "criteria": "1.4.1.1 There are Information Centres along the route to provide guidance and information to visitors.",
              "category": "1.4 Information & Promotion",
              "check": "Does the cultural route have an interactive, digital or GIS map?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.1.1.2.1",
              "required": false,
              "criteria": "1.4.1.1 There are Information Centres along the route to provide guidance and information to visitors.",
              "category": "1.4 Information & Promotion",
              "check": "Please provide the link(s) to the interactive materials.",
              "checkType": "TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.1.2.1",
              "required": true,
              "criteria": "1.4.1.2 Promotion and visitor information material about the route is accurate with regard to its products, services, and sustainability claims.",
              "category": "1.4 Information & Promotion",
              "check": "Is the promotion and visitor information material about the route accurate with regards to its products, services, and sustainability claims?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.1.2.1.1",
              "required": false,
              "criteria": "1.4.1.2 Promotion and visitor information material about the route is accurate with regard to its products, services, and sustainability claims.",
              "category": "1.4 Information & Promotion",
              "check": "Please state the number of public organisations dedicated to tourism, which have included the route in their promotional material.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.1.2.1.2",
              "required": false,
              "criteria": "1.4.1.2 Promotion and visitor information material about the route is accurate with regard to its products, services, and sustainability claims.",
              "category": "1.4 Information & Promotion",
              "check": "Please provide the number of tour operators who have included the products linked to the theme of the route in their catalogues.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.1.2.1.3",
              "required": false,
              "criteria": "1.4.1.2 Promotion and visitor information material about the route is accurate with regard to its products, services, and sustainability claims.",
              "category": "1.4 Information & Promotion",
              "check": "Please specify if a contractual basis with those in charge of the route exists.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.1.3.1",
              "required": true,
              "criteria": "1.4.1.3 Responsible tourist behaviour is encouraged through awareness raising campaigns.",
              "category": "1.4 Information & Promotion",
              "check": "Do awareness raising campaigns exist to encourage responsible tourist behaviour?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.1.3.1.1",
              "required": false,
              "criteria": "1.4.1.3 Responsible tourist behaviour is encouraged through awareness raising campaigns.",
              "category": "1.4 Information & Promotion",
              "check": "If yes, please describe these campaigns.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "1.4.2 Interpretative Material",
          "allQuestionsReq": 2,
          "finishedQuestionsReq": 0,
          "allQuestions": 3,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "1.4.2.1.1",
              "required": true,
              "criteria": "1.4.2.1 Accurate interpretative material is provided which informs visitors of the significance of the cultural and natural aspects of the sites they visit.",
              "category": "1.4 Information & Promotion",
              "check": "Is accurate interpretative material provided which informs visitors of the significance of the cultural and natural aspects of the sites they visit?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.2.2.1",
              "required": true,
              "criteria": "1.4.2.2 The information provided is culturally appropriate, developed with host community collaboration, and clearly communicated in languages pertinent to visitors and residents.",
              "category": "1.4 Information & Promotion",
              "check": "Is the information provided culturally appropriate, developed with host community collaboration, and clearly communicated in languages pertinent to visitors and residents?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.2.2.1.1",
              "required": false,
              "criteria": "1.4.2.2 The information provided is culturally appropriate, developed with host community collaboration, and clearly communicated in languages pertinent to visitors and residents.",
              "category": "1.4 Information & Promotion",
              "check": "If found necessary, please provide additional information.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "1.4.3 Website and Social Media",
          "allQuestionsReq": 2,
          "finishedQuestionsReq": 0,
          "allQuestions": 4,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "1.4.3.1.1",
              "required": true,
              "criteria": "1.4.3.1 The route/path has an updated website and social media profiles where to provide information and promote the activities along the route including its tourism offer.",
              "category": "1.4 Information & Promotion",
              "check": "Does the route/path have an updated website and social media profiles to provide information and promote the activities along the route including its tourism offer?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.3.2.1",
              "required": true,
              "criteria": "1.4.3.2 The website and social media profiles are periodically monitored to check their effectiveness and visitors satisfaction.",
              "category": "1.4 Information & Promotion",
              "check": "Are website and social media profiles periodically monitored to check their effectiveness and visitors satisfaction?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.3.2.1.1",
              "required": false,
              "criteria": "1.4.3.2 The website and social media profiles are periodically monitored to check their effectiveness and visitors satisfaction.",
              "category": "1.4 Information & Promotion",
              "check": "Please provide the number of Cultural Route website visits in the past year.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "1.4.3.2.1.2",
              "required": false,
              "criteria": "1.4.3.2 The website and social media profiles are periodically monitored to check their effectiveness and visitors satisfaction.",
              "category": "1.4 Information & Promotion",
              "check": "Please provide the number of Cultural Route page clicks per visit in the past year (Google Analytics).",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        }
      ]
    },
    {
      "title": "2. Economic Sustainability",
      "yes": 0,
      "no": 0,
      "unanswered": 7,
      "references": [
        {
          "title": "2.1.1 Tourism flows (volume and value)",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 10,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "2.1.1.1.1",
              "required": true,
              "criteria": "2.1.1.1 The direct and indirect economic contribution of tourism to the destination’s economy is monitored and publicly reported. Appropriate metrics include levels of visitor volume, visitor expenditure, employment and investment.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Is the direct and indirect economic contribution of tourism to the destination’s economy monitored and publicly reported? Appropriate metrics include levels of visitor volume, visitor expenditure, employment and investments.",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.0.0.0.0",
              "required": false,
              "criteria": "2.1.1.1 The direct and indirect economic contribution of tourism to the destination’s economy is monitored and publicly reported. Appropriate metrics include levels of visitor volume, visitor expenditure, employment and investment.",
              "category": "2.1 Economic contribution to local economy",
              "check": "For this category of questions, we propose that you select the NUTS 3 region of the destination for tourism data (select the destination of the cultural route which is the most visited). Check the statistical office data about selected NUTS 3 region. Which NUTS3 region did you select?",
              "checkType": "TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.1.1.1.1",
              "required": false,
              "criteria": "2.1.1.1 The direct and indirect economic contribution of tourism to the destination’s economy is monitored and publicly reported. Appropriate metrics include levels of visitor volume, visitor expenditure, employment and investment.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the average number of tourist’s nights per month.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.1.1.1.2",
              "required": false,
              "criteria": "2.1.1.1 The direct and indirect economic contribution of tourism to the destination’s economy is monitored and publicly reported. Appropriate metrics include levels of visitor volume, visitor expenditure, employment and investment.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the average length of stay of tourists for the past year.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.1.1.1.3",
              "required": false,
              "criteria": "2.1.1.1 The direct and indirect economic contribution of tourism to the destination’s economy is monitored and publicly reported. Appropriate metrics include levels of visitor volume, visitor expenditure, employment and investment.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide business sales directly attributable to the Cultural Route for the past year (in EUR).",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.1.1.1.4",
              "required": false,
              "criteria": "2.1.1.1 The direct and indirect economic contribution of tourism to the destination’s economy is monitored and publicly reported. Appropriate metrics include levels of visitor volume, visitor expenditure, employment and investment.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the daily spending per overnight visitor (accommodation, food and drinks, other services)(in EUR).",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.1.1.1.5",
              "required": false,
              "criteria": "2.1.1.1 The direct and indirect economic contribution of tourism to the destination’s economy is monitored and publicly reported. Appropriate metrics include levels of visitor volume, visitor expenditure, employment and investment.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the daily spending per same day visitor (accommodation, food and drinks, other services). (in EUR)",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.1.1.1.6",
              "required": false,
              "criteria": "2.1.1.1 The direct and indirect economic contribution of tourism to the destination’s economy is monitored and publicly reported. Appropriate metrics include levels of visitor volume, visitor expenditure, employment and investment.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the direct tourism employment as percentage of total employment in the destination for the past year.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.1.1.1.7",
              "required": false,
              "criteria": "2.1.1.1 The direct and indirect economic contribution of tourism to the destination’s economy is monitored and publicly reported. Appropriate metrics include levels of visitor volume, visitor expenditure, employment and investment.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the percentage of locally produced food, drinks, goods and services sourced by the destination’s tourism enterprises for the past year.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.1.1.1.8",
              "required": false,
              "criteria": "2.1.1.1 The direct and indirect economic contribution of tourism to the destination’s economy is monitored and publicly reported. Appropriate metrics include levels of visitor volume, visitor expenditure, employment and investment.",
              "category": "2.1 Economic contribution to local economy",
              "check": "If found necessary, please provide additional information about the monitoring of the direct and indirect economic contribution of tourism.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "2.1.2 Supporting Local Enterpreneurs",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 5,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "2.1.2.1.1",
              "required": true,
              "criteria": "2.1.2.1 Local businesses, especially small and medium sized enterprises are supported through funding, training, etc. to benefit from the route.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Are local businesses, especially small and medium sized enterprises supported through funding, training, etc. to benefit from the route?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.2.1.1.1",
              "required": false,
              "criteria": "2.1.2.1 Local businesses, especially small and medium sized enterprises are supported through funding, training, etc. to benefit from the route.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the number of the Local Multiplier 3 (LM3) for the cultural route. For additional information about LM3 click on this link: https://rm.coe.int/1680706995 (p. 74).",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.2.1.1.1.1",
              "required": false,
              "criteria": "2.1.2.1 Local businesses, especially small and medium sized enterprises are supported through funding, training, etc. to benefit from the route.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the explanation of the calculation of the Local Multiplier 3 (LM3).",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.2.1.1.2",
              "required": false,
              "criteria": "2.1.2.1 Local businesses, especially small and medium sized enterprises are supported through funding, training, etc. to benefit from the route.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the number of new businesses and startups that were established in the last five years connected to the cultural route.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.2.1.1.3",
              "required": false,
              "criteria": "2.1.2.1 Local businesses, especially small and medium sized enterprises are supported through funding, training, etc. to benefit from the route.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the number of SMEs in the tourism, culture and creative industries sector.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "2.1.3 Joint Promotion",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "2.1.3.1.1",
              "required": true,
              "criteria": "2.1.3.1 SMEs along the route/path highlight the theme of the route/path in their promotional activities and benefit from its visibility.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Do the SMEs along the route/path  highlight the theme of the route in their promotional activities and benefit from its visibility?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.3.1.1.1",
              "required": false,
              "criteria": "2.1.3.1 SMEs along the route/path highlight the theme of the route/path in their promotional activities and benefit from its visibility.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the approximate percentage of tourist offers available in the destinations that highlight the theme of the cultural route.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "2.1.4 Local products and services",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "2.1.4.1.1",
              "required": true,
              "criteria": "2.1.4.1 The retention of tourism spending at local level is encouraged through the promotion of local sustainable products based on fair trade principles and that reflect the area’s nature and culture. These include food and beverages, crafts, performance arts, agricultural products, etc.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Is the retention of tourism spending at local level encouraged through the promotion of local sustainable products based on fair trade principles and that reflect the area’s nature and culture? These include food and beverages, crafts, performance arts, agricultural products, etc.",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.4.1.1.1",
              "required": false,
              "criteria": "2.1.4.1 The retention of tourism spending at local level is encouraged through the promotion of local sustainable products based on fair trade principles and that reflect the area’s nature and culture. These include food and beverages, crafts, performance arts, agricultural products, etc.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the direct sales from items produced by the Cultural Route such as guidebooks, tours, and souvenirs (in EUR).",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "2.1.5 Employment and career opportunities",
          "allQuestionsReq": 2,
          "finishedQuestionsReq": 0,
          "allQuestions": 7,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "2.1.5.1.1",
              "required": true,
              "criteria": "2.1.5.1 The route/path stimulates the creation of employment opportunities or retention of existing jobs for local inhabitants.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Does the route/path stimulate the creation of employment opportunities or retention of existing jobs for local inhabitants?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.5.1.1.1",
              "required": false,
              "criteria": "2.1.5.1 The route/path stimulates the creation of employment opportunities or retention of existing jobs for local inhabitants.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the sectoral employment multiplier and explain the calculation used.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.5.1.1.2",
              "required": false,
              "criteria": "2.1.5.1 The route/path stimulates the creation of employment opportunities or retention of existing jobs for local inhabitants.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the number of newly created jobs or existing jobs saved as a direct or indirect result of the cultural route for the past year.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.5.2.1",
              "required": true,
              "criteria": "2.1.5.2 Training opportunities and business advice are available to improve the skills of staff directly or indirectly related to the route/path and to increase employment opportunities related to sustainable tourism.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Are training opportunities and business advice available to improve the skills of staff directly or indirectly related to the route and to increase employment opportunities related to sustainable tourism?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.5.2.1.1",
              "required": false,
              "criteria": "2.1.5.2 Training opportunities and business advice are available to improve the skills of staff directly or indirectly related to the route/path and to increase employment opportunities related to sustainable tourism.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the detailed information about the business support activities.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.5.2.1.2",
              "required": false,
              "criteria": "2.1.5.2 Training opportunities and business advice are available to improve the skills of staff directly or indirectly related to the route/path and to increase employment opportunities related to sustainable tourism.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the number of businesses provided with business advice or training in the past year.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.1.5.2.1.3",
              "required": false,
              "criteria": "2.1.5.2 Training opportunities and business advice are available to improve the skills of staff directly or indirectly related to the route/path and to increase employment opportunities related to sustainable tourism.",
              "category": "2.1 Economic contribution to local economy",
              "check": "Please provide the number of training or advice hours provided in the past year.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "2.2.1 Tackling Seasonality",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 3,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "2.2.1.1.1",
              "required": true,
              "criteria": "2.2.1.1 Options to tackle seasonality are investigated and implemented, including a mechanism to identify year-round tourism opportunities, where appropriate—and direct tourism flows from coastal to hinterland areas.",
              "category": "2.2 Seasonality",
              "check": "Are options to tackle seasonality investigated and implemented, including a mechanism to identify year-round tourism opportunities, where appropriate—and direct tourism flows from coastal to hinterland areas?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.2.1.1.1.1",
              "required": false,
              "criteria": "2.2.1.1 Options to tackle seasonality are investigated and implemented, including a mechanism to identify year-round tourism opportunities, where appropriate—and direct tourism flows from coastal to hinterland areas.",
              "category": "2.2 Seasonality",
              "check": "Please explain the measures taken to tackle the seasonality.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "2.2.1.1.1.2",
              "required": false,
              "criteria": "2.2.1.1 Options to tackle seasonality are investigated and implemented, including a mechanism to identify year-round tourism opportunities, where appropriate—and direct tourism flows from coastal to hinterland areas.",
              "category": "2.2 Seasonality",
              "check": "Please state the months that are generally high season.",
              "checkType": "TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        }
      ]
    },
    {
      "title": "3. Socio-Cultural Sustainability",
      "yes": 0,
      "no": 0,
      "unanswered": 8,
      "references": [
        {
          "title": "3.1.1 Protection of cultural heritage",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 4,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "3.1.1.1.1",
              "required": true,
              "criteria": "3.1.1.1 The route management structure together with public authorities supports the protection of tangible and intangible cultural heritage along the route.",
              "category": "3.1 Preservation of Cultural Heritage",
              "check": "Does the route management structure together with public authorities support the protection of tangible and intangible cultural heritage along the route?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.1.1.1.1.1",
              "required": false,
              "criteria": "3.1.1.1 The route management structure together with public authorities supports the protection of tangible and intangible cultural heritage along the route.",
              "category": "3.1 Preservation of Cultural Heritage",
              "check": "Are there policies, strategies, action plans and tools in place to promote regional economic development using cultural heritage?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.1.1.1.1.1.1",
              "required": false,
              "criteria": "3.1.1.1 The route management structure together with public authorities supports the protection of tangible and intangible cultural heritage along the route.",
              "category": "3.1 Preservation of Cultural Heritage",
              "check": "Please describe these policies, strategies, action plans and tools.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.1.1.1.1.2",
              "required": false,
              "criteria": "3.1.1.1 The route management structure together with public authorities supports the protection of tangible and intangible cultural heritage along the route.",
              "category": "3.1 Preservation of Cultural Heritage",
              "check": "Please state the number of cultural sites on the route designated  for protection at local, national and/or World Heritage levels.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "3.1.2 Promotion of cultural assets",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "3.1.2.1.1",
              "required": true,
              "criteria": "3.1.2.1 Thematic events and festivals are celebrated to promote the traditional/local culture and heritage.",
              "category": "3.1 Preservation of Cultural Heritage",
              "check": "Are thematic events and festivals celebrated to promote the traditional/local culture and heritage?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.1.2.1.1.1",
              "required": false,
              "criteria": "3.1.2.1 Thematic events and festivals are celebrated to promote the traditional/local culture and heritage.",
              "category": "3.1 Preservation of Cultural Heritage",
              "check": "Please provide additional information about these events and festivals.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "3.1.3 Respect of cultural heritage",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "3.1.3.1.1",
              "required": true,
              "criteria": "3.1.3.1 Guidelines (Code of Conduct) for visitor behaviour at sensitive sites and cultural events are made available to visitors, tour operators and guides before and at the time of the visit.",
              "category": "3.1 Preservation of Cultural Heritage",
              "check": "Are guidelines (Code of Conduct) for visitor behaviour at sensitive sites and cultural events made available to visitors, tour operators and guides before and at the time of the visit?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.1.3.1.1.1",
              "required": false,
              "criteria": "3.1.3.1 Guidelines (Code of Conduct) for visitor behaviour at sensitive sites and cultural events are made available to visitors, tour operators and guides before and at the time of the visit.",
              "category": "3.1 Preservation of Cultural Heritage",
              "check": "Please provide additional information about these guidelines.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "3.2.1 Accessible facilities",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 5,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "3.2.1.1.1",
              "required": true,
              "criteria": "3.2.1.1 Where practical, sites, facilities and services, including those of natural and cultural importance, are accessible to all, including persons with disabilities and others who have specific access requirements or other special needs.",
              "category": "3.2 Accessibility",
              "check": "Are sites, facilities and services, including those of natural and cultural importance, accessible to all, including persons with disabilities and others who have specific access requirements or other special needs?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.2.1.1.1.1",
              "required": false,
              "criteria": "3.2.1.1 Where practical, sites, facilities and services, including those of natural and cultural importance, are accessible to all, including persons with disabilities and others who have specific access requirements or other special needs.",
              "category": "3.2 Accessibility",
              "check": "Please provide additional information about these facilities and services.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.2.1.1.1.2",
              "required": false,
              "criteria": "3.2.1.1 Where practical, sites, facilities and services, including those of natural and cultural importance, are accessible to all, including persons with disabilities and others who have specific access requirements or other special needs.",
              "category": "3.2 Accessibility",
              "check": "Is there a consistent application of accessibility standards in public facilities?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.2.1.1.1.3",
              "required": false,
              "criteria": "3.2.1.1 Where practical, sites, facilities and services, including those of natural and cultural importance, are accessible to all, including persons with disabilities and others who have specific access requirements or other special needs.",
              "category": "3.2 Accessibility",
              "check": "Please state the approximate percentage of commercial accommodation establishments participating in recognized accessibility information schemes.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.2.1.1.1.4",
              "required": false,
              "criteria": "3.2.1.1 Where practical, sites, facilities and services, including those of natural and cultural importance, are accessible to all, including persons with disabilities and others who have specific access requirements or other special needs.",
              "category": "3.2 Accessibility",
              "check": "Please state the approximate percentage of public transport that is accessible to people with disabilities and specific access requirements.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "3.2.2 Accessibility information",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "3.2.2.1.1",
              "required": true,
              "criteria": "3.2.2.1 Information is made available on the accessibility of sites, facilities and services.",
              "category": "3.2 Accessibility",
              "check": "Is information available on the accessibility of sites, facilities and services?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.2.2.1.1.1",
              "required": false,
              "criteria": "3.2.2.1 Information is made available on the accessibility of sites, facilities and services.",
              "category": "3.2 Accessibility",
              "check": "If necessary, please provide additional information.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "3.3.1 Residents consultation",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 3,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "3.3.1.1.1",
              "required": true,
              "criteria": "3.3.1.1 Residents in the destinations along the route are regularly consulted about their level of satisfaction with tourism along the route.",
              "category": "3.3 Residents engagement & feedback",
              "check": "Are residents in the destinations along the routes regularly consulted about their level of satisfaction with tourism along the route?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.3.1.1.2",
              "required": false,
              "criteria": "3.3.1.1 Residents in the destinations along the route are regularly consulted about their level of satisfaction with tourism along the route.",
              "category": "3.3 Residents engagement & feedback",
              "check": "Are surveys of residents and other systematic feedback mechanisms, covering tourism issues conducted?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.3.1.1.2.1",
              "required": false,
              "criteria": "3.3.1.1 Residents in the destinations along the route are regularly consulted about their level of satisfaction with tourism along the route.",
              "category": "3.3 Residents engagement & feedback",
              "check": "Please provide additional information about these systematic feedback mechanisms.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "3.3.2 Strengthening social identity and cohesion ",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 4,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "3.3.2.1.1",
              "required": true,
              "criteria": "3.3.2.1 The route has strengthened the spirit of social cohesion among community members, their local identity and the opportunities for exchange and learning related to the encounter between residents and visitors.",
              "category": "3.3 Residents engagement & feedback",
              "check": "Did the route strengthen the spirit of social cohesion among community members, their local identity and the opportunities for exchange and learning related to the encounter between residents and visitors?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.3.2.1.2",
              "required": false,
              "criteria": "3.3.2.1 The route has strengthened the spirit of social cohesion among community members, their local identity and the opportunities for exchange and learning related to the encounter between residents and visitors.",
              "category": "3.3 Residents engagement & feedback",
              "check": "In the past five years, did a population decline in rural areas occur?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.3.2.1.2.1",
              "required": false,
              "criteria": "3.3.2.1 The route has strengthened the spirit of social cohesion among community members, their local identity and the opportunities for exchange and learning related to the encounter between residents and visitors.",
              "category": "3.3 Residents engagement & feedback",
              "check": "If yes, please provide additional information about this decline.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.3.2.1.3",
              "required": false,
              "criteria": "3.3.2.1 The route has strengthened the spirit of social cohesion among community members, their local identity and the opportunities for exchange and learning related to the encounter between residents and visitors.",
              "category": "3.3 Residents engagement & feedback",
              "check": "Please state the percentage of local residents who believe they benefit from the trail.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "3.4.1 Gender Equality",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "3.4.1.1.1",
              "required": true,
              "criteria": "3.4.1.1 Gender equality in formal tourism employment is encouraged.",
              "category": "3.4 Gender Equality",
              "check": "Is gender equality in formal tourism employment encouraged?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "3.4.1.1.1.1",
              "required": false,
              "criteria": "3.4.1.1 Gender equality in formal tourism employment is encouraged.",
              "category": "3.4 Gender Equality",
              "check": "If yes, please elaborate on the measures taken to ensure the gender equality.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        }
      ]
    },
    {
      "title": "4. Environmental Sustainability",
      "yes": 0,
      "no": 0,
      "unanswered": 16,
      "references": [
        {
          "title": "4.1.1 Solid Waste Management",
          "allQuestionsReq": 3,
          "finishedQuestionsReq": 0,
          "allQuestions": 6,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "4.1.1.1.1",
              "required": true,
              "criteria": "4.1.1.1 The route/path and destinations along the route have a waste management system to treat and dispose waste safely.",
              "category": "4.1 Resource Management",
              "check": "Does the route and destinations along the route have a waste management system to treat and dispose waste safely?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.1.1.1.1",
              "required": false,
              "criteria": "4.1.1.1 The route/path and destinations along the route have a waste management system to treat and dispose waste safely.",
              "category": "4.1 Resource Management",
              "check": "Please provide additional information about the waste management system.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.1.2.1",
              "required": true,
              "criteria": "4.1.1.2 There are facilities along the trail and in destinations to separate waste and recycle it.",
              "category": "4.1 Resource Management",
              "check": "Are there facilities along the trail and in destinations to separate waste and recycle it?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.1.2.1.1",
              "required": false,
              "criteria": "4.1.1.2 There are facilities along the trail and in destinations to separate waste and recycle it.",
              "category": "4.1 Resource Management",
              "check": "If found necessary, please provide additional information.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.1.3.1",
              "required": true,
              "criteria": "4.1.1.3 The management structure of the route and the public authorities encourage enterprises along the route to reduce waste production, especially plastic waste.",
              "category": "4.1 Resource Management",
              "check": "Does the management structure of the route and the public authorities encourage enterprises along the route to reduce waste production, especially plastic waste?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.1.3.1.1",
              "required": false,
              "criteria": "4.1.1.3 The management structure of the route and the public authorities encourage enterprises along the route to reduce waste production, especially plastic waste.",
              "category": "4.1 Resource Management",
              "check": "State the approximate percentage of tourism enterprises separating different types of waste.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "4.1.2 Sewage treatment (wastewater management)",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "4.1.2.1.1",
              "required": true,
              "criteria": "4.1.2.1 The route/path and destinations along the route have a wastewater management system to treat and dispose sewage safely.",
              "category": "4.1 Resource Management",
              "check": "Does the route/path and destinations along the route have a wastewater management system to treat and dispose sewage safely?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.2.1.1.1",
              "required": false,
              "criteria": "4.1.2.1 The route/path and destinations along the route have a wastewater management system to treat and dispose sewage safely.",
              "category": "4.1 Resource Management",
              "check": "If found necessary, please provide additional information.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "4.1.3 Water Management",
          "allQuestionsReq": 2,
          "finishedQuestionsReq": 0,
          "allQuestions": 3,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "4.1.3.1.1",
              "required": true,
              "criteria": "4.1.3.1 The quality of drinkable water along the route is regularly monitored.",
              "category": "4.1 Resource Management",
              "check": "Is the quality of drinkable water along the route regularly monitored?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.3.2.1",
              "required": true,
              "criteria": "4.1.3.2 The management structure of the route and the public authorities encourage enterprises along the route to reduce water consumption, incorporating new technologies and awareness raising activities.",
              "category": "4.1 Resource Management",
              "check": "Does the management structure of the route and the public authorities encourage enterprises along the route to reduce water consumption, incorporate new technologies and perform awareness raising activities?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.3.2.1.1",
              "required": false,
              "criteria": "4.1.3.2 The management structure of the route and the public authorities encourage enterprises along the route to reduce water consumption, incorporating new technologies and awareness raising activities.",
              "category": "4.1 Resource Management",
              "check": "Please state the approximate percentage of tourism enterprises taking action to reduce water consumption.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "4.1.4 Energy Consumption",
          "allQuestionsReq": 3,
          "finishedQuestionsReq": 0,
          "allQuestions": 6,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "4.1.4.1.1",
              "required": true,
              "criteria": "4.1.4.1 Facilities using renewable sources of energy are incorporated along the route, such as solar panels, etc.",
              "category": "4.1 Resource Management",
              "check": "Are there facilities using renewable sources of energy incorporated along the route, such as solar panels, geothermal energy, etc.?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.4.1.1.1",
              "required": false,
              "criteria": "4.1.4.1 Facilities using renewable sources of energy are incorporated along the route, such as solar panels, etc.",
              "category": "4.1 Resource Management",
              "check": "Please provide additional information about the usage of renewable sources of energy along the route.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.4.2.1",
              "required": true,
              "criteria": "4.1.4.2 The management structure of the route has created synergies with enterprises sensitive about sustainability who are taking steps to reduce their greenhouse gases emissions and incorporate renewable sources of energy.",
              "category": "4.1 Resource Management",
              "check": "Does the management structure of the route create synergies with enterprises sensitive about sustainability who are taking steps to reduce their greenhouse gases emissions and incorporate renewable sources of energy?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.4.2.1.1",
              "required": false,
              "criteria": "4.1.4.2 The management structure of the route has created synergies with enterprises sensitive about sustainability who are taking steps to reduce their greenhouse gases emissions and incorporate renewable sources of energy.",
              "category": "4.1 Resource Management",
              "check": "Please state the percentage of businesses participating in energy conservation programs, or applying energy saving policy.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.4.3.1",
              "required": true,
              "criteria": "4.1.4.3 The public authorities provide financial incentives to local enterprises working together with Cultural Routes, to encourage the purchase of renewable energy technologies and increase energy efficiency.",
              "category": "4.1 Resource Management",
              "check": "Do the public authorities provide financial incentives to local enterprises working together with cultural routes, to encourage the purchase of renewable energy technologies and increase energy efficiency?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.4.3.1.1",
              "required": false,
              "criteria": "4.1.4.3 The public authorities provide financial incentives to local enterprises working together with Cultural Routes, to encourage the purchase of renewable energy technologies and increase energy efficiency.",
              "category": "4.1 Resource Management",
              "check": "Please provide additional information about these financial incentives.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "4.1.5 Sustainability certifications",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 3,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "4.1.5.1.1",
              "required": true,
              "criteria": "4.1.5.1 Businesses along the route and destinations are encouraged to adopt voluntary certifications/labelling for environmental sustainability as concrete efforts to reduce negative impacts.",
              "category": "4.1 Resource Management",
              "check": "Are the businesses along the route and destinations encouraged to adopt voluntary certifications/labelling for environmental sustainability as concrete efforts to reduce negative impacts?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.5.1.1.1",
              "required": false,
              "criteria": "4.1.5.1 Businesses along the route and destinations are encouraged to adopt voluntary certifications/labelling for environmental sustainability as concrete efforts to reduce negative impacts.",
              "category": "4.1 Resource Management",
              "check": "Please state the percentage of tourism enterprises using a voluntary certification/label for environmental/quality/sustainability or Corporate social Responsibility along the route.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.1.5.1.2",
              "required": false,
              "criteria": "4.1.5.1 Businesses along the route and destinations are encouraged to adopt voluntary certifications/labelling for environmental sustainability as concrete efforts to reduce negative impacts.",
              "category": "4.1 Resource Management",
              "check": "Are business along the route encouraged to measure their Ecological Footprint using the ecological footprint calculator developed by Destimed (https://www.meetnetwork.org/calculator)?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "4.2.1 Soft mobility strategy",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "4.2.1.1.1",
              "required": true,
              "criteria": "4.2.1.1 The route has a soft mobility strategy with concrete targets to reduce transport emissions from travel within the route and its destinations.",
              "category": "4.2 Sustainable Mobility",
              "check": "Does the route have a soft mobility strategy with concrete targets to reduce transport emissions from travel within the route and its destinations?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.2.1.1.1.1",
              "required": false,
              "criteria": "4.2.1.1 The route has a soft mobility strategy with concrete targets to reduce transport emissions from travel within the route and its destinations.",
              "category": "4.2 Sustainable Mobility",
              "check": "Please provide additional information about the soft mobility strategy.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "4.2.2 Low impact transportation",
          "allQuestionsReq": 2,
          "finishedQuestionsReq": 0,
          "allQuestions": 6,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "4.2.2.1.1",
              "required": true,
              "criteria": "4.2.2.1 Soft mobility options are encouraged to travel to and along the route (walking and cycling).",
              "category": "4.2 Sustainable Mobility",
              "check": "Are soft mobility options encouraged to travel to and along the route (walking and cycling)?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.2.2.1.1.1",
              "required": false,
              "criteria": "4.2.2.1 Soft mobility options are encouraged to travel to and along the route (walking and cycling).",
              "category": "4.2 Sustainable Mobility",
              "check": "Please state the approximate percentage of tourists and same-day visitors using different modes of transport to arrive at the destination.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.2.2.1.2",
              "required": false,
              "criteria": "4.2.2.1 Soft mobility options are encouraged to travel to and along the route (walking and cycling).",
              "category": "4.2 Sustainable Mobility",
              "check": "Are multimodal platforms available to connect different modes of transport?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.2.2.1.2.1",
              "required": false,
              "criteria": "4.2.2.1 Soft mobility options are encouraged to travel to and along the route (walking and cycling).",
              "category": "4.2 Sustainable Mobility",
              "check": "Please state the approximate percentage of tourists and same-day visitors using local/soft mobility/public transport services to get around the destination.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.2.2.2.1",
              "required": true,
              "criteria": "4.2.2.2 Public transport is available to reach destinations and attractions along the route.",
              "category": "4.2 Sustainable Mobility",
              "check": "Is public transport available to reach destinations and attractions along the route?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.2.2.2.1.1",
              "required": false,
              "criteria": "4.2.2.2 Public transport is available to reach destinations and attractions along the route.",
              "category": "4.2 Sustainable Mobility",
              "check": "Please state the approximate percentage of environmentally friendly transport alternatives (bikes, electric public transport, etc.).",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "4.3.1 Protected Areas",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "4.3.1.1.1",
              "required": true,
              "criteria": "4.3.1.1 Natural sites and biodiversity are protected along the route thanks to national and regional laws and designated protected areas.",
              "category": "4.3 Landscape & Biodiversity protection",
              "check": "Are natural sites and biodiversity protected along the route thanks to national and regional laws and designated protected areas?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.3.1.1.1.1",
              "required": false,
              "criteria": "4.3.1.1 Natural sites and biodiversity are protected along the route thanks to national and regional laws and designated protected areas.",
              "category": "4.3 Landscape & Biodiversity protection",
              "check": "Please state the number of protected areas the route crosses.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "4.3.2 Landscape & Scenery ",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "4.3.2.1.1",
              "required": true,
              "criteria": "4.3.2.1 Natural and rural scenic views along the route and destinations visited are protected and the sense of place is maintained.",
              "category": "4.3 Landscape & Biodiversity protection",
              "check": "Are natural and rural scenic views along the route and destinations visited protected and is the sense of place maintained?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.3.2.1.1.1",
              "required": false,
              "criteria": "4.3.2.1 Natural and rural scenic views along the route and destinations visited are protected and the sense of place is maintained.",
              "category": "4.3 Landscape & Biodiversity protection",
              "check": "State the approximate percentage of local enterprises in the tourism sector actively supporting protection, conservation and management of local biodiversity and landscapes.",
              "checkType": "NUMBER",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        },
        {
          "title": "4.3.3 Wildlife ",
          "allQuestionsReq": 1,
          "finishedQuestionsReq": 0,
          "allQuestions": 2,
          "finishedQuestions": 0,
          "questions": [
            {
              "questionId": "4.3.3.1.1",
              "required": true,
              "criteria": "4.3.3.1 Activities and services provided throughout the route do not disturb wildlife, or come into contact with endangered, threatened species.",
              "category": "4.3 Landscape & Biodiversity protection",
              "check": "Do the activities and services provided along throughout the route not disturb wildlife, or come into contact with endangered, threatened species?",
              "checkType": "YES_NO",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            },
            {
              "questionId": "4.3.3.1.1.1",
              "required": false,
              "criteria": "4.3.3.1 Activities and services provided throughout the route do not disturb wildlife, or come into contact with endangered, threatened species.",
              "category": "4.3 Landscape & Biodiversity protection",
              "check": "If necessary, provide additional information about set activities and services.",
              "checkType": "LONG_TEXT",
              "value": "",
              "lastModified": "undefined",
              "lastModifiedBy": "undefined"
            }
          ]
        }
      ]
    }
  ]
};

export default saq;
