import LogoInterreg from "../../assets/img/logo_interreg_acknowledgement.svg";

import {
    Button,
    TextField,
    Grid,
    Container,
    IconButton,
    Collapse,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useState, useContext } from "react";
import firebase from "firebase";
import AuthContext from "../../context/auth/AuthContext";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    closeAlertButton: {
        backgroundColor: "#fdecea",
        "&:hover": {
            backgroundColor: "#ffe1de",
        },
    },
    closeSuccessAlertButton: {
        backgroundColor: "#edf7ed",
        "&:hover": {
            backgroundColor: "#5fb76226",
        },
    }
}));

function ChangePassword() {
    const { t } = useTranslation();
    const Auth = useContext(AuthContext);
    const classes = useStyles();
    let history = useHistory();
    let location = useLocation();

    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] = useState("");

    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState("");

    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("");

    const [changeSuccess, setChangeSuccess] = useState(false);
    const [changeError, setChangeError] = useState(false);

    const onCurrentPasswordChange = event => {
        setCurrentPassword(event.target.value);
        setCurrentPasswordError(false);
        setCurrentPasswordErrorMessage("");
    };
    const onNewPasswordChange = event => {
        setNewPassword(event.target.value);
        setNewPasswordError(false);
        setNewPasswordErrorMessage("");
    };
    const onConfirmNewPasswordChange = event => {
        setConfirmNewPassword(event.target.value);
        setConfirmPasswordError(false);
        setConfirmPasswordErrorMessage("");
    };
    const validatePassword = (password) => {
        if (password.length >= 6) {
            return true;
        }
        else {
            return false;
        }
    }
    const clearAllErrors = () => {
        setChangeError(false);
        setCurrentPasswordError(false);
        setCurrentPasswordErrorMessage("");
        setNewPasswordError(false);
        setNewPasswordErrorMessage("");
        setConfirmPasswordError(false);
        setConfirmPasswordErrorMessage("");        
    }

    const isNewUser = () => {
        if(location.state){
            if(location.state.isNewUser === true){
                return true;
            }
        }
        return false;
    }

    const handleSubmit = event => {
        event.preventDefault();
        if (currentPassword) {
            var user = Auth.user;
            var credential = firebase.auth.EmailAuthProvider.credential(
                firebase.auth().currentUser.email,
                currentPassword
            );

            user.reauthenticateWithCredential(credential)
                .then(() => {
                    if (validatePassword(newPassword)) {
                        if (newPassword === confirmNewPassword) {
                            user.updatePassword(newPassword)
                                .then(() => {
                                    setChangeSuccess(true);
                                    clearAllErrors();
                                    setCurrentPassword("");
                                    setNewPassword("");
                                    setConfirmNewPassword("");
                                    setTimeout(()=>history.push("/dashboard"), 3000);
                                }).catch((error) => {
                                    setChangeError(true);
                                    setChangeSuccess(false);
                                });
                        }
                        else {
                            setConfirmPasswordError(true);
                            setConfirmPasswordErrorMessage(t("changePassword.confirmPasswordError"))
                        }
                    }
                    else {
                        setNewPasswordError(true);
                        setNewPasswordErrorMessage(t("changePassword.newPasswordRequired"));
                    }
                })
                .catch((error) => {
                    setCurrentPasswordError(true);
                    setCurrentPasswordErrorMessage(t("changePassword.currentPasswordWrong"));
                });
        }
        else {
            setCurrentPasswordError(true);
            setCurrentPasswordErrorMessage(t("changePassword.currentPasswordRequired"));
        }
    };

    const errorAlert = (
        <Collapse in={changeError}>
            <Alert
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        className={classes.closeAlertButton}
                        onClick={() => {
                            setChangeError(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                severity="error"
                style={{ border: "solid 1px rgb(255, 224, 220)", marginBottom: '10px' }}
            >
                {t("changePassword.changeError")}
            </Alert>
        </Collapse>
    );
    const successAlert = (
        <Collapse in={changeSuccess}>
            <Alert
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        className={classes.closeSuccessAlertButton}
                        onClick={() => {
                            setChangeSuccess(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                severity="success"
                style={{ border: "solid 1px #6dbd7154", marginBottom: '10px' }}
            >
                {t("changePassword.changeSuccess")}
            </Alert>
        </Collapse>
    );
    var newUserText = (
        <Typography style={{color: '#3e3e3e'}}>
            {t("changePassword.changePasswordNewUser")}
        </Typography>
    );

    return (
        <Grid container className="change-password-container" justify="center" alignItems="stretch">
            <Grid item xs={false} sm={false} md={4} className="change-password-image"></Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    className="change-password-content-container"
                >
                    <Container component="main" maxWidth="xs">
                        <a
                            href="https://best-med.interreg-med.eu/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={LogoInterreg}
                                alt="Interreg Mediterranean BEST MED"
                                className="change-password-logotype-container"
                            />
                        </a>
                        {errorAlert}
                        {successAlert}
                        <form noValidate onSubmit={handleSubmit}>
                            <TextField
                                error={currentPasswordError}
                                helperText={currentPasswordErrorMessage}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="currentPassword"
                                label={t("changePassword.currentPassword")}
                                name="currentPassword"
                                type="password"
                                autoComplete="current-password"
                                value={currentPassword}
                                onChange={onCurrentPasswordChange}
                            />
                            <TextField
                                error={newPasswordError}
                                helperText={newPasswordErrorMessage}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="newPassword"
                                label={t("changePassword.newPassword")}
                                name="newPassword"
                                type="password"
                                value={newPassword}
                                onChange={onNewPasswordChange}
                            />
                            <TextField
                                error={confirmPasswordError}
                                helperText={confirmPasswordErrorMessage}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="confirmNewPassword"
                                label={t("changePassword.confirmNewPassword")}
                                name="confirmNewPassword"
                                type="password"
                                value={confirmNewPassword}
                                onChange={onConfirmNewPasswordChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className="change-password-submit-button"
                            >
                                {t("changePassword.changePassword")}
                            </Button>
                        </form>
                    </Container>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ChangePassword;
