import LogoInterreg from "../../assets/img/logo_interreg_acknowledgement.svg";

import {
    Button,
    TextField,
    Grid,
    Container,
    IconButton,
    Collapse,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import firebase from "firebase";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { config } from "../../components/User/firebaseConfig";
import { signIn, isLoggedIn } from "../User/Auth";
import AuthContext from "../../context/auth/AuthContext";

const useStyles = makeStyles(theme => ({
    closeAlertButton: {
        backgroundColor: "#fdecea",
        "&:hover": {
            backgroundColor: "#ffe1de",
        },
    },
    closeSuccessAlertButton: {
        backgroundColor: "#edf7ed",
        "&:hover": {
            backgroundColor: "#5fb76226",
        },
    },
    forgotPasswordText: {
        fontSize: '12px',
        textAlign: 'right'
    },
    resetPasswordInfo: {
        color: '#3e3e3e'
    }
}));

function ForgotPassword() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const Auth = useContext(AuthContext);
    var auth = firebase.auth();

    const [email, setEmail] = useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [emptyError, setEmptyError] = React.useState(false);

    const onEmailChange = event => {
        setEmail(event.target.value);
        setEmptyError(false);
        setErrorAlertOpen(false);
        setSuccessAlertOpen(false);
    };

    const handleSubmit = event => {
        event.preventDefault();
        if(email){
            auth.sendPasswordResetEmail(email).then(function() {
                setSuccessAlertOpen(true);
                setErrorAlertOpen(false);
                setEmail("");
            }).catch(function(error) {
                setErrorAlertOpen(true);
                setSuccessAlertOpen(false);
            });
        }
        else{
            setEmptyError(true);
        }
          
    };

    if (Auth.isLoggedIn) {
        return <Redirect to="/login" />;
    }

    const errorAlert = (
        <Collapse in={errorAlertOpen}>
            <Alert
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        className={classes.closeAlertButton}
                        onClick={() => {
                            setErrorAlertOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                severity="error"
                style={{ border: "solid 1px rgb(255, 224, 220)", marginBottom: '10px' }}
            >
                {t("forgotPassword.resetError")}
            </Alert>
        </Collapse>
    );
    const successAlert = (
        <Collapse in={successAlertOpen}>
            <Alert
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        className={classes.closeSuccessAlertButton}
                        onClick={() => {
                            setSuccessAlertOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                severity="success"
                style={{ border: "solid 1px #6dbd7154", marginBottom: '10px' }}
            >
                {t("forgotPassword.resetSuccess")}
            </Alert>
        </Collapse>
    );
    return (
        <Grid container className="login-container" justify="center" alignItems="stretch">
            <Grid item xs={false} sm={false} md={4} className="login-image"></Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    className="login-content-container"
                >
                    <Container component="main" maxWidth="xs">
                        <a
                            href="https://best-med.interreg-med.eu/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={LogoInterreg}
                                alt="Interreg Mediterranean BEST MED"
                                className="login-logotype-container"
                            />
                        </a>
                        {errorAlert}
                        {successAlert}
                        <Typography className={classes.resetPasswordInfo}>
                            {t("forgotPassword.resetPasswordInfo")}
                        </Typography>
                        <form noValidate onSubmit={handleSubmit}>
                            <TextField
                                error={emptyError}
                                helperText={emptyError ? t("forgotPassword.emptyEmail") : ""}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="email"
                                label={t("forgotPassword.emailAddress")}
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={onEmailChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className="login-submit-button"
                            >
                                {t("forgotPassword.sendPasswordResetEmail")}
                            </Button>
                        </form>
                    </Container>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ForgotPassword;
