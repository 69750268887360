import React, { useEffect, useState } from "react";
import { AllCulturalRoutes } from "../../data/Routes/AllCulturalRoutes";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Link, useHistory } from "react-router-dom";
import { routesApi } from "../../services/api/axios";
import { AllHtmlEntities } from "html-entities";
import { useTranslation } from "react-i18next";

function Dashboard() {
    const { t } = useTranslation();

    const tableColumns = [
        { field: "title", headerName: t("dashboard.table.name"), width: 350 },
        {
            field: "visible",
            headerName: t("dashboard.table.visible"),
            width: 150,
            valueFormatter: params => {
                if (params.value !== null)
                    return params.value ? t("dashboard.table.yes") : t("dashboard.table.no");
            },
        },
        {
            field: "description",
            headerName: t("dashboard.table.description"),
            flex: 1,
            valueFormatter: params => {
                if (params.value !== null) return params.value.replace(/(<([^>]+)>)/gi, "");
            },
        },
        {
            field: "type",
            headerName: t("dashboard.table.routeType"),
            width: 150,
            valueFormatter: params => {
                if (params.value !== null)
                    return params.value.charAt(0).toUpperCase() + params.value.slice(1);
            },
        },
    ];

    const [routesData, setRoutesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const fetchRoutesData = async () => {
            setIsLoading(true);
            let data = AllCulturalRoutes;
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                const result = await routesApi.get("/routes");
                result.data.forEach(function (route, index) {
                    this[index].description = AllHtmlEntities.decode(this[index].description);
                }, result.data);
                data = result.data;
            }
            setRoutesData(data);
            setIsLoading(false);
        };

        fetchRoutesData();
    }, []);

    let history = useHistory();
    const handleRowClick = routeId => {
        history.push(`/dashboard/cultural-route/${routeId}`);
    };

    return (
        <div className="dashboard-container">
            <Container maxWidth="xl">
                <Grid container alignItems="flex-end">
                    <Grid item xs={12} sm={9}>
                        <Typography
                            variant="h5"
                            color="textSecondary"
                            className="title"
                            component="h2"
                        >
                            {t("dashboard.pageTitle")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} className="add-button-container">
                        <Link to="/dashboard/add-cultural-route">
                            <Button
                                size="medium"
                                variant="contained"
                                color="primary"
                                className="add-button"
                            >
                                {t("dashboard.addNewRoute")}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <Grid item xs={12}>
                        <DataGrid
                            rows={routesData}
                            columns={tableColumns}
                            autoPageSize
                            disableSelectionOnClick
                            disableColumnMenu
                            onRowClick={params => handleRowClick(params.row.id)}
                            className="table"
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Dashboard;
