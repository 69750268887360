import React, { useState } from "react";
import { Checkbox, Typography } from "@material-ui/core";
import IconCheckBox from "@material-ui/icons/CheckBox";
import { useTranslation } from "react-i18next";

function InputCheckbox({ initialValue, routeVisibility, isReadOnly }) {
    const { t } = useTranslation();

    const [value, setValue] = useState(initialValue);
    const label = value ? "forms.inputs.visible.yes" : "forms.inputs.visible.no";
    return (
        <>
            <Checkbox
                className="input-checkbox"
                icon={<span className="input-checkbox-icon" />}
                checkedIcon={<IconCheckBox className="input-checkbox-icon-checked" />}
                disableRipple
                checked={value}
                onChange={e => {
                    setValue(e.target.checked);
                    routeVisibility(e.target.checked);
                }}
                color="primary"
                disabled={isReadOnly}
            />
            {isReadOnly && <Typography className="input-checkbox-label">{t(label)}</Typography>}
        </>
    );
}

export default InputCheckbox;
