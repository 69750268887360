export const VisitsLineGraphData = {
    dateTime: '2021-01-23T16:04:32.616+01:00',
    label: 'Same-day visits - annual data',
    href: 'http://ec.europa.eu/eurostat/wdds/rest/data/v2.1/json/en/tour_dem_sdva?purpose=PERS&purpose=PROF&purpose=TOTAL&precision=1&unit=NR&partner=DOM&partner=OUT&partner=WORLD',
    source: 'Eurostat',
    updated: '2020-01-16T23:00:00Z',
    extension: {
      datasetId: 'tour_dem_sdva',
      lang: 'EN',
      description: null,
      subTitle: null
    },
    value: [
      {
        name: 2014,
        PERS: 1574306,
        PROF: 131157,
        TOTAL: 1705463
      },
      {
        name: 2015,
        PERS: 6076046,
        PROF: 359748,
        TOTAL: 6435794
      },
      {
        name: 2016,
        PERS: 5589360,
        PROF: null,
        TOTAL: 5744202
      },
      {
        name: 2017,
        PERS: 3107149,
        PROF: null,
        TOTAL: 3242630
      },
      {
        name: 2018,
        PERS: 3196270,
        PROF: null,
        TOTAL: 3325462
      }
    ],
    graphDimensions: [
      {
        label: 'Personal reasons',
        key: 'PERS'
      },
      {
        label: 'Professional, business',
        key: 'PROF'
      },
      {
        label: 'Total',
        key: 'TOTAL'
      }
    ],
    dimensions: {
      unit: {
        label: 'unit',
        values: {
          NR: 'Number'
        }
      },
      partner: {
        label: 'partner',
        values: {
          OUT: 'Outbound'
        }
      },
      purpose: {
        label: 'purpose',
        values: {
          PERS: 'Personal reasons',
          PROF: 'Professional, business',
          TOTAL: 'Total'
        }
      },
      geo: {
        label: 'geo',
        values: {
          ES: 'Spain'
        }
      },
      time: {
        label: 'time',
        values: {
          '2014': '2014',
          '2015': '2015',
          '2016': '2016',
          '2017': '2017',
          '2018': '2018'
        }
      }
    },
    nuts: {
      nuts2: 'ES',
      label: 'Spain'
    }
  }