import { DestinationsDataNuts } from "../../data/Nuts/DestinationsDataNuts";
import { DestinationsNutsLabelsEn } from "../../data/Nuts/DestinationsNutsLabelsEn";
import { CulturalRoutesDataCountry } from "../../data/Routes/CulturalRoutes";
import { CulturalRoutesArTourData } from "../../data/Routes/CulturalRoutesArTourData";

import { destinationsApi } from "../../services/api/axios";

import React, { useContext, useEffect, useState } from "react";
import LocationContext from "../../context/Destinations/Location/LocationContext";
import { GET_LOCATION } from "../../context/types";
import {
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    InputBase,
    ListSubheader,
    ListItemText,
} from "@material-ui/core";
import { Search as IconSearch } from "@material-ui/icons";
import { UnfoldMore as IconSort } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

function ListData({ destinationsData, sortedDestinationsData }) {
    const { state, dispatch } = useContext(LocationContext);

    const changeInputValue = newValue => {
        /*const destinations = destinationsData.filter(
            destination => destination.nuts.mitomedName === newValue
        );*/
        dispatch({
            type: GET_LOCATION,
            data: [newValue.latitude, newValue.longitude],
        });
    };

    return (
        <div className="destination-list">
            {CulturalRoutesDataCountry.map(data => (
                <li key={`section-${data.sectionName}`}>
                    <ul>
                        <ListSubheader disableSticky className="destination-list-section">
                            {data.sectionName}
                        </ListSubheader>
                        {CulturalRoutesArTourData.map(destination => (
                            <ListItem
                                button
                                onClick={() => changeInputValue(destination.geoLocation)}
                                key={`destination--${destination.guideID}`}
                            >
                                <ListItemText>
                                    <Typography variant="body2" className="destination-list-name">
                                        {destination.title}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        ))}
                    </ul>
                </li>
            ))}
        </div>
    );
}

function DestinationsSearch() {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [destinationsData, setDestinationsData] = useState({
        data: [],
        sortedCountry: [],
        sortedAlphabet: [],
    });
    const [sortTypeCountry, setSortTypeCountry] = useState(true);

    const handleSort = () => setSortTypeCountry(!sortTypeCountry);

    useEffect(() => {
        const fetchDestinationsData = async () => {
            setIsLoading(true);
            let dataLabels = DestinationsNutsLabelsEn;            
            let data = DestinationsDataNuts;
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                const resultLabels = await destinationsApi.get("/labels");
                dataLabels = resultLabels.data;
                const result = await destinationsApi.get("/destinations");
                data = result.data;
            }

            let destinations = data;
            destinations.sort((a, b) =>
                a.nuts.mitomedName.localeCompare(b.nuts.mitomedName, "es", { sensitivity: "base" })
            );

            let sortedDataCountry = [];
            let countries = [];
            destinations.forEach(element => {
                countries.push(element.nuts.country);
            });
            let uniqueCountries = countries.filter((item, index, array) => {
                return array.indexOf(item) === index;
            });
            uniqueCountries.forEach((country, index) => {
                sortedDataCountry.push({
                    sectionName: dataLabels[0][country],
                    destinations: [],
                });
                let destinationName = [];
                DestinationsDataNuts.forEach(element => {
                    if (country === element.nuts.country) {
                        destinationName.push({ name: element.nuts.mitomedName });
                    }
                });
                sortedDataCountry[index].destinations = destinationName;
            });

            let sortedDataAlphabet = Object.values(
                destinations.reduce((r, e) => {
                    let firstLetter = e.nuts.mitomedName[0];

                    if (!r[firstLetter]) {
                        r[firstLetter] = {
                            sectionName: firstLetter,
                            destinations: [{ name: e.nuts.mitomedName }],
                        };
                    } else {
                        r[firstLetter].destinations.push({ name: e.nuts.mitomedName });
                    }

                    return r;
                }, {})
            );

            setDestinationsData({
                data: data,
                sortedCountry: sortedDataCountry,
                sortedAlphabet: sortedDataAlphabet,
            });

            setIsLoading(false);
        };

        fetchDestinationsData();
    }, []);

    return (
        <Box className="destination-search-list">
            <List>
                <ListItem className="destination-search-bar">
                    <InputBase
                        className="destination-search-input"
                        placeholder={t("destinations.list.search")}
                    />
                    <IconButton type="submit" disabled className="destination-search-icon">
                        <IconSearch />
                    </IconButton>
                    <IconButton
                        className="destination-sort-icon"
                        onClick={handleSort}
                        disableRipple
                    >
                        <IconSort />
                    </IconButton>
                </ListItem>
                {/*<Fragment>
                        {isLoading ? (
                            <div>Loading ...</div>
                        ) : (*/}
                <ListData
                    destinationsData={destinationsData.data}
                    sortedDestinationsData={
                        sortTypeCountry
                            ? destinationsData.sortedCountry
                            : destinationsData.sortedAlphabet
                    }
                />
                {/*})}
                    </Fragment>*/}
            </List>
        </Box>
    );
}

export default DestinationsSearch;
