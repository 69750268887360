import React, { useState } from "react";

import { routeevalApi } from "../../services/api/axios";
import { whoAmI, getToken } from "../User/Auth";

import { useTranslation } from "react-i18next";

import {
    Grid,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
    Button,
    makeStyles,
    Snackbar,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    check: {
        fontSize: "0.95rem !important",
        paddingLeft: "50px",
        paddingRight: "50px",
    },
    question: {
        marginTop: "5px",
        marginRight: "50px",
        paddingRight: "50px",
    },
    questionWrapper: {
        marginTop: "15px",
        marginBottom: "15px",
    },
    radioGroup: {
        paddingLeft: "50px",
    },
    submitButton: {
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "5px",
        paddingBottom: "5px",
        marginTop: "15px",
    },
    resetButton: {
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "5px",
        paddingBottom: "5px",
        marginTop: "15px",
        marginRight: "5px",
    },
    submitButtonWrapper: {
        marginTop: "auto",
        marginBottom: "auto",
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SAYesNo(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    let defaultValue;
    if (props.value) {
        defaultValue = props.value;
    } else {
        defaultValue = "";
    }

    const [value, setValue] = React.useState(defaultValue);
    const [filled, setFilled] = React.useState(true);

    const handleChange = event => {
        setValue(event.target.value);

        if (event.target.value) {
            setFilled(false);
        } else {
            setFilled(true);
        }
    };

    const handleReset = () => {
        setValue("");
        setFilled(false);
    };

    const handleSubmit = async id => {
        if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
            let currentUserIdToken = "";
            let currentUser = "";
            if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                currentUser = whoAmI();
                if (currentUser) {
                    const idToken = await getToken();
                    if (idToken) {
                        currentUserIdToken = idToken;
                    }
                }
            } else {
                currentUser = JSON.parse(localStorage.getItem("user"));
            }
            console.log(currentUser.email);
            let data = {
                lastModified: Date.now().toString(),
                lastModifiedBy: currentUser.email,
                value: value,
                idQuestionnaire: props.id
            };
            routeevalApi
                .put(`/routeeval/${props.partId}/${id}`, JSON.stringify(data), {
                    headers: { Authorization: "Bearer " + currentUserIdToken },
                })
                .then(response => {
                    if (response.status === 200) {
                        setSeverity("success");
                        setMessage(t("messageRouteeval.success"));
                    } else {
                        setSeverity("error");
                        setMessage(t("messageRouteeval.error"));
                    }
                    setOpen(true);
                });
        }

        props.handleSave(id, !filled, value);
        setFilled(true);
    };

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState();
    const [message, setMessage] = useState();

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid item xs={12} className={classes.questionWrapper} id={props.checkId}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Typography className={classes.check} align="justify">
                {props.check}
            </Typography>
            <Grid container className={classes.question}>
                <Grid item xs={12}>
                    <FormControl id={props.checkId + ".input"}>
                        <RadioGroup
                            name={props.checkId + ".radio"}
                            aria-labelledby={props.checkId + ".radio"}
                            id={props.checkId + ".radio"}
                            className={classes.radioGroup}
                            onChange={handleChange}
                            value={value}
                        >
                            <FormControlLabel
                                value="Yes"
                                label="Yes"
                                control={
                                    <Radio
                                        size="small"
                                        name={props.groupKey}
                                        style={{ color: "#4c4c4c" }}
                                    />
                                }
                            />
                            <FormControlLabel
                                value="No"
                                label="No"
                                control={
                                    <Radio
                                        size="small"
                                        name={props.groupKey}
                                        style={{ color: "#4c4c4c" }}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12} className={classes.submitButtonWrapper}>
                    <Box alignItems="center" align="right">
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            className={classes.resetButton}
                            onClick={handleReset}
                            disabled={value !== "" ? false : true}
                        >
                            Clear
                        </Button>
                        <a href={"#" + props.nextId}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.submitButton}
                                onClick={() => handleSubmit(props.checkId)}
                                disabled={filled}
                            >
                                Save
                            </Button>
                        </a>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SAYesNo;
