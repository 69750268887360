import { DestinationsDataNuts } from "../../data/Nuts/DestinationsDataNuts";
import { AllCulturalRoutes } from "../../data/Routes/AllCulturalRoutes";
import DestinationCard, { HoverCard } from "./DestinationCard";
import { destinationsApi, routesApi } from "../../services/api/axios";
import LocationContext from "../../context/Destinations/Location/LocationContext";
import { DestinationsNutsLabelsEn } from "../../data/Nuts/DestinationsNutsLabelsEn";
import { default as bezierSpline } from "@turf/bezier-spline";
import * as helpers from "@turf/helpers";
import { ParentRoutesObject } from "../../data/Routes/ParentRoutes";

import React, { useContext, useEffect, useState, useRef } from "react";
import {
    MapContainer,
    TileLayer,
    CircleMarker,
    Popup,
    useMap,
    Tooltip,
    leafletElement,
    Polyline,
    Polygon,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L, { marker } from "leaflet";
import "leaflet/dist/leaflet.css";
import { makeStyles } from "@material-ui/core";

import RouteCard, { RouteHoverCard } from "../Routes/RouteCard";
import { RefreshSharp } from "@material-ui/icons";

const URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: "transparent",
        border: "0px",
        boxShadow: "0 0",
    },
}));

const createDestinationsClusterIcon = cluster => {
    const numberOfDestinations = cluster.getChildCount();
    let markerClusterSize = "xl";

    if (numberOfDestinations <= 2) markerClusterSize = "xs";
    else if (numberOfDestinations <= 3) markerClusterSize = "sm";
    else if (numberOfDestinations <= 4) markerClusterSize = "md";
    else if (numberOfDestinations <= 5) markerClusterSize = "lg";

    return L.divIcon({
        html: `<span class='map-marker-cluster-label'>${numberOfDestinations}</span>`,
        className: `map-marker-cluster-${markerClusterSize} map-marker-cluster-destinations`,
    });
};

const createRoutesClusterIcon = cluster => {
    const numberOfDestinations = cluster.getChildCount();
    let markerClusterSize = "xl";

    if (numberOfDestinations <= 2) markerClusterSize = "xs";
    else if (numberOfDestinations <= 3) markerClusterSize = "sm";
    else if (numberOfDestinations <= 4) markerClusterSize = "md";
    else if (numberOfDestinations <= 5) markerClusterSize = "lg";

    return L.divIcon({
        html: `<span class='map-marker-cluster-label map-marker-cluster-label-routes'>${numberOfDestinations}</span>`,
        className: `map-marker-cluster-${markerClusterSize} map-marker-cluster-routes`,
    });
};

let markersDestinations = [];

function MapDestinationsData({ showDestinationDetailsView }) {
    const classes = useStyles();

    const [destinationsData, setDestinationsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const markerRef = useRef([]);
    let dataLabels = DestinationsNutsLabelsEn;
    useEffect(() => {
        const fetchDestinationsData = async () => {
            setIsLoading(true);
            let data = DestinationsDataNuts;
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                const resultLabels = await destinationsApi.get("/labels");
                dataLabels = resultLabels.data;
                const result = await destinationsApi.get("/destinations");
                data = result.data;
            }
            setDestinationsData(data);

            setIsLoading(false);
        };

        fetchDestinationsData();
    }, []);

    if (markerRef != null) {
        markersDestinations = markerRef;
    }

    return (
        /*<Fragment>
          {isLoading ? (
            <div>Loading ...</div>
          ) : (*/
        <>
            {destinationsData.map((destination, index) => (
                /*{data.destinations.map(destination => (*/
                <CircleMarker
                    ref={el => (markerRef.current[index] = el)}
                    key={`marker-${destination._id}`}
                    center={[destination.latitude, destination.longitude]}
                    radius={6}
                    fillColor="#006fba"
                    fillOpacity="1"
                    stroke={true}
                    weight={2}
                    zIndexOffset={10}
                    color="#006fba"
                    className="destination-marker-animate"
                    onMouseOver={e => {
                        e.target.openPopup();
                    }}
                >
                    <Tooltip className={classes.tooltip} opacity={1}>
                        <HoverCard selectedDestination={destination} dataLabels={dataLabels} />
                    </Tooltip>
                    <Popup>
                        <DestinationCard
                            selectedDestination={destination}
                            showDestinationDetailsView={showDestinationDetailsView}
                            dataLabels={dataLabels}
                        />
                    </Popup>
                </CircleMarker>
            ))}
        </>
        /*)}
        </Fragment>*/
    );
}

function MapNewLocation({ showRouteDetailsView }) {
    const { state, dispatch } = useContext(LocationContext);
    const map = useMap();
    useEffect(() => {
        map.panTo([state.location[0] || 40.849065, state.location[1] || 14.486211]);
        let markerDestination = markersDestinations.current.find(
            element =>
                state.location[0] === element.getLatLng().lat ||
                state.location[1] === element.getLatLng().lng
        );
        if (markerDestination !== undefined) {
            markerDestination.addTo(map).openPopup();
        }
        let markerRoute = markersRoutes.current.find(
            element =>
                state.location[0] === element.getLatLng().lat ||
                state.location[1] === element.getLatLng().lng
        );
        if (markerRoute !== undefined) {
            markerRoute.addTo(map).openPopup();
        }
    }, [state.location, map]);
    return null;
}

let markersRoutes = [];

function MapRoutesData({ showRouteDetailsView }) {
    const classes = useStyles();

    const [routesData, setRoutesData] = useState([]);

    const markerRef = useRef([]);

    useEffect(() => {
        const fetchRoutesData = async () => {
            let data = AllCulturalRoutes;
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                const result = await routesApi.get("/routes");
                data = result.data;
            }
            let publiclyVisibleData = data.filter(route => route.visible);
            setRoutesData(publiclyVisibleData);
        };

        fetchRoutesData();
    }, []);

    if (markerRef != null) {
        markersRoutes = markerRef;
    }

    return (
        <>
            {routesData.map((route, index) => (
                <>
                    <CircleMarker
                        ref={el => (markerRef.current[index] = el)}
                        key={`marker-${route.id}`}
                        center={[route.geoLocation.latitude, route.geoLocation.longitude]}
                        radius={6}
                        fillColor="#fbbe00"
                        fillOpacity="1"
                        stroke={true}
                        zIndexOffset={10}
                        weight={2}
                        color="#fbbe00"
                        className="route-marker-animate"
                    >
                        <Tooltip className={classes.tooltip} opacity={1}>
                            <RouteHoverCard route={route} />
                        </Tooltip>
                        <Popup>
                            <RouteCard route={route} showRouteDetailsView={showRouteDetailsView} />
                        </Popup>
                    </CircleMarker>
                    <RouteVisualise
                        routeType={route.type}
                        routePoints={route.routePoints}
                        route={route}
                        routeIndex={index}
                    />
                </>
            ))}
        </>
    );
}

function Map({ showDestinationDetailsView, showRouteDetailsView }) {
    return (
        <MapContainer
            center={[40.849065, 14.486211]}
            minZoom={5}
            zoom={5}
            maxZoom={14}
            scrollWheelZoom={true}
        >
            <MapNewLocation />

            <TileLayer attribution={attribution} url={URL} />

            <MarkerClusterGroup
                maxClusterRadius={30}
                showCoverageOnHover={false}
                iconCreateFunction={createDestinationsClusterIcon}
            >
                <MapDestinationsData showDestinationDetailsView={showDestinationDetailsView} />
            </MarkerClusterGroup>

            <MarkerClusterGroup
                maxClusterRadius={30}
                showCoverageOnHover={false}
                iconCreateFunction={createRoutesClusterIcon}
            >
                <MapRoutesData showRouteDetailsView={showRouteDetailsView} />
            </MarkerClusterGroup>
        </MapContainer>
    );
}

export default Map;

function RouteVisualise({ routeType, routePoints, route, routeIndex }) {
    const map = useMap();
    const line_polyline = useRef(null);
    const area_polyline = useRef(null);
    const [refAquired, setRefAquired] = useState(false);
    const [active, setActive] = useState(false);
    const color = route.routeColor;
    var routeMarker = markersRoutes.current[routeIndex];
    var line;
    const defaultLineColor = "#046FB8";
    var polyLinePositions = [];
    if (routePoints) {
        routePoints.forEach(point => {
            polyLinePositions.push([point.latitude, point.longitude]);
        });
    }
    if (polyLinePositions.length > 1) {
        var test = polyLinePositions.map(lngLat => [lngLat[0], lngLat[1]]);
        line = helpers.lineString(test);
    }
    useEffect(() => {
        setRefAquired(true);
    }, []);

    useEffect(() => {
        if (routeMarker !== undefined) {
            routeMarker.on("popupopen", event => {
                setActive(true);
            });
            routeMarker.on("popupclose", event => {
                setActive(false);
            });
        }
    }, [refAquired]);
    if (routeType === "circular") {
        const line = polyLinePositions;
        if (polyLinePositions.length > 1) {
            line.push(polyLinePositions[0]);
        }
        return polyLinePositions.length > 1 ? (
            <Polyline
                ref={line_polyline}
                pathOptions={{
                    color: color || defaultLineColor,
                    opacity: active ? 0.9 : 0.6,
                    weight: active ? 8 : 6,
                }}
                className="polyline-animate"
                positions={line}
                zIndexOffset={9}
                onMouseOver={e => e.target.openPopup()}
                eventHandlers={{
                    click: e => {
                        e.target.setStyle({ opacity: 1 });
                        routeMarker.openPopup();
                        routeMarker.getPopup().on("remove", function () {
                            e.target.setStyle({ opacity: 0.7 });
                        });
                    },
                    mouseover: e => {
                        e.target.setStyle({ opacity: 0.8 });
                    },
                    mouseout: e => {
                        e.target.setStyle({ opacity: 0.6 });
                    },
                }}
            ></Polyline>
        ) : (
            ""
        );
    } else if (routeType === "linear") {
        return polyLinePositions.length > 1 ? (
            <Polyline
                ref={line_polyline}
                pathOptions={{
                    color: color || defaultLineColor,
                    opacity: active ? 0.9 : 0.6,
                    weight: active ? 8 : 6,
                }}
                zIndexOffset={9}
                className="polyline-animate"
                positions={polyLinePositions}
                eventHandlers={{
                    click: e => {
                        e.target.setStyle({ opacity: 1 });
                        routeMarker.openPopup();
                        routeMarker.getPopup().on("remove", function () {
                            e.target.setStyle({ opacity: 0.7 });
                        });
                    },
                    mouseover: e => {
                        e.target.setStyle({ opacity: 0.8 });
                    },
                    mouseout: e => {
                        e.target.setStyle({ opacity: 0.6 });
                    },
                }}
            />
        ) : (
            ""
        );
    } else {
        return polyLinePositions.length > 1 ? (
            <Polygon
                ref={area_polyline}
                pathOptions={{
                    color: color || defaultLineColor,
                    fillOpacity: active ? 0.5 : 0.3,
                    opacity: active ? 0.5 : 0.3,
                    weight: 30,
                    stroke: false,
                    lineCap: "square",
                    smoothFactor: 0.2,
                }}
                positions={polyLinePositions}
                zIndexOffset={9}
                eventHandlers={{
                    click: e => {
                        e.target.setStyle({ fillOpacity: 0.7, opacity: 0.7 });
                        routeMarker.openPopup();
                        routeMarker.getPopup().on("remove", function () {
                            e.target.setStyle({ fillOpacity: 0.3, opacity: 0.3 });
                        });
                    },
                    mouseover: e => {
                        e.target.setStyle({ fillOpacity: 0.4 });
                        console.log("hover");
                    },
                    mouseout: e => {
                        e.target.setStyle({ fillOpacity: 0.3 });
                    },
                }}
            />
        ) : (
            ""
        );
    }
}
