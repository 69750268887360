export const GenerationOfWasteLineGraphData = {
    dateTime:"2021-02-04T18:33:00.456+01:00",
    label:"Generation of waste by economic activity",
    href:"http://ec.europa.eu/eurostat/wdds/rest/data/v2.1/json/en/ten00106?precision=1&nace_r2=G-U_X_G4677",
    source:"Eurostat",
    updated:"2020-10-21T22:00:00Z",
    extension:{
       datasetId:"ten00106",
       lang:"EN",
       description:"Total amount of waste generated by households and businesses by economic activity according to NACE Rev. 2 and year.",
       subTitle:"Tonne"
    },
    value:[
       {
          name:2010,
          value:10122638
       },
       {
          name:2012,
          value:8234852
       },
       {
          name:2014,
          value:6299154
       },
       {
          name:2016,
          value:6084055
       },
       {
          name:2018,
          value:5620159
       }
    ],
    graphDimensions:[
       {
          label:"value",
          key:"value"
       }
    ],
    dimensions:{
       hazard:{
          label:"hazard",
          values:{
             HAZ_NHAZ:"Hazardous and non-hazardous - Total"
          }
       },
       waste:{
          label:"waste",
          values:{
             TOTAL:"Total waste"
          }
       },
       unit:{
          label:"unit",
          values:{
             T:"Tonne"
          }
       },
       "nace_r2":{
          label:"nace_r2",
          values:{
             "G-U_X_G4677":"Services (except wholesale of waste and scrap)"
          }
       },
       geo:{
          label:"geo",
          values:{
             ES:"Spain"
          }
       },
       time:{
          label:"time",
          values:{
             "2004":"2004",
             "2006":"2006",
             "2008":"2008",
             "2010":"2010",
             "2012":"2012",
             "2014":"2014",
             "2016":"2016",
             "2018":"2018"
          }
       }
    },
    nuts:{
       "nuts2":"ES",
       label:"Spain"
    }
 }