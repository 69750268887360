export const AnnualOccupancyCoastalLineGraphData = {
    dateTime:"2021-02-06T11:41:41.494+01:00",
    label:"Nights spent at tourist accommodation establishments by coastal and non-coastal area and by NUTS 2 regions (from 2012 onwards)",
    href:"http://ec.europa.eu/eurostat/wdds/rest/data/v2.1/json/en/tour_occ_nin2c?c_resid=TOTAL&unit=NR&nace_r2=I551-I553",
    source:"Eurostat",
    updated:"2020-12-01T23:00:00Z",
    extension:{
       datasetId:"tour_occ_nin2c",
       lang:"EN",
       description:null,
       subTitle:null
    },
    value:[
       {
          name:2012,
          CST_A:3443607,
          NCST_A:656684,
          TOTAL:4100291
       },
       {
          name:2013,
          CST_A:3393258,
          NCST_A:647982,
          TOTAL:4041240
       },
       {
          name:2014,
          CST_A:3647868,
          NCST_A:671751,
          TOTAL:4319620
       },
       {
          name:2015,
          CST_A:3950005,
          NCST_A:741425,
          TOTAL:4691430
       },
       {
          name:2016,
          CST_A:4236200,
          NCST_A:858665,
          TOTAL:5094865
       },
       {
          name:2017,
          CST_A:4484973,
          NCST_A:838171,
          TOTAL:5323145
       },
       {
          name:2018,
          CST_A:4563834,
          NCST_A:966312,
          TOTAL:5530146
       },
       {
          name:2019,
          CST_A:4653990,
          NCST_A:929018,
          TOTAL:5583008
       }
    ],
    graphDimensions:[
       {
          label:"Coastal areas",
          key:"CST_A"
       },
       {
          label:"Non-coastal areas",
          key:"NCST_A"
       },
       {
          label:"Total",
          key:"TOTAL"
       }
    ],
    dimensions:{
       c_resid:{
          label:"c_resid",
          values:{
             TOTAL:"Total"
          }
       },
       unit:{
          label:"unit",
          values:{
             NR:"Number"
          }
       },
       "nace_r2":{
          label:"nace_r2",
          values:{
             "I551-I553":"Hotels; holiday and other short-stay accommodation; camping grounds, recreational vehicle parks and trailer parks"
          }
       },
       terrtypo:{
          label:"terrtypo",
          values:{
             CST_A:"Coastal areas",
             NCST_A:"Non-coastal areas",
             TOTAL:"Total"
          }
       },
       geo:{
          label:"geo",
          values:{
             "ES13":"Cantabria"
          }
       },
       time:{
          label:"time",
          values:{
             "2012":"2012",
             "2013":"2013",
             "2014":"2014",
             "2015":"2015",
             "2016":"2016",
             "2017":"2017",
             "2018":"2018",
             "2019":"2019"
          }
       }
    },
    nuts:{
       "nuts2":"ES13",
       label:"Cantabria"
    }
 }