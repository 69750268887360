import React from "react";

import { Grid, Typography, Box, TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    check: {
        fontSize: "0.95rem !important",
        paddingLeft: "50px",
        paddingRight: "50px",
    },
    question: {
        marginTop: "5px",
        marginRight: "50px",
    },
    appendix: {
        marginTop: "10px",
        marginBottom: "10px",
    },
}));

function SAAppendix(props) {
    const classes = useStyles();

    let defaultValue;
    if (props.appendixValue) {
        defaultValue = props.appendixValue;
    } else {
        defaultValue = "";
    }

    const [value, setValue] = React.useState(defaultValue);

    const handleChange = event => {
        setValue(event.target.value);
        let returnValue = [];
        returnValue.push(event.target.value);
        returnValue.push(props.index);
        props.handleAppendixChange(returnValue);
    };

    const handleNumberChange = event => {
        if (event.target.value) {
            setValue(event.target.value);
            let returnValue = [];
            returnValue.push(event.target.value);
            returnValue.push(props.index);
            props.handleAppendixChange(returnValue);
        } else {
            setValue("");
            let returnValue = [];
            returnValue.push(event.target.value);
            returnValue.push(props.index);
            props.handleAppendixChange(returnValue);
        }
    };

    let appendix = "";
    let index = "";
    if (props.index) {
        index = "." + props.index;
    }

    if (props.appendix) {
        if (props.appendixType === "LONG_TEXT") {
            appendix = (
                <Grid item xs={12} style={{ marginTop: "15px" }}>
                    <Typography className={classes.check}>{props.appendix}</Typography>
                    <Box
                        justifyContent="center"
                        align="center"
                        style={{
                            marginTop: "5px",
                            paddingLeft: "50px",
                        }}
                    >
                        <Grid container justify="center">
                            <Grid item xs={12}>
                                <TextField
                                    id={props.checkId + ".appendix" + index}
                                    name={props.checkId + ".appendix"}
                                    className={classes.appendix}
                                    multiline
                                    fullWidth
                                    variant="outlined"
                                    rowsMax={5}
                                    value={value}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            );
        } else if (props.appendixType === "NUMBER") {
            appendix = (
                <Grid item xs={12} style={{ marginTop: "15px" }}>
                    <Typography className={classes.check}>{props.appendix}</Typography>
                    <Grid container className={classes.question}>
                        <Grid item xs={12}>
                            <TextField
                                id={props.checkId + ".appendix" + index}
                                name={props.checkId + ".appendix"}
                                size="small"
                                style={{
                                    marginLeft: "50px",
                                    marginTop: "10px",
                                    marginBottom: "15px",
                                    width: "23%",
                                }}
                                variant="outlined"
                                value={value}
                                onChange={handleNumberChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            );
        } else if (props.appendixType === "TEXT") {
            appendix = (
                <Grid item xs={12} style={{ marginTop: "15px" }}>
                    <Typography className={classes.check}>{props.appendix}</Typography>
                    <Box
                        justifyContent="center"
                        align="center"
                        style={{
                            marginTop: "5px",
                            paddingLeft: "50px",
                        }}
                    >
                        <Grid container justify="center">
                            <Grid item xs={12}>
                                <TextField
                                    id={props.checkId + ".appendix" + index}
                                    name={props.checkId + ".appendix"}
                                    className={classes.appendix}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    value={value}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            );
        }
    }

    return appendix;
}

export default SAAppendix;
