import React, { PureComponent } from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    makeStyles,
    Typography,
    IconButton,
    Button,
} from "@material-ui/core";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const StackedBarchartTestData = [
    {
        name: 'Sustainable management',
        yes: 60,
        no: 20,
        unanswered: 20
    },
    {
        name: 'Economic sustainability',
        yes: 20,
        no: 60,
        unanswered: 20
    },
    {
        name: 'Socio-cultural sustainability',
        yes: 35,
        no: 55,
        unanswered: 10
    },
    {
        name: 'Environmental sustainability',
        yes: 45,
        no: 50,
        unanswered: 5
    }
];
const useStyles = makeStyles(theme => ({
    axisValues: {
        fontSize: "0.8rem",
        fontFamily: "Roboto",
        color: "#dfdfdf",
    },
    tooltipCard: {
        padding: "10px",
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    title: {
        fontSize: "18px",
        marginLeft: 'auto',
        marginRight: 'auto',
        color: '#434343'
    }
}));

export default function StackedBarchart({ data, additionalDimension }) {
    const { t } = useTranslation();
    const classes = useStyles();

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload[0] !== undefined) {
            return (
                <Card className={classes.tooltipCard}>
                    <Typography variant="body2" component="p">
                        <b>{label}</b>
                    </Typography>
                    {payload.map(item => (
                        <Typography variant="body2" component="p" key={item.name}>
                            <b style={{ color: item.fill }}>
                                {`${item.name} • ${item.value}%`}
                            </b>
                        </Typography>
                    ))}

                </Card>
            );
        }
        return null;
    };
    const legendLabelFormatter = (value, entry) => {
        return (
            <Typography variant="body2" component="span" style={{ fontSize: "12px", fontFamily: "Roboto" }}>
                {value}
            </Typography>
        );
    };
    const format = v => {
        return v + "%"
    };

    return (
        <div className={classes.container} id="stacked-bar-chart">
            <Typography className={classes.title}>
                Percentage of compliance by sections
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >

                    <XAxis
                        dataKey="name"
                        tickLine={false}
                        tick={{
                            fontSize: "0.6rem",
                            fontFamily: "Roboto",
                            fill: "#555",
                            dx: 25
                        }}
                        axisLine={false}
                        height={130}
                        angle={60}
                        interval={0}
                        tickMargin={50}
                    />
                    <YAxis
                        tickFormatter={format}
                        tickLine={false}
                        tick={{
                            fontSize: "0.75rem",
                            fontFamily: "Roboto",
                            fill: "#555",
                        }}
                        axisLine={false}
                        domain={["0", "100"]}
                    />
                    <Tooltip content={CustomTooltip} />
                    <Legend formatter={legendLabelFormatter} align="center" />;
                <Bar dataKey="yes" stackId="a" fill="#006fba" />
                    <Bar dataKey="no" stackId="a" fill="#F8842E" />
                    <Bar dataKey="unanswered" stackId="a" fill="#adadad" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={-4} dy={16} dx={100} textAnchor="end" fill="#555" transform="rotate(35)">
                    {payload.value.split(" ").map((value) => (
                        <div>
                            {value}
                            <br />
                        </div>
                    ))}
                </text>
            </g>
        );
    }
}
