export const AppMenuItems = [
    {
        id: "home",
        title: "header.appMenu.home",
        link: "/",
    },
    {
        id: "dashboard",
        title: "header.appMenu.dashboard",
        link: "/dashboard",
    },
];
