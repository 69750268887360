import FormAddCulturalRoute from "./FormAddCulturalRoute";
import FormEditCulturalRoute from "./FormEditCulturalRoute";
import FormViewCulturalRoute from "./FormViewCulturalRoute";

import React, { useState, useEffect } from "react";
import FormInputLayout from "./FormInputLayout";
import InputTextField from "./InputTextField";
import InputCheckbox from "./InputCheckbox";
import InputTextEditor from "./InputTextEditor";
import InputCardSelect from "./InputCardSelect";
import InputImageUpload from "./InputImageUpload";
import InputLinks from "./InputLinks";
import InputContacts from "./InputContacts";
import InputRouteParts from "./InputRouteParts";
import InputRoutePoints from "./InputRoutePoints";
import InputRouteLocation from "./InputRouteLocation";
import InputRouteSelfAssessment from "./InputRouteSelfAssessment";
import InputRoutePartIndicators from "./InputRoutePartIndicators";
import InputParentRoute from "./InputParentRoute";

import { AllCulturalRoutes } from "../../data/Routes/AllCulturalRoutes";
import { routesApi } from "../../services/api/axios";
import { whoAmI, getToken } from "../User/Auth";

import { useTranslation } from "react-i18next";
import { Container, Divider } from "@material-ui/core";
import { useParams, Redirect } from "react-router-dom";

import { AllHtmlEntities } from "html-entities";

function FormCulturalRoute({ formType }) {
    const { t } = useTranslation();

    const [routeTitle, setRouteTitle] = useState(null);
    const [routeColor, setrouteColor] = useState(null);
    const [routeVisibility, setRouteVisibility] = useState(null);
    const [routeType, setRouteType] = useState(null);
    const [routeDescription, setRouteDescription] = useState(null);
    const [routeImages, setRouteImages] = useState(null);
    const [routeLinks, setRouteLinks] = useState(null);
    const [routeContacts, setRouteContacts] = useState(null);
    const [routeGeoLocation, setRouteGeoLocation] = useState({
        latitude: 45.90333,
        longitude: 15.59111,
    });
    const [routePoints, setRoutePoints] = useState(null);
    const [routePartTitle, setRoutePartTitle] = useState(null);
    const [allImages, setAllImages] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [route, setRoute] = useState(null);

    const [validationMessage, setValidationMessage] = useState(null);
    useEffect(() => {
        if (validationMessage) window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [validationMessage]);

    let { routeId } = useParams();

    let isReadOnly = false;
    let formTitleActions;
    let formActions;

    useEffect(() => {
        if (formType !== "add") {
            const fetchRouteData = async () => {
                setIsLoading(true);
                if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                    let currentUserIdToken = "";
                    if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                        const currentUser = whoAmI();
                        if (currentUser) {
                            const idToken = await getToken();
                            if (idToken) {
                                currentUserIdToken = idToken;
                            }
                        }
                    }
                    const result = await routesApi.get(`/routes/${routeId}`, {
                        headers: { Authorization: "Bearer " + currentUserIdToken },
                    });
                    await Promise.all(
                        result.data.images.map(async (image, index) => {
                            const res = await routesApi.get("/routes/download/" + image.image, {
                                headers: {
                                    Authorization: "Bearer " + currentUserIdToken,
                                },
                                responseType: "blob",
                            });
                            const file = new File([res.data], image.caption, {
                                type: res.data.type,
                            });
                            result.data.images[index] = {
                                file,
                                caption: image.caption,
                                preview: URL.createObjectURL(res.data),
                                id: image.image,
                            };
                        })
                    ).then(async () => {
                        let parts = [];
                        await Promise.all(
                            result.data.parts.map(async element => {
                                const resultPart = await routesApi.get(`/parts/${element}`, {
                                    headers: {
                                        Authorization: "Bearer " + currentUserIdToken,
                                    },
                                });
                                parts.push(resultPart.data);
                                parts.sort((a, b) => a.title.localeCompare(b.title));
                            })
                        ).then(async () => {
                            result.data.description = AllHtmlEntities.decode(
                                result.data.description
                            );
                            setRouteTitle(result.data.title);
                            setrouteColor(result.data.routeColor);
                            setRouteVisibility(result.data.visible);
                            setRouteType(result.data.type);
                            setRouteDescription(result.data.description);
                            setRouteImages(result.data.images);
                            setAllImages(result.data.images);
                            setRouteLinks(result.data.links);
                            setRouteContacts(result.data.contacts);
                            setRouteGeoLocation(result.data.geoLocation);
                            setRoutePoints(result.data.routePoints);
                            setRoutePartTitle(parts);
                            result.data.parts = parts;
                            setRoute(result.data);
                            setIsLoading(false);
                        });
                    });
                } else {
                    setRoute(AllCulturalRoutes.filter(route => route.id == routeId)[0]);
                    setIsLoading(false);
                }
            };
            fetchRouteData();
        } else {
            setRoute({
                title: "",
                routeColor: "",
                visible: false,
                type: "",
                description: "",
                images: [],
                links: [],
                contacts: [],
                geoLocation: {},
                parts: [],
                routePoints: [],
            });
            setIsLoading(false);
        }
    }, [formType, routeId]);

    if (formType === "add") {
        formTitleActions = (
            <FormAddCulturalRoute
                titles={true}
                routeTitle={routeTitle}
                routeColor={routeColor}
                routeVisible={routeVisibility}
                routeType={routeType}
                routeDescription={routeDescription}
                routeImages={routeImages}
                routeLinks={routeLinks}
                routeContacts={routeContacts}
                routeGeoLocation={routeGeoLocation}
                routePartTitle={routePartTitle}
                routePoints={routePoints}
                validationMessage={validationMessage => setValidationMessage(validationMessage)}
            />
        );

        formActions = (
            <FormAddCulturalRoute
                titles={false}
                routeTitle={routeTitle}
                routeColor={routeColor}
                routeVisible={routeVisibility}
                routeType={routeType}
                routeDescription={routeDescription}
                routeImages={routeImages}
                routeLinks={routeLinks}
                routeContacts={routeContacts}
                routeGeoLocation={routeGeoLocation}
                routePartTitle={routePartTitle}
                routePoints={routePoints}
                validationMessage={validationMessage => setValidationMessage(validationMessage)}
            />
        );
    } else if (formType === "edit") {
        formTitleActions = (
            <FormEditCulturalRoute
                titles={true}
                initialRoute={route}
                routeId={routeId}
                routeTitle={routeTitle}
                routeColor={routeColor}
                routeVisible={routeVisibility}
                routeType={routeType}
                routeDescription={routeDescription}
                routeImages={routeImages}
                routeLinks={routeLinks}
                routeContacts={routeContacts}
                routeGeoLocation={routeGeoLocation}
                routePartTitle={routePartTitle}
                allImages={allImages}
                routePoints={routePoints}
                validationMessage={validationMessage => setValidationMessage(validationMessage)}
            />
        );

        formActions = (
            <FormEditCulturalRoute
                titles={false}
                initialRoute={route}
                routeId={routeId}
                routeTitle={routeTitle}
                routeColor={routeColor}
                routeVisible={routeVisibility}
                routeType={routeType}
                routeDescription={routeDescription}
                routeImages={routeImages}
                routeLinks={routeLinks}
                routeContacts={routeContacts}
                routeGeoLocation={routeGeoLocation}
                routePartTitle={routePartTitle}
                allImages={allImages}
                routePoints={routePoints}
                validationMessage={validationMessage => setValidationMessage(validationMessage)}
            />
        );
    } else if (formType === "view") {
        isReadOnly = true;
        formTitleActions = (
            <FormViewCulturalRoute
                titles={true}
                routeId={routeId}
                allImages={allImages}
                allParts={routePartTitle}
                author={route ? route.author : ""}
            />
        );
        formActions = (
            <FormViewCulturalRoute
                titles={false}
                routeId={routeId}
                allImages={allImages}
                allParts={routePartTitle}
                author={route ? route.author : ""}
            />
        );
    }

    if (route === undefined) {
        return <Redirect to="/dashboard" />;
    } else {
        return (
            <>
                {isLoading ? null : (
                    <div className="form-cultural-route-container">
                        <Container maxWidth="lg">
                            {formTitleActions}

                            {(formType !== "view" || route.title) && (
                                <FormInputLayout
                                    label={t("forms.inputs.name.label")}
                                    instructions={t("forms.inputs.name.instructions")}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputTextField
                                            initialValue={route.title}
                                            routeTitle={routeTitle => setRouteTitle(routeTitle)}
                                            isReadOnly={isReadOnly}
                                            validationMessage={validationMessage}
                                        />
                                    }
                                />
                            )}

                            {(formType !== "view" || route.routeColor) && (
                                <FormInputLayout
                                    label={t("forms.inputs.routeColor.label")}
                                    instructions={t("forms.inputs.routeColor.instructions")}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputParentRoute
                                            initialValue={route.routeColor}
                                            routeColor={routeColor => setrouteColor(routeColor)}
                                            isReadOnly={isReadOnly}
                                            validationMessage={validationMessage}
                                        />
                                    }
                                />
                            )}

                            {(formType !== "view" || route.visible !== null) && (
                                <FormInputLayout
                                    label={t("forms.inputs.visible.label")}
                                    instructions={t("forms.inputs.visible.instructions")}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputCheckbox
                                            initialValue={route.visible}
                                            routeVisibility={routeVisibility =>
                                                setRouteVisibility(routeVisibility)
                                            }
                                            isReadOnly={isReadOnly}
                                        />
                                    }
                                />
                            )}

                            {(formType !== "view" || route.description) && (
                                <FormInputLayout
                                    label={t("forms.inputs.description.label")}
                                    instructions={t("forms.inputs.description.instructions")}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputTextEditor
                                            initialValue={route.description}
                                            routeDescription={routeDescription =>
                                                setRouteDescription(routeDescription)
                                            }
                                            isReadOnly={isReadOnly}
                                        />
                                    }
                                />
                            )}

                            {(formType !== "view" || route.type) && (
                                <FormInputLayout
                                    label={t("forms.inputs.routeType.label")}
                                    instructions={t("forms.inputs.routeType.instructions")}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputCardSelect
                                            initialValue={route.type}
                                            routeType={routeType => setRouteType(routeType)}
                                            isReadOnly={isReadOnly}
                                        />
                                    }
                                />
                            )}

                            {(formType !== "view" ||
                                (route.geoLocation.latitude && route.geoLocation.longitude)) && (
                                <FormInputLayout
                                    label={t("forms.inputs.routeLocation.label")}
                                    instructions={t("forms.inputs.routeLocation.instructions")}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputRouteLocation
                                            initialValue={route.geoLocation}
                                            routeGeoLocation={routeGeoLocation =>
                                                setRouteGeoLocation(routeGeoLocation)
                                            }
                                            isReadOnly={isReadOnly}
                                        />
                                    }
                                />
                            )}

                            {(formType !== "view" || route.routePoints !== null) && (
                                <FormInputLayout
                                    label={t("forms.inputs.routePoints.label")}
                                    instructions={t("forms.inputs.routePoints.instructions")}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputRoutePoints
                                            initialValue={route.routePoints}
                                            routeType={routeType}
                                            routePoints={routePoints => setRoutePoints(routePoints)}
                                            isReadOnly={isReadOnly}
                                        />
                                    }
                                />
                            )}

                            {(formType !== "view" || route.images.length > 0) && (
                                <FormInputLayout
                                    label={t("forms.inputs.gallery.label")}
                                    instructions={t("forms.inputs.gallery.instructions")}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputImageUpload
                                            initialValue={route.images}
                                            routeImages={routeImages => setRouteImages(routeImages)}
                                            isReadOnly={isReadOnly}
                                        />
                                    }
                                />
                            )}

                            {(formType !== "view" || route.links.length > 0) && (
                                <FormInputLayout
                                    label={t("forms.inputs.links.label")}
                                    instructions={t("forms.inputs.links.instructions")}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputLinks
                                            initialValue={route.links}
                                            routeLinks={routeLinks => setRouteLinks(routeLinks)}
                                            isReadOnly={isReadOnly}
                                        />
                                    }
                                />
                            )}

                            {(formType !== "view" || route.contacts.length > 0) && (
                                <FormInputLayout
                                    label={t("forms.inputs.contacts.label")}
                                    instructions={t("forms.inputs.contacts.instructions")}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputContacts
                                            initialValue={route.contacts}
                                            routeContacts={routeContacts =>
                                                setRouteContacts(routeContacts)
                                            }
                                            isReadOnly={isReadOnly}
                                        />
                                    }
                                />
                            )}

                            {(formType !== "view" || route.parts.length > 0) && (
                                <FormInputLayout
                                    label={t("forms.inputs.routeParts.label")}
                                    instructions={t("forms.inputs.routeParts.instructions")}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputRouteParts
                                            initialValue={route.parts}
                                            routePartTitle={routePartTitle =>
                                                setRoutePartTitle(routePartTitle)
                                            }
                                            isReadOnly={isReadOnly}
                                        />
                                    }
                                />
                            )}

                            {formType === "view" && route.parts.length > 0 && (
                                <FormInputLayout
                                    label={t("forms.inputs.routeSelfAssessment.label")}
                                    instructions={""}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputRouteSelfAssessment
                                            routeParts={route.parts}
                                            routeName={route.title}
                                            routeAuthor={route.author}
                                        />
                                    }
                                />
                            )}

                            {formType === "view" && route.parts.length > 0 && (
                                <FormInputLayout
                                    label={t("forms.inputs.routePartIndicators.label")}
                                    instructions={""}
                                    isReadOnly={isReadOnly}
                                    input={
                                        <InputRoutePartIndicators
                                            routeParts={route.parts}
                                            routeName={route.title}
                                            routeAuthor={route.author}
                                        />
                                    }
                                />
                            )}

                            <Divider className="input-divider" />

                            {formActions}
                        </Container>
                    </div>
                )}
            </>
        );
    }
}

export default FormCulturalRoute;
