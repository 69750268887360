import React, { useState } from "react";

import { routeevalApi } from "../../services/api/axios";
import { whoAmI, getToken } from "../User/Auth";

import { useTranslation } from "react-i18next";

import { Grid, Typography, Box, Button, TextField, makeStyles, Snackbar } from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

import SAAppendix from "./SAAppendix";

const useStyles = makeStyles(theme => ({
    check: {
        fontSize: "0.95rem !important",
        paddingLeft: "50px",
        paddingRight: "50px",
    },
    question: {
        marginTop: "5px",
        marginRight: "50px",
        paddingRight: "50px",
    },
    questionWrapper: {
        marginTop: "15px",
        marginBottom: "15px",
    },
    submitButton: {
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "5px",
        paddingBottom: "5px",
        marginTop: "15px",
    },
    submitButtonWrapper: {
        marginBottom: "auto",
    },
    appendix: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px",
        marginBottom: "15px",
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SALongText(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    let defaultValue;
    if (props.value) {
        defaultValue = props.value;
    } else {
        defaultValue = "";
    }

    let defaultAppendixValue;
    if (props.appendixValue) {
        defaultAppendixValue = props.appendixValue;
    } else {
        defaultAppendixValue = "";
    }

    const [value, setValue] = React.useState(defaultValue);
    const [filled, setFilled] = React.useState(true);
    const [appendixValue, setAppendixValue] = React.useState(defaultAppendixValue);

    const appendixChange = appendixValue => {
        setAppendixValue(appendixValue[0]);
        setFilled(false);
    };

    const handleChange = event => {
        setValue(event.target.value);
        if (event.target.value) {
            setFilled(false);
        } else {
            setFilled(true);
        }
    };

    const handleSubmit = async id => {
        if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
            let currentUserIdToken = "";
            let currentUser = "";
            if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                currentUser = whoAmI();
                if (currentUser) {
                    const idToken = await getToken();
                    if (idToken) {
                        currentUserIdToken = idToken;
                    }
                }
            } else {
                currentUser = JSON.parse(localStorage.getItem("user"));
            }
            let data = {
                lastModified: Date.now().toString(),
                lastModifiedBy: currentUser.email,
                value: value,
                idQuestionnaire: props.id
            };
            routeevalApi
                .put(`/routeeval/${props.partId}/${id}`, JSON.stringify(data), {
                    headers: { Authorization: "Bearer " + currentUserIdToken },
                })
                .then(response => {
                    if (response.status === 200) {
                        setSeverity("success");
                        setMessage(t("messageRouteeval.success"));
                    } else {
                        setSeverity("error");
                        setMessage(t("messageRouteeval.error"));
                    }
                    setOpen(true);
                });
        }

        props.handleSave(id, !filled, value, appendixValue);
        setFilled(true);
    };

    let appendix = "";
    if (props.appendix) {
        appendix = (
            <SAAppendix
                appendixValue={props.appendixValue}
                appendixType={props.appendixType}
                checkId={props.checkId}
                appendix={props.appendix}
                handleAppendixChange={appendixChange}
            />
        );
    }

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState();
    const [message, setMessage] = useState();

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid item xs={12} className={classes.questionWrapper} id={props.checkId}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Typography className={classes.check}>{props.check}</Typography>
            <Box
                style={{
                    marginTop: "15px",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            id={props.checkId + ".input"}
                            name={props.checkId}
                            className={classes.longText}
                            multiline
                            fullWidth
                            variant="outlined"
                            rowsMax={5}
                            value={value}
                            onChange={handleChange}
                        />
                    </Grid>

                    {appendix}

                    <Grid item xs={12}>
                        <Box alignItems="center" align="right">
                            <a href={"#" + props.nextId}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.submitButton}
                                    onClick={() => handleSubmit(props.checkId)}
                                    disabled={filled}
                                >
                                    Save
                                </Button>
                            </a>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}

export default SALongText;
