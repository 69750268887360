export const ArrivalsMonthlyLineGraphData = {
    dateTime:"2021-02-24T17:07:20.021+01:00",
    label:"Arrivals at tourist accommodation establishments - monthly data",
    href:"http://ec.europa.eu/eurostat/wdds/rest/data/v2.1/json/en/tour_occ_arm?c_resid=TOTAL&c_resid=FOR&c_resid=NAT&precision=1&unit=NR&nace_r2=I551-I553",
    source:"Eurostat",
    updated:"2021-02-22T23:00:00Z",
    extension:{
       datasetId:"tour_occ_arm",
       lang:"EN",
       description:null,
       subTitle:null
    },
    value:[
       {
          name:2019,
          TOTAL:6109368,
          NAT:3127776,
          FOR:2981592
       },
       {
          name:2019,
          TOTAL:7016000,
          NAT:3805268,
          FOR:3210732
       },
       {
          name:2019,
          TOTAL:8923506,
          NAT:4770403,
          FOR:4153103
       },
       {
          name:2019,
          TOTAL:11232669,
          NAT:5698320,
          FOR:5534349
       },
       {
          name:2019,
          TOTAL:12737373,
          NAT:5703780,
          FOR:7033593
       },
       {
          name:2019,
          TOTAL:14416631,
          NAT:6954361,
          FOR:7462270
       },
       {
          name:2019,
          TOTAL:15933094,
          NAT:7835544,
          FOR:8097550
       },
       {
          name:2019,
          TOTAL:17738068,
          NAT:9309867,
          FOR:8428201
       },
       {
          name:2019,
          TOTAL:13645499,
          NAT:6132142,
          FOR:7513357
       },
       {
          name:2019,
          TOTAL:11578144,
          NAT:5196101,
          FOR:6382043
       },
       {
          name:2019,
          TOTAL:7886628,
          NAT:4237770,
          FOR:3648858
       },
       {
          name:2019,
          TOTAL:7490821,
          NAT:4319005,
          FOR:3171816
       }
    ],
    graphDimensions:[
       {
          label:"Total",
          key:"TOTAL"
       },
       {
          label:"Reporting country",
          key:"NAT"
       },
       {
          label:"Foreign country",
          key:"FOR"
       }
    ],
    dimensions:{
       unit:{
          label:"unit",
          values:{
             NR:"Number"
          }
       },
       "nace_r2":{
          label:"nace_r2",
          values:{
             "I551-I553":"Hotels; holiday and other short-stay accommodation; camping grounds, recreational vehicle parks and trailer parks"
          }
       },
       c_resid:{
          label:"c_resid",
          values:{
             TOTAL:"Total",
             NAT:"Reporting country",
             FOR:"Foreign country"
          }
       },
       geo:{
          label:"geo",
          values:{
             ES:"Spain"
          }
       },
       time:{
          label:"time",
          values:{
             "2019M01":"2019M01",
             "2019M02":"2019M02",
             "2019M03":"2019M03",
             "2019M04":"2019M04",
             "2019M05":"2019M05",
             "2019M06":"2019M06",
             "2019M07":"2019M07",
             "2019M08":"2019M08",
             "2019M09":"2019M09",
             "2019M10":"2019M10",
             "2019M11":"2019M11",
             "2019M12":"2019M12"
          }
       }
    },
    nuts:{
       "nuts2":"ES",
       label:"Spain"
    }
 }