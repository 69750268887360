export const ArrivalsLineGraphData = {
    dateTime: '2021-01-23T16:04:32.286+01:00',
  label: 'Arrivals at tourist accommodation establishments by NUTS 2 regions',
  href: 'http://ec.europa.eu/eurostat/wdds/rest/data/v2.1/json/en/tour_occ_arn2?c_resid=TOTAL&c_resid=FOR&c_resid=NAT&precision=1&unit=NR&nace_r2=I551-I553',
  source: 'Eurostat',
  updated: '2020-12-01T23:00:00Z',
  extension: {
    datasetId: 'tour_occ_arn2',
    lang: 'EN',
    description: null,
    subTitle: null
  },
  value: [
    {
      name: 2010,
      TOTAL: 1570953,
      NAT: 1298217,
      FOR: 272736
    },
    {
      name: 2011,
      TOTAL: 1574052,
      NAT: 1302100,
      FOR: 271952
    },
    {
      name: 2012,
      TOTAL: 1541756,
      NAT: 1268303,
      FOR: 273453
    },
    {
      name: 2013,
      TOTAL: 1516360,
      NAT: 1229955,
      FOR: 286406
    },
    {
      name: 2014,
      TOTAL: 1560186,
      NAT: 1274368,
      FOR: 285818
    },
    {
      name: 2015,
      TOTAL: 1671362,
      NAT: 1364136,
      FOR: 307226
    },
    {
      name: 2016,
      TOTAL: 1807524,
      NAT: 1466451,
      FOR: 341073
    },
    {
      name: 2017,
      TOTAL: 1936458,
      NAT: 1572516,
      FOR: 363942
    },
    {
      name: 2018,
      TOTAL: 2032945,
      NAT: 1627699,
      FOR: 405246
    },
    {
      name: 2019,
      TOTAL: 2030719,
      NAT: 1608751,
      FOR: 421968
    }
  ],
  graphDimensions: [
    {
      label: 'Total',
      key: 'TOTAL'
    },
    {
      label: 'Reporting country',
      key: 'NAT'
    },
    {
      label: 'Foreign country',
      key: 'FOR'
    }
  ],
  dimensions: {
    unit: {
      label: 'unit',
      values: {
        NR: 'Number'
      }
    },
    nace_r2: {
      label: 'nace_r2',
      values: {
        'I551-I553': 'Hotels; holiday and other short-stay accommodation; camping grounds, recreational vehicle parks and trailer parks'
      }
    },
    c_resid: {
      label: 'c_resid',
      values: {
        TOTAL: 'Total',
        NAT: 'Reporting country',
        FOR: 'Foreign country'
      }
    },
    geo: {
      label: 'geo',
      values: {
        ES13: 'Cantabria'
      }
    },
    time: {
      label: 'time',
      values: {
        '1990': '1990',
        '1991': '1991',
        '1992': '1992',
        '1993': '1993',
        '1994': '1994',
        '1995': '1995',
        '1996': '1996',
        '1997': '1997',
        '1998': '1998',
        '1999': '1999',
        '2000': '2000',
        '2001': '2001',
        '2002': '2002',
        '2003': '2003',
        '2004': '2004',
        '2005': '2005',
        '2006': '2006',
        '2007': '2007',
        '2008': '2008',
        '2009': '2009',
        '2010': '2010',
        '2011': '2011',
        '2012': '2012',
        '2013': '2013',
        '2014': '2014',
        '2015': '2015',
        '2016': '2016',
        '2017': '2017',
        '2018': '2018',
        '2019': '2019'
      }
    }
  },
  nuts: {
    nuts2: 'ES13',
    label: 'Cantabria'
  }
};
