import firebase from "firebase";
import { config } from "./firebaseConfig";
import { usersApi } from "../../services/api/axios.js";

export const isLoggedIn = () => {
    if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
        var user = JSON.parse(
            window.sessionStorage.getItem(`firebase:authUser:${config.apiKey}:[DEFAULT]`)
        );
        if (user) {
            return true;
        } else {
            return false;
        }
    } else {
        if (localStorage.getItem("user")) {
            return true;
        } else {
            return false;
        }
    }
};

export const whoAmI = () => {
    if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
        var user = JSON.parse(
            window.sessionStorage.getItem(`firebase:authUser:${config.apiKey}:[DEFAULT]`)
        );
        if (user) {
            return user;
        } else {
            return null;
        }
    } else {
        if (localStorage.getItem("user")) {
            return JSON.parse(localStorage.getItem("user"));
        } else {
            return null;
        }
    }
};

export const signIn = (email, password) => {
    if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
        return firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                return firebase.auth().signInWithEmailAndPassword(email, password);
            });
    } else {
        var user = {
            displayName: null,
            email: email,
        };
        if (email === "") user.email = "user@gmail.com";
        localStorage.setItem("user", JSON.stringify(user));
        return new Promise((resolve, reject) => {
            if (localStorage.getItem("user")) {
                resolve({ user: user });
            } else {
                reject("Could not store login");
            }
        });
    }
};

export const logOut = () => {
    if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
        return firebase.auth().signOut();
    } else {
        return new Promise((resolve, reject) => {
            localStorage.removeItem("user");
            if (!localStorage.getItem("user")) {
                resolve();
            } else {
                reject();
            }
        });
    }
};

export const getToken = async () => {
    var user = JSON.parse(
        window.sessionStorage.getItem(`firebase:authUser:${config.apiKey}:[DEFAULT]`)
    );
    if (user) {
        return user.stsTokenManager.accessToken;
    }
};

export const checkRole = async email => {
    console.log("checking role");
    if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
        const result = await usersApi.get(`/${email}`);
        if (result.data) {
            console.log(result.data);
            return result.data.role;
        }
    } else {
        return "admin";
    }
};

export const registerUser = async email => {
    const randPass = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 9);
    var secondaryApp = firebase.initializeApp(config, "Secondary");
    if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
        return new Promise((resolve, reject) => {
            secondaryApp
                .auth()
                .createUserWithEmailAndPassword(email, randPass)
                .then(userCredential => {
                    secondaryApp
                        .auth()
                        .signOut()
                        .then(() => {
                            secondaryApp
                                .auth()
                                .sendPasswordResetEmail(email)
                                .then(function () {
                                    secondaryApp.delete();
                                    resolve(userCredential);
                                })
                                .catch(function (error) {
                                    secondaryApp.delete();
                                    reject(error);
                                });
                        })
                        .catch(error => {
                            secondaryApp.delete();
                            reject(error);
                        });
                })
                .catch(error => {
                    secondaryApp.delete();
                    reject(error);
                });
        });
    } else {
        return new Promise((resolve, reject) => reject());
    }
};

export const isFirstTime = () => {
    if(sessionStorage.getItem("isFirstTime") || localStorage.getItem("isFirstTime")){
        return false;
    } else {
        return true;
    }
}
export const completedFirstTimeForNow = () => {
    sessionStorage.setItem("isFirstTime", false);
}
export const completedFirstTimeForever = () => {
    localStorage.setItem("isFirstTime", false);
};
