import Map from "./RoutesMap";
import RoutesSearch from "./RoutesSearch";
import Footer from "../Footer/Footer";

import { Grid, Hidden } from "@material-ui/core";

function Routes() {
    return (
        <Grid container>
            <Grid item className="destinations-layout-container-left">
                <RoutesSearch />
                <Hidden mdUp>
                    <Footer />
                </Hidden>
            </Grid>
            <Hidden smDown>
                <Grid item className="destinations-layout-container-right">
                    <Map />
                    <Footer />
                </Grid>
            </Hidden>
        </Grid>
    );
}

export default Routes;
