import { DestinationsDataNuts } from "../../data/Nuts/DestinationsDataNuts";
import { CulturalRoutesArTourData } from "../../data/Routes/CulturalRoutesArTourData";

import { destinationsApi } from "../../services/api/axios";
import LocationContext from "../../context/Destinations/Location/LocationContext";

import React, { useContext, useEffect, useState, Fragment } from "react";
import { MapContainer, TileLayer, CircleMarker, Popup, useMap, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { makeStyles } from "@material-ui/core";

import RouteCard, { RouteHoverCard } from "./RouteCard";

const URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: "transparent",
        border: "0px",
        boxShadow: "0 0",
    },
}));

const createClusterIcon = cluster => {
    const numberOfDestinations = cluster.getChildCount();
    let markerClusterSize = "xl";

    if (numberOfDestinations <= 2) markerClusterSize = "xs";
    else if (numberOfDestinations <= 3) markerClusterSize = "sm";
    else if (numberOfDestinations <= 4) markerClusterSize = "md";
    else if (numberOfDestinations <= 5) markerClusterSize = "lg";

    return L.divIcon({
        html: `<span class='map-marker-cluster-label'>${numberOfDestinations}</span>`,
        className: `map-marker-cluster-${markerClusterSize}`,
    });
};

function MapData() {
    const classes = useStyles();

    const [destinationsData, setDestinationsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchDestinationsData = async () => {
            setIsLoading(true);

            let data = DestinationsDataNuts;
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                const result = await destinationsApi.get("/destinations");
                data = result.data;
            }
            setDestinationsData(data);

            setIsLoading(false);
        };

        fetchDestinationsData();
    }, []);

    return (
        /*<Fragment>
          {isLoading ? (
            <div>Loading ...</div>
          ) : (*/
        <>
            {CulturalRoutesArTourData.map(destination =>
                /*{data.destinations.map(destination => (*/
                destination.points.map(point => (
                    <CircleMarker
                        key={`marker-${point.id}`}
                        center={[point.geo.latitude, point.geo.longitude]}
                        radius={7}
                        fillColor="#006fba"
                        fillOpacity="1"
                        stroke={false}
                    >
                        <Tooltip className={classes.tooltip} opacity={1}>
                            <RouteHoverCard route={destination} />
                        </Tooltip>
                        <Popup>
                            <RouteCard route={destination} />
                        </Popup>
                    </CircleMarker>
                ))
            )}
        </>
        /*)}
        </Fragment>*/
    );
}

function MapNewLocation() {
    const { state, dispatch } = useContext(LocationContext);
    const map = useMap();
    useEffect(() => {
        map.panTo([state.location[0] || 40.849065, state.location[1] || 14.486211], 8);
    }, [state.location]);
    return null;
}

function Map() {
    return (
        <MapContainer center={[40.849065, 14.486211]} minZoom={5} zoom={5} scrollWheelZoom={true}>
            <MapNewLocation />
            <TileLayer attribution={attribution} url={URL} />
            <MarkerClusterGroup
                maxClusterRadius={30}
                showCoverageOnHover={false}
                iconCreateFunction={createClusterIcon}
            >
                <MapData />
            </MarkerClusterGroup>
        </MapContainer>
    );
}

export default Map;
