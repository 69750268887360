import IconDashboard from "../../assets/img/icon_dashboard.svg";
import IconMap from "../../assets/img/icon_map.svg";
import IconDev from "../../assets/img/icon_dev.svg";
import IconAvatar from "../../assets/img/icon_avatar.svg";

const UserMenuItems = [
    {
        id: "dashboard",
        title: "header.userMenu.dashboard",
        icon: <img src={IconDashboard} alt="dashboard" className="user-menu-item-icon" />,
        link: "/dashboard",
    },
    {
        id: "map",
        title: "header.userMenu.map",
        icon: <img src={IconMap} alt="map" className="user-menu-item-icon" />,
        link: "/",
    }
];

const UserDevMenuItems = [
    {
        id: "routes",
        title: "header.userMenu.routes",
        icon: <img src={IconDev} alt="DEV" className="user-menu-item-icon" />,
        link: "/routes",
    },
    {
        id: "test",
        title: "header.userMenu.test",
        icon: <img src={IconDev} alt="DEV" className="user-menu-item-icon" />,
        link: "/test",
    },
    {
        id: "questionnaire",
        title: "header.userMenu.questionnaire",
        icon: <img src={IconDev} alt="DEV" className="user-menu-item-icon" />,
        link: "/questionnaire",
    },
];

export { UserMenuItems, UserDevMenuItems };
