import { useEffect, useState, useContext } from "react";
import Map from "./Map";
import DestinationsSearch from "./DestinationsSearch";
import Footer from "../Footer/Footer";
import { Grid, Hidden } from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import DestinationDetails from "./DestinationDetails/DestinationDetails";
import { route } from "../../data/Routes/CulturalRoutesExample";
import LocationContext from "../../context/Destinations/Location/LocationContext";
import { GET_LOCATION } from "../../context/types";
import RouteDetails from "../Routes/RouteDetails/RouteDetails";
import { isFirstTime } from "../User/Auth";

function Destinations() {
    //0=map, 1=destination details, 2=route details
    const { state, dispatch } = useContext(LocationContext);
    const [selectedView, setSelectedView] = useState(0);
    const [detailsId, setDetailsId] = useState(null);
    const [allRoutes, setAllRoutes] = useState([]);
    let history = useHistory();

    useEffect(() => {
        if (isFirstTime()) {
            history.push("/welcome-page");
        }
    }, []);

    const showMapView = () => {
        setSelectedView(0);
    };
    const showDestinationDetailsView = destination => {
        setDetailsId(destination._id);
        setSelectedView(1);
    };
    const showRouteDetailsView = route => {
        var id = route.id;
        if (route.id === undefined) id = route._id;
        setDetailsId(id);
        setSelectedView(2);
    };
    const showLocationOnMap = destination => {
        dispatch({
            type: GET_LOCATION,
            data: [destination.lat, destination.lng],
        });
    };
    var search = (
        <DestinationsSearch
            showDestinationDetailsView={showDestinationDetailsView}
            showRouteDetailsView={showRouteDetailsView}
            allRoutes={setAllRoutes}
        />
    );
    var currentView = "";
    if (selectedView === 2 && detailsId !== undefined) {
        currentView = (
            <RouteDetails showMapView={showMapView} id={detailsId} allRoutes={allRoutes} />
        );
    } else if (selectedView === 1 && detailsId !== undefined) {
        currentView = <DestinationDetails showMapView={showMapView} id={detailsId} />;
    } else {
        currentView = (
            <Map
                showDestinationDetailsView={showDestinationDetailsView}
                showRouteDetailsView={showRouteDetailsView}
            />
        );
        search = (
            <DestinationsSearch
                showDestinationDetailsView={showLocationOnMap}
                showRouteDetailsView={showLocationOnMap}
                allRoutes={setAllRoutes}
            />
        );
    }
    return (
        <Grid container>
            <Grid item className="destinations-layout-container-left">
                {search}
                <Hidden mdUp>
                    <Footer />
                </Hidden>
            </Grid>

            <Hidden smDown>
                <Grid item className="destinations-layout-container-right">
                    {currentView}
                    <Footer />
                </Grid>
            </Hidden>
        </Grid>
    );
}

export default Destinations;
