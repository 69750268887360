import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import 'font-awesome/css/font-awesome.min.css';
import { destinationsApi } from "../../services/api/axios";
import { DestinationsDataNuts } from "../../data/Nuts/DestinationsDataNuts";
import { MapContainer, TileLayer, CircleMarker, useMapEvents, GeoJSON, Polyline, Polygon } from "react-leaflet";
import { default as bezierSpline } from "@turf/bezier-spline";
import * as helpers from "@turf/helpers";

const URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

function InputRoutePoints({ initialValue, routeType, routePoints, isReadOnly }) {
    const { t } = useTranslation();
    const [selectedDestinations, setSelectedDestinations] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState([]);
    const [destinationsData, setDestinationsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [polyLinePositions, setPolyLinePositions] = useState([]);
    const [curvedLine, setCurvedLine] = useState(null);

    useEffect(() => {
        const fetchDestinationsData = async () => {
            setIsLoading(true);
            var data = DestinationsDataNuts;
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                const result = await destinationsApi.get("/destinations");
                data = result.data;
            }
            

            setOptions(data.map((destination) => ({ value: "" + destination._id, label: destination.nuts.mitomedName })))
            var selected = [];
            var positions = [];
            var destinations = [];
            if (initialValue !== null) {

                initialValue.forEach(point => {
                    selected.push(point.destinationId.toString());
                    var destination = data.find(destination => destination._id == point.destinationId);
                    destinations.push(destination);
                    positions.push([destination.latitude, destination.longitude]);
                });
            }
            setDestinationsData(data);
            setSelectedOptions(selected);
            setPolyLinePositions(positions);
            setSelectedDestinations(destinations);
            setIsLoading(false);
        };

        fetchDestinationsData();
    }, [initialValue])

    const calculateRoutePoints = (destinations) => {
        var points = [];
        var index = 0;
        destinations.forEach((destination) => {
            var point = {
                destinationId: destination._id,
                name: destination.nuts.mitomedName.toString(),
                latitude: destination.latitude.toString(),
                longitude: destination.longitude.toString(),
                position: index
            }
            points.push(point);
            index++;
        })
        routePoints(points);
    }

    const onChange = (selected) => {
        setSelectedOptions(selected);
        var destinations = [];
        var positions = [];
        selected.forEach(item => {
            var destination = destinationsData.find(destination => destination._id == item);
            destinations.push(destination);
            positions.push([destination.latitude, destination.longitude]);
        });
        setSelectedDestinations(destinations);
        setPolyLinePositions(positions);

        if (positions.length > 1) {
            var test = positions.map(lngLat => [lngLat[1], lngLat[0]]);
            var line = helpers.lineString(
                test
            );
            line = bezierSpline(line);
            setCurvedLine(line);
        }
        calculateRoutePoints(destinations);
    }
    return (
        <Grid
            container

            alignItems="center"
        >
            <Grid item xs={12}>
                <MapContainer
                    key={`map-${isReadOnly}`}
                    center={[45.90333, 15.59111]}
                    minZoom={4}
                    zoom={5}
                    maxZoom={14}
                    className={"input-route-location-map"}
                >
                    <TileLayer attribution={attribution} url={URL} />

                    {selectedDestinations.map((destination) => (
                        <CircleMarker
                            key={destination._id}
                            center={[destination.latitude, destination.longitude]}
                            radius={7}
                            fillColor="#006fba"
                            fillOpacity="1"
                            stroke={false}
                            opacity={0.7}
                            className={"input-route-location-marker-not-clickable"}
                        />
                    ))}

                    <RouteVisualise key={routeType} routeType={routeType} polyLinePositions={polyLinePositions} />


                </MapContainer>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '15px' }}>
                {true ? (
                    <DualListBox
                        disabled={isReadOnly ? true : false}
                        preserveSelectOrder
                        showOrderButtons
                        canFilter
                        alignActions="top"
                        options={options}
                        selected={selectedOptions}
                        onChange={onChange}
                    />
                ) : ('')}

            </Grid>
        </Grid>
    );
}

export default InputRoutePoints;

function RouteVisualise({ routeType, polyLinePositions }) {

    if (routeType === 'circular') {
        const line = polyLinePositions;
        if (polyLinePositions.length > 1) {
            line.push(polyLinePositions[0]);
        }
        return (
            polyLinePositions.length > 1 ? (
                <Polyline
                    pathOptions={{
                        color: "#006fba",
                        opacity: 0.7,
                        weight: 4
                    }}
                    positions={line}
                />
            ) : ('')
        );
    }
    else if (routeType === 'linear') {
        return (
            polyLinePositions.length > 1 ? (
                <Polyline
                    pathOptions={{
                        color: "#006fba",
                        opacity: 0.7,
                        weight: 4
                    }}
                    positions={polyLinePositions}
                />
            ) : ('')
        );
    }
    else {
        return (
            polyLinePositions.length > 1 ? (
                <Polygon
                pathOptions={{
                    color: "#006fba",
                    opacity: 0.7,
                    weight: 4,
                    stroke: false
                }}
                positions={polyLinePositions} />
            ) : ('')
        );
    }
}
