import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import {
    Container,
    Grid,
    Typography,
    Collapse,
    IconButton,
    Stepper,
    Step,
    StepButton,
    StepLabel,
    Divider,
    LinearProgress,
    makeStyles,
    CircularProgress,
    Button,
    Switch,
    FormControlLabel,
    Snackbar,
} from "@material-ui/core";
import clsx from "clsx";

import { useTranslation } from "react-i18next";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import GroupIcon from "@material-ui/icons/Group";
import EcoIcon from "@material-ui/icons/Eco";
import IconArrowBack from "@material-ui/icons/ArrowBack";

import { Icon, InlineIcon } from "@iconify/react";
import baselineVolunteerActivism from "@iconify-icons/ic/baseline-volunteer-activism";

import { routeevalApi } from "../../services/api/axios";
import saq from "./SelfAssessmentQuestionnaire";

import SALongText from "./SALongText";
import SANumber from "./SANumber";
import SAText from "./SAText";
import SAYesNo from "./SAYesNo";

import { whoAmI, getToken } from "../User/Auth";

import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    questionnaireBackground: {
        padding: "40px",
        paddingRight: "10px",
        marginTop: "24px",
        marginBottom: "10px",
        backgroundColor: "#ffffff",
        border: "1px solid rgba(224, 224, 224, 1)",
    },
    questionnaireInfo: {
        fontWeight: "450",
    },
    container: {
        marginLeft: "45px",
        marginRight: "30px",
        paddingBottom: "48px",
        paddingTop: "48px",
    },
    hr: {
        background: "#006fba",
        opacity: "50%",
        marginBottom: "20px",
        marginTop: "25px",
        marginLeft: "12px",
        height: "2px",
        width: "100%",
    },
    title: {
        fontWeight: "500 !important",
        fontSize: "1.625em !important",
        textTransform: "uppercase",
    },
    subtitle: {
        fontSize: "1.05em",
        fontWeight: "500 !important",
    },
    subtitleContainer: {
        marginTop: "auto",
    },
    routeName: {
        fontWeight: "500 !important",
        fontSize: "0.95em !important",
        textTransform: "uppercase",
    },
    extraInfo: {
        marginTop: "10px",
    },
    collapseButton: {
        width: "100%",
        paddingRight: "0px",
    },
    section: {
        fontSize: "1.05rem !important",
        marginBottom: "5px",
    },
    category: {
        fontSize: "0.95rem !important",
        fontWeight: "500 !important",
        paddingLeft: "0px",
        color: "#006fba",
    },
    categoryProgress: {
        color: "#129861",
    },
    questionDivider: {
        marginBottom: "20px",
        marginTop: "5px",
        marginLeft: "50px",
        marginRight: "50px",
        borderTop: "solid 1px",
        color: "#cccccc",
    },
    sectionButton: {
        padding: "5px",
    },
    stepper: {
        marginLeft: "auto",
        marginRight: "auto",
        width: "80%",
    },
    stepButton: {
        backgroundColor: "transparent",
    },
    progressBar: {
        paddingLeft: "0px",
        marginTop: "15px",
        marginBottom: "15px",
    },
    volunteerActivism: {
        height: "2.1875em",
        width: "2.1875em",
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SelfAssessment({ saqData, partId, routeName, routeId }) {
    console.log(saqData);
    const { t } = useTranslation();
    const classes = useStyles();
    const expandTime = 200;

    const [short, setShort] = React.useState(!saqData.defaultShort);

    const handleSwitch = async () => {
        const newShort = short;
        setShort(!short);
        let currentUserIdToken = "";
        let currentUser = "";
        if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
            currentUser = whoAmI();
            if (currentUser) {
                const idToken = await getToken();
                if (idToken) {
                    currentUserIdToken = idToken;
                }
            }
        } else {
            currentUser = JSON.parse(localStorage.getItem("user"));
        }
        routeevalApi
            .put(`/routeeval/default/${saqData.id}/${newShort}`, {
                headers: { Authorization: "Bearer " + currentUserIdToken },
            })
            .then(response => {
                if (response.status === 200) {
                    setSeverity("success");
                    setMessage(t("messageRouteeval.success"));
                } else {
                    setSeverity("error");
                    setMessage(t("messageRouteeval.error"));
                }
                setOpen(true);
            });
    };

    let progressBarDefault = [];
    for (let i = 0; i < saqData.sections.length; i++) {
        let finishedQuestions = 0;
        let allQuestions = 0;
        let currentProgress = 0;
        for (let j = 0; j < saqData.sections[i].references.length; j++) {
            if (!short) {
                finishedQuestions += saqData.sections[i].references[j].finishedQuestionsReq;
                allQuestions += saqData.sections[i].references[j].allQuestionsReq;
            } else {
                finishedQuestions +=
                    saqData.sections[i].references[j].finishedQuestions +
                    saqData.sections[i].references[j].finishedQuestionsReq;
                allQuestions += saqData.sections[i].references[j].allQuestions;
            }
        }
        currentProgress = (finishedQuestions / allQuestions) * 100;
        progressBarDefault.push(currentProgress);
    }

    let defaultQuestionnaireProgress = [];
    for (let i = 0; i < saqData.sections.length; i++) {
        defaultQuestionnaireProgress.push([]);
    }

    for (let i = 0; i < saqData.sections.length; i++) {
        for (let j = 0; j < saqData.sections[i].references.length; j++) {
            defaultQuestionnaireProgress[i].push([]);
            for (let k = 0; k < saqData.sections[i].references[j].questions.length; k++) {
                let id = saqData.sections[i].references[j].questions[k].questionId;

                let filled = false;
                if (saqData.sections[i].references[j].questions[k].value) filled = true;

                defaultQuestionnaireProgress[i][j].push({ QuestionId: id, filled: filled });
            }
        }
    }

    let defaultFinishedQuestions = [];
    for (let i = 0; i < saqData.sections.length; i++) {
        defaultFinishedQuestions.push([]);
        for (let j = 0; j < saqData.sections[i].references.length; j++) {
            if (!short) {
                defaultFinishedQuestions[i].push(
                    saqData.sections[i].references[j].finishedQuestionsReq
                );
            } else {
                defaultFinishedQuestions[i].push(
                    saqData.sections[i].references[j].finishedQuestions +
                        saqData.sections[i].references[j].finishedQuestionsReq
                );
            }
        }
    }

    let defaultValues = [];
    for (let i = 0; i < saqData.sections.length; i++) {
        defaultValues.push([]);
        for (let j = 0; j < saqData.sections[i].references.length; j++) {
            defaultValues[i].push([]);
            for (let k = 0; k < saqData.sections[i].references[j].questions.length; k++) {
                defaultValues[i][j].push(saqData.sections[i].references[j].questions[k].value);
            }
        }
    }

    const [progress, setProgress] = React.useState(progressBarDefault);
    const [questionnaireProgress, setQuestionnaireProgress] = React.useState(
        defaultQuestionnaireProgress
    );
    const [finishedQuestions, setFinishedQuestions] = React.useState(defaultFinishedQuestions);
    const [answers, setAnswers] = React.useState(defaultValues);

    const updateQuestionnaireProgress = (id, filled, value) => {
        let questionnaireProgressCopy = questionnaireProgress;
        for (let i = 0; i < questionnaireProgressCopy.length; i++) {
            for (let j = 0; j < questionnaireProgressCopy[i].length; j++) {
                for (let k = 0; k < questionnaireProgressCopy[i][j].length; k++) {
                    let questionState = questionnaireProgressCopy[i][j][k];

                    if (questionState.QuestionId === id) {
                        questionState.filled = filled;
                        questionnaireProgressCopy[i][j][k] = questionState;
                    }
                }
            }
        }

        setQuestionnaireProgress(questionnaireProgressCopy);
        updateAnswers(id, value);
        updateFinishedQuestions();
        updateProgressBar(questionnaireProgressCopy);
    };

    const updateProgressBar = questionnaireProgressCopy => {
        let currentQuestionnaireProgress = [];

        if (short) {
            for (let i = 0; i < answers.length; i++) {
                let allQuestions = 0,
                    finishedQuestions = 0;
                for (let j = 0; j < answers[i].length; j++) {
                    for (let k = 0; k < answers[i][j].length; k++) {
                        if (answers[i][j][k] !== "") finishedQuestions++;
                        allQuestions++;
                    }
                }
                let currentProgress = (finishedQuestions / allQuestions) * 100;
                currentQuestionnaireProgress.push(currentProgress);
            }
        } else {
            for (let i = 0; i < answers.length; i++) {
                let allQuestions = 0,
                    finishedQuestions = 0;
                for (let j = 0; j < answers[i].length; j++) {
                    for (let k = 0; k < answers[i][j].length; k++) {
                        if (saqData.sections[i].references[j].questions[k].required) {
                            if (answers[i][j][k] !== "") finishedQuestions++;
                            allQuestions++;
                        }
                    }
                }
                let currentProgress = (finishedQuestions / allQuestions) * 100;
                currentQuestionnaireProgress.push(currentProgress);
            }
        }

        setProgress(currentQuestionnaireProgress);
    };

    const updateFinishedQuestions = () => {
        let sectionFinishedQuestions = [];

        if (short) {
            for (let i = 0; i < answers.length; i++) {
                sectionFinishedQuestions.push([]);
                for (let j = 0; j < answers[i].length; j++) {
                    let finishedQuestions = 0;
                    for (let k = 0; k < answers[i][j].length; k++) {
                        if (answers[i][j][k] !== "") finishedQuestions++;
                    }
                    sectionFinishedQuestions[i].push(finishedQuestions);
                }
            }
        } else {
            for (let i = 0; i < answers.length; i++) {
                sectionFinishedQuestions.push([]);
                for (let j = 0; j < answers[i].length; j++) {
                    let finishedQuestions = 0;
                    for (let k = 0; k < answers[i][j].length; k++) {
                        if (saqData.sections[i].references[j].questions[k].required) {
                            if (answers[i][j][k] !== "") finishedQuestions++;
                        }
                    }
                    sectionFinishedQuestions[i].push(finishedQuestions);
                }
            }
        }

        setFinishedQuestions(sectionFinishedQuestions);
    };

    React.useEffect(() => {
        updateFinishedQuestions();
    }, [short]);

    const updateAnswers = (id, value) => {
        let answersCopy = answers;

        for (let i = 0; i < saqData.sections.length; i++) {
            for (let j = 0; j < saqData.sections[i].references.length; j++) {
                for (let k = 0; k < saqData.sections[i].references[j].questions.length; k++) {
                    if (saqData.sections[i].references[j].questions[k].questionId === id) {
                        answersCopy[i][j][k] = value;
                        break;
                    }
                }
            }
        }

        setAnswers(answersCopy);
    };

    const handleSave = (id, filled, value) => {
        updateQuestionnaireProgress(id, filled, value);
    };

    let steps = [];
    for (let i = 0; i < saqData.sections.length; i++) {
        let phSection = saqData.sections[i].title.split(". ");
        if (phSection.length > 1) {
            steps.push(phSection[1]);
        } else {
            steps.push(saqData.sections[i].title);
        }
    }

    let sectionCollapseDefault = {};
    let categoryCollapseDefault = {};
    let collapseButtonDefault = {};
    let categoryCollapseButtonDefault = {};

    for (let i = 0; i < saqData.sections.length; i++) {
        let newSection = { ["visible" + i]: true };
        let newButton = { ["button" + i]: <ArrowDropUpIcon /> };
        sectionCollapseDefault = { ...sectionCollapseDefault, ...newSection };
        collapseButtonDefault = { ...collapseButtonDefault, ...newButton };
        for (let j = 0; j < saqData.sections[i].references.length; j++) {
            let phString = saqData.sections[i].references[j].title;
            let referenceNumber = phString.split(" ");

            let newCategory = {
                ["categoryVisible" + referenceNumber[0]]: false,
            };
            let newCategoryButton = {
                ["categoryButton" + referenceNumber[0]]: <ArrowDropDownIcon />,
            };
            categoryCollapseDefault = { ...categoryCollapseDefault, ...newCategory };
            categoryCollapseButtonDefault = {
                ...categoryCollapseButtonDefault,
                ...newCategoryButton,
            };
        }
    }

    const [categoryCollapse, setCategoryCollapse] = React.useState(categoryCollapseDefault);
    const [categoryCollapseButton, setCategoryCollapseButton] = React.useState(
        categoryCollapseButtonDefault
    );
    const [activeStep, setActiveStep] = React.useState(0);

    const handleCategoryCollapse = index => {
        let phSectionCollapse = categoryCollapse["categoryVisible" + index];
        setCategoryCollapse({
            ...categoryCollapse,
            ["categoryVisible" + index]: !phSectionCollapse,
        });

        if (!phSectionCollapse) {
            setCategoryCollapseButton({
                ...categoryCollapseButton,
                ["categoryButton" + index]: <ArrowDropUpIcon />,
            });
        } else {
            setCategoryCollapseButton({
                ...categoryCollapseButton,
                ["categoryButton" + index]: <ArrowDropDownIcon />,
            });
        }
    };

    const handleStep = step => {
        setActiveStep(step);
    };

    let questionnaire = [];

    //  sections
    for (let i = 0; i < saqData.sections.length; i++) {
        //  references
        for (let j = 0; j < saqData.sections[i].references.length; j++) {
            let phString = saqData.sections[i].references[j].title;
            let referenceNumber = phString.split(" ");

            let counterColor = "";

            if (!short) {
                if (finishedQuestions[i][j] === saqData.sections[i].references[j].allQuestionsReq) {
                    counterColor = "#60b277";
                } else {
                    counterColor = "#000000";
                }
            } else {
                if (finishedQuestions[i][j] === saqData.sections[i].references[j].allQuestions) {
                    counterColor = "#60b277";
                } else {
                    counterColor = "#000000";
                }
            }

            questionnaire.push(
                <Grid
                    item
                    xs={12}
                    style={{ marginRight: "0px", paddingRight: "50px" }}
                    id={saqData.sections[i].references[j].title}
                >
                    <IconButton
                        style={{
                            backgroundColor: "transparent",
                        }}
                        className={classes.collapseButton}
                        disableRipple={true}
                        disableFocusRipple={true}
                        onClick={() => {
                            handleCategoryCollapse(referenceNumber[0]);
                        }}
                    >
                        <Grid container>
                            <Grid item xs={11}>
                                <Typography className={classes.category} align="left">
                                    {saqData.sections[i].references[j].title}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <div style={{ float: "right" }}>
                                    <Grid
                                        container
                                        style={{ marginLeft: "auto", marginRight: "0" }}
                                    >
                                        {
                                            categoryCollapseButton[
                                                "categoryButton" + referenceNumber[0]
                                            ]
                                        }
                                        <Typography
                                            display="inline"
                                            className={classes.category}
                                            style={{ color: counterColor }}
                                        >
                                            {finishedQuestions[i][j]}/
                                            {!short
                                                ? saqData.sections[i].references[j].allQuestionsReq
                                                : saqData.sections[i].references[j].allQuestions}
                                        </Typography>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </IconButton>
                </Grid>
            );
            //  questions
            for (let k = 0; k < saqData.sections[i].references[j].questions.length; k++) {
                let phString = saqData.sections[i].references[j].title;
                let referenceNumber = phString.split(" ");

                let qtype = saqData.sections[i].references[j].questions[k].checkType;
                let checkId = saqData.sections[i].references[j].questions[k].questionId;

                let nextId = null;

                if (!short) {
                    for (
                        let l = k + 1;
                        l < saqData.sections[i].references[j].questions.length;
                        l++
                    ) {
                        if (saqData.sections[i].references[j].questions[l].required) {
                            nextId = saqData.sections[i].references[j].questions[l].questionId;
                            break;
                        }
                    }

                    if (nextId === null) {
                        if (saqData.sections[i].references[j + 1]) {
                            nextId = saqData.sections[i].references[j + 1].title;
                        } else if (saqData.sections[i + 1]) {
                            nextId = "sectionStepper";
                        }
                    }
                } else {
                    if (saqData.sections[i].references[j].questions[k + 1]) {
                        nextId = saqData.sections[i].references[j].questions[k + 1].questionId;
                    } else if (saqData.sections[i].references[j + 1]) {
                        nextId = saqData.sections[i].references[j + 1].title;
                    } else if (saqData.sections[i + 1]) {
                        nextId = "sectionStepper";
                    }
                }

                if (qtype === "YES_NO") {
                    let groupKey =
                        saqData.sections[i].references[j].questions[k].questionId + ".radioControl";

                    if (saqData.sections[i].references[j].questions[k].required) {
                        questionnaire.push(
                            <Collapse
                                in={categoryCollapse["categoryVisible" + referenceNumber[0]]}
                                timeout={expandTime}
                            >
                                <SAYesNo
                                    nextId={nextId}
                                    groupKey={groupKey}
                                    check={saqData.sections[i].references[j].questions[k].check}
                                    checkId={checkId}
                                    value={answers[i][j][k]}
                                    partId={partId}
                                    handleSave={handleSave}
                                    id={saqData.id}
                                />
                            </Collapse>
                        );
                    } else {
                        questionnaire.push(
                            <Collapse
                                in={categoryCollapse["categoryVisible" + referenceNumber[0]]}
                                timeout={expandTime}
                            >
                                <Collapse in={short} timeout={expandTime}>
                                    <SAYesNo
                                        nextId={nextId}
                                        groupKey={groupKey}
                                        check={saqData.sections[i].references[j].questions[k].check}
                                        checkId={checkId}
                                        value={answers[i][j][k]}
                                        partId={partId}
                                        handleSave={handleSave}
                                        id={saqData.id}
                                    />
                                </Collapse>
                            </Collapse>
                        );
                    }
                } else if (qtype === "NUMBER") {
                    questionnaire.push(
                        <Collapse
                            in={categoryCollapse["categoryVisible" + referenceNumber[0]]}
                            timeout={expandTime}
                        >
                            <Collapse in={short} timeout={expandTime}>
                                <SANumber
                                    nextId={nextId}
                                    check={saqData.sections[i].references[j].questions[k].check}
                                    checkId={checkId}
                                    value={answers[i][j][k]}
                                    partId={partId}
                                    handleSave={handleSave}
                                    id={saqData.id}
                                />
                            </Collapse>
                        </Collapse>
                    );
                } else if (qtype === "LONG_TEXT") {
                    questionnaire.push(
                        <Collapse
                            in={categoryCollapse["categoryVisible" + referenceNumber[0]]}
                            timeout={expandTime}
                        >
                            <Collapse in={short} timeout={expandTime}>
                                <SALongText
                                    nextId={nextId}
                                    check={saqData.sections[i].references[j].questions[k].check}
                                    checkId={checkId}
                                    value={answers[i][j][k]}
                                    partId={partId}
                                    handleSave={handleSave}
                                    id={saqData.id}
                                />
                            </Collapse>
                        </Collapse>
                    );
                } else if (qtype === "TEXT") {
                    questionnaire.push(
                        <Collapse
                            in={categoryCollapse["categoryVisible" + referenceNumber[0]]}
                            timeout={expandTime}
                        >
                            <Collapse in={short} timeout={expandTime}>
                                <SAText
                                    nextId={nextId}
                                    check={saqData.sections[i].references[j].questions[k].check}
                                    checkId={checkId}
                                    value={answers[i][j][k]}
                                    partId={partId}
                                    handleSave={handleSave}
                                    id={saqData.id}
                                />
                            </Collapse>
                        </Collapse>
                    );
                }

                if (saqData.sections[i].references[j].questions[k + 1]) {
                    if (saqData.sections[i].references[j].questions[k + 1].required) {
                        questionnaire.push(
                            <Collapse
                                in={categoryCollapse["categoryVisible" + referenceNumber[0]]}
                                timeout={expandTime}
                            >
                                <hr className={classes.questionDivider} />
                            </Collapse>
                        );
                    } else {
                        questionnaire.push(
                            <Collapse
                                in={categoryCollapse["categoryVisible" + referenceNumber[0]]}
                                timeout={expandTime}
                            >
                                <Collapse in={short} timeout={expandTime}>
                                    <hr className={classes.questionDivider} />
                                </Collapse>
                            </Collapse>
                        );
                    }
                } else {
                    questionnaire.push(
                        <Collapse
                            in={categoryCollapse["categoryVisible" + referenceNumber[0]]}
                            timeout={expandTime}
                        >
                            <Grid container>
                                <Grid item xs={12} style={{ marginRight: "60px" }}>
                                    <Divider className={classes.hr} />
                                </Grid>
                            </Grid>
                        </Collapse>
                    );
                }
            }
        }
    }

    let stepContent = [];
    for (let index = 0; index < saqData.sections.length; index++) {
        stepContent.push([]);
        let contentCount = 0;
        for (let j = 0; j < saqData.sections[index].references.length; j++) {
            contentCount++;
            for (let k = 0; k < saqData.sections[index].references[j].questions.length; k++) {
                contentCount += 2;
            }
        }

        for (let counter = 0; counter < contentCount; counter++) {
            let itemsInArray = 0;
            for (let rowCounter = 0; rowCounter < stepContent.length - 1; rowCounter++) {
                itemsInArray += stepContent[rowCounter].length;
            }
            stepContent[index].push(
                <Grid item xs={12} key={counter + itemsInArray}>
                    {questionnaire[counter + itemsInArray]}
                </Grid>
            );
        }
    }

    const stepperContent = activeStep => {
        return stepContent[activeStep];
    };

    const icons = {
        1: <Icon icon={baselineVolunteerActivism} className={classes.volunteerActivism} />,
        2: <EuroSymbolIcon fontSize="large" />,
        3: <GroupIcon fontSize="large" />,
        4: <EcoIcon fontSize="large" />,
    };

    const useStepperStyle = makeStyles({
        root: {
            backgroundColor: "#cccccc",
            zIndex: 1,
            color: "#ffffff",
            width: 60,
            height: 60,
            display: "flex",
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
        },
        active: {
            backgroundColor: "#006fba",
        },
    });

    const stepperStyle = useStepperStyle();

    const stepperIcon = element => {
        const { active } = element;

        return (
            <div
                className={clsx(stepperStyle.root, {
                    [stepperStyle.active]: active,
                })}
            >
                {icons[String(element.icon)]}
            </div>
        );
    };

    let dateOptions = { weekday: "long", day: "numeric", month: "long", year: "numeric" };

    let lastModified = new Date(parseInt(saqData.lastModified)).toLocaleDateString(
        "en-US",
        dateOptions
    );
    let started = new Date(parseInt(saqData.started)).toLocaleDateString("en-US", dateOptions);

    let lastModifiedBy = saqData.lastModifiedBy;
    let startedBy = saqData.leadAuditor;

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState();
    const [message, setMessage] = useState();

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    return (
        <div className={classes.container}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Container maxWidth="xl">
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={12} sm={9}>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                    component="h2"
                                    className={classes.routeName}
                                >
                                    {routeName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                    component="h2"
                                    className={classes.title}
                                >
                                    {saqData.assessmentFor}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container alignItems="flex-end">
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    className={classes.buttons}
                                    justify="flex-end"
                                >
                                    <Grid item>
                                        <Link to={"/dashboard/cultural-route/" + routeId}>
                                            <Button
                                                size="medium"
                                                variant="outlined"
                                                color="primary"
                                                className="back-button"
                                            >
                                                <IconArrowBack className="back-button-icon" />
                                                Back to dashboard
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-end">
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid
                                item
                                xs={3}
                                sm={4}
                                md={5}
                                lg={6}
                                xl={7}
                                className={classes.subtitleContainer}
                            >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h5"
                                            color="textSecondary"
                                            className={classes.subtitle}
                                        >
                                            {saqData.questionnaireTitle}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9} sm={8} md={7} lg={6} xl={5}>
                                <Grid container>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item xs={2} sm={3} md={4} lg={5} xl={6}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                align="right"
                                            >
                                                Started:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10} sm={9} md={8} lg={7} xl={6}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                align="left"
                                            >
                                                <span className={classes.questionnaireInfo}>
                                                    {startedBy}
                                                </span>{" "}
                                                ({started})
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item xs={2} sm={3} md={4} lg={5} xl={6}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                align="right"
                                            >
                                                Last modified:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10} sm={9} md={8} lg={7} xl={6}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                align="left"
                                            >
                                                <span className={classes.questionnaireInfo}>
                                                    {lastModifiedBy}
                                                </span>{" "}
                                                ({lastModified})
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.questionnaireBackground} id="sectionStepper">
                    <Stepper
                        alternativeLabel
                        nonLinear
                        className={classes.stepper}
                        activeStep={activeStep}
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {};

                            return (
                                <Step key={label} {...stepProps}>
                                    <StepButton
                                        onClick={() => handleStep(index)}
                                        {...buttonProps}
                                        className={classes.stepButton}
                                    >
                                        <StepLabel StepIconComponent={stepperIcon}>
                                            {label}
                                        </StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <Grid container justify="center">
                        <Grid item xs={12} className={classes.progressBar}>
                            <center>
                                <LinearProgress
                                    variant="determinate"
                                    value={progress[activeStep]}
                                    style={{ width: "73%" }}
                                />
                            </center>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} style={{ marginRight: "60px" }}>
                            <Divider className={classes.hr} />
                        </Grid>
                    </Grid>
                    {stepperContent(activeStep)}
                </Grid>
            </Container>
        </div>
    );
}

function SelfAssessmentSource() {
    const [isLoading, setIsLoading] = useState(false);
    const [saqSource, setSaqSource] = useState(saq);
    const [routeName, setRouteName] = useState();
    const [routeId, setRouteId] = useState();
    let { partId } = useParams();
    let location = useLocation();

    useEffect(() => {
        setIsLoading(true);
        (async function fetchData() {
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                let currentUserIdToken = "";
                if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                    const currentUser = whoAmI();
                    if (currentUser) {
                        const idToken = await getToken();
                        if (idToken) {
                            currentUserIdToken = idToken;
                        }
                    }
                }
                routeevalApi
                    .get(`/routeeval/${partId}`, {
                        headers: { Authorization: "Bearer " + currentUserIdToken },
                    })
                    .then(response => {
                        setSaqSource(response.data);
                        setIsLoading(false);
                    });
            } else {
                setSaqSource(saq);
                setIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (location.state !== undefined) {
            setRouteName(location.state.detail);
            setRouteId(location.state.routeId);
        }
    }, [saqSource, location]);

    return (
        <>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <SelfAssessment
                    saqData={saqSource}
                    partId={partId}
                    routeName={routeName}
                    routeId={routeId}
                />
            )}
        </>
    );
}

export default SelfAssessmentSource;
