export const CulturalRoutesData = [
    {
        id: 1,
        name: "The path of sports legends of Maribor",
        description:
            "Exceptional achievements, passion and irresistible will. The path of sports legends of Maribor is full of inspiration for every visitor. It offers insight into their lives, social values and the specifics of their time. The current version of the trail is ready for the European Cemetery Heritage Days 2018 and we anticipate its further development in the coming years. Do you know other sports legends of Maribor who deserve their place on this trail?",
        routeType: "Thematic",
    },
    {
        id: 2,
        name: "War memories at Pobrežje cemetery",
        description: "Monuments reflecting the war times in Maribor region.",
        routeType: "Thematic",
    },
    {
        id: 3,
        name: "Cemetery Pobrežje (Maribor, Slovenia)",
        description:
            "Its calmness, tidy trails, green gardens and benches allow us to step back from the noisy city for a moment and think about memories in silence. Member of ASCE and part of European Cemeteries Route.",
        routeType: "Thematic",
    },
    {
        id: 4,
        name: "Dobrava Cemetery (Maribor, Slovenia)",
        description:
            "The Dobrava cemetery, which was originally designed as a green grassy area with smaller memorials, started operating in 1985.",
        routeType: "Thematic",
    },
    {
        id: 5,
        name: "Important people and monuments in the Dobrava cemetery",
        description:
            "Dobrava Cemetery is a unique area of cultural heritage, where we can find many cultural monuments and graves of some very important personalities from the life of modern Maribor.",
        routeType: "Thematic",
    },
    {
        id: 6,
        name: "Pobrežje Memorial Park",
        description:
            "The Pobrežje Memorial Park was opened in 1879. With its rich 130-year history, it represents a part of the historical and architectural image of the city of Maribor.",
        routeType: "Thematic",
    },
    {
        id: 8,
        name: "Their stories",
        description:
            "Soldiers and civilians, victims of two world wars. Maribor was marked by a large number of victims in both world wars. Both the locals who were dying for and because of the ideologies of the warring parties, and the foreigners whose life path ended violently here. The path of their stories leads us among some of the most important monuments of these wars. And to the graves of two athletes who had a string of world successes during the two world wars.",
        routeType: "Thematic",
    },
];

export const CulturalRoutesDataCountry = [{ sectionName: "Cemetery (Maribor, Slovenia)" }];
