import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#006fba",
        },
        secondary: {
            main: "#FBBE00", //TODO: update secondary color
        },
        text: {
            primary: "rgba(0, 0, 0, 0.9)",
            secondary: "rgba(0, 0, 0, 0.7)",
        },
    },
    typography: {
        fontFamily: "Roboto",
        h1: {
            fontFamily: "Montserrat",
        },
    },
    overrides: {
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "transparent",
                },
            },
        },
        MuiButton: {
            root: {
                fontSize: "0.938rem",
            },
            contained: {
                boxShadow: "none",
            },
        },
        MuiButtonBase: {
            root: {
                backgroundColor: "white",
            },
        },
        MuiDataGrid: {
            root: {
                backgroundColor: "white",
            },
        },
    },
});

export default theme;
