export const ParentRoutesObject = {
    "ATRIUM": "#87C045",
    "Aeneas Route": "#D84315",
    "Alvar Aalto Route": "#FF7043",
    "Cluniac Sites in Europe": "#EF6C00",
    "Cyril and Methodius Route": "#FFA726",
    "Destination Napoleon": "#FFB300",
    "European Cemeteries Route": "#FFEE58",
    "European Mozart Ways": "#9E9D24",
    "European Route d’Artagnan": "#CDDC39",
    "European Route of Ceramics": "#33691E",
    "European Route of Cistercian Abbeys": "#7CB342",
    "European Route of Historic Gardens": "#2E7D32",
    "European Route of Historic Thermal Towns": "#43A047",
    "European Route of Industrial Heritage": "#81C784",
    "European Route of Jewish Heritage": "#009688",
    "European Route of Megalithic Culture": "#00838F",
    "European Routes of Emperor Charles V": "#00BCD4",
    "Fortified Towns of the Grande Region": "#00E5FF",
    "Huguenot and Waldensian Trail": "#0277BD",
    "Impressionisms Routes": "#03A9F4",
    "In the Footsteps of Robert Louis Stevenson": "#4FC3F7",
    "Iron Age Danube Route": "#1565C0",
    "Iron Curtain Trail": "#03A9F4",
    "Iron Route in the Pyrenees": "#283593",
    "Iter Vitis Route": "#3F51B5",
    "Le Corbusier Destinations: Architectural Promenades": "#4527A0",
    "Liberation Route Europe": "#673AB7",
    "Phoenicians’ Route": "#AD1457",
    "Prehistoric Rock Art Trails": "#D81B60",
    "Roman Emperors and Danube Wine Route": "#B71C1C",
    "Route of Saint Olav Ways": "#F44336",
    "Routes of El legado andalusí": "#880E4F",
    "Routes of Reformation": "#F06292",
    "Routes of the Olive Tree": "#536DFE",
    "Réseau Art Nouveau Network": "#FF3D00",
    "Saint Martin of Tours Route": "#C8E6C9",
    "TRANSROMANICA": "#00BFA5",
    "The Hansa": "#FFFF00",
    "VIA REGIA": "#FF9E80",
    "Via Charlemagne": "#424242",
    "Via Francigena": "#66BB6A",
    "Via Habsburg": "#757575",
    "Via Romea Germanica": "#819595",
    "Viking Route": "#814995",
    "Santiago de Compostela Pilgrim Routes": "#812595",
    "Other": "#046FB8"
};
