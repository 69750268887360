export const CapacitiesLineGraphData = {
    dateTime: '2021-01-23T16:04:32.87+01:00',
    label: 'Number of establishments, bedrooms and bed-places by degree of urbanisation and by NUTS 2 regions (from 2012 onwards)',
    href: 'http://ec.europa.eu/eurostat/wdds/rest/data/v2.1/json/en/tour_cap_nuts2d?precision=1&deg_urb=DEG1&deg_urb=DEG2&deg_urb=DEG3&deg_urb=TOTAL&accommod=BEDPL&accommod=BEDRM&accommod=ESTBL&nace_r2=I551-I553',
    source: 'Eurostat',
    updated: '2020-10-22T22:00:00Z',
    extension: {
      datasetId: 'tour_cap_nuts2d',
      lang: 'EN',
      description: null,
      subTitle: null
    },
    value: [
      {
        name: 2012,
        ESTBL: 45740,
        BEDPL: null,
        BEDRM: null
      },
      {
        name: 2013,
        ESTBL: 46488,
        BEDPL: 3437362,
        BEDRM: null
      },
      {
        name: 2014,
        ESTBL: 47689,
        BEDPL: 3482983,
        BEDRM: null
      },
      {
        name: 2015,
        ESTBL: 48328,
        BEDPL: 3526937,
        BEDRM: null
      },
      {
        name: 2016,
        ESTBL: 48701,
        BEDPL: 3515810,
        BEDRM: null
      },
      {
        name: 2017,
        ESTBL: 50518,
        BEDPL: 3558650,
        BEDRM: null
      },
      {
        name: 2018,
        ESTBL: 51418,
        BEDPL: 3599886,
        BEDRM: null
      },
      {
        name: 2019,
        ESTBL: null,
        BEDPL: null,
        BEDRM: null
      }
    ],
    graphDimensions: [
      {
        label: 'Establishments',
        key: 'ESTBL'
      },
      {
        label: 'Bedplaces',
        key: 'BEDPL'
      },
      {
        label: 'Bedrooms',
        key: 'BEDRM'
      }
    ],
    dimensions: {
      unit: {
        label: 'unit',
        values: {
          NR: 'Number'
        }
      },
      nace_r2: {
        label: 'nace_r2',
        values: {
          'I551-I553': 'Hotels; holiday and other short-stay accommodation; camping grounds, recreational vehicle parks and trailer parks'
        }
      },
      deg_urb: {
        label: 'deg_urb',
        values: {
          TOTAL: 'Total'
        }
      },
      accommod: {
        label: 'accommod',
        values: {
          ESTBL: 'Establishments',
          BEDPL: 'Bedplaces',
          BEDRM: 'Bedrooms'
        }
      },
      geo: {
        label: 'geo',
        values: {
          ES13: 'Cantabria'
        }
      },
      time: {
        label: 'time',
        values: {
          '2012': '2012',
          '2013': '2013',
          '2014': '2014',
          '2015': '2015',
          '2016': '2016',
          '2017': '2017',
          '2018': '2018',
          '2019': '2019'
        }
      }
    },
    nuts: {
      nuts2: 'ES13',
      label: 'Cantabria'
    }
  }