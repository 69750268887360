import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "../User/Auth";
import AuthContext from "../../context/auth/AuthContext";
import { useContext } from "react";

const RoleProtectedRoute = ({ role, children, ...rest }) => {
    const Auth = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                Auth.isLoggedIn && Auth.role === role ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default RoleProtectedRoute;
