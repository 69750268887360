import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { FormControl, Chip, InputLabel, Select, MenuItem, Input } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const areas = ["Management", "Economic", "Socio-Cultural", "Environmental"];

function getStyles(name, inName, theme) {
    return {
        fontWeight:
            inName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const RouteIndicatorsBenchmark = ({ indicatorsAssAll, indicatorsLatest, allRoutes }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [years, setYears] = useState([]);
    const [parts, setParts] = useState([]);
    const [areaName, setAreaName] = useState([
        "Management",
        "Economic",
        "Socio-Cultural",
        "Environmental",
    ]);
    const [stateYear, setYear] = useState([]);
    const [statePart, setPart] = useState([]);
    const [firstPart, setFirstPart] = useState([]);

    const handleChangeArea = event => {
        setAreaName(event.target.value);
    };

    const handleChangeYear = event => {
        setYear(event.target.value);
    };

    const handleChangePart = event => {
        setPart(event.target.value);
    };

    const handleChangeFirstPart = event => {
        setFirstPart([event.target.value.at(-1)]);
    };

    const columnsStart = [
        { field: "indicatorReference", headerName: "ID", width: 90 },
        {
            field: "coreIndicators",
            headerName: "Indicator name",
            flex: 1,
            minWidth: 170,
        },
    ];

    useEffect(() => {
        indicatorsAssAll.map(indAss => {
            setYears(years => [...years, indAss.indicatorsYear]);
            setYears(years => [...new Set(years)]);
            allRoutes.map(route =>
                route.parts.map(part =>
                    indAss.assessmentSubject === part ? (indAss["routeName"] = route.title) : null
                )
            );
        });
        setParts([]);
        indicatorsAssAll
            .reduce(
                (entryMap, e) =>
                    entryMap.set(e.routeName, [...(entryMap.get(e.routeName) || []), e]),
                new Map()
            )
            .forEach((val, key) => {
                let names = [];
                val.reduce(
                    (entryMap, e) =>
                        entryMap.set(e.assessmentFor, [
                            ...(entryMap.get(e.assessmentFor) || []),
                            e,
                        ]),
                    new Map()
                ).forEach((val, key) => {
                    names = [...names, key];
                });
                setParts(parts => [...parts, { route: key, parts: names }]);
            });
        const concatParts = firstPart.concat(statePart);
        console.log(concatParts);
        const filterByPart = indicatorsAssAll.filter(indAss =>
            concatParts.includes(indAss.assessmentFor)
        );

        const sortedParts = [
            ...filterByPart.filter(item => item.assessmentFor === firstPart[0]),
            ...filterByPart.filter(item => item.assessmentFor !== firstPart[0]),
        ];

        const newIndAss = sortedParts.filter(indAss => stateYear.includes(indAss.indicatorsYear));

        setColumns(columnsStart);
        setRows([]);
        indicatorsLatest.map((ind, index) => {
            newIndAss.map(indAss => {
                ind[`${indAss.assessmentFor}-${indAss.indicatorsYear}`] =
                    indAss.indicators[index].value;
                ind[`${indAss.group}`] = indAss.indicators[index].group;
            });
            setRows(rows => [...rows, ind]);
        });
        newIndAss.map(indAss => {
            setColumns(columns => [
                ...columns,
                {
                    field: `${indAss.assessmentFor}-${indAss.indicatorsYear}`,
                    headerName: `${indAss.indicatorsYear}-${indAss.assessmentFor}-Value`,
                    width: indAss.assessmentFor.length + 170,
                    renderCell: params => <>{params.value.length ? params.value : "N/A"}</>,
                },
            ]);
        });
        setRows(rows => rows.filter(ind => areaName.includes(ind.group)));
    }, [stateYear, statePart, areaName, firstPart]);

    const renderSelectGroup = element => {
        const items = element.parts.map(part => {
            return (
                <MenuItem key={part} value={part} style={getStyles(part, statePart, theme)}>
                    {part}
                </MenuItem>
            );
        });
        return [<MenuItem disabled>{element.route}</MenuItem>, items];
    };

    return (
        <>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">Area</InputLabel>
                <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={areaName}
                    onChange={handleChangeArea}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={selected => (
                        <div className={classes.chips}>
                            {selected.map(value => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )}
                >
                    {areas.map(name => (
                        <MenuItem key={name} value={name} style={getStyles(name, areaName, theme)}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">Year</InputLabel>
                <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={stateYear}
                    onChange={handleChangeYear}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={selected => (
                        <div className={classes.chips}>
                            {selected.map(value => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )}
                >
                    {years.map(year => (
                        <MenuItem key={year} value={year} style={getStyles(year, stateYear, theme)}>
                            {year}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">Leading Part</InputLabel>
                <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={firstPart}
                    onChange={handleChangeFirstPart}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={selected => (
                        <div className={classes.chips}>
                            {selected.map(value => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )}
                >
                    {parts.map(e => renderSelectGroup(e))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">Other Parts</InputLabel>
                <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={statePart}
                    onChange={handleChangePart}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={selected => (
                        <div className={classes.chips}>
                            {selected.map(value => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )}
                >
                    {parts.map(e => renderSelectGroup(e))}
                </Select>
            </FormControl>
            <div style={{ height: 500, width: "100%", paddingTop: "16px" }}>
                <DataGrid rows={rows} getRowId={row => row.indicatorReference} columns={columns} />
            </div>
        </>
    );
};

export default RouteIndicatorsBenchmark;
