import LogoInterreg from "../../assets/img/logo_interreg.svg";
import LogoEurostat from "../../assets/img/logo_eurostat.png";
import LogoMitomed from "../../assets/img/interreg-mitomed-transparente.png";
import LogoUM from "../../assets/img/logo_um.png";
import { SocialMediaItems } from "./SocialMediaItems";

import { Divider, Grid, Hidden, Typography, Button } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

const buildId = process.env.REACT_APP_BUILD_ID;

function SocialMedia() {
    return (
        <>
            {SocialMediaItems.map(item => {
                return (
                    <a href={item.path} target="_blank" rel="noreferrer" key={item.id}>
                        <img src={item.icon} alt={item.title} className="footer-social-media" />
                    </a>
                );
            })}
        </>
    );
}

function Footer() {
    const { t } = useTranslation();
    let history = useHistory();

    return (
        <div>
            <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
                className="footer-container"
            >
                <Grid item xs={12} sm="auto" className="footer-container-logotype">
                    <Grid container justify="flexStart" spacing={1} alignItems="flex-start">
                        <Grid
                            item
                            xs={12}
                            sm="auto"
                            className="footer-container-logotype"
                            style={{ paddingTop: "0px" }}
                        >
                            <Typography className="footer-acknowledgment">Data sources:</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid item xs={12} sm="auto" className="footer-datasource-logotypes">
                            <a
                                href="https://mitomed-plus.interreg-med.eu/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={LogoMitomed}
                                    alt="Mitomed"
                                    className="footer-eurostat-logotype"
                                    style={{ height: "22px" }}
                                />
                            </a>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid item xs={12} sm="auto" className="footer-datasource">
                            <a
                                href="https://ec.europa.eu/eurostat"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={LogoEurostat}
                                    alt="Eurostat"
                                    className="footer-eurostat-logotype"
                                    style={{ height: "8px" }}
                                />
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden only={["xs", "sm"]}>
                    <Divider orientation="vertical" className="footer-divider" />
                </Hidden>
                <Grid item xs={12} sm="auto" className="footer-container-logotype">
                    <a href="https://www.um.si/" target="_blank" rel="noreferrer">
                        <img
                            src={LogoUM}
                            alt="UM"
                            className="footer-eurostat-logotype"
                            style={{ height: "50px" }}
                        />
                    </a>
                </Grid>
                <Hidden only={["xs", "sm"]}>
                    <Divider orientation="vertical" className="footer-divider" />
                </Hidden>
                <Grid item xs={12} sm="auto" className="footer-container-logotype">
                    <a href="https://best-med.interreg-med.eu/" target="_blank" rel="noreferrer">
                        <img
                            src={LogoInterreg}
                            alt="Interreg Mediterranean BEST MED"
                            className="footer-interreg-logotype"
                        />
                    </a>
                </Grid>
                <Hidden only={["xs", "sm"]}>
                    <Divider orientation="vertical" className="footer-divider" />
                </Hidden>
                <Grid item xs={12} sm={12} md="auto">
                    <Grid container direction="column" spacing={1}>
                        <Grid item className="footer-container-acknowledgment">
                            <Typography className="footer-acknowledgment">
                                {t("footer.acknowledgement.financier")}
                            </Typography>
                            <Typography className="footer-acknowledgment">
                                <Trans i18nKey="footer.acknowledgement.support">
                                    <a
                                        href="https://mitomedplus.andalucia.org/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Mitomed+
                                    </a>
                                    <a
                                        href="https://ec.europa.eu/eurostat"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Eurostat
                                    </a>
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item className="footer-container-social-media">
                            <SocialMedia />
                            
                            <div
                                onClick={() => history.push("/welcome-page")}
                                style={{
                                    backgroundColor: "#87c045",
                                    display: "inline-block",
                                    position: "relative",
                                    top: "-7px",
                                    height: "21px",
                                    borderRadius: "4px",
                                    cursor: "pointer"
                                }}
                            >
                
                                <Typography style={{
                                    lineHeight: "21px", 
                                    paddingLeft: "5px", 
                                    paddingRight: "5px", 
                                    fontSize: "12px",
                                    color: "white",
                                    fontWeight: "500"
                                }}
                                >
                                    {t("footer.welcomePage")}
                                </Typography>
                            </div>
            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className="footer-build-id-container">
                <Typography className="footer-build-id">Build ID: {buildId}</Typography>
            </Grid>
        </div>
    );
}

export default Footer;
