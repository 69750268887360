import { useTranslation } from "react-i18next";
import { Grid, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import AlertCancel from "../Dialogs/AlertCancel";
import { routesApi } from "../../services/api/axios";
import { AllHtmlEntities } from "html-entities";
import { whoAmI, getToken } from "../User/Auth";

import {
    Route,
    RouteImage,
    RouteLink,
    RouteContact,
    RouteLocation,
    RoutePart,
    RoutePoint,
    Point,
} from "./model/Route";

function FormAddCulturalRoute({
    titles,
    routeTitle,
    routeColor,
    routeVisible,
    routeType,
    routeDescription,
    routeImages,
    routeLinks,
    routeContacts,
    routeGeoLocation,
    routePartTitle,
    routePoints,
    validationMessage,
}) {
    const { t } = useTranslation();

    let history = useHistory();

    const handleSubmit = async e => {
        e.preventDefault();

        if (routeTitle) {
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                let currentUserIdToken = "";
                let currentUserEmail = "";
                if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                    const currentUser = whoAmI();
                    if (currentUser) {
                        currentUserEmail = currentUser;
                        const idToken = await getToken();
                        if (idToken) {
                            currentUserIdToken = idToken;
                        }
                    }
                }
                const formData = new FormData();

                if (routeImages !== null) {
                    let captions = [];
                    routeImages.forEach((file, index) => {
                        formData.append("File[" + index + "]", file.file);
                        captions.push(file.caption);
                    });
                    const response = await routesApi.post("/routes/upload", formData, {
                        headers: { Authorization: "Bearer " + currentUserIdToken },
                    });
                    if (response.data) {
                        const images = [];
                        if (routeImages !== null) {
                            response.data.forEach((id, index) => {
                                images.push(new RouteImage(id, captions[index]));
                            });
                        }
                        const points = [];
                        if (routePoints) {
                            routePoints.forEach(point => {
                                if (point.destinationId !== null) {
                                    points.push(
                                        new RoutePoint(
                                            point.destinationId,
                                            point.name,
                                            point.latitude,
                                            point.longitude,
                                            point.position
                                        )
                                    );
                                }
                            });
                        }
                        const links = [];
                        if (routeLinks !== null) {
                            routeLinks.forEach(link => {
                                if (link.caption !== "" || link.link !== "")
                                    links.push(new RouteLink(link.link, link.caption));
                            });
                        }

                        const contacts = [];
                        if (routeContacts !== null) {
                            routeContacts.forEach(contact => {
                                if (contact.type !== "" || contact.contact !== "")
                                    contacts.push(new RouteContact(contact.type, contact.contact));
                            });
                        }

                        const parts = [];
                        if (routePartTitle !== null) {
                            await Promise.all(
                                routePartTitle.map(async part => {
                                    if (part.title !== "") {
                                        const undef = "undefined";
                                        const partId = await routesApi.post(
                                            "/parts",
                                            JSON.stringify(
                                                new RoutePart(
                                                    part.title,
                                                    undef,
                                                    new RouteLocation(undef, undef, undef),
                                                    [new RouteImage(undef, undef)],
                                                    [new RouteLink(undef, undef)],
                                                    [
                                                        new Point(
                                                            undef,
                                                            undef,
                                                            new RouteLocation(undef, undef, undef),
                                                            [new RouteImage(undef, undef)],
                                                            [new RouteLink(undef, undef)]
                                                        ),
                                                    ]
                                                )
                                            ),
                                            {
                                                headers: {
                                                    Authorization: "Bearer " + currentUserIdToken,
                                                },
                                            }
                                        );
                                        parts.push(partId.data.id);
                                    }
                                })
                            ).then(async () => {
                                const route = new Route(
                                    routeTitle,
                                    routeColor,
                                    routeVisible !== null ? routeVisible : false,
                                    routeType,
                                    AllHtmlEntities.encode(routeDescription),
                                    images,
                                    links,
                                    contacts,
                                    new RouteLocation(
                                        routeGeoLocation.latitude +
                                            ", " +
                                            routeGeoLocation.longitude,
                                        routeGeoLocation.latitude.toString(),
                                        routeGeoLocation.longitude.toString()
                                    ),
                                    parts,
                                    points,
                                    currentUserEmail.email
                                );
                                const addRoute = await routesApi.post(
                                    "/routes",
                                    JSON.stringify(route),
                                    {
                                        headers: {
                                            Authorization: "Bearer " + currentUserIdToken,
                                        },
                                    }
                                );
                            });
                        } else {
                            const route = new Route(
                                routeTitle,
                                routeColor,
                                routeVisible !== null ? routeVisible : false,
                                routeType,
                                AllHtmlEntities.encode(routeDescription),
                                images,
                                links,
                                contacts,
                                new RouteLocation(
                                    routeGeoLocation.latitude + ", " + routeGeoLocation.longitude,
                                    routeGeoLocation.latitude.toString(),
                                    routeGeoLocation.longitude.toString()
                                ),
                                parts,
                                points,
                                currentUserEmail.email
                            );
                            const addRoute = await routesApi.post(
                                "/routes",
                                JSON.stringify(route),
                                {
                                    headers: {
                                        Authorization: "Bearer " + currentUserIdToken,
                                    },
                                }
                            );
                        }
                    }
                } else {
                    const images = [];

                    const links = [];
                    if (routeLinks !== null) {
                        routeLinks.forEach(link => {
                            if (link.caption !== "" || link.link !== "")
                                links.push(new RouteLink(link.link, link.caption));
                        });
                    }
                    const points = [];
                    if (routePoints) {
                        routePoints.forEach(point => {
                            if (point.destinationId !== null) {
                                points.push(
                                    new RoutePoint(
                                        point.destinationId,
                                        point.name,
                                        point.latitude,
                                        point.longitude,
                                        point.position
                                    )
                                );
                            }
                        });
                    }
                    const contacts = [];
                    if (routeContacts !== null) {
                        routeContacts.forEach(contact => {
                            if (contact.type !== "" || contact.contact !== "")
                                contacts.push(new RouteContact(contact.type, contact.contact));
                        });
                    }

                    const parts = [];
                    if (routePartTitle !== null) {
                        await Promise.all(
                            routePartTitle.map(async part => {
                                if (part.title !== "") {
                                    const undef = "undefined";
                                    const partId = await routesApi.post(
                                        "/parts",
                                        JSON.stringify(
                                            new RoutePart(
                                                part.title,
                                                undef,
                                                new RouteLocation(undef, undef, undef),
                                                [new RouteImage(undef, undef)],
                                                [new RouteLink(undef, undef)],
                                                [
                                                    new Point(
                                                        undef,
                                                        undef,
                                                        new RouteLocation(undef, undef, undef),
                                                        [new RouteImage(undef, undef)],
                                                        [new RouteLink(undef, undef)]
                                                    ),
                                                ]
                                            )
                                        ),
                                        {
                                            headers: {
                                                Authorization: "Bearer " + currentUserIdToken,
                                            },
                                        }
                                    );
                                    parts.push(partId.data.id);
                                }
                            })
                        ).then(async () => {
                            const route = new Route(
                                routeTitle,
                                routeColor,
                                routeVisible !== null ? routeVisible : false,
                                routeType,
                                AllHtmlEntities.encode(routeDescription),
                                images,
                                links,
                                contacts,
                                new RouteLocation(
                                    routeGeoLocation.latitude + ", " + routeGeoLocation.longitude,
                                    routeGeoLocation.latitude.toString(),
                                    routeGeoLocation.longitude.toString()
                                ),
                                parts,
                                points,
                                currentUserEmail.email
                            );
                            const addRoute = await routesApi.post(
                                "/routes",
                                JSON.stringify(route),
                                {
                                    headers: {
                                        Authorization: "Bearer " + currentUserIdToken,
                                    },
                                }
                            );
                        });
                    } else {
                        const route = new Route(
                            routeTitle,
                            routeColor,
                            routeVisible !== null ? routeVisible : false,
                            routeType,
                            AllHtmlEntities.encode(routeDescription),
                            images,
                            links,
                            contacts,
                            new RouteLocation(
                                routeGeoLocation.latitude + ", " + routeGeoLocation.longitude,
                                routeGeoLocation.latitude.toString(),
                                routeGeoLocation.longitude.toString()
                            ),
                            parts,
                            points,
                            currentUserEmail.email
                        )
                        const addRoute = await routesApi.post("/routes", JSON.stringify(route), {
                            headers: { Authorization: "Bearer " + currentUserIdToken },
                        });
                    }
                }
            }
            history.push(`/dashboard/`);
        } else validationMessage("forms.addCulturalRoute.requiredField");
    };

    const [modalOpen, setModalOpen] = useState(false);

    let existChanges =
        routeTitle ||
        routeColor ||
        routeDescription ||
        routeVisible === true ||
        routeType ||
        (routeImages !== null && routeImages.length > 0) ||
        (routeLinks !== null && routeLinks.length > 0) ||
        (routeContacts !== null && routeContacts.length > 0) ||
        (routeGeoLocation.latitude !== 45.9033 && routeGeoLocation.longitude !== 15.59111) ||
        (routePartTitle !== null && routePartTitle.length > 0);

    const handleCancel = () => {
        if (existChanges) setModalOpen(true);
        else history.push(`/dashboard/`);
    };

    const handleConfirmAction = () => {
        history.push(`/dashboard/`);
    };

    return (
        <>
            <Grid container alignItems="flex-end">
                <Grid item xs={12} sm={8}>
                    {titles && (
                        <Typography
                            variant="h5"
                            color="textSecondary"
                            className="title"
                            component="h2"
                        >
                            {t("forms.addCulturalRoute.pageTitle")}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={4} className="add-button-container">
                    <Grid container spacing={1} className="edit-delete-button">
                        <Grid item>
                            <Button
                                size="medium"
                                variant="contained"
                                color="primary"
                                className="add-button"
                                onClick={handleSubmit}
                            >
                                {t("forms.addCulturalRoute.add")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                size="medium"
                                variant="outlined"
                                color="primary"
                                className="cancel-button"
                                onClick={handleCancel}
                            >
                                {t("forms.addCulturalRoute.cancel")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AlertCancel
                confirmAction={handleConfirmAction}
                modalOpen={modalOpen}
                modalClose={modalClose => setModalOpen(modalClose)}
            />
        </>
    );
}

export default FormAddCulturalRoute;
