import React, { useState } from "react";
import {
    Grid,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import { ColorPicker, ColorButton } from 'material-ui-color';
import { useTranslation } from "react-i18next";
import { ParentRoutesList, ParentRoutesObject } from "../../data/Routes/ParentRoutes";

function InputParentRoute({ initialValue, routeColor, isReadOnly }) {
    const { t } = useTranslation();
    const [value, setValue] = useState(initialValue);
    const handleChange = (value) => {
        setValue("#" + value.hex);
        routeColor("#" + value.hex);
    };
    return (
        <>
            <div style={{ float: "left" }}>
                {isReadOnly ? (
                    <div className="colorButton">
                        <ColorButton color={initialValue} />
                    </div>

                ) : (
                    <div className="colorPicker">
                        <ColorPicker value={value} defaultValue={ParentRoutesObject["Other"]} hideTextfield onChange={handleChange} />
                    </div>

                )}
            </div>
            {isReadOnly ? ("") : (
                <Grid container style={{ width: "auto", marginTop: "39px" }}>
                    <Grid item xs={12} >
                        <FormControl variant="outlined" fullWidth>
                            <Select
                                labelId="parent-route-select-label"
                                id="parent-route-select"
                                value={value}
                                inputProps={{ readOnly: isReadOnly }}
                                style={{
                                    backgroundColor: "white",
                                }}
                                onChange={e => {
                                    setValue(e.target.value);
                                    routeColor(e.target.value);
                                    console.log(routeColor);
                                }}
                            >
                                {Object.keys(ParentRoutesObject).map((key, index) => (
                                    <MenuItem key={index} value={ParentRoutesObject[key]}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            )}

        </>
    );
}

export default InputParentRoute;
