import React, { useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function InputTextField({ initialValue, routeTitle, isReadOnly, validationMessage }) {
    const { t } = useTranslation();

    const [value, setValue] = useState(initialValue);
    return (
        <>
            <TextField
                variant="outlined"
                className={
                    validationMessage ? "input-text-field-validation-message" : "input-text-field"
                }
                value={value}
                onChange={e => {
                    setValue(e.target.value);
                    routeTitle(e.target.value);
                }}
                disabled={isReadOnly}
            />
            {validationMessage && (
                <Typography className="validation-message">{t(validationMessage)}</Typography>
            )}
        </>
    );
}

export default InputTextField;
