import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import ExportCSV, { csvToJson, generateCSV } from "../../utils/CsvExport";
import saq from "./SelfAssessmentQuestionnaire";
import * as htmlToImage from "html-to-image";
import { renderToStaticMarkup } from "react-dom/server";
import { exportXlsxStructure, exportXlsxContent } from "../../utils/XlsxExport";
import {
    Container,
    Grid,
    Typography,
    makeStyles,
    CircularProgress,
    Button,
    Switch,
    FormControlLabel,
    MenuItem,
    Menu,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import IconArrowBack from "@material-ui/icons/ArrowBack";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { routeevalApi } from "../../services/api/axios";
import { whoAmI, getToken } from "../User/Auth";

import StackedBarchart from "../Graphs/StackedBarChart";

const useStyles = makeStyles(theme => ({
    container: {
        marginLeft: "45px",
        marginRight: "30px",
        paddingBottom: "48px",
        paddingTop: "48px",
    },
    buttons: {
        marginLeft: "auto",
    },
    routeName: {
        fontWeight: "500 !important",
        fontSize: "0.95em !important",
        textTransform: "uppercase",
    },
    title: {
        fontWeight: "500 !important",
        fontSize: "1.625em !important",
        textTransform: "uppercase",
    },
    subtitle: {
        fontSize: "1.05em",
        fontWeight: "500 !important",
    },
    subtitleContainer: {
        marginTop: "auto",
    },
    questionnaireInfo: {
        fontWeight: "450",
    },
    questionnaireBackground: {
        padding: "40px",
        paddingRight: "10px",
        marginTop: "24px",
        marginBottom: "10px",
        backgroundColor: "#ffffff",
        border: "1px solid rgba(224, 224, 224, 1)",
    },
    graphBackground: {
        paddingRight: "10px",
        marginBottom: "10px",
        backgroundColor: "#ffffff",
        maxHeight: "600px",
        position: "-webkit - sticky",
        position: "sticky",
        top: 20,
        minHeight: "500px",
    },
    section: {
        fontWeight: "450",
    },
    category: {
        fontWeight: "425",
        marginLeft: "20px",
    },
    question: {
        marginLeft: "60px",
    },
    answer: {
        marginLeft: "60px",
    },
    questionWrapper: {
        marginBottom: "18px",
        marginRight: "50px",
    },
    appendix: {
        marginLeft: "60px",
        marginBottom: "5px",
    },
    multipleChoice: {
        marginTop: "0px",
        marginLeft: "40px",
    },
    na: {
        color: "#eb4034",
    },
    qa: {
        fontWeight: "450",
        marginRight: "5px",
    },
    qaMultipleChoice: {
        marginTop: "0px",
        marginLeft: "60px",
        listStyleType: "none",
    },
    qaAppendix: {
        marginRight: "5px",
    },
}));

const StyledMenu = styled(props => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(6),
        minWidth: 180,
        color: "#000000",
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                marginRight: theme.spacing(1.5),
            },
        },
    },
}));

function SelfAssessmentView(props) {
    const classes = useStyles();

    const saqData = props.saqData;
    const [short, setShort] = React.useState(!props.saqData.defaultShort);
    const [graphData, setGraphData] = React.useState(null);

    const [xlsMenu, setXlsMenu] = React.useState(false);
    const [docMenu, setDocMenu] = React.useState(false);
    const [csvMenu, setCsvMenu] = React.useState(false);
    const [anchor, setAnchor] = React.useState(null);

    const handleSwitch = () => {
        setShort(!short);
    };

    const handleXlsClose = () => {
        setXlsMenu(!xlsMenu);
        setAnchor(null);
    };

    const handleDocClose = () => {
        setDocMenu(!docMenu);
        setAnchor(null);
    };

    useEffect(() => {
        if (saqData) {
            var data = [];
            saqData.sections.forEach(section => {
                const all = section.yes + section.no + section.unanswered;
                var dataitem = {
                    name: section.title.substring(3),
                    yes: Math.round((section.yes / all) * 100),
                    no: Math.round((section.no / all) * 100),
                    unanswered: Math.round((section.unanswered / all) * 100),
                };
                data.push(dataitem);
            });
            setGraphData(data);
        }
    }, []);

    let answers = [];

    let history = useHistory();

    let exportContent = [],
        docSimple = [],
        docAdvanced = [];

    if (saqData) {
        //  Sections
        for (let i = 0; i < saqData.sections.length; i++) {
            answers.push(
                <Grid item xs={12} key={saqData.sections[i].title}>
                    <Typography className={classes.section}>{saqData.sections[i].title}</Typography>
                </Grid>
            );

            docSimple.push(
                <Grid item xs={12} key={saqData.sections[i].title}>
                    <Typography
                        className={classes.section}
                        style={{
                            fontWeight: "bold",
                            fontFamily: "Roboto",
                            fontSize: "1rem",
                            marginBottom: "0px",
                            paddingBottom: "0px",
                        }}
                    >
                        {saqData.sections[i].title}
                    </Typography>
                </Grid>
            );

            docAdvanced.push(
                <Grid item xs={12} key={saqData.sections[i].title}>
                    <Typography
                        className={classes.section}
                        style={{
                            fontWeight: "bold",
                            fontFamily: "Roboto",
                            fontSize: "1rem",
                            marginBottom: "0px",
                            paddingBottom: "0px",
                        }}
                    >
                        {saqData.sections[i].title}
                    </Typography>
                </Grid>
            );
            //  References
            for (let j = 0; j < saqData.sections[i].references.length; j++) {
                answers.push(
                    <Grid item xs={12} key={saqData.sections[i].references[j].title}>
                        <Typography className={classes.category}>
                            {saqData.sections[i].references[j].title}
                        </Typography>
                    </Grid>
                );

                docSimple.push(
                    <Grid item xs={12} key={saqData.sections[i].references[j].title}>
                        <Typography
                            className={classes.category}
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                marginLeft: "20px",
                                fontFamily: "Roboto",
                                marginBottom: "0px",
                                paddingBottom: "0px",
                                marginTop: "0px",
                            }}
                        >
                            {saqData.sections[i].references[j].title}
                        </Typography>
                    </Grid>
                );

                docAdvanced.push(
                    <Grid item xs={12} key={saqData.sections[i].references[j].title}>
                        <Typography
                            className={classes.category}
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                marginLeft: "20px",
                                fontFamily: "Roboto",
                                marginBottom: "0px",
                                paddingBottom: "0px",
                                marginTop: "0px",
                            }}
                        >
                            {saqData.sections[i].references[j].title}
                        </Typography>
                    </Grid>
                );
                //  Questions
                for (let k = 0; k < saqData.sections[i].references[j].questions.length; k++) {
                    exportContent.push({
                        ID: saqData.sections[i].references[j].questions[k].questionId,
                        answer: saqData.sections[i].references[j].questions[k].value
                            ? saqData.sections[i].references[j].questions[k].value
                            : "",
                    });

                    if (saqData.sections[i].references[j].questions[k].required) {
                        docSimple.push(
                            <Grid
                                item
                                xs={12}
                                className={classes.questionWrapper}
                                style={{
                                    marginBottom: "18px",
                                    marginRight: "50px",
                                }}
                                key={saqData.sections[i].references[j].questions[k].QuestionId}
                            >
                                <Typography
                                    className={classes.question}
                                    style={{
                                        marginLeft: "60px",
                                        fontFamily: "Calibri",
                                        marginBottom: "0px",
                                        paddingBottom: "0px",
                                        marginTop: "0px",
                                    }}
                                >
                                    <span
                                        className={classes.qa}
                                        style={{
                                            fontWeight: "bold",
                                            fontFamily: "Calibri",
                                            marginRight: "5px",
                                        }}
                                    >
                                        Q:
                                    </span>
                                    {" " + saqData.sections[i].references[j].questions[k].check}
                                </Typography>
                                <Typography
                                    className={classes.answer}
                                    style={{
                                        marginLeft: "60px",
                                        fontFamily: "Calibri",
                                        marginBottom: "0px",
                                        paddingBottom: "0px",
                                        marginTop: "0px",
                                    }}
                                >
                                    <span
                                        className={classes.qa}
                                        style={{
                                            fontWeight: "bold",
                                            marginRight: "5px",
                                        }}
                                    >
                                        A:
                                    </span>
                                    {saqData.sections[i].references[j].questions[k].value ? (
                                        " " + saqData.sections[i].references[j].questions[k].value
                                    ) : (
                                        <span style={{ color: "red" }}>{" N/A"}</span>
                                    )}
                                </Typography>
                            </Grid>
                        );
                    }

                    docAdvanced.push(
                        <Grid
                            item
                            xs={12}
                            className={classes.questionWrapper}
                            style={{
                                marginBottom: "18px",
                                marginRight: "50px",
                            }}
                            key={saqData.sections[i].references[j].questions[k].QuestionId}
                        >
                            <Typography
                                className={classes.question}
                                style={{
                                    marginLeft: "60px",
                                    fontFamily: "Calibri",
                                    marginBottom: "0px",
                                    paddingBottom: "0px",
                                    marginTop: "0px",
                                }}
                            >
                                <span
                                    className={classes.qa}
                                    style={{
                                        fontWeight: "bold",
                                        fontFamily: "Calibri",
                                        marginRight: "5px",
                                    }}
                                >
                                    Q:
                                </span>
                                {" " + saqData.sections[i].references[j].questions[k].check}
                            </Typography>
                            <Typography
                                className={classes.answer}
                                style={{
                                    marginLeft: "60px",
                                    fontFamily: "Calibri",
                                    marginBottom: "0px",
                                    paddingBottom: "0px",
                                    marginTop: "0px",
                                }}
                            >
                                <span
                                    className={classes.qa}
                                    style={{
                                        fontWeight: "bold",
                                        marginRight: "5px",
                                    }}
                                >
                                    A:
                                </span>
                                {saqData.sections[i].references[j].questions[k].value ? (
                                    " " + saqData.sections[i].references[j].questions[k].value
                                ) : (
                                    <span style={{ color: "red" }}>{" N/A"}</span>
                                )}
                            </Typography>
                        </Grid>
                    );

                    if (!short) {
                        if (saqData.sections[i].references[j].questions[k].required) {
                            let value;
                            let valueExport;
                            if (saqData.sections[i].references[j].questions[k].value) {
                                value = saqData.sections[i].references[j].questions[k].value;
                                valueExport = saqData.sections[i].references[j].questions[k].value;
                            } else {
                                value = <span className={classes.na}>N/A</span>;
                                valueExport = "N/A";
                            }

                            answers.push(
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.questionWrapper}
                                    key={saqData.sections[i].references[j].questions[k].QuestionId}
                                >
                                    <Typography className={classes.question}>
                                        <span className={classes.qa}>Q:</span>
                                        {saqData.sections[i].references[j].questions[k].check}
                                    </Typography>
                                    <Typography className={classes.answer}>
                                        <span className={classes.qa}>A:</span>
                                        {value}
                                    </Typography>
                                </Grid>
                            );
                        }
                    } else {
                        let value;
                        let valueExport;
                        if (saqData.sections[i].references[j].questions[k].value) {
                            value = saqData.sections[i].references[j].questions[k].value;
                            valueExport = saqData.sections[i].references[j].questions[k].value;
                        } else {
                            value = <span className={classes.na}>N/A</span>;
                            valueExport = "N/A";
                        }

                        answers.push(
                            <Grid
                                item
                                xs={12}
                                className={classes.questionWrapper}
                                key={saqData.sections[i].references[j].questions[k].QuestionId}
                            >
                                <Typography className={classes.question}>
                                    <span className={classes.qa}>Q:</span>
                                    {saqData.sections[i].references[j].questions[k].check}
                                </Typography>
                                <Typography className={classes.answer}>
                                    <span className={classes.qa}>A:</span>
                                    {value}
                                </Typography>
                            </Grid>
                        );
                    }
                }
            }
        }
    }

    let contentData = exportContent;

    const exportXls = event => {
        setXlsMenu(true);
        setAnchor(event.currentTarget);
    };

    const exportDoc = event => {
        setDocMenu(true);
        setAnchor(event.currentTarget);
    };

    const exportCsv = event => {
        setCsvMenu(true);
        setAnchor(event.currentTarget);
    };

    const exportXlsContent = () => {
        var node = document.getElementById("stacked-bar-chart");
        var height = node.offsetHeight;
        var width = node.offsetWidth;
        var newDimensions = calculateAspectRatioFit(width, height, 600, 600);
        htmlToImage
            .toPng(node)
            .then(function (dataUrl) {
                exportXlsxContent(
                    contentData,
                    props.routeName,
                    assessmentFor,
                    graphData,
                    dataUrl,
                    newDimensions
                );
            })
            .catch(function (error) {
                console.error("oops, something went wrong!", error);
            });
        handleXlsClose();
    };

    const exportXlsStructure = async () => {
        var csv = generateCSV(saqData, "Structure", props.routeName, assessmentFor, graphData);
        var json = JSON.parse(csvToJson(csv));
        exportXlsxStructure(json, props.routeName, assessmentFor);
        handleXlsClose();
    };

    function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
        var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return { width: srcWidth * ratio, height: srcHeight * ratio };
    }

    const exportDocSimple = () => {
        var documentHtml = document.getElementById("docSimple").innerHTML;
        var node = document.getElementById("stacked-bar-chart");
        var height = node.offsetHeight;
        var width = node.offsetWidth;
        var newDimensions = calculateAspectRatioFit(width, height, 600, 600);
        htmlToImage
            .toPng(node)
            .then(function (dataUrl) {
                documentHtml += renderToStaticMarkup(
                    <img src={dataUrl} width={newDimensions.width} height={newDimensions.height} />
                );
                var header = makeDocxHeader("Simplified");
                let preHtml =
                    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
                let postHtml = "</body></html>";

                let html = preHtml + header + documentHtml + postHtml;

                let blob = new Blob(["\ufeff", html], {
                    type: "application/msword",
                });

                let url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
                let downloadLink = document.createElement("a");

                document.body.appendChild(downloadLink);

                if (navigator.msSaveOrOpenBlob) {
                    navigator.msSaveOrOpenBlob(
                        blob,
                        `${props.routeName}_${assessmentFor}_Simple.doc`
                    );
                } else {
                    downloadLink.href = url;
                    downloadLink.download = `${props.routeName}_${assessmentFor}_Simple.doc`;
                    downloadLink.click();
                }

                document.body.removeChild(downloadLink);
            })
            .catch(function (error) {
                console.error("oops, something went wrong!", error);
            });
        handleDocClose();
    };

    const exportDocAdvanced = () => {
        var documentHtml = document.getElementById("docAdvanced").innerHTML;
        var node = document.getElementById("stacked-bar-chart");
        var height = node.offsetHeight;
        var width = node.offsetWidth;
        var newDimensions = calculateAspectRatioFit(width, height, 600, 600);
        htmlToImage
            .toPng(node)
            .then(function (dataUrl) {
                documentHtml += renderToStaticMarkup(
                    <img src={dataUrl} width={newDimensions.width} height={newDimensions.height} />
                );
                var header = makeDocxHeader("Advanced");
                let preHtml =
                    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
                let postHtml = "</body></html>";

                let html = preHtml + header + documentHtml + postHtml;

                let blob = new Blob(["\ufeff", html], {
                    type: "application/msword",
                });

                let url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
                let downloadLink = document.createElement("a");

                document.body.appendChild(downloadLink);

                if (navigator.msSaveOrOpenBlob) {
                    navigator.msSaveOrOpenBlob(
                        blob,
                        `${props.routeName}_${assessmentFor}_Advanced.doc`
                    );
                } else {
                    downloadLink.href = url;
                    downloadLink.download = `${props.routeName}_${assessmentFor}_Advanced.doc`;
                    downloadLink.click();
                }

                document.body.removeChild(downloadLink);

                handleDocClose();
            })
            .catch(function (error) {
                console.error("oops, something went wrong!", error);
            });
        handleDocClose();
    };

    const makeDocxHeader = (questionnaireType) => {
        return renderToStaticMarkup(
            <>
                <Typography
                    variant="body2"
                    color="primary"
                    component="h2"
                    className={classes.routeName}
                    style={{
                        color: "#3f51b5",
                        fontFamily: "Roboto",
                        fontWeight: "500 !important",
                        fontSize: "0.95em !important",
                        textTransform: "uppercase",
                        marginBottom: "0px"
                    }}
                >
                    {props.routeName}
                </Typography>
                <Typography
                    variant="body2"
                    color="primary"
                    component="h2"
                    className={classes.title}
                    style={{
                        color: "#3f51b5",
                        fontFamily: "Roboto",
                        fontWeight: "500 !important",
                        fontSize: "1.625em !important",
                        textTransform: "uppercase",
                        marginTop: "0px"
                    }}
                >
                    {assessmentFor}
                </Typography>

                <Typography
                    variant="h5"
                    color="textSecondary"
                    className={classes.subtitle}
                    style={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        color: "#3B3B3B",
                        marginBottom: "0px",
                        paddingBottom: "0px",
                    }}
                >
                    {questionnaireTitle}
                </Typography>
                <Typography
                    variant="p"
                    color="textSecondary"
                    className={classes.subtitle}
                    style={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        color: "#3B3B3B"
                    }}
                >
                    {questionnaireType} version of the questionnaire
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{
                        fontFamily: "Roboto",
                        marginBottom: "0px",
                        fontSize: "14px",
                        color: "#3B3B3B"
                    }}
                >
                    Started:
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{
                        fontFamily: "Roboto",
                        marginTop: "0px",
                        paddingTop: "0px",
                        fontSize: "14px",
                        color: "#3B3B3B"
                    }}
                >
                    <b>
                        {startedBy}
                    </b>{" "}
                    ({started})
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{
                        fontFamily: "Roboto",
                        marginBottom: "0px",
                        paddingBottom: "0px",
                        fontSize: "14px",
                        color: "#3B3B3B"
                    }}
                >
                    Last modified:
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{
                        fontFamily: "Roboto",
                        marginTop: "0px",
                        paddingTop: "0px",
                        fontSize: "14px",
                        color: "#3B3B3B"
                    }}
                >
                    <b>
                        {lastModifiedBy}
                    </b>{" "}
                    ({lastModified})
                </Typography>
                <hr style={{
                    border: "1px solid #707071"
                }}/>
            </>

        );
    }

    let assessmentFor, questionnaireTitle, lastModifiedBy, lastModified, started, startedBy;
    let dateOptions = { weekday: "long", day: "numeric", month: "long", year: "numeric" };

    if (saqData) {
        assessmentFor = saqData.assessmentFor;
        questionnaireTitle = saqData.questionnaireTitle;

        lastModifiedBy = saqData.lastModifiedBy;
        startedBy = saqData.leadAuditor;
        lastModified = new Date(parseInt(saqData.lastModified)).toLocaleDateString(
            "en-US",
            dateOptions
        );
        started = new Date(parseInt(saqData.started)).toLocaleDateString("en-US", dateOptions);
    }

    return (
        <div className={classes.container}>
            <Container maxWidth="xl">
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={12} sm={9}>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                    component="h2"
                                    className={classes.routeName}
                                >
                                    {props.routeName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                    component="h2"
                                    className={classes.title}
                                >
                                    {assessmentFor}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container alignItems="flex-end">
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={1}
                                    className={classes.buttons}
                                    justify="flex-end"
                                >
                                    <Grid item>
                                        <Button
                                            size="medium"
                                            variant="outlined"
                                            color="primary"
                                            className="back-button"
                                            onClick={() => history.goBack()}
                                        >
                                            <IconArrowBack className="back-button-icon" />
                                            Back to dashboard
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            color="primary"
                                            className="edit-button"
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={exportCsv}
                                            endIcon={<KeyboardArrowDownIcon />}
                                        >
                                            Export CSV
                                        </Button>
                                        <StyledMenu
                                            anchorEl={anchor}
                                            open={csvMenu}
                                            onClose={() => setCsvMenu(false)}
                                        >
                                            <MenuItem disableRipple>
                                                <ExportCSV
                                                    saqData={saqData}
                                                    type={"Structure"}
                                                    routeName={props.routeName}
                                                    assFor={assessmentFor}
                                                    graphData={graphData}
                                                />
                                            </MenuItem>
                                            <MenuItem disableRipple>
                                                <ExportCSV
                                                    saqData={saqData}
                                                    type={"Content"}
                                                    routeName={props.routeName}
                                                    assFor={assessmentFor}
                                                    graphData={graphData}
                                                />
                                            </MenuItem>
                                        </StyledMenu>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            color="primary"
                                            className="edit-button"
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={exportXls}
                                            endIcon={<KeyboardArrowDownIcon />}
                                        >
                                            Export XLS
                                        </Button>
                                        <StyledMenu
                                            anchorEl={anchor}
                                            open={xlsMenu}
                                            onClose={handleXlsClose}
                                        >
                                            <MenuItem onClick={exportXlsStructure} disableRipple>
                                                Structure
                                            </MenuItem>
                                            <MenuItem onClick={exportXlsContent} disableRipple>
                                                Content
                                            </MenuItem>
                                        </StyledMenu>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            color="primary"
                                            className="edit-button"
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={exportDoc}
                                            endIcon={<KeyboardArrowDownIcon />}
                                        >
                                            Export DOC
                                        </Button>
                                        <StyledMenu
                                            anchorEl={anchor}
                                            open={docMenu}
                                            onClose={handleDocClose}
                                        >
                                            <MenuItem onClick={exportDocSimple} disableRipple>
                                                Simple
                                            </MenuItem>
                                            <MenuItem onClick={exportDocAdvanced} disableRipple>
                                                Advanced
                                            </MenuItem>
                                        </StyledMenu>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container alignItems="flex-end">
                    <Grid item xs={12} className={classes.extraInfo}>
                        <Grid container>
                            <Grid
                                item
                                xs={3}
                                sm={4}
                                md={5}
                                lg={6}
                                xl={7}
                                className={classes.subtitleContainer}
                            >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h5"
                                            color="textSecondary"
                                            className={classes.subtitle}
                                        >
                                            {questionnaireTitle}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={short}
                                                    onChange={handleSwitch}
                                                    name="shortLongSwitch"
                                                    inputProps={{ "aria-label": "shortLongSwitch" }}
                                                />
                                            }
                                            label="Show advanced version of the questionnaire"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9} sm={8} md={7} lg={6} xl={5}>
                                <Grid container>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item xs={2} sm={3} md={4} lg={5} xl={6}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                align="right"
                                            >
                                                Started:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10} sm={9} md={8} lg={7} xl={6}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                align="left"
                                            >
                                                <span className={classes.questionnaireInfo}>
                                                    {startedBy}
                                                </span>{" "}
                                                ({started})
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item xs={2} sm={3} md={4} lg={5} xl={6}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                align="right"
                                            >
                                                Last modified:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10} sm={9} md={8} lg={7} xl={6}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                align="left"
                                            >
                                                <span className={classes.questionnaireInfo}>
                                                    {lastModifiedBy}
                                                </span>{" "}
                                                ({lastModified})
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={2}
                    className={classes.questionnaireBackground}
                    id="scrollarea"
                >
                    <Grid item xs={12} lg={8} id="self-assessment-answers">
                        {answers}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={4}
                        className={classes.graphBackground}
                        id="self-assessment-summary"
                    >
                        {graphData ? <StackedBarchart data={graphData} /> : ""}
                    </Grid>
                </Grid>
            </Container>

            <div id="docSimple" style={{ display: "none" }}>
                {docSimple}
            </div>

            <div id="docAdvanced" style={{ display: "none" }}>
                {docAdvanced}
            </div>
        </div>
    );
}

function SelfAssessmentViewSource() {
    const [isLoading, setIsLoading] = useState(false);
    const [saqSource, setSaqSource] = useState(saq);
    const [routeName, setRouteName] = useState();
    let { partId } = useParams();
    let location = useLocation();

    useEffect(() => {
        setIsLoading(true);
        (async function fetchData() {
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                let currentUserIdToken = "";
                if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                    const currentUser = whoAmI();
                    if (currentUser) {
                        const idToken = await getToken();
                        if (idToken) {
                            currentUserIdToken = idToken;
                        }
                    }
                }
                routeevalApi
                    .get(`/routeeval/${partId}`, {
                        headers: { Authorization: "Bearer " + currentUserIdToken },
                    })
                    .then(response => {
                        setSaqSource(response.data);
                        setIsLoading(false);
                    });
            } else {
                //  Error
            }
        })();
    }, []);

    useEffect(() => {
        if (location.state !== undefined) {
            setRouteName(location.state.detail);
        }
    }, [saqSource, location]);

    return (
        <>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <SelfAssessmentView saqData={saqSource} partId={partId} routeName={routeName} />
            )}
        </>
    );
}

export default SelfAssessmentViewSource;
