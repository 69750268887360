import React, { useState } from "react";
import ReactQuill from "react-quill";

function InputTextEditor({ initialValue, routeDescription, isReadOnly }) {
    const [value, setValue] = useState(initialValue);

    const modules = {
        toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    const modulesNoToolbar = {
        toolbar: false,
        clipboard: {
            matchVisual: false,
        },
    };

    const formats = ["bold", "italic", "underline", "list", "bullet", "link"];

    return (
        <ReactQuill
            theme="snow"
            modules={isReadOnly ? modulesNoToolbar : modules}
            formats={formats}
            value={value}
            className={isReadOnly ? "input-text-editor-read-only" : "input-text-editor"}
            readOnly={isReadOnly}
            onChange={value => {
                setValue(value);
                routeDescription(value !== "<p><br></p>" ? value : null);
            }}
        />
    );
}

export default InputTextEditor;
