import { Grid, Typography, Divider } from "@material-ui/core";

function FormInputLayout({ label, instructions, input, isReadOnly }) {
    return (
        <>
            <Divider className="input-divider" />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Typography variant="body2" color="textSecondary" className="form-input-label">
                        {label}
                    </Typography>
                    {isReadOnly ? null : (
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            className="form-input-instructions"
                        >
                            {instructions}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={9}>
                    {input}
                </Grid>
            </Grid>
        </>
    );
}

export default FormInputLayout;
