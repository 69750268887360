import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import {
    Button,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Snackbar,
} from "@material-ui/core";
import { FiberManualRecord as IconCircle } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routeevalApi, usersApi } from "../../services/api/axios";
import { whoAmI, getToken } from "../User/Auth";

import AlertDelete from "../Dialogs/AlertDelete";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function formatDate(date, currentLanguage) {
    let dateOptions = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(currentLanguage, dateOptions);
}

function InputRouteEvaluation({ routeParts, routeName, routeAuthor }) {
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language;

    const { routeId } = useParams();

    const [routeSelfAssessmentData, setRouteSelfAssessmentData] = useState([]);
    const [accordionContent, setAccordionContent] = React.useState("");
    const [isRouteAuthor, setIsRouteAuthor] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    console.log(routeSelfAssessmentData);

    let history = useHistory();

    const handleEdit = data => {
        history.push({
            pathname: `/questionnaire/${data.idQuestionnaire}`,
            state: { detail: routeName, routeId: routeId },
        });
    };

    const handleResults = data => {
        if (data.status !== "Not started") {
            history.push({
                pathname: `/questionnaire/answers/${data.idQuestionnaire}`,
                state: { detail: routeName },
            });
        }
    };

    const handleShort = async data => {
        if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
            let currentUserIdToken = "";
            let currentUser = "";
            if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                currentUser = whoAmI();
                if (currentUser) {
                    const idToken = await getToken();
                    if (idToken) {
                        currentUserIdToken = idToken;
                    }
                }
            } else {
                currentUser = JSON.parse(localStorage.getItem("user"));
            }
            const result = await routeevalApi.post(
                "/routeeval/create",
                {
                    id: `${data.id}`, //just for fun
                    LeadAuditor: currentUser.email, //get current user
                    Started: `${Date.now()}`,
                    LastModified: `${Date.now()}`,
                    LastModifiedBy: currentUser.email, //get current user
                    Status: "STARTED",
                    AssessmentFor: data.name,
                    AssessmentSubject: `${data.id}`,
                    defaultShort: true,
                },
                { headers: { Authorization: "Bearer " + currentUserIdToken } }
            );
            history.push({
                pathname: `/questionnaire/${result.data.id}`,
                state: { detail: routeName, routeId: routeId },
            });
        }
    };

    const handleLong = async data => {
        if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
            let currentUserIdToken = "";
            let currentUser = "";
            if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                currentUser = whoAmI();
                if (currentUser) {
                    const idToken = await getToken();
                    if (idToken) {
                        currentUserIdToken = idToken;
                    }
                }
            } else {
                currentUser = JSON.parse(localStorage.getItem("user"));
            }
            const result = await routeevalApi.post(
                "/routeeval/create",
                {
                    id: `${data.id}`, //just for fun
                    LeadAuditor: currentUser.email, //get current user
                    Started: `${Date.now()}`,
                    LastModified: `${Date.now()}`,
                    LastModifiedBy: currentUser.email, //get current user
                    Status: "STARTED",
                    AssessmentFor: data.name,
                    AssessmentSubject: `${data.id}`,
                    defaultShort: false,
                },
                { headers: { Authorization: "Bearer " + currentUserIdToken } }
            );
            history.push({
                pathname: `/questionnaire/${result.data.id}`,
                state: { detail: routeName, routeId: routeId },
            });
        }
    };

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState();
    const [message, setMessage] = useState();

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const [deleteData, setDeleteData] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = data => {
        setModalOpen(true);
        setDeleteData(data);
    };

    const deleteQuestionnaire = async () => {
        let currentUserIdToken = "";
        let currentUser = "";
        if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
            currentUser = whoAmI();
            if (currentUser) {
                const idToken = await getToken();
                if (idToken) {
                    currentUserIdToken = idToken;
                }
            }
        } else {
            currentUser = JSON.parse(localStorage.getItem("user"));
        }
        routeevalApi
            .delete(`/routeeval/${deleteData.idQuestionnaire}`, {
                headers: { Authorization: "Bearer " + currentUserIdToken },
            })
            .then(response => {
                if (response.status === 200) {
                    setSeverity("success");
                    setMessage(t("messageRouteeval.successDelete"));
                    setModalOpen(false);
                    setDeleteData(null);
                    if (
                        routeSelfAssessmentData.filter(evaluation => evaluation.id == deleteData.id)
                            .length === 1
                    ) {
                        console.log("last one");
                        let newHeaders = routeSelfAssessmentData.filter(
                            evaluation => evaluation.idQuestionnaire !== deleteData.idQuestionnaire
                        );
                        newHeaders.push({
                            id: deleteData.id,
                            name: deleteData.name,
                            status: "Not started",
                            started: "/",
                            lastModified: "/",
                        });
                        newHeaders.sort((a, b) => a.name.localeCompare(b.name));
                        setRouteSelfAssessmentData(newHeaders);
                    } else {
                        setRouteSelfAssessmentData(
                            routeSelfAssessmentData.filter(
                                evaluation =>
                                    evaluation.idQuestionnaire !== deleteData.idQuestionnaire
                            )
                        );
                    }
                } else {
                    setSeverity("error");
                    setMessage(t("messageRouteeval.errorDelete"));
                }
                setOpen(true);
            });
    };

    useEffect(() => {
        let phAccordion = [],
            routePartId = [];
        for (let i = 0; i < routeSelfAssessmentData.length; i++) {
            let tableRows = [];
            for (let j = 0; j < routeSelfAssessmentData.length; j++) {
                if (routeSelfAssessmentData[i].id === routeSelfAssessmentData[j].id) {
                    if (routeSelfAssessmentData[j].status !== "Not started") {
                        if (isAdmin) {
                            routeSelfAssessmentData[j].isAuthor = true;
                        }
                        tableRows.push(
                            <TableRow>
                                <TableCell align="left">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        className={`self-assessment-status-${routeSelfAssessmentData[
                                            j
                                        ].status.replace(/ /g, "-")}`}
                                        disabled
                                    >
                                        <IconCircle className="self-assessment-status-indicator" />
                                        <span className="self-assessment-status-text">
                                            {routeSelfAssessmentData[j].status}
                                        </span>
                                    </Button>
                                </TableCell>
                                <TableCell align="left">
                                    {routeSelfAssessmentData[j].started instanceof Date
                                        ? formatDate(
                                              routeSelfAssessmentData[j].started,
                                              currentLanguage
                                          )
                                        : ""}
                                </TableCell>
                                <TableCell align="left">
                                    {routeSelfAssessmentData[j].started instanceof Date
                                        ? formatDate(
                                              routeSelfAssessmentData[j].lastModified,
                                              currentLanguage
                                          )
                                        : ""}
                                </TableCell>
                                <TableCell align="left">
                                    {routeSelfAssessmentData[j].isAuthor ? (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={() => handleEdit(routeSelfAssessmentData[j])}
                                        >
                                            <EditIcon fontSize="small" />
                                            <span style={{ marginLeft: "3px" }}>Continue</span>
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </TableCell>
                                <TableCell align="left">
                                    {routeSelfAssessmentData[j].status === "Not started" ? (
                                        <></>
                                    ) : routeSelfAssessmentData[j].isAuthor ? (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={() =>
                                                handleResults(routeSelfAssessmentData[j])
                                            }
                                        >
                                            <VisibilityIcon fontSize="small" />
                                            <span style={{ marginLeft: "3px" }}>Results</span>
                                        </Button>
                                    ) : isRouteAuthor ? (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            onClick={() =>
                                                handleResults(routeSelfAssessmentData[j])
                                            }
                                        >
                                            <VisibilityIcon fontSize="small" />
                                            <span style={{ marginLeft: "3px" }}>Results</span>
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </TableCell>
                                <TableCell align="left">
                                    {routeSelfAssessmentData[j].status === "Not started" ? (
                                        <></>
                                    ) : routeSelfAssessmentData[j].isAuthor ? (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            className="input-route-self-assessment-delete"
                                            onClick={() =>
                                                handleModalOpen(routeSelfAssessmentData[j])
                                            }
                                        >
                                            <HighlightOffIcon fontSize="small" />
                                            <span style={{ marginLeft: "3px" }}>Delete</span>
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    }
                }
            }

            if (!routePartId.includes(routeSelfAssessmentData[i].id)) {
                routePartId.push(routeSelfAssessmentData[i].id);
                phAccordion.push(
                    <Accordion key={routeSelfAssessmentData[i].id}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={routeSelfAssessmentData[i].id}
                            id={routeSelfAssessmentData[i].id}
                        >
                            <Typography style={{ fontSize: "14px", fontWeight: "425" }}>
                                {routeSelfAssessmentData[i].name}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ paddingTop: "0px", paddingBottom: "24px" }}>
                            <Grid container justify="center" spacing={1}>
                                <Grid container style={{ marginBottom: "24px" }}>
                                    <Grid item xs={12}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Started</TableCell>
                                                    <TableCell>Last modified</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            size="small"
                                                            className="self-assessment-start-short"
                                                            onClick={() =>
                                                                handleShort(
                                                                    routeSelfAssessmentData[i]
                                                                )
                                                            }
                                                        >
                                                            <AddCircleIcon fontSize="small" />
                                                            <span style={{ marginLeft: "3px" }}>
                                                                Start simplified
                                                            </span>
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            size="small"
                                                            className="self-assessment-start-long"
                                                            onClick={() =>
                                                                handleLong(
                                                                    routeSelfAssessmentData[i]
                                                                )
                                                            }
                                                        >
                                                            <AddCircleIcon fontSize="small" />
                                                            <span style={{ marginLeft: "3px" }}>
                                                                Start advanced
                                                            </span>
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>{tableRows}</TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            }
        }

        setAccordionContent(phAccordion);
    }, [routeSelfAssessmentData]);

    useEffect(async () => {
        let routeSelfAssessment = [];
        await Promise.all(
            routeParts.map(async routePart => {
                if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                    let currentUserIdToken = "";
                    let currentUser = "";
                    if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                        currentUser = whoAmI();
                        if (currentUser) {
                            let email = currentUser.email;
                            if (email === routeAuthor) {
                                setIsRouteAuthor(true);
                            } else if (!routeAuthor) {
                                setIsRouteAuthor(true);
                            }
                            const idToken = await getToken();
                            if (idToken) {
                                currentUserIdToken = idToken;
                                const user = await usersApi.get(`/${email}`, {
                                    headers: {
                                        Authorization: "Bearer " + idToken,
                                    },
                                });
                                if (user.data.role === "admin") {
                                    setIsAdmin(true);
                                }
                            }
                        }
                    }
                    await routeevalApi
                        .get(`/routeeval/header/${routePart.id}`, {
                            headers: { Authorization: "Bearer " + currentUserIdToken },
                        })
                        .then(res => {
                            if (res.status === 200 && res.data.length) {
                                console.log(res.data);
                                res.data.map(evaluation => {
                                    routeSelfAssessment.push({
                                        id: routePart.id,
                                        name: routePart.title,
                                        status: evaluation.status,
                                        started: new Date(parseInt(evaluation.started)),
                                        lastModified: new Date(parseInt(evaluation.lastModified)),
                                        idQuestionnaire: evaluation.id,
                                        isAuthor:
                                            currentUser.email === evaluation.leadAuditor
                                                ? true
                                                : false,
                                    });
                                });
                            } else {
                                routeSelfAssessment.push({
                                    id: routePart.id,
                                    name: routePart.title,
                                    status: "Not started",
                                    started: "/",
                                    lastModified: "/",
                                });
                            }
                            routeSelfAssessment.sort((a, b) => a.name.localeCompare(b.name));
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }
            })
        ).then(() => {
            setRouteSelfAssessmentData(routeSelfAssessment);
        });
    }, [routeParts]);

    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <div className="table-container">{accordionContent}</div>
            <AlertDelete
                confirmAction={deleteQuestionnaire}
                modalOpen={modalOpen}
                modalClose={modalClose => setModalOpen(modalClose)}
                textTitle={t("forms.viewCulturalRoute.deleteEvaluation.title")}
                textDescription={t("forms.viewCulturalRoute.deleteEvaluation.description")}
                textDelete={t("forms.viewCulturalRoute.deleteEvaluation.delete")}
                textCancel={t("forms.viewCulturalRoute.deleteEvaluation.cancel")}
            />
        </>
    );
}

export default InputRouteEvaluation;
