import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    makeStyles,
    Typography,
    IconButton,
    Tooltip,
    Button,
    ButtonGroup,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    CircularProgress,
    Menu,
    Fab,
} from "@material-ui/core";
import DestinationCardLineGraph from "./DestinationCardLineGraph";

import { ActiveEnterprisesLineGraphData } from "../../data/Eurostat/ActiveEnterprises";
import { ActiveEnterprisesEmployeesLineGraphData } from "../../data/Eurostat/ActiveEnterprisesEmployees";
import { AnnualOccupancyCoastalLineGraphData } from "../../data/Eurostat/AnnualOccupancyCoastal";
import { AnnualOccupancyUrbanisationLineGraphData } from "../../data/Eurostat/AnnualOccupancyUrbanisation";
import { ArrivalsLineGraphData } from "../../data/Eurostat/Arrivals";
import { ArrivalsMonthlyLineGraphData } from "../../data/Eurostat/ArrivalsMonthly";
import { CapacitiesLineGraphData } from "../../data/Eurostat/Capacities";
import { EmployedPersonsLineGraphData } from "../../data/Eurostat/EmployedPersons";
import { GenerationOfWasteLineGraphData } from "../../data/Eurostat/GenerationOfWaste";
import { VisitsLineGraphData } from "../../data/Eurostat/Visits";

import { eurostatApi } from "../../services/api/axios";

import BarChartIcon from "@material-ui/icons/BarChart";
import TimelineIcon from "@material-ui/icons/Timeline";
import DestinationCardBarGraph from "./DestinationCardBarGraph";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';

const useStyles = makeStyles(theme => ({
    axisValues: {
        fontSize: "0.8rem",
        fontFamily: "Roboto",
        color: "#dfdfdf",
    },
    tooltipCard: {
        padding: "5px",
    },
    graphWrapper: {
        width: "100%",
    },
    selectListItemText: {
        fontSize: "13px",
        textAlign: "center",
        fontWeight: "500",
    },
    menuItem: {
        fontSize: "13px",
    },
    buttonGroupWrapper: {
        alignItems: "center",
        width: "100%",
    },
    buttonGroup: {
        alignItems: "center",
        width: "100%",
    },
    buttonGroupButton: {
        width: "50%",
        textTransform: "capitalize",
        fontSize: "14px",
    },
    buttonGroupButtonSelected: {
        color: "var(--dark-blue)",
    },
    listItem: {
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingTop: '0px',
        paddingBottom: '0px'
    },
    graphSettingsButton: {
        float: 'right',
        transition: 'all 0.4s ease',
        "&:hover": {
            backgroundColor: 'lightgrey'
        }
    },
    graphInfoButton: {
        float: 'right',
        transition: 'all 0.4s ease',
        "&:hover": {
            backgroundColor: 'lightgrey'
        }
    },
    graphTitleBar: {
        textAlign: 'center',
        paddingLeft: '48px'

    },
    graphTitle: {
        /*display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'*/
    }
}));

function DestinationDetailsGraphHolder({ selectedDestination, graphHeight, handleDialogOpen, setCurrentDataset }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [arrivalsGraphData, setArrivalsGraphData] = useState(null);
    const [isArrivalsDataLoaded, setIsArrivalsDataLoaded] = useState(false);

    const [capacitiesGraphData, setCapacitiesGraphData] = useState(null);
    const [isCapacitiesDataLoaded, setIsCapacitiesDataLoaded] = useState(false);

    const [visitsGraphData, setVisitsGraphData] = useState(null);
    const [isVisitsDataLoaded, setIsVisitsDataLoaded] = useState(false);

    const [activeEnterprisesGraphData, setActiveEnterprisesGraphData] = useState(null);
    const [isActiveEnterprisesDataLoaded, setIsActiveEnterprisesDataLoaded] = useState(false);

    const [activeEnterprisesEmployeesGraphData, setActiveEnterprisesEmployeesGraphData] = useState(
        null
    );
    const [
        isActiveEnterprisesEmployeesDataLoaded,
        setIsActiveEnterprisesEmployeesDataLoaded,
    ] = useState(false);

    const [annualOccupancyCoastalGraphData, setAnnualOccupancyCoastalGraphData] = useState(null);
    const [
        isAnnualOccupancyCoastalGraphDataLoaded,
        setIsAnnualOccupancyCoastalGraphDataLoaded,
    ] = useState(false);

    const [
        annualOccupancyUrbanisationGraphData,
        setAnnualOccupancyUrbanisationGraphData,
    ] = useState(null);
    const [
        isAnnualOccupancyUrbanisationGraphDataLoaded,
        setIsAnnualOccupancyUrbanisationGraphDataLoaded,
    ] = useState(false);

    const [employedPersonsGraphData, setEmployedPersonsGraphData] = useState(null);
    const [isEmployedPersonsGraphDataLoaded, setIsEmployedPersonsGraphDataLoaded] = useState(false);

    const [generationOfWasteGraphData, setGenerationOfWasteGraphData] = useState(null);
    const [isGenerationOfWasteGraphDataLoaded, setIsGenerationOfWasteGraphDataLoaded] = useState(
        false
    );

    const [arrivalsMonthlyGraphData, setArrivalsMonthlyGraphData] = useState(null);
    const [isArrivalsMonthlyDataLoaded, setIsArrivalsMonthlyDataLoaded] = useState(
        false
    );
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [graphExpanded, setGraphExpanded] = React.useState(0);
    //0=line chart, 1=bar chart
    const [selectedChartType, setSelectedChartType] = useState(1);
    const [errorLoading, setErrorLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            var arrivalsResponseData = ArrivalsLineGraphData;
            var capacitiesResponseData = CapacitiesLineGraphData;
            var visitsResponseData = VisitsLineGraphData;
            var activeEnterprisesResponseData = ActiveEnterprisesLineGraphData;
            var activeEnterprisesEmployeesResponseData = ActiveEnterprisesEmployeesLineGraphData;
            var annualOccupancyCoastalResponseData = AnnualOccupancyCoastalLineGraphData;
            var annualOccupancyUrbanisationResponseData = AnnualOccupancyUrbanisationLineGraphData;
            var employedPersonsResponseData = EmployedPersonsLineGraphData;
            var generationOfWasteResponseData = GenerationOfWasteLineGraphData;
            var arrivalsMonthlyResponseData = ArrivalsMonthlyLineGraphData;
            var yearFrom = new Date().getFullYear() - 1;
            if (process.env.REACT_APP_IS_DATA_LIVE == 1) {
                const arrivalsResult = await eurostatApi.get(
                    `/arrivals/line/${selectedDestination.nuts.nutS2}/0/9999`
                )
                .then((response) => {
                    arrivalsResponseData = response.data;
                    setArrivalsGraphData(arrivalsResponseData);
                    setIsArrivalsDataLoaded(true);
                    return response.data;
                })
                .catch((error) => setErrorLoading(true));

                const capacitiesResult = await eurostatApi.get(
                    `/available_capacities/line/${selectedDestination.nuts.nutS2}/0/9999`
                )
                .then((response) => {capacitiesResponseData = response.data; return response.data;})
                .catch((error) => setErrorLoading(true));

                const visitsResult = await eurostatApi.get(
                    `/same_day_visits/line/${selectedDestination.nuts.country}/0/9999`
                )
                .then((response) => {visitsResponseData = response.data; return response.data;})
                .catch((error) => setErrorLoading(true));

                const activeEnterprisesResult = await eurostatApi.get(
                    `/active_enterprises/line/${selectedDestination.nuts.country}/0/9999`
                )
                .then((response) => {activeEnterprisesResponseData = response.data; return response.data;})
                .catch((error) => setErrorLoading(true));

                const activeEnterprisesEmployeesResult = await eurostatApi.get(
                    `/active_enterprises_employees/line/${selectedDestination.nuts.country}/0/9999`
                )
                .then((response) => {activeEnterprisesEmployeesResponseData = response.data; return response.data;})
                .catch((error) => setErrorLoading(true));

                const annualOccupancyCoastalResult = await eurostatApi.get(
                    `/annual_occupancy_coastal/line/${selectedDestination.nuts.nutS2}/0/9999`
                )
                .then((response) => {annualOccupancyCoastalResponseData = response.data; return response.data;})
                .catch((error) => setErrorLoading(true));

                const annualOccupancyUrbanisationResult = await eurostatApi.get(
                    `/annual_occupancy_urbanisation/line/${selectedDestination.nuts.nutS2}/0/9999`
                )
                .then((response) => {annualOccupancyUrbanisationResponseData = response.data; return response.data;})
                .catch((error) => setErrorLoading(true));

                const employedPersonsResult = await eurostatApi.get(
                    `/employed_persons/line/${selectedDestination.nuts.country}/0/9999`
                )
                .then((response) => {employedPersonsResponseData = response.data; return response.data;})
                .catch((error) => setErrorLoading(true));

                const generationOfWasteResult = await eurostatApi.get(
                    `/generation_of_waste/line/${selectedDestination.nuts.country}/0/9999`
                )
                .then((response) => {generationOfWasteResponseData = response.data; return response.data;})
                .catch((error) => setErrorLoading(true));

                const arrivalsMonthlyResult = await eurostatApi.get(
                    `/arrivals_monthly/line/${selectedDestination.nuts.country}/${new Date().getFullYear() - 2}/9999`
                )
                .then((response) => {arrivalsMonthlyResponseData = response.data; return response.data;})
                .catch((error) => setErrorLoading(true));
            }
            else {
                console.log("offline, setting dummy data")
                setArrivalsGraphData(arrivalsResponseData);
                setIsArrivalsDataLoaded(true);
            }
            
            setCapacitiesGraphData(capacitiesResponseData);
            setIsCapacitiesDataLoaded(true);
            setVisitsGraphData(visitsResponseData);
            setIsVisitsDataLoaded(true);
            setActiveEnterprisesGraphData(activeEnterprisesResponseData);
            setIsActiveEnterprisesDataLoaded(true);
            setActiveEnterprisesEmployeesGraphData(activeEnterprisesEmployeesResponseData);
            setIsActiveEnterprisesEmployeesDataLoaded(true);
            setAnnualOccupancyCoastalGraphData(annualOccupancyCoastalResponseData);
            setIsAnnualOccupancyCoastalGraphDataLoaded(true);
            setAnnualOccupancyUrbanisationGraphData(annualOccupancyUrbanisationResponseData);
            setIsAnnualOccupancyUrbanisationGraphDataLoaded(true);
            setEmployedPersonsGraphData(employedPersonsResponseData);
            setIsEmployedPersonsGraphDataLoaded(true);
            setGenerationOfWasteGraphData(generationOfWasteResponseData);
            setIsGenerationOfWasteGraphDataLoaded(true);
            setArrivalsMonthlyGraphData(arrivalsMonthlyResponseData);
            setIsArrivalsMonthlyDataLoaded(true);

        };
        fetchData();
    }, [selectedDestination]);



    const handleClickListItem = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChartTypeButtonClick = index => {
        setSelectedChartType(index);
        handleClose();
    };
    const toggleGraph = () => {
        setGraphExpanded(!graphExpanded);
    };
    const options = [
        t("destinations.card.arrivalsAccomodations"),
        t("destinations.card.arrivalsAccomodationsMonthly"),
        t("destinations.card.sameDayVisits"),
        t("destinations.card.annualOccupancyCoastal"),
        t("destinations.card.annualOccupancyUrbanisation"),
        t("destinations.card.availableCapacities"),
        t("destinations.card.employedPersons"),
        t("destinations.card.activeEnterprises"),
        t("destinations.card.activeEnterprisesEmployees"),
        t("destinations.card.generationOfWaste")

    ];
    var eurostatRegionName;
    var currentGraphData;
    if (isArrivalsDataLoaded) {
        if (selectedIndex === 0) {
            currentGraphData = arrivalsGraphData;
            eurostatRegionName = arrivalsGraphData.nuts.label;
        }
        else if (selectedIndex === 1) {
            var data = arrivalsMonthlyGraphData;
            var counter = 0;
            for (var prop in data.dimensions.time.values) {
                if (data.dimensions.time.values.hasOwnProperty(prop)) {
                    data.value[counter].name = prop.toString();
                }
                counter++;
            }
            currentGraphData = data;
            eurostatRegionName = arrivalsMonthlyGraphData.nuts.label;
        } else if (selectedIndex === 2) {
            currentGraphData = visitsGraphData;
            eurostatRegionName = visitsGraphData.nuts.label;
        } else if (selectedIndex === 3) {
            currentGraphData = annualOccupancyCoastalGraphData;
            eurostatRegionName = annualOccupancyCoastalGraphData.nuts.label;
        } else if (selectedIndex === 4) {
            currentGraphData = annualOccupancyUrbanisationGraphData;
            eurostatRegionName = annualOccupancyUrbanisationGraphData.nuts.label;
        } else if (selectedIndex === 5) {
            currentGraphData = capacitiesGraphData;
            eurostatRegionName = capacitiesGraphData.nuts.label;
        } else if (selectedIndex === 6) {
            currentGraphData = employedPersonsGraphData;
            eurostatRegionName = employedPersonsGraphData.nuts.label;
        } else if (selectedIndex === 7) {
            currentGraphData = activeEnterprisesGraphData;
            eurostatRegionName = activeEnterprisesGraphData.nuts.label;
        } else if (selectedIndex === 8) {
            currentGraphData = activeEnterprisesEmployeesGraphData;
            eurostatRegionName = activeEnterprisesEmployeesGraphData.nuts.label;
        } else if (selectedIndex === 9) {
            currentGraphData = generationOfWasteGraphData;
            eurostatRegionName = generationOfWasteGraphData.nuts.label;
        }
        eurostatRegionName = currentGraphData.nuts.label
        setCurrentDataset(currentGraphData);
    }



    var currentChartType;
    if (selectedChartType === 0) {
        currentChartType = (
            <DestinationCardLineGraph
                data={currentGraphData}
                className={classes.graph}
                selectedDestination={selectedDestination}
            />
        );
    } else if (selectedChartType === 1) {
        currentChartType = (
            <DestinationCardBarGraph
                data={currentGraphData}
                className={classes.graph}
                selectedDestination={selectedDestination}
            />
        );
    }
    if (isArrivalsDataLoaded) {
        return (
            <div style={{ paddingTop: '16px' }}>
                <Grid container>
                    <Grid item xs={12} alignItems="center" className={classes.graphTitleBar}>
                        <Grid container className={classes.graphTitle} alignItems="center">
                            <Grid item xs>
                                <Typography align="justify" style={{ display: 'inline-block', marginLeft: '96px', marginTop: '12px', marginBottom: '12px', textAlign: 'center' }}>
                                    {options[selectedIndex] + " (" + eurostatRegionName + ")"}
                                </Typography>

                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "right" }}>
                                <span>
                                    <IconButton
                                        style={{ display: 'inline-block' }}
                                        onClick={handleDialogOpen}
                                        className={classes.graphSettingsButton}
                                    >
                                        <HelpOutlineRoundedIcon />
                                    </IconButton>
                                    <IconButton
                                        style={{ display: 'inline-block' }}
                                        onClick={handleClickListItem}
                                        className={classes.graphSettingsButton}
                                    >
                                        <TuneRoundedIcon />
                                    </IconButton>
                                </span>
                            </Grid>

                        </Grid>


                    </Grid>
                </Grid>
                <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <div className={classes.buttonGroupWrapper}>
                        <Tooltip title={t("destinations.card.chartTypeLine")}>
                            <Button
                                className={[
                                    classes.buttonGroupButton,
                                    selectedChartType === 0
                                        ? classes.buttonGroupButtonSelected
                                        : "",
                                ]}
                                onClick={() => handleChartTypeButtonClick(0)}
                            >
                                <TimelineIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t("destinations.card.chartTypeBar")}>
                            <Button
                                className={[
                                    classes.buttonGroupButton,
                                    selectedChartType === 1
                                        ? classes.buttonGroupButtonSelected
                                        : "",
                                ]}
                                onClick={() => handleChartTypeButtonClick(1)}
                            >
                                <BarChartIcon />
                            </Button>
                        </Tooltip>
                    </div>
                    <Divider />
                    {options.map((option, index) => (
                        <MenuItem
                            classes={{ root: classes.menuItem }}
                            key={option}
                            selected={index === selectedIndex}
                            onClick={event => handleMenuItemClick(event, index)}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Menu>
                <div className={classes.graphWrapper} style={{ height: `${graphHeight}px` }}>{currentChartType}</div>
                <br />
            </div>
        );
    } else {
        if(errorLoading){
            return (
                <Typography style={{fontSize: "12px", textAlign:"center", marginTop: "100px"}}>{t("destinations.detailsPage.graphInfo.noData")}</Typography>
            );
        }
        else{
            return (<div style={{ textAlign: "center", marginTop: "40px" }}><CircularProgress /></div>);
        }
    }
}

export default DestinationDetailsGraphHolder;
