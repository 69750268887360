import React, { useContext, useEffect, useState } from "react";
import { AllCulturalRoutes } from "../../data/Routes/AllCulturalRoutes";
import { Container, Grid, Typography, Button, IconButton, makeStyles, Select, MenuItem, FormControl, Tooltip } from "@material-ui/core";
import { columnsTotalWidthSelector, DataGrid } from "@material-ui/data-grid";
import { Link, useHistory } from "react-router-dom";
import { routesApi } from "../../services/api/axios";
import { AllHtmlEntities } from "html-entities";
import { useTranslation } from "react-i18next";
import { usersApi } from "../../services/api/axios.js";
import CheckIcon from '@material-ui/icons/Check';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AuthContext from "../../context/auth/AuthContext";
import { whoAmI, getToken, registerUser } from "../User/Auth";
import AlertConfirm from "../Dialogs/AlertConfirm";
import AlertDelete from "../Dialogs/AlertDelete";
import ApplicationsContext from "../../context/UserManagement/ApplicationsContext";

const useStyles = makeStyles(theme => ({
    deleteButton: {
        backgroundColor: 'transparent',
        color: 'var(--coral-red)',
        "&:hover": {
            backgroundColor: 'white',
            color: 'var(--valencia-red)'
        }
    },
    approveButton: {
        backgroundColor: 'transparent',
        color: 'var(--green)',
        "&:hover": {
            backgroundColor: 'white',
            color: 'var(--green-opacity80)'
        }
    },
    roleInput: {
        "& .MuiFilledInput-input": {
            padding: '12px 8px 10px'
        }
    }
}));

function AdminDashboard() {
    const { t } = useTranslation();
    let history = useHistory();
    const classes = useStyles();
    const Auth = useContext(AuthContext);
    const Applications = useContext(ApplicationsContext);

    const [applications, setApplications] = useState([]);
    const [approvedUsers, setApprovedUsers] = useState([]);
    const [deleteApplicationModelOpen, setDeleteApplicationModelOpen] = useState(false);
    const [confirmApplicationModelOpen, setConfirmApplicationModelOpen] = useState(false);
    const [currentApplication, setCurrentApplication] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
                var currentUserIdToken = "";
                const currentUser = whoAmI();
                if (currentUser) {
                    const idToken = await getToken();
                    if (idToken) {
                        currentUserIdToken = idToken;
                    }
                }
                const applicationsResult = await usersApi.get(`/applications`, {
                    headers: { Authorization: "Bearer " + currentUserIdToken },
                });
                if (applicationsResult.data) {
                    setApplications(applicationsResult.data);
                }
                const approvedUsersResult = await usersApi.get(`/approved`, {
                    headers: { Authorization: "Bearer " + currentUserIdToken },
                });
                if (approvedUsersResult.data) {
                    setApprovedUsers(approvedUsersResult.data);
                }
            }
        }
        return fetchUsers();
    }, [])
    const handleApplicationsCellClick = params => {
        if (params.field === "approve") {
            setConfirmApplicationModelOpen(true);
            setCurrentApplication({ email: params.row.email, adminEmail: Auth.user.email })
            //approveUser(params.row.email, Auth.user.email);
        }
        else if (params.field === "delete") {
            setDeleteApplicationModelOpen(true);
            setCurrentApplication({ email: params.row.email, adminEmail: Auth.user.email })
            //deleteUserApplication(params.row.email, Auth.user.email);
        }
    };

    const handleApprovedCellClick = params => {
        if (params.field === "delete") {
            deleteUserApproved(params.row.email, Auth.user.email);
        }
        else if (params.field === "role") {
            handleRoleChangeApplication(Auth.user.email, params.role);
        }
    };

    const approveUserMicroservice = async (email, adminEmail, currentUserIdToken) => {
        var currentUserIdToken = "";
        const currentUser = whoAmI();
        if (currentUser) {
            const idToken = await getToken();
            if (idToken) {
                currentUserIdToken = idToken;
            }
        }
        const result = await usersApi.put(`/${email}/approve/${adminEmail}`, {}, {
            headers: { Authorization: "Bearer " + currentUserIdToken },
        });
        if (result.status == 200) {
            Applications.setApplications(--Applications.applications);
            var user = applications.find((user) => user.email === email);
            var newApproved = approvedUsers.slice();
            newApproved.push(user);
            setApprovedUsers(newApproved);
            var applicationsUsers = applications.slice();
            var filtered = applicationsUsers.filter((value) => value.email !== email);
            setApplications(filtered);
        }
    }

    const approveUser = async (email, adminEmail) => {
        if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
            registerUser(email)
                .then(() => {
                    approveUserMicroservice(email, adminEmail);
                })
        }
    }
    const deleteUserApproved = async (email, adminEmail) => {
        /*if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
            const result = await usersApi.delete(`/${email}/${adminEmail}`);
            if (result.status == 200) {
                var filtered = approvedUsers.filter((value) => value.email !== email);
                setApprovedUsers(filtered);
            }
        }*/
    }
    const deleteUserApplication = async (email, adminEmail) => {
        if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
            var currentUserIdToken = "";
            var currentUser = whoAmI();
            if (currentUser) {
                const idToken = await getToken();
                if (idToken) {
                    currentUserIdToken = idToken;
                }
            }

            const result = await usersApi.delete(`/${email}/${adminEmail}`, {
                headers: { Authorization: "Bearer " + currentUserIdToken },
            });
            if (result.status == 200) {
                Applications.setApplications(--Applications.applications);
                var filtered = applications.filter((value) => value.email !== email);
                setApplications(filtered);
            }
        }
    }
    const handleRoleChangeApplication = (email, role) => {
        if (process.env.REACT_APP_IS_LOGIN_REAL == 1) {
            console.log(role);
            var userInedex = applications.slice().findIndex((user => user.email == 1));
            /*if (currentUser) {
                const idToken = await getToken();
                if (idToken) {
                    currentUserIdToken = idToken;
                }
            }
            const result = await usersApi.delete(`/${email}/${adminEmail}`, {
                headers: { Authorization: "Bearer " + currentUserIdToken },
            });
            if (result.status == 200) {
                var filtered = applications.filter((value) => value.email !== email);
                setApplications(filtered);
            }*/
        }
    }

    const handleApproveApplicationModal = () => {
        approveUser(currentApplication.email, currentApplication.adminEmail);
        setConfirmApplicationModelOpen(false);
    }
    const handleDeleteApplicationModal = () => {
        deleteUserApplication(currentApplication.email, currentApplication.adminEmail);
        setDeleteApplicationModelOpen(false);
    }
    const applicationsTableColumns = [
        {
            field: "firstName",
            headerName: "First name",
            width: 200,
        },
        {
            field: "lastName",
            headerName: "Last name",
            width: 200,
        },
        {
            field: "email",
            headerName: "Email",
            width: 300,
        },
        {
            field: "institution",
            headerName: "Institution",
            width: 200,
            renderCell: (params) => (
                <Tooltip title={params.row.institution} placement="bottom">
                    <span className="table-cell-trucate">{params.row.institution}</span>
                </Tooltip>
            ),
        },
        {
            field: "culturalRoute",
            headerName: "Cultural Route",
            flex: 1,
            renderCell: (params) => (
                <Tooltip title={params.row.culturalRoute} placement="bottom">
                    <span className="table-cell-trucate">{params.row.culturalRoute}</span>
                </Tooltip>
            ),
        },
        {
            field: 'approve',
            headerName: `Approve`,
            width: 120,
            renderCell: params => (
                <IconButton className={classes.approveButton} size="medium">
                    <CheckIcon fontSize="small" />
                </IconButton>
            ),
        },
        {
            field: 'delete',
            headerName: `Delete`,
            width: 100,
            renderCell: params => (
                <IconButton className={classes.deleteButton} size="medium">
                    <DeleteOutlineIcon fontSize="small" />
                </IconButton>
            ),
        },
    ];
    const approvedTableColumns = [
        {
            field: "firstName",
            headerName: "First name",
            width: 200,
        },
        {
            field: "lastName",
            headerName: "Last name",
            width: 200,
        },
        {
            field: "email",
            headerName: "email",
            width: 300,
        },
        {
            field: "institution",
            headerName: "Institution",
            width: 200,
            renderCell: (params) => (
                <Tooltip title={params.row.institution} placement="bottom">
                    <span className="table-cell-trucate">{params.row.institution}</span>
                </Tooltip>
            ),
        },
        {
            field: "culturalRoute",
            headerName: "Cultural Route",
            flex: 1,
            renderCell: (params) => (
                <Tooltip title={params.row.culturalRoute} placement="bottom">
                    <span className="table-cell-trucate">{params.row.culturalRoute}</span>
                </Tooltip>
            ),
        },
        {
            field: "role",
            headerName: "Role",
            width: 150,
        },
        /*{
            field: 'delete',
            headerName: `Delete user`,
            width: 200,
            renderCell: params => (
                <div>
                    <IconButton className={classes.deleteButton} size="medium">
                        <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                </div>
            ),
        },*/
    ];

    return (
        <div className="dashboard-container">
            <Container maxWidth="xl">
                <Grid container alignItems="flex-end">
                    <Grid item xs={12} sm={9}>
                        <Typography
                            variant="h5"
                            color="textSecondary"
                            className="title"
                            component="h2"
                        >
                            {t("userManagement.applications")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <Grid item xs={12}>
                        <DataGrid
                            rows={applications}
                            columns={applicationsTableColumns}
                            autoPageSize
                            disableSelectionOnClick
                            disableColumnMenu
                            className="table"
                            onCellClick={params => handleApplicationsCellClick(params)}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-end" >
                    <Grid item xs={12} sm={9}>
                        <Typography
                            variant="h5"
                            color="textSecondary"
                            className="title"
                            component="h2"
                        >
                            {t("userManagement.approved")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="table-container">
                    <Grid item xs={12}>
                        <DataGrid
                            rows={approvedUsers}
                            columns={approvedTableColumns}
                            autoPageSize
                            disableSelectionOnClick
                            disableColumnMenu
                            className="table"
                        />
                    </Grid>
                    <AlertConfirm
                        confirmAction={handleApproveApplicationModal}
                        modalOpen={confirmApplicationModelOpen}
                        modalClose={modalClose => setConfirmApplicationModelOpen(modalClose)}
                        textTitle={t("userManagement.actionDialog.approveTitle")}
                        textDescription={t("userManagement.actionDialog.approveDescription")}
                        textConfirm={t("userManagement.actionDialog.approveText")}
                        textCancel={t("userManagement.actionDialog.cancel")}
                    />
                    <AlertDelete
                        confirmAction={handleDeleteApplicationModal}
                        modalOpen={deleteApplicationModelOpen}
                        modalClose={modalClose => setDeleteApplicationModelOpen(modalClose)}
                        textTitle={t("userManagement.actionDialog.deleteTitle")}
                        textDescription={t("userManagement.actionDialog.deleteDescription")}
                        textDelete={t("userManagement.actionDialog.deleteText")}
                        textCancel={t("userManagement.actionDialog.cancel")}
                    />
                </Grid>
            </Container>
        </div>
    );
}

export default AdminDashboard;
