import { check } from "prettier";
import { CSVLink } from "react-csv";

const ExportCSV = ({ saqData, type, routeName, assFor, graphData }) => {

    var csv = generateCSV(saqData, type, routeName, assFor, graphData);

    return (
        <CSVLink
            data={csv}
            filename={`${routeName}_${assFor}_${type}.csv`}
            target="_blank"
            style={{ color: "inherit" }}
        >
            {type}
        </CSVLink>
    );
};

export const generateCSV = (saqData, type, routeName, assFor, graphData) => {
    const {
        Parser,
        transforms: { unwind },
    } = require("json2csv");

    const fieldsStructure = [
        {
            label: "ID",
            value: "id",
        },
        {
            label: "Title",
            value: "title",
        },
        {
            label: "Question type",
            value: "type",
        },
        {
            label: "required",
            value: "required",
        }
    ];

    const fieldsContent = [
        {
            label: "ID",
            value: "sections.references.questions.questionId",
        },
        {
            label: "answer",
            value: "sections.references.questions.value",
        }
    ];
    const transformStructure = (item) => {
        if (item.sections.title) {
            return [
                { id: item.sections.title.substring(0, 1), title: item.sections.title.substring(3) },
                { id: item.sections.references.questions.category.substring(0, 3), title: item.sections.references.questions.category.substring(4) },
                { id: item.sections.references.title.substring(0, 5), title: item.sections.references.title.substring(6) },
                { id: item.sections.references.questions.criteria.substring(0, 7), title: item.sections.references.questions.criteria.substring(8) },
                {
                    id: item.sections.references.questions.questionId,
                    title: item.sections.references.questions.check,
                    type: item.sections.references.questions.checkType === "LONG_TEXT" ? "TEXT" : item.sections.references.questions.checkType,
                    required: item.sections.references.questions.required ? "required" : ""
                }
            ];
        }
        else {
            var showCriteria = item.sections.references.questions.questionId.charAt(8) === "1";
            var rows = []
            if (showCriteria) {
                rows.push({ id: item.sections.references.questions.criteria.substring(0, 7), title: item.sections.references.questions.criteria.substring(8) });
            }
            rows.push(
                {
                    id: item.sections.references.questions.questionId,
                    title: item.sections.references.questions.check,
                    type: item.sections.references.questions.checkType === "LONG_TEXT" ? "TEXT" : item.sections.references.questions.checkType,
                    required: item.sections.references.questions.required ? "required" : ""
                }
            );
            return rows;
        }

    }
    const doNothing = (item) => {
        return item;
    }

    const transforms = [
        unwind({
            paths: ["sections", "sections.references", "sections.references.questions"],
            blankOut: true,
        }),
        type === "Structure" ? transformStructure : doNothing
    ];

    var checkType = type === "Structure" ? fieldsStructure : fieldsContent;

    const json2csvParser = new Parser({
        fields: checkType,
        transforms: transforms,
        encoding: "utf8",
        delimiter: ";",
    });
    return json2csvParser.parse(saqData);
}

export const csvToJson = (csv) => {
    var csv = csv.replaceAll("\"", "");
    var lines = csv.split("\n");

    var result = [];
    var headers = lines[0].split(";");
    for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(";");
        for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
        }
        result.push(obj);
    }
    return JSON.stringify(result);
}

export default ExportCSV;
