export const DestinationsNutsLabelsEn = [
    {
        ME: "Montenegro",
        BE: "Belgium",
        BG: "Bulgaria",
        CZ: "Czechia",
        DK: "Denmark",
        DE: "Germany",
        EE: "Estonia",
        IE: "Ireland",
        EL: "Greece",
        ES: "Spain",
        FR: "France",
        HR: "Croatia",
        IT: "Italy",
        CY: "Cyprus",
        LV: "Latvia",
        LT: "Lithuania",
        LU: "Luxembourg",
        HU: "Hungary",
        MT: "Malta",
        NL: "Netherlands",
        AT: "Austria",
        PL: "Poland",
        PT: "Portugal",
        RO: "Romania",
        SI: "Slovenia",
        SK: "Slovakia",
        FI: "Finland",
        SE: "Sweden",
        UK: "United Kingdom",
        IS: "Iceland",
        NO: "Norway",
        CH: "Switzerland",
        MK: "North Macedonia",
        RS: "Serbia",
        BA: "Bosnia and Herzegovina",
        XK: "Kosovo",

        ES6: "South Spain",
        ES5: "East Spain",
        CY0: "Cyprus",
        HR0: "Croatia",
        ITI: "Central Italy",
        EL4: "Aegean Islands, Crete",
        SI0: "Slovenia",

        SI03: "Eastern Slovenia",
        SI04: "Western Slovenia",
        ES61: "Andalucia",
        ES51: "Catalonia",
        CY00: "Cyprus",
        HR03: "Istria",
        ITI1: "Toscana",
        ES53: "Balearic Islands",
        ES52: "Valencian Community",
        ITI4: "Lazio",
        EL41: "North Aegean",
    },
];

