import IconWww from "../../assets/img/icon_www.svg";
import IconTwitter from "../../assets/img/icon_twitter.svg";
import IconLinkedIn from "../../assets/img/icon_linkedin.svg";

export const SocialMediaItems = [
    {
        id: "website",
        title: "BEST MED WebSite",
        path: "https://best-med.interreg-med.eu/",
        icon: IconWww,
    },
    {
        id: "twitter",
        title: "BEST MED Twitter",
        path: "https://twitter.com/BestMedProject",
        icon: IconTwitter,
    },
    {
        id: "linkedin",
        title: "BEST MED LinkedIn",
        path: "http://www.linkedin.com/in/bestmed-project/",
        icon: IconLinkedIn,
    },
];
